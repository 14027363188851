import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { warningMessage } from '../../../../utils/messageHelper/messageHelper';

const SliderPreview = ({ attachment, imgSlider, handleImgSlider }) => {
  const deleteImage = (imageDelete) => {
    const filteredImages = imgSlider.filter((img) => img !== imageDelete);
    handleImgSlider(filteredImages);
    warningMessage('Presione guardar para conservar los cambios');
  };
  return (
    <div className="div-image-prev">
      <img src={attachment} alt="Adjuntos" className="image-prev" />
      <Button
        icon={<DeleteOutlined />}
        size="small"
        className="btn-image-prev"
        onClick={() => deleteImage(attachment)}
      />
    </div>
  );
};

export default SliderPreview;
