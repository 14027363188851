import { Upload, Button } from 'antd';
import { useState } from 'react';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';
import {
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';

const CreateAdditionalDocs = ({ offer, setReloadData, goBack }) => {
  const [attachmentId, setAttachmentId] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const authorization = getLocalStorage('token');
  const props = {
    name: 'attachment',
    action: `${process.env.REACT_APP_API_URL}/attachments/private`,
    withCredentials: true,
    headers: { authorization },
  };

  const handleTakeImage = (info) => {
    const fileName = info?.file?.name ?? '';
    const fileStatus = info?.file?.status;

    if (fileStatus === 'done') {
      const nameWithoutExtension = fileName.replace(/\.[^.]+$/, '');
      setDocumentName(nameWithoutExtension);
      setAttachmentId(info?.file?.response?._id);
    } else if (fileStatus === 'error') {
      errorMessage(`${fileName} error al cargar el archivo`);
    }
  };

  const updateAttachment = async () => {
    try {
      setIsLoadingBtn(true);
      const payload = { ...offer };
      const attachment = {
        _id: attachmentId,
        name: documentName,
      };

      payload.additionalDocumentation.push(attachment);
      await clientAxios.put(`/user/offers/${offer?._id}`, payload);
      goBack();
      successMessage('Archivo subido correctamente.');
    } catch (error) {
      errorMessage('Hubo un error a la hora de subir el archivo.');
    } finally {
      setReloadData(true);
      setIsLoadingBtn(false);
    }
  };

  return (
    <>
      <span className="d-block">Adjuntar Comprobante/Archivo</span>
      <div className="mb-3">
        <Upload
          key="upload"
          {...props}
          accept="image/*,.pdf,.doc,.docx"
          maxCount={1}
          onChange={handleTakeImage}
        >
          <Button>Seleccionar Archivo</Button>
        </Upload>
      </div>
      <Button
        type="primary"
        loading={isLoadingBtn}
        onClick={() => {
          updateAttachment();
        }}
      >
        Subir
      </Button>
    </>
  );
};

export default CreateAdditionalDocs;
