import React, { useState } from 'react';
import { personalDataStructure } from 'utils/investorProfileHelper/initialValuesForms';
import {
  Input,
  Form,
  Button,
  DatePicker,
  Switch,
  Space,
  InputNumber,
  message,
} from 'antd';
import {
  successModal,
  warningModal,
} from 'utils/modalMessageHelper/modalMessage';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import UploadImages from 'components/investor-room/components/uploadImages/UploadImages';
import ModalAttachment from '../../../components/modalAttachment/ModalAttachment';
import styles from '../investorProfile.module.css';
import { datePickerFormat } from 'utils/datePickerFormat/datePickerFormat';
import { twoStyles } from 'utils/twoStyles/twoStyles';
import { setLocalStorage } from 'utils/localStorageHelper/localHelper';

const PersonalDataForm = ({
  imageUrl,
  setIsSendingDataForm,
  isModalPassport,
  handleModalPassport,
  handleEditSuccess,
  handleTakeImages,
  isSendingDataForm,
  userData,
}) => {
  const [user, setUser] = useState(userData);
  const [form] = Form.useForm();

  const MAX_AGE = 18;
  const LAST_HOUR = 23;
  const LAST_MINUTES = 59;

  const onChange = (value) => {  
    const currentYear = new Date().getFullYear();
    const birthDay = new Date(value);
    const limitDate = new Date();
    limitDate.setFullYear(currentYear - MAX_AGE);
    limitDate.setHours(LAST_HOUR);
    limitDate.setMinutes(LAST_MINUTES);
    if (birthDay > limitDate) {
      form.setFieldsValue({ birthday: null });
      message.warning('Debes tener mas de 18 años para realizar esta accion');
    };
  };

  const handleOnFinish = async (values) => {
    if (imageUrl !== '') {
      setUser(values);
      setIsSendingDataForm(true);
      const dataToSend = {
        ...values,
        passport: {
          url: imageUrl,
          isAproved: userData.passport.isAproved,
          toValidate: true,
        },
      };
      try {
        const respond = await clientAxios.put(`/user`, dataToSend);
        const dataUser = respond.data.user;
        setLocalStorage("user", dataUser);
        
        if (!userData.passport.isAproved) {
          successModal(
            <div>
              <h5>Se actualizo correctamente.</h5>
              <p>Aguarde la validacion de su Documento Identidad, podra verlo desde:</p>
              <ul>
                <li>Mi perfil del inversor</li>
                <li>Novedades</li>
                <li>Correo electronico</li>
              </ul>
            </div>,
            '',
            handleEditSuccess(),
          );
        } else {
          successModal(
            <div>
              <h5>Se actualizo correctamente los datos.</h5>
            </div>,
            '',
            handleEditSuccess(),
          );
        }
      } catch (error) {
        errorMessage('error al actualizar los datos.');
      } finally {
        setIsSendingDataForm(false);
      }
    } else {
      warningModal(
        'Para continuar con la actualizacion de los datos por favor adjunte el Documento Identidad.'
      );
    }
  };
  return (
    <Form
      form={form}
      className={styles.wrapper}
      layout="vertical"
      onFinish={handleOnFinish}
      initialValues={personalDataStructure(userData)}
    >
      <div>
        <Form.Item
          label="Nombre"
          name="firstName"
          className={styles.formItem}
          rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
        >
          <Input
            className={!user?.firstName ? styles.inputWarning : styles.inputOk}
          />
        </Form.Item>
        {!user?.firstName && (
          <p className={styles.warningMessage}>Por favor complete el campo</p>
        )}
        <Form.Item
          label="Apellido"
          name="lastName"
          className={styles.formItem}
          rules={[{ required: true, message: 'Por favor ingrese su apellido' }]}
        >
          <Input
            className={!user?.lastName ? styles.inputWarning : styles.inputOk}
          />
        </Form.Item>
        {!user?.lastName && (
          <p className={styles.warningMessage}>Por favor complete el campo</p>
        )}
        <Form.Item
          label="Fecha de nacimiento"
          name="birthday"
          className={styles.formItem}
        >
          <DatePicker
            format={datePickerFormat()}
            onChange={(value) => onChange(value)}
            className="prefix-width-modal input-size select-item datePicker-border-color"
          />
        </Form.Item>
        <Form.Item label="Mail" name="email" className={styles.formItem}>
          <Input
            autoComplete="autocomplete_off"
            className="prefix-width-modal readOnly"
          />
        </Form.Item>
        <Form.Item
          label="DNI / Pasaporte / ID Empresa"
          name="dni"
          className={styles.formItem}
          rules={[{ required: true, message: 'Por favor ingrese su DNI / Pasaporte / ID Empresa' }]}
        >
          <Input className={
              !user?.dni
                ? twoStyles(styles.inputWarning, styles.inputNumber)
                : twoStyles(styles.inputOk, styles.inputNumber)
            } />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Celular"
          name="phoneNumber"
          className={styles.formItem}
        >
          <InputNumber
            className={
              !user?.phoneNumber
                ? twoStyles(styles.inputWarning, styles.inputNumber)
                : twoStyles(styles.inputOk, styles.inputNumber)
            }
          />
        </Form.Item>
        {!user?.phoneNumber && (
          <p className={styles.warningMessage}>Por favor complete el campo</p>
        )}
        <Form.Item label="Domicilio" name="address" className={styles.formItem}>
          <Input
            className={!user?.address ? styles.inputWarning : styles.inputOk}
          />
        </Form.Item>
        {!user?.address && (
          <p className={styles.warningMessage}>Por favor complete el campo</p>
        )}
        <Form.Item
          label="CP"
          name="zipCode"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese su codigo postal',
            },
          ]}
        >
          <InputNumber
            className={
              !user?.zipCode
                ? twoStyles(styles.inputWarning, styles.inputNumber)
                : twoStyles(styles.inputOk, styles.inputNumber)
            }
          />
        </Form.Item>
        <Form.Item
          label="Pais"
          name="country"
          rules={[{ required: true, message: 'Por favor ingrese su pais' }]}
        >
          <Input
            className={!user?.country ? styles.inputWarning : styles.inputOk}
          />
        </Form.Item>
      </div>
      <div>
        <ModalAttachment
          open={isModalPassport}
          handleCancel={handleModalPassport}
          attachmentId={userData?.passport?.url}
        />
        <Form.Item label="Documento Identidad" name="passport">
          <Space direction="vertical">
            <Switch
              className={
                userData?.passport?.isAproved
                  ? styles.switchColorSelect
                  : styles.switchColorNotSelect
              }
              checkedChildren="Validado"
              unCheckedChildren="No validado"
              checked={userData?.passport?.isAproved}
            />
            <Button
              type="primary"
              disabled={!userData?.passport?.url ? true : false}
              ghost
              className={styles.btnConfirm}
              onClick={() => handleModalPassport()}
            >
              Ver Documento Identidad
            </Button>
            <UploadImages
              nameButton="Subir Documento Identidad"
              takeImages={handleTakeImages}
              disabled={userData?.passport?.isAproved}
            />
          </Space>
        </Form.Item>
        <Button
          loading={isSendingDataForm}
          type="primary"
          htmlType="submit"
          className={styles.btnConfirm}
        >
          Actualizar datos personales
        </Button>
      </div>
    </Form>
  );
};

export default PersonalDataForm;
