import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Banks from './Banks';
import FormBank from './FormBank';

const BanksRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/editarBanco/:id`} component={FormBank} />
      <Route path={`${path}/agregarBanco`} component={FormBank} />
      <Route path={path} component={Banks} />
    </Switch>
  );
};

export default BanksRoutes;
