import { Table } from 'antd';

const TableReportUsers = ({ dataSourse, tableHeadings, rowKey, isLoading }) => {
  return (
    <Table
      size="small"
      columns={tableHeadings}
      dataSource={dataSourse}
      loading={isLoading}
      rowKey={rowKey}
      pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
      scroll={{ x: 1000 }}
    />
  );
};

export default TableReportUsers;
