import { Row, Col } from 'antd';
import style from '../../styles/homePage/sectionshome.module.css';
import icon1 from '../../img/icons/Grupo123.svg';
import icon2 from '../../img/icons/Grupo124.svg';
import icon3 from '../../img/icons/Grupo127.svg';
import icon4 from '../../img/icons/Grupo125.svg';

const Section5Home = () => {
  return (
    <div className={style.containerSection5} id="section5">
      <Row className={style.row1section5}>
        <Col lg={12}>
          <Row className={style.colsm}>
            <Col lg={6} md={4}>
              <div className={style.divicons}>
                <img src={icon1} alt="" className={style.svgS5} />
              </div>
            </Col>
            <Col lg={18} md={20}>
              <h4 className={style.titleSection5}>
                OBTENGA RENDIMIENTOS PASIVOS <br />
                DEL 10% AL 20% ANUAL
              </h4>
              <p className={style.textSection5}>
                Usted se beneficia a través de estructuras de inversión
                <br />
                profesionales, que tienen como objetivo rendimientos
                <br />
                potenciales en un rango del 10% al 20% anual.              
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} sm={24}>
          <Row className={style.colsm}>
            <Col lg={6} md={4}>
              <div className={style.divicons}>
                <img src={icon2} alt="" className={style.svgS5} />
              </div>
            </Col>
            <Col lg={18} md={20}>
              <h4 className={style.titleSection5}>
                DE CALIDAD <br />
                INSTITUCIONAL
              </h4>
              <p className={style.textSection5}>
                Totallia realiza una preselección de las inversiones
                <br />
                a través de nuestro proceso de suscripción y due diligence.
                <br />
                Nos asociamos con sponsors y carteras de inversión
                <br />
                de reputación y trayectoria, buscando además una tasa
                <br />
                de rendimiento preferida
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={24}>
          <Row className={style.colsm}>
            <Col lg={6} md={4}>
              <div className={style.divicons}>
                <img src={icon3} alt="" className={style.svgS5} />
              </div>
            </Col>
            <Col lg={18} md={20}>
              <h4 className={style.titleSection5}>
                SEGUIMIENTO <br />
                DE INVERSIONES
              </h4>
              <p className={style.textSection5}>
                Totallia se encargará de toda la contabilidad y<br />
                administración de su inversión, mientras usted
                <br />
                puede monitorear los retornos.
              </p>
            </Col>
          </Row>
        </Col>

        <Col lg={12} md={12} sm={24}>
          <Row className={style.colsm}>
            <Col lg={6} md={4}>
              <div className={style.divicons}>
                <img src={icon4} alt="" className={style.svgS5} />
              </div>
            </Col>
            <Col lg={18} md={20}>
              <h4 className={style.titleSection5}>
                DIVERSIFICACIÓN <br />
                DE PORTAFOLIOS
              </h4>
              <p className={style.textSection5}>
                Dado que las inversiones inmobiliarias suelen generar
                <br />
                ingresos de flujo de efectivo, mientras que las acciones
                <br />
                ordinarias no lo hacen, las valoraciones de los inmuebles
                <br />
                tienden a ser menos volátiles y menos sensibles a los
                <br />
                factores de riesgo del mercado
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Section5Home;
