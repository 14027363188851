import { Modal, Row, Col, Divider } from 'antd';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';
import { dateFormat } from '../../../utils/dateFormatHelper/dateFormat';
import icon from '../../../img/icons/Grupo106.svg';

const ModalDetailUser = ({
  handleToggleDetailModal,
  toggleDetailModal,
  dataModal,
}) => {
  return (
    <Modal
      visible={toggleDetailModal}
      onCancel={handleToggleDetailModal}
      width={800}
      footer={null}
    >
      <Divider
        orientation="left"
        style={{ fontWeight: 'bold', color: '#e75649' }}
      >
        {' '}
        Datos Personales
      </Divider>
      <Row>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Nombre: </span>
            {dataModal?.firstName}
          </p>
          <p>
            <span>Email: </span>
            {dataModal?.email}
          </p>
          <p>
            <span>Rol: </span>
            {dataModal?.role}
          </p>
          <p>
            <span>DNI: </span>-
          </p>
          <p>
            <span>Domicilio: </span>-
          </p>
          <p>
            <span>CP: </span>-
          </p>
        </Col>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Apellido: </span>
            {dataModal?.lastName}
          </p>
          <p>
            <span>Teléfono: </span>
            {dataModal?.phoneNumber}
          </p>
          <p>
            <span>País: </span>-
          </p>
          <p>
            <span>Newsletter: </span>
            {dataModal?.newsletter ? 'Si' : 'No'}
          </p>
          <p>
            <span>Documento Identidad: </span>
            {dataModal?.passport?.isAproved ? 'Si' : 'No'}
          </p>
          <p>
            <span>Foto de Documento Identidad: </span>-
          </p>
        </Col>
      </Row>
      <Divider
        orientation="left"
        style={{ fontWeight: 'bold', color: '#e75649' }}
      >
        {' '}
        Datos de la cuenta
      </Divider>
      <Row>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Fecha de Registro: </span>
            {dateFormat(dataModal?.createdAt)}
          </p>
          <p>
            <span>Ultimo Acceso: </span>-
          </p>
        </Col>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Fecha aprobación de inversión: </span>-
          </p>
          <p>
            <span>Inversiones Activas: </span>
            {dataModal?.investments?.length}
          </p>
        </Col>
      </Row>
      <Divider
        orientation="left"
        style={{ fontWeight: 'bold', color: '#e75649' }}
      >
        {' '}
        Datos Bancarios
      </Divider>
      <Row>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Nombre entidad bancaria: </span>-
          </p>
          <p>
            <span>Dirección entidad bancaria: </span>-
          </p>
          <p>
            <span>Beneficiario: </span>-
          </p>
          <p>
            <span>Número de ruta/ABA: </span>-
          </p>
        </Col>
        <Col span={12} className={styles.detailModal}>
          <p>
            <span>Código Swift: </span>-
          </p>
          <p>
            <span>Número de Cuenta: </span>-
          </p>
          <p>
            <span>Nota de financiación: </span>-
          </p>
          <p>
            <span>Observaciones: </span>-
          </p>
        </Col>
      </Row>
      <Divider
        orientation="left"
        style={{ fontWeight: 'bold', color: '#e75649' }}
      >
        {' '}
        Documentación
      </Divider>
      <Row>
        <Col span={24} className={styles.detailModal}>
          <img src={icon} alt="" className={styles.icon} />
        </Col>
      </Row>
    </Modal>
  );
};
export default ModalDetailUser;
