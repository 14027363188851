import { Form, Row, Col, Space, Button, Upload, Image } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import TextEditor from 'components/Admin/TextEditor/TextEditor';
import { uploadProps } from './UploadProps';

const FormParagraph = ({ articleEdit }) => {
  const propsUpload = { ...uploadProps, multiple: true };
  const body = articleEdit?.body || [];
  return (
    <Form.Item label="Cuerpo del artículo">
      <Form.List name="body">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row key={key}>
                <Space align="baseline">
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'paragraph']}
                      fieldKey={[fieldKey, 'paragraph']}
                    >
                      <TextEditor
                        label={'Párrafo:'}
                        initialValue={
                          Object.keys(articleEdit)?.length
                            ? body[fieldKey]?.paragraph
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
                <Col span={24}>
                  <Form.Item
                    {...restField}
                    name={[name, 'image']}
                    fieldKey={[fieldKey, 'image']}
                  >
                    <Upload {...propsUpload}>
                      <Button icon={<UploadOutlined />}>Subir imágenes</Button>
                    </Upload>
                  </Form.Item>
                  <Row>
                    {Object.keys(articleEdit)?.length
                      ? body[fieldKey]?.image?.map((src) => (
                          <div style={{ marginLeft: '5px' }}>
                            <Image width={60} height={60} src={src} />
                          </div>
                        ))
                      : null}
                  </Row>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                className="select-item"
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
export default FormParagraph;
