import styles from '../../styles/sliderHome.module.css';
import { useState, useEffect } from 'react';
import { LastInvestment, LastNews, ItemSlider } from './SliderItems';
import { LoadingOutlined } from '@ant-design/icons';
import { Carousel } from 'antd';

const Slider = ({
  user,
  handleToggleRegister,
  settings,
  isLoading,
  lastInvestment,
  lastNews,
}) => {
  const { sliderHome } = settings;
  const [isMounted, setIsMounted] = useState(null);

  useEffect(() => sliderHome && setIsMounted(true), [sliderHome]);

  if (isLoading && !sliderHome)
    return <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div>
      <Carousel
        autoplay
        autoplaySpeed={5800}
        className={`${styles.containerSlider} carousel-home`}
        effect={'fade'}
        pauseOnHover={false}
      >
        {sliderHome?.map(
          (img, index) =>
            index === 0 && (
              <ItemSlider
                key={img}
                img={img}
                user={user}
                handleToggleRegister={handleToggleRegister}
              />
            )
        )}
        {isMounted && <LastInvestment investment={lastInvestment} />}
        {isMounted && <LastNews news={lastNews} />}
      </Carousel>
    </div>
  );
};

export default Slider;
