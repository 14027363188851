import { Row, Col } from 'antd';
import style from '../../styles/homePage/sectionshome.module.css';
import icon1 from '../../img/icons/Grupo120.svg';
import icon2 from '../../img/icons/Grupo38.svg';
import icon3 from '../../img/icons/Grupo121.svg';

const Section3Home = () => {
  return (
    <Row className={style.container}>
      <Col lg={8} sm={24} className={style.columns}>
        <Row className={style.colsm}>
          <Col lg={24} md={24} sm={12}>
            <div className={style.icons}>
              <img src={icon1} alt="" className={style.svg} />
            </div>
          </Col>
          <Col lg={24} md={24} sm={12}>
            <h4 className={style.texth4}>Ingresos</h4>
            <p className={style.text}>
              Año tras año, el sector inmobiliario ha <br />
              demostrado su capacidad para <br />
              generar flujos de ingresos superiores <br />a los inversores.
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={8} sm={24} className={style.columns}>
        <Row className={style.colsm}>
          <Col lg={24} md={24} sm={12}>
            <div className={style.icons}>
              <img src={icon2} alt="" className={style.svg} />
            </div>
          </Col>
          <Col lg={24} md={24} sm={12}>
            <h4 className={style.texth4}>Estabilidad</h4>
            <p className={style.text}>
              Mientras que otros tipos de <br />
              inversiones son volátiles, las <br />
              propiedades tienen la reputación de <br />
              mantenerse estables.
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={8} sm={24} className={style.columns}>
        <Row className={style.colsm}>
          <Col lg={24} md={24} sm={12}>
            <div className={style.icons}>
              <img src={icon3} alt="" className={style.svg} />
            </div>
          </Col>
          <Col lg={24} md={24} sm={12}>
            <h4 className={style.texth4}>
              Rentabilidad <br /> ajustada al riesgo
            </h4>
            <p className={style.text}>
              Gestionar el riesgo de su cartera no <br />
              tiene por qué significar sacrificar <br />
              retorno potencial.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Section3Home;
