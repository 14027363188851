import React from 'react';
import { Gauge } from '@ant-design/charts';

const GaugeGraph = ({responseDataReport}) => {
   const percent = responseDataReport?.percentageAchieved
  const config = {
    percent,
    range: { color: '#30BF78' },
    indicator: {
      pointer: { style: { stroke: '#D0D0D0' } },
      pin: { style: { stroke: '#D0D0D0' } },
    },
    axis: {
      label: {
        formatter: function formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: { count: 3 },
    },
    statistic: {
      content: {
        formatter: function formatter(_ref) {
          var percent = _ref.percent;
          return 'Rango: '.concat((percent * 100).toFixed(0), '%');
        },
        style: {
          color: 'rgba(0,0,0,0.65)',
          fontSize: 30,
        },
      },
    },
  };
  return <Gauge {...config} />;
};

export default GaugeGraph;
