import React, { useEffect, useState } from 'react';
import { Row, Typography } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { warningModalConfirm } from 'utils/modalMessageHelper/modalMessage';
import styles from './investNow.module.css';
import InvestNowLogo from 'components/investor-room/components/investNowLogo/InvestNowLogo';
import InvestNowTabs from './tabs/InvestNowTabs';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';

const InvestNow = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { Text } = Typography;

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const handleGetUser = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get('/user');
        const user = response.data;
        if (!user?.passport?.isAproved) {
          const handleRedirect = () => {
            history.push('/investorRoom/Profile');
          };
          warningModalConfirm(
            'Su Documento de Identidad no esta aprobado.',
            'Sera redirigido a su perfil para completar datos personales, o puede verificar el estado del mismo.',
            handleRedirect
          );
        }
        window.scrollTo(0, 0);
      } catch (error) {
        errorMessage('Tuvimos inconvenientes al traer los datos.');
      } finally {
        setIsLoading(false);
      }
    };
    handleGetUser();
  }, [history]);

  return (
    <LoadingInvestorRoom isLoading={isLoading}>
      <Row align="middle" justify="center">
        <Text className={styles.title}>Invertí de manera rápida y facil</Text>
      </Row>
      <Row align="middle" justify="center">
        <InvestNowLogo />
      </Row>
      <Row align="middle" justify="center">
        <InvestNowTabs idInvestment={id} />
      </Row>
    </LoadingInvestorRoom>
  );
};

export default InvestNow;
