import {
  errorMessage,
  successMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { Button, Row, Col, Form } from 'antd';
import { useEffect, useRef, useState } from 'react';
import FormAttachments from './FormAttachments';

const Attachment = ({
  handleInvestment,
  singleInvestment,
  handleUpdateInvestmentAttachment,
}) => {
  const [attachment, setAttachment] = useState();
  const formAttachment = useRef();

  useEffect(() => {
    if (Object.entries(singleInvestment).length !== 0) {
      setAttachment(singleInvestment.attachments);
    }
  }, [singleInvestment]);

  const onFinish = (values) => {
    const arrayGallery = values?.galleryImg?.fileList;
    const arrayVideo = values?.videos?.fileList;
    const arrayDownload = values?.downloadFiles?.fileList;
    const arrayContract = values?.contracts?.fileList;
    const videos = arrayVideo?.map((v) => v.response) || [];
    const gallery = arrayGallery?.map((v) => v.response) || [];
    const download = arrayDownload?.map((v) => v.response) || [];
    const contracts = arrayContract?.map((v) => v.response) || [];

    const attachments = {
      backgroundImg:
        values?.backgroundImg?.file.response || attachment?.backgroundImg,
      featuredImg:
        values?.featuredImg?.file.response || attachment?.featuredImg,
      sponsorLogo:
        values?.sponsorLogo?.file.response || attachment?.sponsorLogo,
      termAndConditions:
        values?.termAndConditions?.file.response ||
        attachment?.termAndConditions,
      galleryImg: attachment ? [...attachment.galleryImg, ...gallery] : gallery,
      videos: attachment ? [...attachment.videos, ...videos] : videos,
      downloadFiles: attachment
        ? [...attachment.downloadFiles, ...download]
        : download,
      contracts: attachment
        ? [...attachment.contracts, ...contracts]
        : contracts,
    };

    handleInvestment({ attachments });
    successMessage('Datos Guardados');
    window.scrollTo(0, 0);
  };

  const onFinishFailed = () => {
    errorMessage('Hubo un error al guardar los archivos');
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h4 className="title-dataPublish">Adjuntos</h4>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            ref={formAttachment}
            labelCol={{ span: 8 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <FormAttachments
              singleInvestment={singleInvestment}
              handleUpdateInvestmentAttachment={
                handleUpdateInvestmentAttachment
              }
            />
            <div className="center-container">
              <Button type="primary" htmlType="submit" className="btn-save">
                Guardar formulario
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default Attachment;
