import { Row } from 'antd';
import style from '../../styles/Aprender/aprender.module.css';
import img from '../../img/aprender_1.JPG';
import ArticleCard from './ArticleCard';
import LoadingComponent from '../Loader/LoadingComponent';
import { useState, useEffect } from 'react';
import clientAxios from 'config/clientAxios';
import { warningMessage } from 'utils/messageHelper/messageHelper';

const AllArticles = () => {
  const [dataArticle, setDataArticle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/articlesPosted');
        const articles = res.data;
        setDataArticle(articles);
        setIsLoading(false);
      } catch (error) {
        warningMessage('Los artículos no están disponibles');
      }
    };
    fetchArticles();
  }, []);
  return (
    <>
      <div className={style.container}>
        <img src={img} alt="edificio" className={style.imgEdificio} />
        <h3 className={style.title}>Artículos</h3>
      </div>
      <Row className='mt-5'>
        <LoadingComponent isLoading={isLoading}>
          <Row className={style.divRowCards}>
            {dataArticle.map((article) => (
                <ArticleCard article={article} key={article._id} />
            ))}
          </Row>
        </LoadingComponent>
      </Row>
    </>
  );
};

export default AllArticles;
