import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import {
  errorMessage,
  successMessage,
} from '../../../utils/messageHelper/messageHelper';
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import clientAxios from '../../../config/clientAxios';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';

const FormEditeUser = () => {
  const [dataUser, setDataUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/users/${id}`);
        setDataUser(res.data[0]);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Los datos del usuario no están disponibles');
      }
      setIsLoading();
    };
    getUser();
  }, [id]);

  const editUser = async (values) => {
    try {
      await clientAxios.put(`/users/${id}`, values);
      window.scrollTo(0, 0);
      successMessage('El usuario se editó exitosamente');
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un problema al editar el usuario');
    }
  };

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 24 }} spin />;
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <h4 className="bg-title-nuevaInversion">Editar usuario</h4>
      <Row>
        <Col span={24}>
          <Form
            name="formEditUser"
            className={styles.formEditUser}
            initialValues={dataUser}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={editUser}
          >
            <Form.Item
              name="firstName"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese su nombre',
                },
              ]}
            >
              <Input placeholder="Nombre" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Apellido"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese su apellido',
                },
              ]}
            >
              <Input placeholder="Apellido" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Teléfono">
              <Input placeholder="Teléfono" className="inputs-logRegister " />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese un email válido',
                },
              ]}
            >
              <Input placeholder="Email" className="inputs-logRegister" />
            </Form.Item>
            <Row>
              <Col span={24} offset={7} className={styles.colBtnsEditUser}>
                <Button
                  type="primary"
                  htmlType="button"
                  className={styles.btnCancel}
                  onClick={() => history.goBack()}
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-register"
                >
                  Editar usuario
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default FormEditeUser;
