export const setSessionStorage = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const removeSessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};

export const removeAllSessionStorage = () => {
  return sessionStorage.clear();
};
