import { Table, Button, Select, Popover, Popconfirm, Tag } from 'antd';
import clientAxios from '../../../config/clientAxios';
import {
  EditOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';
import {
  errorMessage,
  successMessage,
} from '../../../utils/messageHelper/messageHelper';
const { Option } = Select;

const TableUsers = ({
  filters,
  dataUsers,
  setDataUsers,
  handleToggleDetailModal,
  setDataModal,
  isLoading,
}) => {
  const history = useHistory();
  let { path } = useRouteMatch();

  const handleDetailModal = (data) => {
    setDataModal(data);
    handleToggleDetailModal();
  };
  const handlechange = (value, uid) => {
    editRole(value, uid);
  };
  const handleEditUser = (id) => {
    history.push(`${path}/editarUsuario/${id}`);
    window.scrollTo(0, 0);
  };
  const editRole = async (value, uid) => {
    try {
      await clientAxios.put(`/users/${uid}`, { role: value });
      window.scrollTo(0, 0);
      successMessage('Se cambió el rol del usuario exitosamente');
      const editArray = dataUsers.map((user) =>
        user.uid === uid ? { ...user, role: value } : user
      );
      setDataUsers(editArray);
    } catch (error) {
      errorMessage('Hubo un problema al cambiar el rol');
    }
  };
  const deleteUser = async (uid) => {
    try {
      await clientAxios.delete(`/users/${uid}`);
      window.scrollTo(0, 0);
      successMessage('Usuario eliminado exitosamente');
      const filterArray = dataUsers.filter((user) => user.uid !== uid);
      setDataUsers(filterArray);
    } catch (error) {
      errorMessage('Hubo un error al eliminar el usuario');
    }
  };

  const popover = (
    <div className={styles.rowDirection}>
      <QuestionCircleOutlined style={{ color: 'gold' }} />
      <p className={styles.pPopover}>
        Si cambia el rol será de forma permanente ¿Está seguro?
      </p>
    </div>
  );

  const columns = [
    {
      title: 'Nombre',
      key: 'firstName',
      render: (data) => {
        return (
          <Button
            type="text"
            onClick={() => handleDetailModal(data)}
            className={styles.btnName}
          >
            {data.firstName}
          </Button>
        );
      },
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
      rowKey: 'uid',
      render: (key, rowKey) => {
        return key === 'Contacto' ? (
          <Popover content={popover} trigger="click">
            <Select
              defaultValue={key}
              style={{ width: 120 }}
              bordered={false}
              onChange={(value) => handlechange(value, rowKey.uid)}
            >
              <Option value="Lead">Lead</Option>
            </Select>
          </Popover>
        ) : (
          <p>{key}</p>
        );
      },
    },
    {
      title: 'Proyecto',
      key: 'proyect',
      render: (data) => {
        return data?.investments?.map((investment) => (
          <Tag color="#034078" key={investment?._id}>
            {investment?.name}
          </Tag>
        ));
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'uid',
      key: 'uid',
      render: (key, data) => {
        return (
          <>
            <Button
              style={{ border: 'none' }}
              size="small"
              onClick={() => handleDetailModal(data)}
              icon={<InfoCircleOutlined />}
            />
            <Button
              style={{ border: 'none' }}
              size="small"
              onClick={() => handleEditUser(key)}
              icon={<EditOutlined />}
            />
            <Popconfirm
              title="¿Seguro desea eliminar el usuario?"
              onConfirm={() => deleteUser(key)}
              okText="Si"
              cancelText="No"
            >
              <Button
                style={{ border: 'none' }}
                size="small"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="uid"
      loading={isLoading}
      dataSource={filters}
      columns={columns}
      pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
      scroll={{ x: 1000}}
    />
  );
};
export default TableUsers;
