import { Row, Col } from 'antd';
import LogoFooter from '../img/logoFooter.png';
import style from '../styles/footer.module.css';

const Footer = () => {
  return (
    <div>
      <Row className={style.container}>
        <Col lg={10} className={style.colLogo}>
          <img src={LogoFooter} alt="logo" className={style.logo} />
          <p className={style.followNet}>Seguinos en nuestras redes sociales</p>
          <div className={style.divIcons}>
            <a
              href="https://www.facebook.com/totallia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/totalliacapital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com/totallia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/totallia-capital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCw_JgL1NKgeV4i12Ptr3cyQ/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </Col>
        <Col
          lg={3}
          md={6}
          sm={6}
          className={`${style.colFooter} ${style.divInvestment}`}
        >
          <h6 className={style.title}>Inversiones</h6>
          <hr className={style.hr} />
          <a href="/investments#foundProyect">Cartera</a>
          <a href="/investment#foundProyect">Proyectos individuales</a>
        </Col>
        <Col lg={3} md={6} sm={6} className={`${style.colFooter} ${style.divAprender}`}>
          <h6 className={style.title}>Noticias</h6>
          <hr className={style.hr} />
          <a href="/aprender?tabKey=1">Articulos</a>
          <a href="/aprender?tabKey=2">Wiki</a>
          <a href="/aprender?tabKey=3">Newsletter</a>
          <a href="/aprender?tabKey=4">Videos-Canal</a>
          <a href="/aprender?tabKey=5">FAQs</a>
        </Col>
        <Col
          lg={3}
          md={6}
          sm={6}
          className={`${style.divNosotros} ${style.colFooter}`}
        >
          <h6 className={style.title}>Nosotros</h6>
          <hr className={style.hr} />
          <a href="/aboutUs#sectionMision">Misión</a>
          <a href="/aboutUs#sectionTeam">El equipo</a>
        </Col>
        <Col
          lg={3}
          md={6}
          sm={6}
          className={`${style.colFooter} ${style.divContacto}`}
        >
          <h6 className={style.title}>Contacto</h6>
          <hr className={style.hr} />
          <div>
            <i className="fab fa-whatsapp"></i>
            <a href="https://wa.me/3815986532" className={style.icons}>
              Chateá con un representante
            </a>
          </div>
          <div>
            <i className="far fa-calendar-alt"></i>
            <a
              href="https://calendar.google.com/calendar/selfsched?sstoken=UU05V0VQVklhRkVYfGRlZmF1bHR8YjcyNGRkZDEwNmQwMzg2NWJlMzBmYTI5ZjMyN2IxMzA"
              className={style.icons}
            >
              Agendá una reunión
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Footer;
