import { useState } from 'react';
import { Modal, Button, Popconfirm } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
const AttachmentModal = ({
  attachmentModalStatus,
  toggleAttachmentModal,
  attachment,
  attachmentURL,
  deleteAttachmentFromOffer,
}) => {
  const [numPages, setNumPages] = useState(null);
  const pageNumber = 1;

  const getURLFileExtension = (url) => {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      title={null}
      visible={attachmentModalStatus}
      onCancel={toggleAttachmentModal}
      style={{ textAlign: 'center' }}
      footer={null}
      width={getURLFileExtension(attachmentURL) === 'pdf' ? 700 : 600}
      height={400}
    >
      {getURLFileExtension(attachmentURL) === 'pdf' ? (
        <>
          <Document
            file={{ url: attachmentURL }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Página {pageNumber} de {numPages} -{' '}
            <a href={attachmentURL} rel="noreferrer" target="_blank">
              Descargar <DownloadOutlined />
            </a>
          </p>

          {attachment?.isAproved ? null : (
            <Popconfirm
              title="¿Seguro que desea borrar este adjunto?"
              okText="Si"
              cancelText="No"
              onConfirm={() => deleteAttachmentFromOffer(attachment)}
            >
              <Button
                type="primary"
                style={{ background: 'red', border: 'none', margin: 3 }}
              >
                Borrar
              </Button>
            </Popconfirm>
          )}
        </>
      ) : attachmentURL ? (
        <>
          <img style={{ width: '100%' }} src={attachmentURL} alt="attachment" />
          {attachment?.isAproved ? null : (
            <Popconfirm
              title="¿Seguro que desea borrar este adjunto?"
              okText="Si"
              cancelText="No"
              onConfirm={() => deleteAttachmentFromOffer(attachment)}
            >
              <Button
                type="primary"
                style={{ background: 'red', border: 'none', margin: 3 }}
              >
                Borrar
              </Button>
            </Popconfirm>
          )}
        </>
      ) : (
        <h2>No se subió ningun archivo</h2>
      )}
    </Modal>
  );
};

export default AttachmentModal;
