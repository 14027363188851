import React from 'react';
import { Form, Button, Space } from 'antd';
import ModalImages from '../modalImages/ModalImages';
import styles from 'components/investor-room/pages/investorProfile/investorProfile.module.css';
import BankForm from './BankForm';
import BankUsaForm from './BankUsaForm';

const DataBankForm = ({
  handleModalinstructionsURL,
  isModalInstructionsURL,
  userData,
}) => {
  return (
    <Form
      layout="vertical"
      className={styles.wrapper}
      initialValues={userData.bankType === "USA" ? userData.bankUSA : userData.bank}
    >
       {
        userData.bankType === "USA" ? (
          <BankUsaForm/>
        ):(
          <BankForm/>
        )
      }
      <div>
        <Form.Item label="Instrucciones bancarias" name="instructionsURL">
          <Space direction="vertical">
            <Button
              type="primary"
              disabled={!userData?.bank?.instructionsURL ? true : false}
              ghost
              className={styles.btnConfirm}
              onClick={() => handleModalinstructionsURL()}
            >
              Ver instrucciones bancarias
            </Button>
          </Space>
        </Form.Item>
        <ModalImages
          isModalPassport={isModalInstructionsURL}
          handleCancel={handleModalinstructionsURL}
          imageKey={userData?.bank?.instructionsURL}
        />
      </div>
    </Form>
  );
};

export default DataBankForm;
