import { Modal } from 'antd';
import { useState } from 'react';
import styles from 'styles/contactPage/contactPage.module.css';
import Contact from './Contact';
import InfoToInvest from './InfoToInvest';
import TrackRecords from './TrackRecords';
import ReceiveInfo from './ReceiveInfo';
import Footer from '../Footer';
import TheNavbar from '../TheNavbar';
import ContactSuccess from './Exito/ContactSuccess';

const Investments = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <Contact
        isMessageSent={isMessageSent}
        setIsMessageSent={setIsMessageSent}
      />
      <InfoToInvest />
      <TrackRecords />
      <ReceiveInfo />
      <Footer />
      <Modal
        className={styles.modalContactSuccess}
        visible={isMessageSent}
        onCancel={() => setIsMessageSent(false)}
        style={{ top: 10 }}
        footer={false}
        width={1000}
      >
        <ContactSuccess handleClose={() => setIsMessageSent(false)} />
      </Modal>
    </>
  );
};

export default Investments;
