import moment from 'moment';

export const personalDataStructure = (userData) => ({
  firstName: userData.firstName,
  lastName: userData.lastName,
  email: userData?.email,
  birthday: userData?.birthday && moment(userData?.birthday),
  phoneNumber: userData?.phoneNumber,
  dni: userData?.dni,
  address: userData?.address,
  zipCode: userData?.zipCode,
  country: userData?.country,
});

export const bankDataStructure = (userData) => ({
  bankEntity: userData?.bank?.bankEntity,
  addressBankEntity: userData?.bank?.addressBankEntity,
  beneficiaryName: userData?.bank?.beneficiaryName,
  accountNumber: userData?.bank?.accountNumber,
  ABA: userData?.bank?.ABA,
  SWIFT: userData?.bank?.SWIFT,
});
