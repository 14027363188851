import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Input, Button } from 'antd';
import { useEffect, useState } from 'react';
import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import { handleToggleColumns } from '../../../../utils/toggleColumnsHelper/toggleColumnsHelper';
import { tableColumns } from './table-Structure/ToValidateTableStructure';
import ToggleColumnsModal from '../../toggleColumnsModal/ToggleColumnsModal';
import ModalPassport from '../ModalPassport/ModalPassport';
import clientAxios from '../../../../config/clientAxios';
import GenericTable from '../../reusableTable/GenericTable';
import notificationPostHelper from 'utils/notificationPostHelper/notificationPostHelper';

const { Search } = Input;
const ToValidateTable = ({ dataUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalPassportImg, setModalPassportImg] = useState('');
  const [isModalPassport, setisModalPassport] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [toValidateInvestors, setToValidateInvestors] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [tableHeadings, setTableHeadings] = useState([]);
  const [investorPreference, setInvestorPreference] = useState([]);

  const history = useHistory();
  let { path } = useRouteMatch();

  const tableName = 'toValidateTable';

  useEffect(() => {
    setInvestorPreference(getLocalStorage(`${dataUser?.uid}${tableName}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(
          '/users?passport.isAproved=false&passport.toValidate=true'
        );
        setToValidateInvestors(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage('Los datos no estan disponibles');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleUserTogglePreference = () => {
      if (investorPreference) {
        const togglePreferenceOpenSave = tableColumns(
          toggleModal,
          handleInvestorProfile
        ).map((column) => ({
          ...column,
          enable: investorPreference[column.dataIndex],
        }));
        setTableHeadings(togglePreferenceOpenSave);
      } else {
        setTableHeadings(
          tableColumns(
            toggleModal,
            handleInvestorProfile,
            fetchPassport,
            handlePassportStatus
          )
        );
      }
    };
    handleUserTogglePreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorPreference]);

  const handleSearch = (value) => {
    setSearchInput(value);
  };

  const handlePassportStatus = async (uid, status, _id) => {
    try {
      await clientAxios.post(`/users/${uid}/passport`, {
        status,
      });
      const msg = status
        ? 'Tu perfil ha sido aprobado'
        : 'Tu perfil ha sido rechazado';

      notificationPostHelper(msg, _id);
      setToValidateInvestors(() => {
        toValidateInvestors.filter((investor) => investor.uid !== uid);
      });
    } catch (error) {
      errorMessage('No se pudo actualizar el estado del Documento Identidad');
    }
  };

  const fetchPassport = async (id) => {
    try {
      const response = await clientAxios.get(`/attachments/private/${id}`);
      setModalPassportImg(response.data.url);
    } catch (error) {
      errorMessage('No se pudo cargar imagen de Documento Identidad');
    }
  };

  const handleInvestorProfile = (uid) => {
    history.push(`${path}/perfil-del-Inversor/${uid}`);
    window.scrollTo(0, 0);
  };

  const toggleModal = () => {
    setisModalPassport((state) => !state);
  };

  const handleFilterByName = () => {
    if (searchInput === '') return toValidateInvestors;
    if (searchInput) {
      return toValidateInvestors.filter((investor) => {
        const investorFullname = investor.firstName + ' ' + investor.lastName;
        return investorFullname
          ?.toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    }
  };

  const handleToggleModal = () => setIsModalVisible((state) => !state);

  return (
    <div>
      <Row>
        <Col span={24}>
          <Button
            onClick={handleToggleModal}
            className="btn-closeInv-filter input-size"
          >
            <i className="fas fa-cog me-2"></i>Tablas
          </Button>
          <Search
            enterButton
            placeholder="Buscar"
            onSearch={handleSearch}
            className="btn-closeInv-filter input-size search-border-color"
            style={{ width: 200 }}
          />
          <ToggleColumnsModal
            isModalVisible={isModalVisible}
            tableHeadings={tableHeadings}
            handleToggleModal={handleToggleModal}
            handleToggleColumns={handleToggleColumns(
              tableHeadings,
              setTableHeadings,
              dataUser,
              tableName
            )}
          />
          <ModalPassport
            isModalPassport={isModalPassport}
            handleCancel={toggleModal}
            passportImg={modalPassportImg}
          />
        </Col>
      </Row>
      <GenericTable
        handleFilterByName={handleFilterByName}
        tableHeadings={tableHeadings}
        isLoading={isLoading}
        rowKey="uid"
      />
    </div>
  );
};

export default ToValidateTable;
