import { Button, Popconfirm } from 'antd';
import { dateFormat } from '../../../../../utils/dateFormatHelper/dateFormat';

export const tableColumns = (
  toggleModal,
  handleInvestorProfile,
  fetchPassport,
  handlePassportStatus
) => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (key) => {
      const createdAtNewFormat = dateFormat(key);
      return createdAtNewFormat;
    },
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
    render: (key, data) => {
      return (
        <Button type="link" onClick={() => handleInvestorProfile(data.uid)}>
          {key}
        </Button>
      );
    },
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
  {
    title: 'Tel.',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    enable: true,
  },
  {
    title: 'Documento Identidad',
    dataIndex: 'passport',
    key: 'passport',
    enable: true,
    render: (key, data) => {
      return (
        <Button
          type="primary"
          onClick={() => {
            fetchPassport(data.passport.url);
            toggleModal();
          }}
        >
          Ver Documento Identidad
        </Button>
      );
    },
  },
  {
    title: 'Acciones',
    dataIndex: '_id',
    key: '_id',
    enable: true,
    render: (key, data) => (
      <>
        <Popconfirm
          title="¿Seguro que desea aprobar este Documento de Identidad?"
          onConfirm={() => handlePassportStatus(data.uid, true, data._id)}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            style={{ background: 'green', border: 'none', margin: 6 }}
          >
            Aprobar
          </Button>
        </Popconfirm>
        <Popconfirm
          title="¿Seguro que desea rechazar este Documento de Identidad?"
          onConfirm={() => handlePassportStatus(data.uid, false, data._id)}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            style={{ background: 'red', border: 'none', margin: 6 }}
          >
            Rechazar
          </Button>
        </Popconfirm>
      </>
    ),
  },
];
