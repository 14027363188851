import React from 'react';
import { Input, Form } from 'antd';

function BankForm() {
  return (
    <div>
      <Form.Item label="País" name="country">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Nombre del banco intermediario" name="bankName">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="SWIFT Code del banco intermediario" name="bankSwift">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Nombre del banco beneficiario" name="bankBeneficiaryName">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Dirección del banco beneficiario" name="bankBeneficiaryAddress">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="SWIFT Code del banco beneficiario" name="bankBeneficiarySwift">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Nº Cuenta del banco beneficiario / IBAN" name="accountNumber">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Titular de cuenta del banco beneficiario" name="bankBeneficiaryOwner">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Dirección del titular del banco beneficiario (calle, nro, codigo postal, ciudad, país)" name="bankBeneficiaryOwnerAddress">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Comentarios / aclaraciones / consultas" name="comments">
        <Input className="prefix-width-modal" />
      </Form.Item>
    </div>
  );
}

export default BankForm;