import { Col, Row, Input, Button } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import { handleToggleColumns } from '../../../../utils/toggleColumnsHelper/toggleColumnsHelper';
import { tableHeadingsTemplate } from './table-Structure/OpenInvestmentTableStructure';
import ModalInvesment from '../modal/ModalInvesment';
import ToggleColumnsModal from '../../toggleColumnsModal/ToggleColumnsModal';
import GenericTable from '../../reusableTable/GenericTable';

const { Search } = Input;

const OpenInvestment = ({ allDataInvesment, dataUser, isLoading }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [dataModal, setDataModal] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [filterInput, setFilterInput] = useState('');

  const tableName = 'openTable';

  const togglePreferenceOpen = getLocalStorage(`${dataUser?.uid}${tableName}`);

  const history = useHistory();
  let { path } = useRouteMatch();

  const handleInfoInvesmentModal = (data) => {
    setDataModal(data);
    setIsVisible((state) => !state);
  };

  const handleToggleModal = () => setIsModalVisible((state) => !state);

  useEffect(() => {
    const openInvesment = allDataInvesment.filter(
      (invesment) => invesment.isOpen === true
    ).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));

    setDataTable(openInvesment);
  }, [allDataInvesment]);

  useEffect(() => {
    if (togglePreferenceOpen) {
      const togglePreferenceOpenSave = tableHeadingsTemplate(
        handleInfoInvesmentModal,
        handleEditInvesment
      ).map((column) => ({
        ...column,
        enable: togglePreferenceOpen[column.dataIndex],
      }));
      setTableHeadings(togglePreferenceOpenSave);
    } else {
      setTableHeadings(
        tableHeadingsTemplate(handleInfoInvesmentModal, handleEditInvesment)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);

  const handleSearchInvesment = (value) => {
    setFilterInput(value);
  };

  const handleFilterByName = () => {
    if (filterInput === '') return dataTable;
    if (filterInput) {
      return dataTable.filter(({ name }) =>
        name?.toLowerCase().includes(filterInput.toLowerCase())
      );
    }
    if (filterInput) {
      return dataTable.filter(({ investmentType }) =>
        investmentType?.includes(filterInput)
      );
    }
  };
  const handleEditInvesment = (id) => {
    history.push(`${path}/editarInversion/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            onClick={handleToggleModal}
            className="btn-closeInv-filter input-size"
          >
            <i className="fas fa-cog me-2"></i>Tablas
          </Button>
          <ToggleColumnsModal
            isModalVisible={isModalVisible}
            tableHeadings={tableHeadings}
            handleToggleModal={handleToggleModal}
            handleToggleColumns={handleToggleColumns(
              tableHeadings,
              setTableHeadings,
              dataUser,
              tableName
            )}
          />
          <ModalInvesment
            dataModal={dataModal}
            isVisible={isVisible}
            handleEditInvesment={handleEditInvesment}
            handleInfoInvesmentModal={handleInfoInvesmentModal}
          />
          <Search
            enterButton
            placeholder="Buscar"
            onSearch={handleSearchInvesment}
            className="btn-closeInv-filter input-size search-border-color"
          />
        </Col>
      </Row>
      <GenericTable
        handleFilterByName={handleFilterByName}
        tableHeadings={tableHeadings}
        isLoading={isLoading}
        rowKey="_id"
      />
    </>
  );
};

export default OpenInvestment;
