import { Table } from 'antd';

const GenericTable = ({
  handleFilterByName,
  tableHeadings,
  rowKey,
  isLoading,
  totalData,
  withoutPagination,
}) => {
  const defaultPaginationData = {
    position: ['bottomCenter'],
    defaultPageSize: 10
  };
  return (
    <>
      <p className="btn-closeInv-filter">
        Total: {!totalData ? handleFilterByName()?.length : totalData}
      </p>
      <Table
        size='small'
        columns={tableHeadings.filter((th) => th.enable)}
        dataSource={handleFilterByName()}
        loading={isLoading}
        rowKey={rowKey}
        pagination={!withoutPagination ? defaultPaginationData : false}
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default GenericTable;
