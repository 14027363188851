import { Col, Row, Collapse } from 'antd';
import styles from './FAQ.module.css';
import './FAQ.css';
import FAQtable from '../../../img/FAQtable.png';
import { Link } from 'react-router-dom';

export default function FAQ() {
  const { Panel } = Collapse;
  const { container, faqContainer, title, subTitle, text, panel, whiteSpace } =
    styles;

  return (
    <Row id="FAQ" justify="center" align="middle" className={container}>
      <Col sm={16} className={faqContainer}>
        <Col span={24} className={panel}>
          <h4 className={title}>Las Propiedades Inmobiliarias Comerciales</h4>
        </Col>
        <Col span={24}>
          <Collapse accordion bordered={false}>
            <Panel
              className={subTitle}
              header="¿Qué son las propiedades inmobiliarias comerciales?"
              key="1"
            >
              <p className={text}>
                Las propiedades inmobiliarias comerciales están focalizadas en
                generar ganancias, tanto por ganancia de capital como por
                ingreso de renta por alquiler. Dentro de esta categoría
                encontramos los siguientes tipos de activos: Edificios de
                oficinas, complejos industriales, centros médicos, hoteles,
                centros comerciales, locales comerciales, campos, edificios
                comerciales, depósitos y estacionamientos.
                <br />
                Hasta hace muy poco un inversor individual no podía acceder a
                este tipo de inversiones. Solo eran accesibles a los grandes
                inversores institucionales. Las barreras de entrada eran muy
                grandes, ellos hacían los mejores negocios.
                <br />
                Nuestra misión es romper esa barrera y acercar este mundo a los
                inversores de una forma simple, práctica y económica.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cuáles son las principales ventajas de este tipo de inversiones?"
              key="2"
            >
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                className={text}
              >
                El real estate comercial es una de las clases de activos más
                dinámicas que existen. Se trata de la única clase de activo que
                produce rendimientos corrientes altos, una alta apreciación del
                capital, se puede apalancar para incrementar los rendimientos,
                tiene la seguridad de ser un activo duro que puedes ver y tocar
                y además brinda importantes beneficios fiscales.
                <br />
                En el siguiente cuadro podrás ver estas ventajas comparadas con
                otras clases de activos:
                <br />
                <img
                  src={FAQtable}
                  alt="table"
                  style={{
                    width: '60%',
                    height: 'auto',
                    marginTop: '20px',
                  }}
                />
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Quiénes eligen las propiedades inmobiliarias comerciales?"
              key="3"
            >
              <p className={text}>
                Todos los mayores y más sofisticados inversores del mundo
                destinan una parte de sus activos a invertir en propiedades
                inmobiliarias comerciales.
                <br />
                La Universidad de Yale, una de las más prestigiosas del mundo,
                destina un 22% de su capital total a invertir en propiedades
                comerciales. Durante los últimos 20 años logro un retorno
                promedio para toda la cartera del 14% anual.
                <br />
                Warren Buffett, el mayor inversor de todos los tiempos, es un
                activo inversor en propiedades comerciales. Siempre menciona que
                una de sus mejores inversiones fue la compra de un edificio de
                oficinas en Nueva York en el año 1993.
                <br />
                Bill Gates, la persona más rica del mundo, tiene una empresa
                dedicada 100% a este tipo de inversiones, Cascade Investments
                LLC. A principios de 2015 Gates invirtió U$ 200 millones en la
                compra de un hotel en Puerta Vallarta, México. Luego compro
                otros dos hoteles en Londres y Atlanta.
                <br />
                Futbolistas exitosos como Lionel Messi y Cristiano Ronaldo
                también eligen esta inversión para sus ahorros. A principios del
                año 2018 Messi compro su segundo hotel en España y Ronaldo su
                tercer hotel en Portugal.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className={panel}>
          <div className={whiteSpace}></div>
        </Col>
      </Col>

      <Col sm={16} className={faqContainer}>
        <Col span={24} className={panel}>
          <h4 className={title}>La Empresa</h4>
        </Col>
        <Col span={24}>
          <Collapse accordion bordered={false}>
            <Panel
              className={subTitle}
              header="¿Qué es Totallia y cómo funciona?"
              key="1"
            >
              <p className={text}>
                Totallia es una plataforma online para invertir en propiedades
                inmobiliarias comerciales. Conectamos a inversores que quieren
                invertir en real estate con empresas de real estate que buscan
                capital para lanzar sus proyectos.
                <br />
                A través de nuestra plataforma los inversores pueden conocer,
                estudiar y seleccionar las oportunidades más atractivas para su
                perfil de inversor.
                <br />
                Buscamos que los inversores individuales también puedan invertir
                en los mejores proyectos inmobiliarios del mundo.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Quiénes forman Totallia?"
              key="2"
            >
              <p className={text}>
                Totallia está formando por un equipo de profesionales, con
                experiencia en real estate, inversiones, finanzas y tecnología.
                Puede aprender más de nosotros haciendo clic
                <Link to="/aboutUs"> aquí</Link>.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Quién y cuándo fundo Totallia?"
              key="3"
            >
              <p className={text}>
                Totallia fue fundada en el año 2013 por Federico Tessore. Puede
                conocer más de Federico y cómo surgió el proyecto haciendo clic
                <Link to="/aboutUs"> aquí</Link>.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Dónde están ubicadas las oficinas de Totallia?"
              key="4"
            >
              <p className={text}>
                Tenemos una oficina ubicada en{' '}
                <address>
                  Buenos Aires, Argentina, en el barrio de Palermo, Gorriti 4949
                  Capital Federal, CP 1414.
                </address>{' '}
                Y otra oficina ubicada en{' '}
                <address>
                  Miami, Estados Unidos, 260 Crandon Blvd Ste 32.64, Key
                  Biscayne, 33149, USA.
                </address>{' '}
                El teléfono de Buenos Aires es 0054 11 4833 6387 y el de Estados
                Unidos es 001 786 600 1692.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cuánto dinero se invirtió a través de Totallia?"
              key="5"
            >
              <p className={text}>
                A través de nuestra plataforma se invirtieron más de U$ 20
                millones en diferentes proyectos. Además, seguro te interesen
                las siguientes cifras:
                <br />
                350: Los inversores que participaron de nuestros proyectos. U$ 5
                millones: El monto de los proyectos que vendimos con éxito. 12%
                al 45% TIR: La rentabilidad anual que logramos en los proyectos
                que vendimos. U$ 1,5 millones: El monto que distribuimos en
                dividendos corrientes a nuestros inversores.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className={panel}>
          <div className={whiteSpace}></div>
        </Col>
      </Col>

      <Col sm={16} className={faqContainer}>
        <Col span={24} className={panel}>
          <h4 className={title}>Comenzar a Invertir</h4>
        </Col>
        <Col span={24}>
          <Collapse accordion bordered={false}>
            <Panel
              className={subTitle}
              header="¿Cómo puedo comenzar a invertir?"
              key="1"
            >
              <p className={text}>
                Para comenzar a invertir tienes dos caminos. El primero es
                invertir a través del fondo de Totallia. A través de este fondo
                accedes a una cartera diversificada de propiedades comerciales
                manejadas directamente por el equipo de Totallia. De una forma
                simple y fácil comienzas a conocer este apasionante mundo. Para
                acceder a esta alternativa puedes hacer clic
                <Link to="/investments"> aquí</Link>.
                <br />
                El segundo camino es seleccionar vos mismo la o las propiedades
                que más te gustan, e invertir en forma directa en la misma.
                Dentro de la sección invertir puedes encontrar las oportunidades
                disponibles en este momento. Para acceder a esta alternativa
                puedes hacer clic
                <Link to="/investments"> aquí</Link>.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Quiénes invierten a través de Totallia?"
              key="2"
            >
              <p className={text}>
                Hay una gama muy amplia de inversores que usan Totallia para
                invertir sus ahorros. Desde inversores individuales de todo el
                mundo, hasta asesores financieros, grupos de inversión
                institucionales, empresas y familias.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cuál es el mínimo de inversión?"
              key="3"
            >
              <p className={text}>
                La mayoría de las oportunidades de inversión parten de los U$
                25.000 de inversión mínima. Pero algunos proyectos comienzan con
                mínimos más bajos, que pueden llegar a los U$ 10.000 y otros más
                altos, que parten de los U$ 50.000.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿A dónde transfiero mi dinero?"
              key="4"
            >
              <p className={text}>
                El dinero lo transferís al proyecto que elegís en forma directa.
                La mayoría de los proyectos están organizados mediante un
                fideicomiso o sociedad. Como inversor, te convertís en socio de
                esa entidad, que es la que compra la propiedad elegida.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className={panel}>
          <div className={whiteSpace}></div>
        </Col>
      </Col>

      <Col sm={16} className={faqContainer}>
        <Col span={24} className={panel}>
          <h4 className={title}>Cómo funcionan las inversiones</h4>
        </Col>
        <Col span={24}>
          <Collapse accordion bordered={false}>
            <Panel
              className={subTitle}
              header="¿Cómo me entero de las novedades de la inversión?"
              key="1"
            >
              <p className={text}>
                Todas las oportunidades de inversión emiten reportes
                trimestrales y algunos emiten en forma mensual. Todos los
                inversores de Totallia accederán a esos informes cada vez que se
                publiquen.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cómo obtengo ganancias de la inversión?"
              key="2"
            >
              <p className={text}>
                Hay dos caminos para obtener retornos. El primero es mediante
                las distribuciones corrientes, que pueden ser mensuales,
                trimestrales o anuales. Esas distribuciones vienen de los
                ingresos por alquiler que las propiedades generan.
                <br />
                El segundo retorno viene por la apreciación del capital. Este
                retorno se da cuando la propiedad logra incrementar su valor
                gracias a las reformas y renovaciones que se le hicieron o
                gracias a la mejora de la gestión de la misma. Este valor se
                puede captar al final del proyecto cuando la propiedad se vende
                a durante el proyecto cuando la propiedad se refinancia.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Es mi inversión líquida?"
              key="3"
            >
              <p className={text}>
                En general las inversiones no son líquidas, hay que esperar a
                que se venda el activo para recuperar la inversión. Pero puede
                haber oportunidades que sí tengan un grado de liquidez
                adicional, como el caso del Cartera Totallia que se puede salir
                una vez por año. Hay que analizar cada proyecto en particular
                para ver el grado de liquidez que tienen.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cuáles son los gastos de la inversión?"
              key="4"
            >
              <p className={text}>
                Los mismos dependen de cada oportunidad. Hay que revisar las
                condiciones de cada proyecto para tener en claro los gastos. En
                el caso del fondo el único gasto es un fee de administración del
                2% anual más un 15% de las ganancias que superen el 5% anual.
              </p>
            </Panel>
            <Panel
              className={subTitle}
              header="¿Cuáles son los beneficios de usar Totallia?"
              key="5"
            >
              <p className={text}>
                El principal beneficio es el acceso a proyectos inmobiliarios
                que solían ser de casi imposible acceso a los inversores
                individuales. Estamos hablando de proyectos en todo el mundo, de
                todas las clases de activos, llevados adelante por las empresas
                inmobiliarias más prestigiosas del mundo. 
                <br/>
                Además ese acceso se hace de una forma clara, simple y económica. Ya que los costos
                de acceder a estas alternativas de inversión privadas son 70%
                más económicos que acceder a alternativas similares que cotizan
                en la bolsa.
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className={panel}>
          <div className={whiteSpace}></div>
        </Col>
      </Col>
    </Row>
  );
}
