import { Route, Switch, useRouteMatch } from 'react-router-dom';
import QuickStartGuide from './QuickStartGuide';
import FormGuide from './FormGuide';

const QuickStartGuideRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/editarGuia/:id`} component={FormGuide}/>
      <Route path={`${path}/nuevaGuia`} component={FormGuide}/>
      <Route path={path} component={QuickStartGuide} />
    </Switch>
  );
};

export default QuickStartGuideRoutes;
