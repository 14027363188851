import { currentFormat } from "utils/currentFormatHelper/currentFormatHelper";

export const TableHeadingsBalance = [
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
  {
    title: 'Inversión',
    dataIndex: 'investments',
    key: 'investments',
    enable: true,
  },
  {
    title: 'Saldo en cuenta',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    enable: true,
    render: (key)=>currentFormat(key)
  },
];
