import React from 'react';
import { Form, Input, Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getSessionStorage,
  setSessionStorage,
} from 'utils/sessionStorageHelper/sessionHelper';
import styles from './formDocuSign.module.css';

const FormDocuSign = ({ onFinish }) => {
  const MAXLIMITFILDS = 4

  const history = useHistory();
  const returnPath = getSessionStorage('pointReturn');
  const validateMessages = {
    required: 'Ingrese el mail',
    types: {
      email: 'Email incorrecto!',
    },
  };

  const handleReturn = () => {
    setSessionStorage('nextTab', 'tab2');
    history.push(returnPath);
  };

  return (
    <div className={styles.formContainer}>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        validateMessages={validateMessages}
      >
        <Form.List name="signers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => {
                if (key <= MAXLIMITFILDS) {
                  return (
                    <Space
                      key={key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'completeName']}
                        label="Nombre completo"
                        fieldKey={[fieldKey, 'completeName']}
                        rules={[
                          {
                            required: true,
                            message: 'Ingrese el nombre completo',
                          },
                        ]}
                      >
                        <Input placeholder="Nombre completo" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'dni']}
                        label="DNI/ID"
                        fieldKey={[fieldKey, 'dni']}
                        rules={[
                          { required: true, message: 'Ingrese el DNI/ID' },
                        ]}
                      >
                        <Input placeholder="DNI/ID" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Email"
                        name={[name, 'mail']}
                        fieldKey={[fieldKey, 'mail']}
                        rules={[
                          {
                            required: true,
                            type: 'email',
                          },
                        ]}
                      >
                        <Input placeholder="Mail" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  );
                } else {
                  return null
                }
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  className={styles.btnConfirm}
                  style={{ width: '278px' }}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Agregar firmantes
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.btnConfirm}
          >
            Firmar el contrato
          </Button>
        </Form.Item>
        <Link to={returnPath}>
          <Button danger onClick={handleReturn} className={styles.btnConfirm}>
            Volver a la sección de firmas
          </Button>
        </Link>
      </Form>
    </div>
  );
};

export default FormDocuSign;
