import { Row, Col, Button, Form, Input, Checkbox, Spin, Popover } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clientAxios from '../../config/clientAxios';
import {
  setLocalStorage,
  getLocalStorage,
} from '../../utils/localStorageHelper/localHelper';
import {
  successMessage,
  errorMessage,
} from '../../utils/messageHelper/messageHelper';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { useState, useRef } from 'react';
import styles from './FormRegister.module.css';

const FormRegister = ({ handleToggleRegister, setUser }) => {
  const reCaptchaKey = '6LddXMIeAAAAAOXJJ6s_WuQRoPzLu4Axcg1xJmTu';
  const [isLoader, setIsLoader] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: 'black' }} spin />
  );
  const [formRegister] = Form.useForm();
  const recaptcha = useRef();
  const history = useHistory();

  const cancelSubmit = () => {
    formRegister.resetFields();
    recaptcha.current.reset();
    handleToggleRegister();
  };
  const onChange = () => {
    console.log('Hubo un cambio');
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoader(true);
      const response = await clientAxios.post('/register', values);
      setLocalStorage('user', response.data.user);
      setLocalStorage('token', response.data.token);
      const loggedUser = getLocalStorage('user');
      setUser(loggedUser);
      setIsLoader(false);
      successMessage('Usuario Registrado correctamente');
      formRegister.resetFields();
      handleToggleRegister();
      history.push('/thankyoupage');
    } catch (error) {
      console.log(error);
      setIsLoader(false);
      errorMessage(
        'Hubo un error en el registro, pruebe con otro correo o intente mas tarde'
      );
    }
  };

  const popoverContent = (
    <div>
      <p className={styles.popoverText}>
        Acuerdo:

        Entiendo que el sponsor del proyecto confiará en mi certificación de que
        soy un inversor acreditado para hacer que las oportunidades estén
        disponibles para mí. Entiendo que para poder participar en ciertas
        oportunidades de inversión a través del sitio web, que son parte de una
        oferta que involucra la solicitud general de posibles inversores, el
        patrocinador del proyecto debe tener la certeza de que soy un
        inversionista acreditado. Por esta razón, entiendo y acepto que, antes
        de cada inversión, el Reglamento de Valores de los Estados Unidos me
        exigirá que proporcione o cargue documentos que respalden mi
        certificación antes de que califique como un inversionista acreditado,
        como declaraciones de impuestos, estados de cuenta de inversión,
        confirmación de un tercero por parte de mi contador o corredor y / o
        cualquier otro documento que permitirá a los emisores evaluar
        razonablemente mi estado de inversor acreditado.
      </p>
    </div>
  );

  return (
    <div className="form-register-position">
      {isLoader && <Spin indicator={antIcon} />}
      <Row>
        <Col span={24}>
          <Form name="formRegister" autoComplete='off' initialValues={{ newsletter: true }} form={formRegister} onFinish={handleSubmit}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese su nombre',
                },
              ]}
            >
              <Input placeholder="Nombre" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese su apellido',
                },
              ]}
            >
              <Input placeholder="Apellido" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item name="phoneNumber">
              <Input placeholder="Teléfono" className="inputs-logRegister " />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese un email válido',
                },
              ]}
            >
              <Input placeholder="Email" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Porfavor Ingrese una contraseña',
                },
                {
                  min: 8,
                  message: 'La contraseña debe tener minimo 8 caracteres',
                },
                {
                  pattern: /(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*/,
                  message:
                    'La contraseña debe tener al menos una minúscula, una mayúscula y un número',
                },
              ]}
            >
              <Input.Password
                placeholder="Contraseña"
                className="inputs-logRegister"
              />
            </Form.Item>
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onChange={onChange}
              ref={recaptcha}
              required
            />
            <div className="div-radio-btn">
              <Form.Item name="newsletter" valuePropName="checked">
                <Checkbox
                  className="radio-btn checkbox-reg">
                  Acepto recibir alertas y novedades
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="termAndConditions"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: 'Campo requerido',
                  },
                ]}
              >
                <Popover overlayStyle={{ width: "30vw" }} content={popoverContent} title="Términos y Condiciones">
                  <Form.Item
                    name="termAndConditions"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: 'Campo requerido',
                      },
                    ]}
                  >
                    <Checkbox className="radio-btn checkbox-reg">
                      Acepto términos y condiciones
                    </Checkbox>
                  </Form.Item>
                </Popover>
              </Form.Item>
            </div>
            <Row>
              <Col span={6}>
                <Button
                  type="primary"
                  htmlType="button"
                  className="cancelBtn-formLogin"
                  onClick={cancelSubmit}
                >
                  Cancelar
                </Button>
              </Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-register"
                >
                  Registrarme
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default FormRegister;
