import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import Description from './Description';
import AllInvestments from './AllInvestments';
import { useState } from 'react';

const Investments = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <Description />
      <AllInvestments />
      <Footer />
    </>
  );
};

export default Investments;
