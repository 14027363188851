import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Upload,
  Switch,
  Image,
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { uploadProps } from '../Articles/UploadProps';
import { useState, useEffect } from 'react';
import FormParagraph from './FormParagraph';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import clientAxios from 'config/clientAxios';
import {
  warningMessage,
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import LoadingComponent from 'components/Loader/LoadingComponent';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

const FormGuide = () => {
  const [isPost, setIsPost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [guideEdit, setGuideEdit] = useState({});
  const [form] = Form.useForm();

  const { id } = useParams();
  const history = useHistory();
  const dateFormat = 'DD/MM/YYYY';

  const callbackImg = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      successMessage(`${info.file.name} carga de imagen exitosa`);
      setGuideEdit({ ...guideEdit, imgThumbnail: info.file.response });
    } else if (info.file.status === 'error') {
      errorMessage(`${info.file.name} error al cargar la imagen`);
    }
  }
  const imgThumbnailProps = { ...uploadProps, maxCount: 1, onChange: callbackImg };

  useEffect(() => {
    if (id) {
      const fetchGuide = async () => {
        try {
          setIsLoading(true);
          const res = await clientAxios.get(`/quickStartGuide/${id}`);
          let guide = res.data;
          const writingDate = moment(guide.writingDate);
          guide = { ...guide, writingDate };
          setGuideEdit(guide);
          setIsPost(guide.isPosted);
          setIsLoading(false);
        } catch (error) {
          errorMessage('Error al cargar el artículo');
        }
      };
      fetchGuide();
    }
  }, [id]);

  useEffect(() => {
    if (guideEdit?.imgThumbnail) {
      return form.setFieldsValue({ imgThumbnail: guideEdit?.imgThumbnail });
    }
    form.setFieldsValue({ imgThumbnail: undefined });
  }, [guideEdit, form])
  

  const handleSwitchIsPosted = () => {
    setIsPost((state) => !state);
  };

  const isBodyEqualToFormData = (data) => {
    const originalArticle = guideEdit.body.map(({image}) => image);
    const newArticle = form.getFieldValue().body.map(({image}) => image);
    originalArticle.map((image, index) => {
      if (JSON.stringify(image) ===  JSON.stringify(newArticle[index])) {
        data.body[index].image = image;
      }
      return null;
    })
    return data;
  };

  const saveGuide = (values) => {
    const dateWritin = values.writingDate;
    const miniImg = values.imgThumbnail;
    const writingDate = dateWritin.format();
    const imgThumbnail = miniImg?.file?.response || guideEdit?.imgThumbnail;
    const arrayBody = values.body;
    const body = arrayBody?.map((body) => ({
      ...body,
      image: body?.image?.fileList?.map((img) => img?.response || img?.image),
    }));
    const bodyParagraph = arrayBody
      ?.map((body) => body?.paragraph === undefined)
      .find((paragraph) => paragraph === true);
    const data = { ...values, writingDate, imgThumbnail, body };
    if (!body || bodyParagraph) {
      return warningMessage('Ingrese al menos un párrafo para el artículo');
    }
    if (!id) {
      sendData(data);
    } else {
      const payload = isBodyEqualToFormData(data);
      updateData(payload, id);
    }
  };

  const sendData = async (guide) => {
    try {
      setIsLoading(true);
      await clientAxios.post('/quickStartGuide', guide);
      successMessage('Guía creada exitosamente');
      history.push('/admin/guiadeiniciorapido');
      window.scrollTo(0, 0);
    } catch (error) {
      errorMessage('Hubo un error al crear la guía');
    } finally {
      setIsLoading(false);
    }
  };

  const updateData = async (guide, id) => {
    try {
      setIsLoading(true);
      await clientAxios.put(`/quickStartGuide/${id}`, guide);
      successMessage('Guía editada exitosamente');
      history.push('/admin/guiadeiniciorapido');
      window.scrollTo(0, 0);
    } catch (error) {
      errorMessage('Error al editar la guía');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row className="bg-title-nuevaInversion">
        <Col sm={{ span: 8 }}>
          <h3> {id ? 'Editar Wiki' : 'Agregar Wiki'} </h3>
        </Col>
      </Row>
      <LoadingComponent isLoading={isLoading}>
        <Form
          form={form}
          name="formBank"
          className={styles.formBank}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={id ? guideEdit : { isPosted: false }}
          onFinish={saveGuide}
        >
          <Form.Item label="Estado de la wiki" name="isPosted">
            <Switch
              checked={isPost}
              checkedChildren="Publicado"
              unCheckedChildren="No publicado"
              onChange={() => handleSwitchIsPosted()}
            />
          </Form.Item>
          <Form.Item
            label="Fecha"
            name="writingDate"
            rules={[
              {
                required: true,
                message: 'Ingrese una fecha',
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              style={{ width: '300px' }}
              locale={locale}
            />
          </Form.Item>
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: 'Ingrese el título de la wiki',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Clasificación"
            name="classification"
            rules={[
              {
                required: true,
                message: 'Ingrese la clasificación de la wiki',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Escrito por"
            name="writerName"
            rules={[
              {
                required: true,
                message: 'Ingrese nombre y apellido del escritor',
              },
            ]}
          >
            <Input placeholder="Nombre y Apellido" />
          </Form.Item>
          <Form.Item
            label="Imagen Thumbnail"
            name="imgThumbnail"
            rules={[
              {
                required: true,
                message: 'Ingrese una imagen miniatura',
              },
            ]}
          >
            <Upload {...imgThumbnailProps}>
              <Button icon={<UploadOutlined />}>Subir imagen</Button>
            </Upload>
            <span style={{ fontSize: '10px' }}>
              Imagen recomendada .jpg 1350 x 530px
            </span>
          </Form.Item>
          <Row>
            <Col span={16} offset={8}>
              {id && (
                <Image width={50} height={50} src={guideEdit?.imgThumbnail} />
              )}
            </Col>
          </Row>
          <FormParagraph guideEdit={guideEdit} />
          <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.btnAddBank}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </LoadingComponent>
    </>
  );
};

export default FormGuide;
