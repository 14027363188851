import {Form, Input, Space, Button} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const FormItemDataPublish = () => {
  const inputs = [
    {key:0, label: 'País', name:'country'},
    {key:1, label: 'Ciudad', name:'city'}
  ];

  return (
    <>
      {
        inputs.map((i)=> (
        <Form.Item label={i.label} key={i.key}>
              <Form.List name={i.name} key={i.key}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, i.name]}
                          fieldKey={[fieldKey, i.name]}
                        >
                          <Input placeholder={i.label} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button className="select-item" type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Agregar
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
          </Form.Item>
        ))
      }
    </>
  )
}

export default FormItemDataPublish;