import { useQuerySearch } from 'custom/hooks/queryHook';
import style from '../../styles/Aprender/aprender.module.css';
import img from '../../img/imgBottomContact.jpg';
import Articles from './Articles';
import Newsletter from './Newsletter/Newsletter';
import Guides from './QuickStartGuide/Guides'
import VideoCanal from './VideoCanal/VideoCanal';
import FAQ from './FAQ/FAQ';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const Learn = () => {
  const query = useQuerySearch();

  const tabKey = query.get('tabKey');
  return (
    <>
      <div className={style.container}>
        <img src={img} alt="edificio" className={style.imgEdificio} />
        <h3 className={style.title}>Noticias</h3>
      </div>
      <Tabs defaultActiveKey={tabKey} className={style.tabs}>
        <TabPane tab={<p className={style.tabPaneTitle}>Artículos</p>} key="1">
          <Articles />
        </TabPane>
        <TabPane
          tab={<p className={style.tabPaneTitle}>Wiki</p>}
          key="2"
        >
          <Guides />
        </TabPane>
        <TabPane tab={<p className={style.tabPaneTitle}>Newsletter</p>} key="3">
          <Newsletter />
        </TabPane>
        <TabPane
          tab={<p className={style.tabPaneTitle}>Video Canal</p>}
          key="4"
        >
          <VideoCanal />
        </TabPane>
        <TabPane tab={<p className={style.tabPaneTitle}>FAQs</p>} key="5">
          <FAQ/>
        </TabPane>
      </Tabs>
    </>
  );
};

export default Learn;
