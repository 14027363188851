const investmentStatusOpen = [
  {
    name: 'Abierta',
    value: 0,
  },
  {
    name: 'Re-Abierta',
    value: 1,
  },
];

const investmentStatusClose = [
  {
    name: 'Cancelada',
    value: 2,
  },
  {
    name: 'Cerrada por cupo',
    value: 3,
  },
  {
    name: 'Realizada',
    value: 4,
  },
];

export const getInvestmentStatus = (status) =>
  status ? investmentStatusOpen : investmentStatusClose;