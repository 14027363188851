import { Row, Col, Carousel } from 'antd';
import styles from '../../styles/homePage/testimonios.module.css';
import img1 from '../../img/testimonio1.png';
import img2 from '../../img/testimonio2.png';
import img3 from '../../img/testimonio3.png';
import arrow from '../../img/icons/Trazado48.svg';

const Testimonios = () => {
  const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      ><img src={arrow} alt="" className={styles.arrowRight}/></div>
    )
  }
  
  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      ><img src={arrow} alt="" className={styles.arrowLeft}/></div>
    )
  }
  
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  return (
    <>
      <Row className={styles.containerTitle}>
        <h4 className={styles.texth4}>Testimonios</h4>
      </Row>
      <Row className={styles.containerSlider}>
        <Col span={24}>
          <Carousel effect="fade" className={styles.slider} arrows {...settings}>
            <div className={styles.itemSlider}>
              <img src={img1} alt="person" className={styles.personImg} />
              <h6 className={styles.name}>Iván</h6>
              <p className={styles.p}>
                "Totallia me ayudó a acceder al mundo de la inversión
                inmobiliaria <br />
                comercial global, realizando inversiones con algunas <br />
                de las desarrolladoras más importantes del mundo. <br />
                Los resultados son excelentes y lo recomiendo <br />a todos los
                inversores"
              </p>
            </div>
            <div className={styles.itemSlider}>
              <img src={img2} alt="person" className={styles.personImg} />
              <h6 className={styles.name}>Ariel</h6>
              <p className={styles.p}>
                "Estoy muy contento de haber encontrado un mercado <br />
                donde acceder a los mejores proyectos inmobiliarios <br />
                de todo el mundo, diversificando mis inversiones en alternativas{' '}
                <br />
                que normalmente eran imposible acceder para inversores como yo"{' '}
                <br />
              </p>
            </div>
            <div className={styles.itemSlider}>
              <img src={img3} alt="person" className={styles.personImg} />
              <h6 className={styles.name}>Jorge</h6>
              <p className={styles.p}>
                "Totallia me permite acceder a inversiones con alta renta <br />
                mensual, pero con la seguridad de invertir en ladrillos,
                <br />
                Además puedo hacer todo el proceso online, lo que lo hace <br />
                simple, transparente y divertido" <br />
              </p>
            </div>
          </Carousel>
        </Col>
      </Row>
    </>
  );
};

export default Testimonios;
