import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { errorMessage } from '../../utils/messageHelper/messageHelper';
import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import ContentInvestment from './ContentInvestment';
import clientAxios from '../../config/clientAxios';

const DescriptionInvestment = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [investment, setInvestment] = useState({});

  const { id } = useParams();
  const { pathname } = useLocation();
  
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  useEffect(() => {
    const getSingleInvestment = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/posted/${id}`);
        setInvestment(res.data[0]);
      } catch (error) {
        errorMessage('Los datos de la inversión no están disponibles', 3);
      } finally {
        setIsLoading(false);
      }
    };

    getSingleInvestment();
  }, [id]);

  useEffect(() => {
    const createVisitPage = async () => {
      try {
        if (!user) return;
        if (!investment._id) return;

        const payload = {
          contentType: 'INVESTMENT',
          details: {
            investment: investment._id
          },
          pagePath: pathname,
        };

        await clientAxios.post('/page-visit', payload)
      } catch (error) {}
    }
    createVisitPage()
  }, [user, investment, pathname]);

  if (isLoading) {
    return (
      <>
        <LoadingOutlined style={{ fontSize: 30 }} spin />
      </>
    );
  }
  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <ContentInvestment investment={investment} />
      <Footer />
    </>
  );
};
export default DescriptionInvestment;
