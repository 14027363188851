import { Table, Pagination, message } from 'antd';
import { useEffect, useState } from 'react';
import axios from '../../../../../config/clientAxios';
import ButtonCSV from '../../../../../components/Admin/Distributions-Section/buttonExportCSV/ButtonCSV';
import { formatMoney } from '../../../../../utils/currentFormatHelper/currentFormatHelper';
import moment from 'moment';

const FORMAT_DATE = 'DD-MM-YYYY HH:mm:ss';
const DEFAULT_PAGE = 1;
const LIMIT_RECORDS = 10;
const ENABLED_EXPORTS = ['/page-visit', '/users', '/offers'];

const PICKUP_ERROR_MESSAGES = {
  BASIC: 'No se pudieron cargar los datos de exportacion. Por favor, inténtelo nuevamente.',
  EXPORT: 'No se pudieron cargar los datos de exportacion. Por favor, inténtelo nuevamente.'
}

const LastVisitsReport = ({
  columns,
  queryParams = {},
  endpoint = '/page-visit',
  responseName = 'pageVisits',
  isPagination = false,
  rangePicker,
  exportFileName = '',
}) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [recordsWithoutPages, setRecordsWithoutPages] = useState([]);

  useEffect(() => {
    const getDataByRangePiker = () => {
      if (rangePicker.length !== 2) return {};
      const [startDate, endDate] = rangePicker;
      return { from: startDate._d, to: endDate._d };
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const dateParams = getDataByRangePiker();
        const params = !isPagination
          ? { ...queryParams, ...dateParams }
          : { ...queryParams, limit: LIMIT_RECORDS, skip: page, ...dateParams };

        const { data } = await axios.get(endpoint, {
          params,
        });
        const { pagination } = data;
        setRecords(data[responseName]);
        setPages(pagination?.totalPages);
        setTotalRecords(pagination?.total);
      } catch (error) {
        message.warning(PICKUP_ERROR_MESSAGES.BASIC);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [rangePicker, isPagination, endpoint, queryParams, responseName, page]);

  useEffect(() => {
    const getDataByRangePiker = () => {
      if (rangePicker.length !== 2) return {};
      const [startDate, endDate] = rangePicker;
      return { from: startDate._d, to: endDate._d };
    };
    const fetchData = async () => {
      try {
        setExportLoading(true);
        const dateParams = getDataByRangePiker();
        const params = { ...queryParams, ...dateParams };

        const { data } = await axios.get(endpoint, {
          params,
        });
        setRecordsWithoutPages(data);
      } catch (error) {
        message.warning(PICKUP_ERROR_MESSAGES.EXPORT);
      } finally {
        setExportLoading(false);
      }
    };
    if (ENABLED_EXPORTS.includes(endpoint)) fetchData();
  }, [rangePicker, endpoint, queryParams, responseName]);

  useEffect(() => {
    const saveExportData = (data) => {
      const [pageVisit, users, offers] = ENABLED_EXPORTS;
      switch (endpoint) {
        case pageVisit:
          const pageVisitExport = data.map((record) => {
            const newData = {
              Fecha: moment(record?.createdAt).format(FORMAT_DATE),
              User: record.user.email,
            };
            if (queryParams?.contentType === 'ARTICLE') {
              newData['Articulo Visitado'] = record?.details.article?.title;
              return newData;
            }
            newData['Tarjeta Visitada'] = record?.details?.investment.name;
            return newData;
          });
          return setExportData(pageVisitExport);
        case users:
          const lastLoginExport = data.map((record) => {
            return {
              Fecha: moment(record?.lastLogIn).format(FORMAT_DATE),
              User: record?.email,
            };
          });
          return setExportData(lastLoginExport);
        case offers:
          const offersExport = data.map((record) => {
            return {
              Fecha: moment(record?.createdAt).format(FORMAT_DATE),
              User: record?.user?.email,
              'Tarjeta visitada': record?.investment?.name,
              'Monto Oferido': formatMoney(record?.amountOffered),
              'Contrato Firmado': record?.isContractSigned ? 'Si' : 'No',
            };
          });
          return setExportData(offersExport);
        default:
          return setExportData([]);
      }
    };
    if (recordsWithoutPages.length > 0) {
      saveExportData(recordsWithoutPages);
    }
  }, [recordsWithoutPages, queryParams, endpoint]);

  const handleChangePagination = (value = DEFAULT_PAGE) => {
    if (!pages) return null;
    setPage(value);
  };

  return (
    <>
      {ENABLED_EXPORTS.includes(endpoint) && (
        <ButtonCSV
          loading={exportLoading}
          dataTable={exportData}
          section={exportFileName}
          titleColumnTable={{}}
        />
      )}
      <Table
        columns={columns}
        dataSource={records}
        loading={isLoading}
        pagination={false}
      />
      {totalRecords > 0 && (
        <Pagination
          className="d-flex justify-content-center mt-4"
          total={totalRecords}
          defaultPageSize={LIMIT_RECORDS}
          defaultCurrent={DEFAULT_PAGE}
          showSizeChanger={false}
          onChange={(page) => handleChangePagination(page)}
        />
      )}
    </>
  );
};

export default LastVisitsReport;
