import React, { useState } from 'react';
import { Button, Row, Col, Radio, Space } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { setSessionStorage } from 'utils/sessionStorageHelper/sessionHelper';
import { infoAlert } from 'utils/alertMessageHelper/alertMessage';
import styles from './signContract.module.css';
import ManualContract from './manualContract/ManualContract';

const SignContract = ({ investmentId, setTabSelected }) => {
  const [radioSelected, setRadioSelected] = useState(1);
  const [isManualContrat, setIsManualContract] = useState(false);

  const { url } = useRouteMatch();
  const history = useHistory();
  window.scrollTo(0, 0);

  const handleSignDocument = () => {
    if (radioSelected === 1) {
      setSessionStorage('pointReturn', url);
      history.push(`/investorRoom/getTokenDocuSign/docuSign/${investmentId}`);
    } else {
      handleManualContract();
    }
  };

  const handleManualContract = () => {
    setIsManualContract((state) => !state);
  };

  const handleRadioGroup = (event) => {
    setRadioSelected(event.target.value);
  };
  if (isManualContrat)
    return <ManualContract handleManualContract={handleManualContract} setTabSelected={setTabSelected}/>;

  return (
    <Row
      gutter={[16, 16]}
      justify="center"
      align="middle"
      className={styles.container}
    >
      <Col span={24} lg={{ span: 12 }}>
        <h4>PASO 2: Firma de contrato</h4>
        <Radio.Group onChange={handleRadioGroup} value={radioSelected}>
          <Space direction="vertical">
            {/* <Radio value={1}>Quiero firmar con DocuSign</Radio> */}
            {/* <Radio value={2}>Quiero firmar manualmente</Radio> */}
          </Space>
        </Radio.Group>
        <div className={styles.containerButton}>
          <Button
            type="primary"
            className={styles.btnConfirm}
            onClick={handleSignDocument}
          >
            Iniciar el proceso de firmado
          </Button>
        </div>
      </Col>
      <Col span={24} lg={{ span: 12 }}>
        {radioSelected === 1 &&
          infoAlert(
            'Información.',
            'Durante la firma en DocuSign sera redirigido para realizar la firma.',
            'info'
          )}
      </Col>
    </Row>
  );
};

export default SignContract;
