import CardVideo from './CardVideo';
import { Row } from 'antd';
import style from 'styles/Aprender/videoCanal.module.css';
import { useState, useEffect } from 'react';
import LoadingComponent from '../../Loader/LoadingComponent';
import clientAxios from 'config/clientAxios';
import { warningMessage } from 'utils/messageHelper/messageHelper';

const VideoCanal = () => {
  const [dataVideos, setDataVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/videoCanalPublic');
        setDataVideos(res.data);
        setIsLoading(false);
      } catch (error) {
        warningMessage('Los videos no están disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchVideos();
  }, []);

  return (
    <LoadingComponent isLoading={isLoading}>
      <Row className={style.rowCardsVideo} justify="space-around">
        {dataVideos.map((video) => (
          <CardVideo key={video._id} video={video} />
        ))}
      </Row>
    </LoadingComponent>
  );
};

export default VideoCanal;
