import { Row, Button, Popconfirm, Table } from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import { useState, useEffect } from 'react';
import clientAxios from 'config/clientAxios';
import { useHistory, useRouteMatch } from 'react-router';
import {
  errorMessage,
  successMessage,
  warningMessage,
} from 'utils/messageHelper/messageHelper';

const VideoCanal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataVideoCanal, setDataVideoCanal] = useState([]);
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const fetchDataVideoCanal = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/videoCanal?delete=false');
        setDataVideoCanal(res.data);
        setIsLoading(false);
      } catch (error) {
        warningMessage('Los datos no están disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchDataVideoCanal();
  }, []);

  const tableHeadings = [
    {
      title: 'Fecha',
      dataIndex: 'dischargeDate',
      key: 'dischargeDate',
      render: (key) => {
        const dateNewFormat = dateFormat(key);
        return <p>{dateNewFormat}</p>;
      },
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Publicado',
      dataIndex: 'isPosted',
      key: 'isPosted',
      render: (key) => {
        const data = key ? 'Si' : 'No';
        return <p>{data}</p>;
      },
    },
    {
      title: 'Link video',
      dataIndex: 'youtubeLink',
      key: 'youtubeLink',
      render: (key) => {
        return (
          <a href={key} target="_blank" rel="noopener noreferrer">
            {' '}
            Ver video
          </a>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: '_id',
      key: '_id',
      render: (key) => (
        <>
          <Button
            style={{ border: 'none' }}
            size="small"
            icon={<EditOutlined />}
            onClick={() => editVideo(key)}
          />
          <Popconfirm
            title="¿Seguro desea eliminar el video?"
            okText="Si"
            cancelText="No"
            onConfirm={() => deleteVideo(key)}
          >
            <Button
              style={{ border: 'none' }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const deleteVideo = async (id) => {
    try {
      await clientAxios.delete(`/videoCanal/${id}`);
      window.scrollTo(0, 0);
      successMessage('Video eliminado exitosamente');
      const filteredVideo = dataVideoCanal.filter((video) => video._id !== id);
      setDataVideoCanal(filteredVideo);
    } catch (error) {
      errorMessage('Hubo un error al eliminar el video');
    }
  };

  const addVideo = () => {
    history.push(`${path}/nuevoVideo`);
    window.scrollTo(0, 0);
  };

  const editVideo = (id) => {
    history.push(`${path}/editarVideo/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Row justify="space-between" className="bg-title-nuevaInversion">
        <h3>Video Canal</h3>
        <Button
          onClick={addVideo}
          icon={<PlusCircleOutlined />}
          className={styles.btnAddBank}
        >
          Agregar video
        </Button>
      </Row>
      <Row justify="center">
        <Table
          rowKey="_id"
          dataSource={dataVideoCanal}
          columns={tableHeadings}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
          loading={isLoading}
          scroll={{ x: 1000 }}
        />
      </Row>
    </>
  );
};

export default VideoCanal;
