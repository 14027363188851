import { CSVLink } from 'react-csv';
import { Button } from 'antd';

const ButtonCSV = ({ dataTable, titleColumnTable, section, loading = false }) => {
  const dataCsv = [titleColumnTable, ...dataTable];

  return (
    <Button className="btn-closeInv-filter input-size" loading={loading}>
      <CSVLink filename={`Totallia-${section}.csv`} data={dataCsv}>
        Exportar CSV
      </CSVLink>
    </Button>
  );
};

export default ButtonCSV;
