import { Row, Col, Table } from 'antd';
import { NotificationsTableStructure } from './NotificationsTableStructure';

const NotificationsTable = ({ notifications }) => {
  return (
    <>
      <Row justify="center">
        <Col span={20}>
          <Table
            columns={NotificationsTableStructure()}
            dataSource={notifications}
            loading={false}
            rowKey="_id"
          />
        </Col>
      </Row>
    </>
  );
};

export default NotificationsTable;
