import { Row, Col, Button, Table, Popconfirm } from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  errorMessage,
  warningMessage,
  successMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { typeBanks } from '../../../../utils/typeOfBanks/typeBanks';
import clientAxios from '../../../../config/clientAxios';
import styles from '../../../../styles/panelAdmin/adminBanks.module.css';

const Banks = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [dataBanks, setDataBanks] = useState([]);

  const tableHeadings = [
    {
      title: 'Tipo de banco',
      dataIndex: 'type',
      key: 'type',
      render: (key) => {
        const typeOfBanksrender = typeBanks[key];
        return <p className={styles.pTypeBank}>{typeOfBanksrender}</p>;
      },
    },
    {
      title: 'Etiqueta',
      dataIndex: 'labelName',
      key: 'labelName'
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Número de tránsito',
      dataIndex: 'transitNumber',
      key: 'transitNumber',
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Nombre del Beneficiario',
      dataIndex: 'beneficiaryName',
      key: 'beneficiaryName',
    },
    {
      title: 'Dirección del Beneficiario',
      dataIndex: 'beneficiaryAddress',
      key: 'beneficiaryAddress',
    },
    {
      title: 'Número de cuenta',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    {
      title: 'Tipo de cuenta',
      dataIndex: 'typeOfAccount',
      key: 'typeOfAccount',
    },
    {
      title: 'BIC',
      dataIndex: 'BIC',
      key: 'BIC',
    },
    {
      title: 'IBAN',
      dataIndex: 'IBAN',
      key: 'IBAN',
    },
    {
      title: 'Acciones',
      dataIndex: '_id',
      key: '_id',
      render: (key) => (
        <>
          <Button
            style={{ border: 'none' }}
            size="small"
            onClick={() => openEditBank(key)}
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="¿Seguro desea eliminar el banco?"
            onConfirm={() => deleteBank(key)}
            okText="Si"
            cancelText="No"
          >
            <Button
              style={{ border: 'none' }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];
  const addBank = () => {
    history.push(`${path}/agregarBanco`);
  };
  useEffect(() => {
    const fetchDataBanks = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/banks`);
        const activeBanks = res.data.filter((bank) => bank.active === true);
        setDataBanks(activeBanks);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Datos no disponibles');
      }
      setIsLoading(false);
    };
    fetchDataBanks();
  }, []);

  const openEditBank = (id) => {
    history.push(`${path}/editarBanco/${id}`);
    window.scrollTo(0, 0);
  };

  const deleteBank = async (id) => {
    try {
      await clientAxios.delete(`/banks/${id}`);
      window.scrollTo(0, 0);
      successMessage('Banco eliminado exitosamente');
      const filteredBanks = dataBanks.filter((bank) => bank._id !== id);
      setDataBanks(filteredBanks);
    } catch (error) {
      const errorStatus = error.response.status;
      if (errorStatus === 409) {
        warningMessage(
          'No se puede eliminar el banco, porque está asociado a una inversión',
          3
        );
      } else {
        errorMessage('Hubo un problema al eliminar el banco');
      }
    }
  };

  return (
    <div>
      <Row justify="center" className="bg-title-nuevaInversion">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3>Bancos</h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="center-container">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={addBank}
                className={styles.btnAddBank}
              >
                Agregar banco
              </Button>
          </div>
        </Col>
      </Row>
      <Row justify="center" className={styles.containerTable}>
        <Table
          rowKey="_id"
          dataSource={dataBanks}
          columns={tableHeadings}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
          loading={isLoading}
          scroll={{ x: 1300 }}
        />
      </Row>
    </div>
  );
};

export default Banks;
