import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InvestorProfile from '../pages/investorProfile/InvestorProfile';
import InvestmentDetail from '../pages/investments/InvestmentDetail';
import InvestmentsInvestorRoom from '../pages/investments/InvestmentsInvestorRoom';
import InvestNow from '../pages/investNow/InvestNow';
import DocuSign from '../pages/investNow/tabs/options/signContract/docuSign/DocuSign';
import GetTokenDocuSign from '../pages/investNow/tabs/options/signContract/docuSign/GetTokenDocuSign';
import MyInvestments from '../pages/myInvestments/MyInvestments';
import Novelties from '../pages/novelties/Novelties';
import InvestmentView from '../pages/myInvestments/investmentView/InvestmentView';

const InvestorRoomRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/investments/detail/:id/investNow/`}>
        <InvestNow />
      </Route>
      <Route path={`${path}/investments/detail/:id`}>
        <InvestmentDetail />
      </Route>
      <Route path={`${path}/investments`}>
        <InvestmentsInvestorRoom />
      </Route>
      <Route path={`${path}/profile`}>
        <InvestorProfile />
      </Route>
      <Route path={`${path}/getTokenDocuSign/docuSign/:id`}>
        <DocuSign />
      </Route>
      <Route path={`${path}/getTokenDocuSign`}>
        <GetTokenDocuSign />
      </Route>
      <Route path={`${path}/myInvestments/:id`}>
        <InvestmentView />
      </Route>
      <Route path={`${path}/myInvestments`}>
        <MyInvestments />
      </Route>
      <Route path={`${path}/novelties`}>
        <Novelties />
      </Route>
    </Switch>
  );
};

export default InvestorRoomRoutes;
