import { InputNumber } from 'antd';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';

export const tableDistributionsById = (
  handleShareOnChange,
  handleRentsOnChange,
  success,
  isEdit
) => {

  const rows = [
    {
      title: 'Share',
      dataIndex: 'share',
      key: 'share',
      enable: true,
    },
    {
      title: 'Renta',
      dataIndex: 'rent',
      key: 'rent',
      enable: true,
    },
  ];
  const editRows = [
    {
      title: 'Share',
      width: '13%',
      dataIndex: ['share', '_id'],
      key: 'share',
      enable: true,
      render: (text, row) => (
        <InputNumber
          className={
            success
              ? 'text-success border-success'
              : success === false && 'text-danger border-danger'
          }
          value={row['share']}
          max={100}
          parser={(value) => {
            const percentage = value.replace('%', '');
            const decimal = percentage.replace(',', '.');
            const limitedDecimal = decimal.match(/^-?\d+(?:\.\d{0,4})?/);
            return parseFloat(limitedDecimal);
          }}
          maxLength={7}
          min={0}
          name={row['_id']}
          onChange={(e) => handleShareOnChange(row['_id'], e)}
        />
      ),
    },
    {
      title: 'Renta',
      width: '12%',
      dataIndex: ['rent', '_id'],
      key: 'rent',
      enable: true,
      render: (text, row) => (
        <InputNumber
          className={
            success
              ? 'text-success border-success'
              : success === false && 'text-danger border-danger'
          }
          name={row['_id']}
          value={row['rent']}
          onChange={(e) => handleRentsOnChange(row['_id'], e)}
        />
      ),
    },
  ];

  const distributionsRows = isEdit === true ? [...editRows] : [...rows];

  return [
    {
      title: 'Fecha de Creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      enable: true,
      render: (createdAt) => dateFormat(createdAt),
    },
    {
      title: 'Inversor',
      dataIndex: 'offer',
      key: 'offer',
      enable: true,
      render: (offer) => `${offer?.user?.firstName} ${offer?.user?.lastName}`,
    },
    {
      title: 'Monto Invertido',
      dataIndex: 'offer',
      key: 'offer',
      enable: true,
      render: (offer) => `$${offer?.amountOffered}`,
    },
    ...distributionsRows,
  ];
};
