import { Row, Col } from 'antd';
import style from '../../styles/homePage/sectionshome.module.css';
import icon1 from '../../img/icons/Grupo129.svg';
import icon2 from '../../img/icons/Grupo132.svg';
import icon3 from '../../img/icons/Grupo131.svg';
import icon4 from '../../img/icons/Trazado66.svg';
import arrow from '../../img/icons/Trazado48.svg';

const Section6Home = () => {
  return (
    <div className={style.divSection6}>
      <Row>
        <Col span={24}>
          <h1 className={style.titleSection6}>¿Cómo funciona Totallia?</h1>
          <p className={style.subTitleSection6}>
            Acceda. Explore. Invierta. Disfrute.
          </p>
        </Col>
      </Row>
      <Row className={style.divIconSection6}>
        <Col lg={5} md={12} sm={24} className={style.center}>
          <div>
            <img src={icon1} alt="" className={style.diviconGray} />
          </div>
          <h4 className={style.h4Section6}>ACCEDA</h4>
          <p className={style.pSection6}>
            Regístrese para tener acceso <br />
            privilegiado a inversiones <br />
            inmobiliarias globales
          </p>
        </Col>
        <Col lg={1} className={style.displayMdNone}>
          <div className={style.arrowSteps}>
            <img src={arrow} alt="" />
          </div>
        </Col>
        <Col lg={5} md={12} sm={24} className={style.center}>
          <div>
            <img src={icon2} alt="" className={style.diviconGray} />
          </div>
          <h4 className={style.h4Section6}>EXPLORE</h4>
          <p className={style.pSection6}>
            Analice las inversiones
            <br />
            vigentes entre carteras
            <br />y proyectos individuales.
          </p>
        </Col>
        <Col lg={1} className={style.displayMdNone}>
          <div className={style.arrowSteps}>
            <img src={arrow} alt="" />
          </div>
        </Col>
        <Col lg={5} md={12} sm={24} className={style.center}>
          <div>
            <img src={icon3} alt="" className={style.diviconRose} />
          </div>
          <h4 className={style.h4Section6}>INVIERTA</h4>
          <p className={style.pSection6}>
            Seleccione inversión, firme <br />
            documentos legales
            <br />e invierta.
          </p>
        </Col>
        <Col lg={1} className={style.displayMdNone}>
          <div className={style.arrowSteps}>
            <img src={arrow} alt="" />
          </div>
        </Col>
        <Col lg={5} md={12} sm={24} className={style.center}>
          <div>
            <img src={icon4} alt="" className={style.diviconGray} />
          </div>
          <h4 className={style.h4Section6}>DISFRUTE</h4>
          <p className={style.pSection6}>
            Revise los informes de inversión,
            <br />
            recopile las distribuciones
            <br />y anticipe las ganancias de capital.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Section6Home;
