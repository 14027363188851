import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import users from './Users';
import FormEditeUser from './FormEditUser';

const UsersRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/editarUsuario/:id`} component={FormEditeUser} />
      <Route path={path} component={users} />
    </Switch>
  );
};

export default UsersRoutes;