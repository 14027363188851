import { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import FormBank from './form/FormBank';
import Options from './options/Options';
import styles from './transferOptions.module.css';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import FormContact from 'components/ContactPage/FormContact';

const TransferOptions = ({ investmentId }) => {

  const [typeOfBank, setTypeOfBank] = useState(0);
  const [banks, setBanks] = useState([]);

  const otherOptions = 2

  useEffect(() => {
    const handleGetInvestment = async () => {
      try {
        const response = await clientAxios.get(`/posted/${investmentId}`);
        const responseData = response.data;
        const banks = responseData.map(({ bank }) => bank);
        setBanks(banks);
      } catch (error) {
        errorMessage('Tuvimos inconvenientes con la consulta.');
      }
    };
    handleGetInvestment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptions = (value) => {
    setTypeOfBank(value.target.value);
  };

  return (
    <Row
      gutter={[16, 16]}
      justify="center"
      align="middle"
      className={styles.container}
    >
      <Col span={24} lg={{ span: 24 }}>
        <h4>Transferir el monto</h4>
        <Row justify="start">
          <h5>1. Instrucciones bancarias.</h5>
        </Row>
        <Options handleOptions={handleOptions} />
        <div className={styles.containerButton}>
          {typeOfBank === otherOptions ? (
            <FormContact buttonName="Contactanos" />
          ) : (
            <FormBank typeOfBank={typeOfBank} banks={banks} />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default TransferOptions;
