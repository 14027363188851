import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import style from './modalImages.module.css';
import LoadingInvestorRoom from '../loadingComponent/LoadingInvestorRoom';

const ModalImages = ({ isModalPassport, handleCancel, imageKey }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  useEffect(() => {
    const getImageUrlPrivate = async () => {
      if (imageKey) {
        try {
          setIsLoadingImg(true);
          const response = await clientAxios.get(
            `/attachments/private/${imageKey}`
          );
          setImageUrl(response.data.url);
        } catch (error) {
          errorMessage('Tuvimos inconvenientes para traer el Documento Identidad');
        } finally {
          setIsLoadingImg(false);
        }
      }
    };
    getImageUrlPrivate();
  }, [imageKey]);

  return (
    <Modal
      title={null}
      visible={isModalPassport}
      onCancel={handleCancel}
      style={{ textAlign: 'center' }}
      footer={null}
      width={1000}
    >
      <LoadingInvestorRoom isLoading={isLoadingImg}>
        {imageKey ? (
          <img src={imageUrl} className={style.passport} alt="passport" />
        ) : (
          <h1>No se subio el Documento de Identidad</h1>
        )}
      </LoadingInvestorRoom>
    </Modal>
  );
};

export default ModalImages;
