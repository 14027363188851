import { Modal, Row, Divider, Button } from 'antd';
import { PlusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import CreateAdditionalDocs from './CreateAdditionalDocs';
import SeeAdditionalDocs from './SeeAdditionalDocs/SeeAdditionalDocs';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';

const AdditionalDocs = ({ open, onCancel, offer: offerRaw, isAdmin }) => {
  const [offer, setOffer] = useState({});
  const [reloadData, setReloadData] = useState(false);
  const [showCreateDoc, setShowCreateDoc] = useState(false);

  useEffect(() => {
    setOffer(offerRaw);
    setShowCreateDoc(false);
  }, [offerRaw]);

  useEffect(() => {
    const getOfferData = async () => {
      try {
        const { data } = await clientAxios.get(`/user/offers/${offerRaw?._id}`);
        setOffer(data)
      } catch (error) {
        errorMessage('Hubo un error al recargar los datos.');
      } finally {
        setReloadData(false);
      }
    };
    if (reloadData) getOfferData();
  }, [reloadData, offerRaw]);

  return (
    <Modal visible={open} onCancel={onCancel} footer={null} closable={false}>
      <div className="px-2">
        <Row className="pb-3" justify="space-between" align="middle">
          <p className="mb-0" style={{ fontSize: '20px' }}>
            Archivos adicionales
          </p>
          <Button
            type="primary"
            shape="round"
            icon={
              !showCreateDoc ? (
                <PlusCircleOutlined style={{ display: 'inline-flex' }} />
              ) : (
                <ArrowLeftOutlined style={{ display: 'inline-flex' }} />
              )
            }
            onClick={() => setShowCreateDoc((oldData) => !oldData)}
          >
            {!showCreateDoc ? 'Agregar' : 'Volver'}
          </Button>
        </Row>
        <Divider className="mt-0" />
        {showCreateDoc ? (
          <CreateAdditionalDocs
            offer={offer}
            setReloadData={setReloadData}
            goBack={() => setShowCreateDoc(false)}
            key={'create-additional-docs-modal'}
          />
        ) : (
          <SeeAdditionalDocs
            key={'viewAdditionalDocs'}
            offer={offer}
            additionalDocumentation={offer?.additionalDocumentation ?? []}
            setReloadData={setReloadData}
            isAdmin={isAdmin}
          />
        )}
      </div>
    </Modal>
  );
};
export default AdditionalDocs;
