import {
  errorMessage,
  successMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { Divider, Form, Upload, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AttachmentPrev from '../forms/AttachmentPrev';
import { useEffect, useState } from 'react';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';

const FormAttachments = ({
  singleInvestment,
  handleUpdateInvestmentAttachment,
}) => {
  const [investmentData, setInvestmentData] = useState(undefined);

  const token = getLocalStorage('token')

  useEffect(() => {
    if (Object.entries(singleInvestment).length !== 0) {
      setInvestmentData(singleInvestment);
    }
  }, [singleInvestment]);

  const props = {
    name: 'attachment',
    action: `${process.env.REACT_APP_API_URL}/attachments`,
    withCredentials: true,
    onChange(info) {
      if (info.file.status === 'done') {
        successMessage(`${info.file.name} archivo subido exitosamente`);
      } else if (info.file.status === 'error') {
        errorMessage(`${info.file.name} error al cargar el archivo`);
      }
    },
    headers: {
      authorization: token,
    },
  };
  const attachments = [
    {
      divider: 'Galería de Imagenes',
      name: 'galleryImg',
      maxCount: 6,
      type: 'image',
    },
    {
      divider: 'Imágen de Fondo',
      name: 'backgroundImg',
      maxCount: 1,
      type: 'image',
    },
    {
      divider: 'Imágen destacada',
      name: 'featuredImg',
      maxCount: 1,
      type: 'image',
    },
    {
      divider: 'Logo Sponsor',
      name: 'sponsorLogo',
      maxCount: 1,
      type: 'image',
    },
    {
      divider: 'Términos y Condiciones',
      name: 'termAndConditions',
      maxCount: 1,
      type: 'download',
    },
    {
      divider: 'Videos',
      name: 'videos',
      maxCount: 1,
      type: 'video',
    },
    {
      divider: 'Archivos Descargables',
      name: 'downloadFiles',
      maxCount: 50,
      type: 'download',
    },
    {
      divider: 'Contratos',
      name: 'contracts',
      maxCount: 50,
      type: 'download',
    },
  ];

  return attachments.map((attachment) => (
    <div key={attachment.length} className="div-attachments">
      <Divider orientation="left" className="divider-attachments">
        {attachment.divider}
      </Divider>
      <Row>
        <Col span={24} className="row-attachments">
          {investmentData && (
            <AttachmentPrev
              attachment={investmentData?.attachments[attachment?.name]}
              name={attachment?.name}
              type={attachment?.type}
              handleUpdateInvestmentAttachment={
                handleUpdateInvestmentAttachment
              }
            />
          )}
        </Col>
      </Row>
      <Form.Item
        name={attachment.name}
        key={attachment.length}
        className="attachment-form-item"
      >
        <Upload {...props} maxCount={attachment.maxCount}>
          <Button icon={<UploadOutlined />}>Cargar Archivo</Button>
        </Upload>
      </Form.Item>
    </div>
  ));
};

export default FormAttachments;
