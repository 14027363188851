import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';
import {
  errorMessage,
  successMessage,
} from 'utils/messageHelper/messageHelper';
import styles from '../../pages/investorProfile/investorProfile.module.css'


const UploadImages = ({ takeImages, nameButton, disabled }) => {
  const token = getLocalStorage('token');

  const handleTakeImage = (info) => {
    if (info.file.status === 'done') {
      takeImages(info.file.response);
      successMessage(`${info.file.name} archivo subido exitosamente`);
    } else if (info.file.status === 'error') {
      errorMessage(`${info.file.name} error al cargar el archivo`);
    }
  };

  const props = {
    name: 'attachment',
    action: `${process.env.REACT_APP_API_URL}/attachments/private`,
    withCredentials: true,
    headers: {
      authorization: token,
    },
  };
  return (
    <Upload {...props} onChange={handleTakeImage} maxCount={1}>
      <Button className={styles.btnConfirm} disabled={disabled} icon={<UploadOutlined />}>{nameButton}</Button>
    </Upload>
  );
};

export default UploadImages;
