import {
  successMessage,
  warningMessage,
} from '../../../../utils/messageHelper/messageHelper';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Col,
  Row,
  Switch,
  Divider,
  InputNumber,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import {
  formatter,
  parser,
} from '../../../../utils/formatParseHelper/formatParseHelper';
import { initialState } from '../initialStateInvestment/initialStateInvestment';
import { getInvestmentStatus } from '../../../../utils/investmentStatus/investmentStatus';
import FormItemDataPublish from './FormItemDataPublish';
import FormSeccionesDataPublish from './FormSeccionesDataPublish';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

const DatosPublicacionAnt = ({ handleInvestment, singleInvestment }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isPosted, setIsPosted] = useState(false);
  const form = useRef();

  const handleOnFinish = (values) => {
    const arrayCity = values.city;
    const arrayCountry = values.country;
    const arrayProjects = values.projectKeys;
    const city = arrayCity?.map((c) => c.city);
    const country = arrayCountry?.map((c) => c.country);
    const createdAt = values?.CreatedAt?.format()
    const projectKeys = arrayProjects?.map((project) => ({
      ...project,
      icon: project?.icon?.file?.response || project.icon,
    }));
    const dataPublish = { ...values, city, country, createdAt, projectKeys };
    handleInvestment(dataPublish);
    successMessage('Datos Guardados');
    window.scrollTo(0, 0);
  };

  const handleOnFinishFailed = () => {
    warningMessage('Por favor verifique los datos');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleSetIsOpen = () => {
      if (singleInvestment.isOpen !== undefined && !singleInvestment.isOpen) {
        setIsOpen(singleInvestment.isOpen);
      }
    };
    const handleSetIsPosted = () => {
      if (Object.entries(singleInvestment).length !== 0) {
        setIsPosted(singleInvestment.isPosted);
      }
    };
    handleSetIsOpen();
    handleSetIsPosted();
  }, [singleInvestment]);

  const handleChangeStatus = () => {
    setIsOpen((state) => !state);
  };

  const handleSwitchIsPosted = () => {
    setIsPosted((state) => !state);
  };

  const inputsTypeNumber = [
    { key: 0, label: 'TIR%', name: 'irr', suffix: '%' },
    { key: 1, label: 'Apreciación', name: 'appreciation' },
    { key: 2, label: 'Plazos(años)', name: 'termInYears' },
    { key: 3, label: 'Cantidad', name: 'quantity' },
  ];

  return (
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <h4 className="title-dataPublish">Datos de Publicación</h4>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            name="datosPublicacion"
            className="formDataPublish"
            ref={form}
            labelCol={{
              span: 8,
            }}
            initialValues={
              Object.entries(singleInvestment).length === 0
                ? initialState
                : singleInvestment
            }
            onFinish={handleOnFinish}
            onFinishFailed={handleOnFinishFailed}
          >
            <Divider orientation="left">Estados</Divider>
            <Form.Item label="Estado de la Publicación" name="isPosted">
              <Switch
                checked={isPosted}
                checkedChildren="Publicado"
                unCheckedChildren="No publicado"
                onChange={() => handleSwitchIsPosted()}
              />
            </Form.Item>
            <Form.Item label="Estado de Inversión" name="isOpen">
              <Select className="select-item" onChange={handleChangeStatus}>
                <Select.Option value={true}>Abierta</Select.Option>
                <Select.Option value={false}>Cerrada</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={isOpen ? 'Estado' : 'Motivo de cierre'}
              name="investmentStatus"
              rules={[
                {
                  required: true,
                  message: isOpen
                    ? 'Por favor ingrese estado'
                    : 'Por favor ingrese el motivo',
                },
              ]}
            >
              <Select className="select-item">
                <Select.Option value=""></Select.Option>
                {getInvestmentStatus(isOpen).map((status) => (
                  <Select.Option key={status.value} value={status.value}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Finalizo" name="finalized">
              <Select className="select-item" name="finalized">
                <Select.Option value={true}>Si</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Destacado en Home" name="isFeatured">
              <Select className="select-item" name="isFeatured">
                <Select.Option value={true}>Si</Select.Option>
                <Select.Option value={false}>No</Select.Option>
              </Select>
            </Form.Item>
            <Divider orientation="left">Datos</Divider>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese el nombre de la inversión',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Tipo de Inversión" name="investmentType">
              <Select className="select-item">
                <Select.Option value="Fondo de Inversión">
                  Cartera de Inversión
                </Select.Option>
                <Select.Option value="Inversión Individual">
                  Inversión Individual
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Subtitulo"
              name="subtitle"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese el subtitulo de la inversión',
                },
                {
                  max: 80,
                  message: 'Solo puede ingresar un maximo de ochenta caracteres',
                },
              ]}
            >
              <Input maxLength={85} />
            </Form.Item>
            <Form.Item
              label="El Proyecto"
              name="proyect"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese el proyecto de la inversión',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Sponsor" name="sponsor">
              <Input />
            </Form.Item>
            <FormItemDataPublish />
            <Form.Item label="Renta%" name="revenue">
              <Input />
            </Form.Item>
            {inputsTypeNumber.map((input) => (
              <Form.Item key={input.key} label={input.label} name={input.name}>
                <Input suffix={input.suffix} type="number" />
              </Form.Item>
            ))}
            <Form.Item label="Patrimonio" name="networth">
              <InputNumber
                defaultValue={1000}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Inversión Minima" name="minInvestment">
              <InputNumber
                defaultValue={1000}
                formatter={formatter}
                parser={parser}
              />
            </Form.Item>
            <Form.Item label="Fecha de creacion" name="CreatedAt">
              <DatePicker
                className="select-item"
                locale={locale}
                defaultValue={
                  singleInvestment?.createdAt &&
                  moment(singleInvestment?.createdAt)
                }
                placeholder="Seleccionar Fecha"
              />
            </Form.Item>
            <Form.Item label="Etiqueta de Cantidad" name="quantityTag">
              <Input />
            </Form.Item>
            <Form.Item label="Video de YouTube" name="youtubeVideo">
              <Input />
            </Form.Item>
            <FormSeccionesDataPublish singleInvestment={singleInvestment}/>
            <div className="center-container">
              <Button type="primary" htmlType="submit" className="btn-save">
                Guardar formulario
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default DatosPublicacionAnt;
