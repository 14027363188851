import { Modal, Divider, Row } from 'antd';
import imgDownload from '../../img/icons/Grupo106.svg';
import styles from '../../styles/investments/investmentDetail.module.css';
import style from '../../styles/investments/modalsDetailInvestment.module.css';

const ModalDownloads = ({
  handleModalDownloads,
  openModalDownloads,
  attachments,
}) => {
  const getFileNameByURL = (url) => {
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];
  
    if (lastSegment === "") {
      const penultimateSegment = segments[segments.length - 2];
      return getFileNameFromSegment(decodeURIComponent(penultimateSegment));
    }
    return getFileNameFromSegment(decodeURIComponent(lastSegment));
  }
  
  const getFileNameFromSegment = (segment) => {
    const extensionIndex = segment.lastIndexOf(".");
    if (extensionIndex === -1) return segment;

    const nameWithoutExtension = segment.slice(0, extensionIndex);
    const nameSegments = nameWithoutExtension.split("-");

    if (nameSegments.length > 1) return nameSegments.slice(1).join("-");
    return nameWithoutExtension;
  }
  
  return (
    <Modal
      visible={openModalDownloads}
      onCancel={handleModalDownloads}
      footer={null}
      bodyStyle={{ background: '#f5f5f5' }}
    >
      <Divider orientation="left" plain>
        Archivos Descargables
      </Divider>
      {attachments?.downloadFiles?.map((file, i) => (
        <div key={file.length} className='container'>
          <div className='row'>
          <div className='col-3'>
            <img
              key={file.length + i}
              src={imgDownload}
              alt="archivos descargables"
              className={styles.imgDownload}
            />
          </div>
          <div className='col-9'>
            <p className='mb-1 mt-3'>{getFileNameByURL(file)}</p>
            <a href={file} download className={style.aDownload}>
              Descargar
            </a>
          </div>
          </div>
        </div>
      ))}
      <Divider orientation="left" plain>
        Contratos
      </Divider>
      <Row>
        {attachments?.contracts?.map((file) => (
          <div key={file.length} className='container'>
          <div className='row'>
          <div className='col-3'>
            <img
              key={file.length}
              src={imgDownload}
              alt="archivos descargables"
              className={styles.imgDownload}
            />
          </div>
          <div className='col-9'>
            <p className='mb-1 mt-3'>{getFileNameByURL(file)}</p>
            <a href={file} download className={style.aDownload}>
              Descargar
            </a>
          </div>
          </div>
        </div>
        ))}
      </Row>
      {
        attachments?.termAndConditions &&
        <>
          <Divider orientation="left" plain>
            Terminos y condiciones
          </Divider>
          <Row>
            <div className={style.divDownload}>
              <img
                src={imgDownload}
                alt="archivos descargables"
                className={styles.imgDownload}
              />
              <a
                href={attachments?.termAndConditions}
                download
                className={style.aDownload}
              >
                Descargar
              </a>
            </div>
          </Row>
        </>
      }
    </Modal>
  );
};
export default ModalDownloads;
