export const configTools = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'list',
    'textAlign',
    'colorPicker',
    'fontFamily',
  ],
  inline: { inDropdown: true },
  list: { inDropdown: true },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  fontFamily: {
    options: [
      'Arial',
      'Georgia',
      'Impact',
      'Tahoma',
      'Times New Roman',
      'Verdana',
    ],
  },
};
