import styles from '../../../styles/sliderHome.module.css';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import ItemSlider from './ItemSlider';
import defaultBanner from 'img/imgDescriptionInvestment.jpg';

const LastInvestment = ({ investment }) => {
  const limitText = (text, limit) => {
    if (typeof text !== 'string') return '';

    let limitTextData = '';
    for (let i = 0; i < limit; i += limit) {
      limitTextData += text.slice(i, i + limit);
    }
    return limitTextData;
  };
  return (
    <ItemSlider
      img={
        investment?.attachments?.backgroundImg
          ? investment?.attachments?.backgroundImg
          : defaultBanner
      }
      imgClassName={styles.optimizeImg}
    >
      <div className={styles.containerTextInvestment}>
        <div>
          <h1
            className={`${styles.positionTitleInvestment} text-uppercase text-center mb-1`}
          >
            {investment?.name}
          </h1>
          <div className={styles.positionTextInvestment}>
            <p>{limitText(investment?.subtitle, 80)}</p>
          </div>
        </div>
        <Link to={`/investments/detailInvestment/${investment?._id}`}>
          <Button className={styles.btn}>INVERTÍ AHORA</Button>
        </Link>
      </div>
    </ItemSlider>
  );
};

export default LastInvestment;
