import React from 'react';
import { personalDataStructure } from 'utils/investorProfileHelper/initialValuesForms';
import {
  Input,
  Form,
  Button,
  DatePicker,
  Switch,
  Space,
  InputNumber,
} from 'antd';
import ModalAttachment from 'components/investor-room/components/modalAttachment/ModalAttachment';
import styles from 'components/investor-room/pages/investorProfile/investorProfile.module.css';
import { datePickerFormat } from 'utils/datePickerFormat/datePickerFormat';

const PersonalDataForm = ({
  isModalPassport,
  handleModalPassport,
  userData,
}) => {
  return (
    <Form
      className={styles.wrapper}
      layout="vertical"
      initialValues={personalDataStructure(userData)}
    >
      <div>
        <Form.Item label="Nombre" name="firstName" className={styles.formItem}>
          <Input />
        </Form.Item>
        <Form.Item label="Apellido" name="lastName" className={styles.formItem}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Fecha de nacimiento"
          name="birthday"
          className={styles.formItem}
        >
          <DatePicker
            format={datePickerFormat()}
            className="prefix-width-modal input-size select-item datePicker-border-color"
          />
        </Form.Item>
        <Form.Item label="Mail" name="email" className={styles.formItem}>
          <Input
            autoComplete="autocomplete_off"
            className="prefix-width-modal readOnly"
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Celular"
          name="phoneNumber"
          className={styles.formItem}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Domicilio" name="address" className={styles.formItem}>
          <Input />
        </Form.Item>
        <Form.Item label="CP" name="zipCode" className={styles.formItem}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Pais" name="country" className={styles.formItem}>
          <Input />
        </Form.Item>
      </div>
      <div>
        <ModalAttachment
          open={isModalPassport}
          handleCancel={handleModalPassport}
          attachmentId={userData?.passport?.url}
        />
        <Form.Item label="Documento Identidad" name="passport">
          <Space direction="vertical">
            <Switch
              className={
                userData?.passport?.isAproved
                  ? styles.switchColorSelect
                  : styles.switchColorNotSelect
              }
              checkedChildren="Validado"
              unCheckedChildren="No validado"
              checked={userData?.passport?.isAproved}
            />
            <Button
              type="primary"
              disabled={!userData?.passport?.url ? true : false}
              ghost
              className={styles.btnConfirm}
              onClick={() => handleModalPassport()}
            >
              Ver Documento Identidad
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
};

export default PersonalDataForm;
