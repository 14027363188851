import { Modal, Form, Input, Row, Col, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {
  errorMessage,
  successMessage,
} from '../../../utils/messageHelper/messageHelper';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';
import clientAxios from '../../../config/clientAxios';

const ModalAddUser = ({ handleToggleAddUser, toggleModal, dataUsers, setDataUsers }) => {
  const [loading, setLoading] = useState(false);
  const [formAddUser] = Form.useForm();
  const cancelSubmit = () => {
    formAddUser.resetFields();
    handleToggleAddUser();
  };

  const addUser = async (values) => {
    try {
      setLoading(true);
      await clientAxios.post('/register', { ...values, role: 'Admin' });
      setLoading(false);
      const addNewUser = [...dataUsers, {...values, role: 'Admin'}];
      setDataUsers(addNewUser);
      handleToggleAddUser();
      successMessage('Usuario creado exitosamente');
    } catch (error) {
      setLoading(false);
      errorMessage('Hubo un problema al crear el usuario, o puede que ya exista un usuario con ese email', 3);
    }
  };
  return (
    <Modal
      title="Agregar usuario administrador"
      visible={toggleModal}
      onCancel={handleToggleAddUser}
      footer={null}
    >
      {loading && (
        <LoadingOutlined style={{ fontSize: 30, color: '#034078' }} spin />
      )}
      <Form
        name="formRegister"
        className={styles.addUserForm}
        form={formAddUser}
        onFinish={addUser}
      >
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Porfavor ingrese su nombre',
            },
          ]}
        >
          <Input placeholder="Nombre" className="inputs-logRegister" />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Porfavor ingrese su apellido',
            },
          ]}
        >
          <Input placeholder="Apellido" className="inputs-logRegister" />
        </Form.Item>
        <Form.Item name="phoneNumber">
          <Input placeholder="Teléfono" className="inputs-logRegister " />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Porfavor ingrese un email válido',
            },
          ]}
        >
          <Input placeholder="Email" className="inputs-logRegister" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Porfavor Ingrese una contraseña',
            },
            {
              min: 8,
              message: 'La contraseña debe tener minimo 8 caracteres',
            },
            {
              pattern: /(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*/,
              message:
                'La contraseña debe tener al menos una minúscula, una mayúscula y un número',
            },
          ]}
        >
          <Input.Password
            placeholder="Contraseña"
            className="inputs-logRegister"
          />
        </Form.Item>
        <Row>
          <Col span={6}>
            <Button
              type="primary"
              htmlType="button"
              className={styles.btnCancel}
              onClick={cancelSubmit}
            >
              Cancelar
            </Button>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn-register">
              Agregar usuario
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalAddUser;
