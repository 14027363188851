import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import WeAreTotallia from './WeAreTotallia';
import Mission from './Mission';
import TrackRecord from './TrackRecord';
import Projects from './Projects';
import { useState } from 'react';

const AboutUs = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <WeAreTotallia />
      <Mission />
      <TrackRecord />
      <Projects />
      <Footer />
    </>
  );
};

export default AboutUs;
