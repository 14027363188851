import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import OpenInvestment from '../tables/OpenInvestment';
import clientAxios from '../../../../config/clientAxios';
import CloseInvestment from '../tables/CloseInvestment';
import styles from '../../../../styles/panelAdmin/adminInvestment.module.css';

const { TabPane } = Tabs;

const InversionesAnt = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allDataInvesment, setAllDataInvesment] = useState([]);
  const [dataUser, setDataUser] = useState({});

  let { path } = useRouteMatch();

  useEffect(() => {
    const handleGetAllInvestments = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get('/investments?');
        const allInvesment = response.data;
        setAllDataInvesment(allInvesment);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
        setIsLoading(false);
      }
    };
    handleGetAllInvestments();
    setDataUser(getLocalStorage('user'));
  }, []);

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3>Inversiones</h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="center-container">
            <Link to={`${path}/nuevaInversion`}>
              <Button
                icon={<PlusCircleOutlined />}
                className={styles.btnAddInvestment}
              >
                Nueva Inversion
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="Abiertas">
            <TabPane tab="Abiertas" key="Abiertas">
              <OpenInvestment
                allDataInvesment={allDataInvesment}
                isLoading={isLoading}
                dataUser={dataUser}
              />
            </TabPane>
            <TabPane tab="Cerradas" key="Cerradas">
              <CloseInvestment
                allDataInvesment={allDataInvesment}
                isLoading={isLoading}
                dataUser={dataUser}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default InversionesAnt;
