import { Editor } from 'react-draft-wysiwyg';
import { Button, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState } from 'react';
import { configTools } from './configTools';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from 'styles/panelAdmin/adminTextEditor.module.css';

const TextEditor = ({ onChange, label, initialValue }) => {
  let initialEditor;
  const [hiddenEditor, setHiddenEditor] = useState(true);
  if (initialValue !== null && initialValue !== undefined) {
    const contentBlock = htmlToDraft(initialValue);
    initialEditor = () =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      );
  } else {
    initialEditor = () => EditorState.createEmpty();
  }
  const [editorState, setEditorState] = useState(initialEditor);

  const handleEditorChange = (state) => {
    setEditorState(state);
    const content = draftToHtml(convertToRaw(state.getCurrentContent()));
    onChange(content);
  };
  const handleSeeEditor = () => setHiddenEditor((state) => !state);
  return (
    <>
      <Row justify="space-between" style={{ maxWidth: '500px' }}>
        <p>{label}</p>
        <Button
          type="text"
          shape="circle"
          icon={hiddenEditor ? <DownOutlined /> : <UpOutlined />}
          size="small"
          onClick={handleSeeEditor}
        />
      </Row>
      <Editor
        toolbarHidden={hiddenEditor}
        wrapperClassName={styles.bgEditor}
        editorClassName="editor-class"
        defaultEditorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={configTools}
      />
    </>
  );
};

export default TextEditor;
