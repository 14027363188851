import styles from '../../../../styles/panelAdmin/adminInvestment.module.css';
import clientAxios from '../../../../config/clientAxios';
import { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs, message } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom';
import { PlusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import InfoInvestment from './InfoInvestment'
import Distributions from './Distributions'
import {
  errorMessage,
  successMessage
} from '../../../../utils/messageHelper/messageHelper';

const { TabPane } = Tabs;

export default function DistributionsTab() {

  const history = useHistory();
  const { id, inv } = useParams();

  const [distribution, setDistribution] = useState([]);
  const [offers, setOffers] = useState([])
  const [investment, setInvestment] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [newDistribution, setNewDistribution] = useState(true)

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const distributions = await clientAxios.get(`/distributions/investment/${id}`);
        const investment = await clientAxios.get(`/investments/${id}`);
        const offers = await clientAxios.get(`/offers/investment/${id}`);

        setDistribution(distributions?.data);
        setInvestment(investment?.data[0]);
        setOffers(offers?.data)
        const offersWithShare = offers?.data?.filter((offer) => offer?.share)
        if (offersWithShare.length === offers?.data?.length) {
          setNewDistribution(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [id]);

  const updateShare = async (offers) => {
    try {
      const uptadedOffers = await clientAxios.patch('/offers/share', { offers });
      if (uptadedOffers.status === 200) {
        setNewDistribution(false);
        return message.success('Felicidades, se han guardado los cambios con exito!');
      }
    } catch (error) {
      message.error('Ha ocurrido un error inesperado, intentelo de nuevo.')
      console.log(error);
    }
  }

  const updateShareImport = async (formData) => {
    try {
      const response = await clientAxios.patch(`/offers/investment/${id}/share`, formData);
      if (response.status === 200) {
        const updatedOffers = response.data;
        setOffers(updatedOffers);
        successMessage("Archivo procesado correctamente");
      } else {
        errorMessage(response.message, 0);
      }
    } catch (error) {
      if (error.response) {
        errorMessage(error.response.data.message, 0);
      } else {
        errorMessage('Error: ' + error.message, 0);
      }
    }
  }

  const exportCsv = (csv) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `share-${inv}.csv`;
    hiddenElement.click();
  };

  const handleDowndloadCSV = async () => {
    try {
      const response = await clientAxios.get(`/offers/investment/${id}/share`);
      exportCsv(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3>{inv}</h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="center-container">
            <Link to={`/admin/inversiones/newDistribution/${id}`}>
              <Button
                icon={<PlusCircleOutlined />}
                className={styles.btnAddDistribution}
                disabled={newDistribution}
              >
                Nueva Distribucion
              </Button>
            </Link>
            <ArrowLeftOutlined
              className="btn-Back-inver"
              onClick={() => history.goBack()}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="Info">
            <TabPane tab="Info" key="Info">
              <InfoInvestment
                investment={investment}
              />
            </TabPane>
            <TabPane tab="Distribuciones" key="Distribuciones">
              <Distributions
                key={distribution._id}
                distributions={distribution}
                offersData={offers}
                updateShare={updateShare}
                isLoading={isLoading}
                handleDowndloadCSV={handleDowndloadCSV}
                updateShareImport={updateShareImport}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

