import icon1 from '../../../img/icons/Grupo75.svg';
import icon2 from '../../../img/icons/Grupo74.svg';
import icon3 from '../../../img/icons/Grupo76.svg';
import icon4 from '../../../img/icons/Grupo77.svg';

export const missions = [
  {
    title: 'Transformación',
    content: `Transformamos el mundo de las
      inversiónes inmobiliarias acercando a los
      inversores individuales las mismas
      oportunidades de inversión en  propiedades
      inmobiliarias comerciales que eligen los
      grandes inversores institucionales.`,
    icon: icon1,
  },
  {
    title: 'Oportunidades',
    content: `A través de nuestra plataforma de inversión,
      los inversores individuales del mundo 
      hispano acceden a oportunidades de inversión 
      analizadas y seleccionadas, eligiendo las 
      alternativas que más se ajusten a sus necesidades particulares.`,
    icon: icon2,
  },
  {
    title: 'Beneficios',
    content: `Creemos que las propiedades 
      inmobiliarias comerciales brindan 
      beneficios a los inversores que 
      ningún otro tipo de inversión ofrece.`,
    icon: icon3,
  },
  {
    title: 'Estrategia',
    content: `Buscamos crear riqueza de una forma
      sustentable, basados en estrategias y
      principios sólidos, sirviendo a personas
      que desean una estrategia de inversión
      alternativa inteligente y eficiente.`,
    icon: icon4,
  },
];
