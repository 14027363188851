import React, { useState } from 'react';
import { Col, Row, Space, Radio } from 'antd';
import styles from '../../reports/style/reports.module.css';
import InvestorReport from '../../reports/InvestorReport';
import ContactReport from '../../reports/ContactReport';

const UsersReport = ({ rangePicker }) => {
  const [radioSelect, setRadioSelect] = useState(0);

  const handleRadioSelect = (event) => {
    setRadioSelect(event?.target?.value);
  };

  return (
    <>
      <Row>
        <Col span={24} lg={{ span: 24 }}>
          <Radio.Group onChange={handleRadioSelect} value={radioSelect}>
            <Space direction="vertical">
              <Radio value={0}>Usuarios inversores</Radio>
              <Radio value={1}>Usuarios contacto</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col span={24} lg={{ span: 24 }} className={styles.marginRow}>
          <h4>
            Opcion de Reporte de {radioSelect === 0 ? 'inversores' : 'contacto'} {" "}
            (seleccionar una opción)
          </h4>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={{ span: 24 }}>
          {radioSelect === 0 ? (
            <InvestorReport rangePicker={rangePicker} />
          ) : (
            <ContactReport rangePicker={rangePicker} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default UsersReport;
