import { setLocalStorage } from '../localStorageHelper/localHelper';

export const handleToggleColumns =
  (tableHeadings, setTableHeadings, dataUser, tableName) => (key) => {
    const tableHeadingCopy = tableHeadings.map((tableHeading) => ({
      ...tableHeading,
    }));
    const tableHeadingToUpdate = tableHeadingCopy.find(
      (column) => column.dataIndex === key
    );
    tableHeadingToUpdate.enable = !tableHeadingToUpdate.enable;
    setTableHeadings(tableHeadingCopy);

    const togglePreferenceToSave = {};
    tableHeadingCopy.forEach(({ dataIndex, enable }) => {
      togglePreferenceToSave[dataIndex] = enable;
    });
    
    setLocalStorage(`${dataUser.uid}${tableName}`, togglePreferenceToSave);
  };
