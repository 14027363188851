import {
  successMessage,
  loadingMessage,
  errorMessage,
  warningMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row, Col, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import {
  LoadingOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { initialState } from '../initialStateInvestment/initialStateInvestment';
import clientAxios from '../../../../config/clientAxios';
import DatosPublicacion from '../forms/DatosPublicacion';
import InfoComplementaria from '../forms/InfoComplementariaAnt';
import InstruccionesBancarias from '../forms/IntruccionesBancariasAnt';
import styles from '../../../../styles/panelAdmin/adminInvestment.module.css';
import Attachments from '../forms/Attachment';

const { TabPane } = Tabs;

const NuevaInversionAnt = () => {
  const [newInvestment, setNewInvestment] = useState(initialState);
  const [singleInvestment, setSingleInvestment] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const editInvesment = async () => {
        try {
          const result = await clientAxios.get(`/investments/${id}`);
          const dataInvestment = result.data[0];
          const city = dataInvestment.city?.map((city) => ({ city }));
          const country = dataInvestment.country?.map((country) => ({
            country,
          }));
          const investment = { ...dataInvestment, city, country };
          setSingleInvestment(investment);
          setNewInvestment(investment);
          setLoading(false);
        } catch (error) {
          setLoading(true);
        }
      };
      editInvesment();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleInvestment = (value) => {
    setNewInvestment((state) => ({ ...state, ...value }));
  };

  const handleUpdateInvestmentAttachment = (attachmentsUpdate) => {
    setSingleInvestment((state) => ({
      ...state,
      attachments: { ...state?.attachments, ...attachmentsUpdate },
    }));
  };

  const editMode = !!id;

  const handlePublish = async () => {
    if (newInvestment.name !== '') {
      try {
        loadingMessage('Publicando Inversion');
        await clientAxios.post('/investments', newInvestment);
        successMessage('Publicada');
        history.goBack();
      } catch (error) {
        errorMessage('Hubo un Error, intente nuevamente');
      }
    } else {
      warningMessage(
        'Debe Ingresar el nombre de la inversión y guardar los cambios'
      );
    }
  };

  const validateCityAndCountry = ({ city, country, ...rest }) => {
    const investmentToUpdate = { ...rest };
    if (country[0]?.country) {
      const countryData = country?.map((c) => c.country);
      investmentToUpdate.country = [...countryData]
    }
    if (city[0]?.city) {
      const countryData = city?.map((c) => c.city);
      investmentToUpdate.city = [...countryData]
    }
    return investmentToUpdate;
  }

  const handleEditPublish = async () => {
    const editInvestment = {
      ...newInvestment,
      active: newInvestment.isOpen,
    };
    try {
      loadingMessage('Actualizando Inversión');
      const investmentToUpDate = validateCityAndCountry(editInvestment);
      await clientAxios.put(`/investments/${id}`, investmentToUpDate);
      successMessage('Actualizada');
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un Error, intente nuevamente');
    }
  };

  const buttonPublish = (
    <Button
      icon={<PlusCircleOutlined />}
      className={styles.btnCreateInvestment}
      onClick={handlePublish}
    >
      Crear Inversión
    </Button>
  );
  const buttonEdit = (
    <Button
      icon={<EditOutlined />}
      className={styles.btnEditeInvestment}
      onClick={handleEditPublish}
    >
      Actualizar Inversión
    </Button>
  );

  if (loading) {
    return (
      <>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </>
    );
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row justify="center" className="bg-title-nuevaInversion">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3>
            {editMode
              ? `Editar Inversión: ${singleInvestment?.name}`
              : 'Nueva Inversión'}
          </h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="center-container">
            {editMode ? buttonEdit : buttonPublish}
          </div>
        </Col>
      </Row>
      <div>
        <Tabs>
          <TabPane tab="Datos de Publicación" key="datosPublicacion">
            <DatosPublicacion
              handleInvestment={handleInvestment}
              singleInvestment={singleInvestment}
            />
          </TabPane>
          <TabPane tab="Adjuntos" key="adjuntos">
            <Attachments
              handleInvestment={handleInvestment}
              singleInvestment={singleInvestment}
              handleUpdateInvestmentAttachment={
                handleUpdateInvestmentAttachment
              }
            />
          </TabPane>
          <TabPane tab="Información Complementaria" key="infoComplementaria">
            <InfoComplementaria
              handleInvestment={handleInvestment}
              singleInvestment={singleInvestment}
            />
          </TabPane>
          <TabPane tab="Vincular banco" key="Vincular banco">
            <InstruccionesBancarias
              handleInvestment={handleInvestment}
              singleInvestment={singleInvestment}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default NuevaInversionAnt;
