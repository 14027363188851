import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import { useState } from 'react';
import Learn from './Learn';
const LearnPage = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);
  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <Learn />
      <Footer />
    </>
  );
};

export default LearnPage;
