import { Row, Card } from 'antd';
import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import style from '../../styles/Aprender/aprender.module.css';
import img from '../../img/imgSlide2.jpg';
import { useParams, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import clientAxios from 'config/clientAxios';
import LoadingComponent from '../Loader/LoadingComponent';
import { warningMessage } from 'utils/messageHelper/messageHelper';

const OneArticlePage = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const [bodyArticle, setBodyArticle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const { id } = useParams();
  const { pathname } = useLocation();

  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/articlesPosted/${id}`);
        setBodyArticle(res.data[0].body);
        setIsLoading(false);
      } catch (error) {
        warningMessage(
          'La description del artículo no está disponible en este momento',
          3
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchArticle();
  }, [id]);

  useEffect(() => {
    const createVisitPage = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (!user) return;
        if (bodyArticle.length > 0) return;

        const payload = {
          contentType: 'ARTICLE',
          details: {
            article: id
          },
          pagePath: pathname,
        };
        await clientAxios.post('/page-visit', payload)
      } catch (error) {}
    }
    createVisitPage()
  }, [id, bodyArticle, pathname]);

  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <div className={style.container}>
        <img src={img} alt="edificio" className={style.imgEdificio} />
        <h3 className={style.title}>Noticias</h3>
      </div>
      <Row justify="center">
        <LoadingComponent isLoading={isLoading}>
          <Card
            style={{ width: 900, border: 'none' }}
            bodyStyle={{ padding: 0, backgroundColor: '#F5F5F5' }}
            className={style.cardDetailArticle}
          >
            {bodyArticle.map((body, index) => (
              <>
                <div
                  dangerouslySetInnerHTML={{ __html: body?.paragraph }}
                  key={index}
                  className={style.bodyParagraph}
                />
                {body.image.map((image, index) => (
                  <div className={style.containerImg}>
                    <img
                      src={image}
                      alt=""
                      key={index}
                      className={style.imgBodyArticle}
                    />
                  </div>
                ))}
              </>
            ))}
          </Card>
        </LoadingComponent>
      </Row>
      <Footer />
    </>
  );
};

export default OneArticlePage;
