import { Col, Divider, Input, Row, InputNumber } from 'antd';
import { formatter, parser } from '../../../../utils/formatParseHelper/formatParseHelper';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import style from '../modal/modal.module.css';


export default function InfoInvestment({investment}) {
  return (
    <div className={style.modalCenter}>
      <Row gutter={16}>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Fecha de inicio:</label>
          <br />
          <Input
            className='mb-2'
            value={dateFormat(investment?.complementaryInformation?.startDate)}
          />
          <br />
          <label>Fecha de cierre:</label>
          <br />
          <Input
            value={dateFormat(investment?.complementaryInformation?.finishDate)}
          />
        </Col>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Monto Minimo:</label>
          <InputNumber
            className='mb-2'
            value={investment?.complementaryInformation?.minAmount}
            formatter={formatter}
            parser={parser}
          />
          <label>Monto Maximo:</label>
          <InputNumber
            value={investment?.complementaryInformation?.maxAmount}
            formatter={formatter}
            parser={parser}
          />
        </Col>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Nombre de la inversión:</label>
          <br />
          <Input value={investment?.name} />
        </Col>
      </Row>
      <Divider orientation="left"></Divider>
      <Row gutter={16}>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Tipo de inversion:</label>
          <br />
          <Input value={investment?.investmentType} />
        </Col>
        {investment.isOpen ? (
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Cantidad de oferentes:
              <Input value="" />
            </label>
          </Col>
        ) : null}
        <Col className="gutter-row" sm={24} md={8}>
          <label>
            Cantidad de inversores:
            <Input value="" />
          </label>
        </Col>
      </Row>
      <Divider orientation="left"></Divider>
      <Row gutter={16}>
        <Col className="gutter-row" sm={24} md={8}>
          {investment.isOpen ? (
            <>
              <label>Monto comprometido:</label>
              <InputNumber
                className="mb-2"
                defaultValue={0}
                formatter={formatter}
                parser={parser}
                value={investment?.amountInvested}
              />
            </>
          ) : (
            <>
              <label>Monto distribuido:</label>
              <InputNumber
                className="mb-2"
                defaultValue={0}
                formatter={formatter}
                parser={parser}
                value={investment?.amountDistributed}
              />
            </>
          )}
          <label>Firmante:</label>
          <br />
          <Input value={investment?.complementaryInformation?.signer} />
        </Col>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Monto invertido:</label>
          <InputNumber
            className="mb-2"
            value={investment?.amountInvested}
            formatter={formatter}
            parser={parser}
          />
          <label>Estado de publicacion:</label>
          <br />
          <Input value={investment?.isPosted ? 'Si' : 'No'} />
        </Col>
        <Col className="gutter-row" sm={24} md={8}>
          <label>Porcentaje de inversion:</label>
          <br />
          <Input value="" className='mb-2' />
          <br />
          <label>Banco:</label>
          <br />
          <Input value={investment?.bank?.name} />
        </Col>
      </Row>
    </div>
  );
}
