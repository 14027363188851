import React from 'react';
import { Input, Form } from 'antd';

function BankUsaForm() {
  return (
    <div>
      <Form.Item label="Nombre del banco" name="bankEntity">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Dirección del banco (calle, nro, codigo postal, ciudad, país)" name="addressBankEntity">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Numero de la cuenta" name="numberBankEntity">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Numero de routing / ABA" name="ABA">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Titular de la cuenta" name="beneficiaryName">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Dirección del titular (calle, nro, codigo postal, ciudad, país)" name="addressBeneficiary">
        <Input className="prefix-width-modal" />
      </Form.Item>
      <Form.Item label="Comentarios / aclaraciones / consultas" name="commentsUSA">
        <Input className="prefix-width-modal" />
      </Form.Item>
    </div>
  );
}

export default BankUsaForm;