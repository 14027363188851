import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';

export const TableHeadingsAmount = [
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
  {
    title: 'Inversión',
    dataIndex: 'investments',
    key: 'investments',
    enable: true,
  },
  {
    title: 'Monto invertido',
    dataIndex: 'totalInvested',
    key: 'totalInvested',
    enable: true,
    render: (key) => currentFormat(key),
  },
];
