import { Button } from 'antd';
import { dateFormat } from '../../../../../utils/dateFormatHelper/dateFormat';

export const tableColumns = (
  handleUpdateStatus,
  isUpdatingStatus,
  handleInvestorProfile,
  handleSetOffer,
  handleOfferModalStatus
) => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (key) => {
      const createdAtNewFormat = dateFormat(key);
      return createdAtNewFormat;
    },
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
    render: (key, data) => {
      return (
        <Button type="link" onClick={() => handleInvestorProfile(data.uid)}>
          {key}
        </Button>
      );
    },
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
  {
    title: 'Tel.',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    enable: true,
  },
  {
    title: 'Fecha de transferencia',
    dataIndex: 'transferDate',
    key: 'transferDate',
    enable: true,
  },
  {
    title: 'Monto Invertido',
    dataIndex: 'moneyInvested',
    key: 'moneyInvested',
    enable: true,
  },
  {
    title: 'Ofertas de inversión',
    dataIndex: 'investmentOffers',
    key: 'investmentOffers',
    enable: true,
    render: (key) => {
      const offers = key.map((item) => {
        const offer = (
          <li key={item?.investment?.name}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleSetOffer(item);
                handleOfferModalStatus();
              }}
            >
              {item?.investment?.name}
            </Button>
          </li>
        );
        return offer;
      });
      return <ul style={{ listStyleType: 'none' }}>{offers}</ul>;
    },
  },
];
