import {
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';

const token = getLocalStorage('token');

export const uploadProps = {
  name: 'attachment',
  action: `${process.env.REACT_APP_API_URL}/attachments`,
  headers: {
    authorization: token,
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      successMessage(`${info.file.name} carga de imagen exitosa`);
    } else if (info.file.status === 'error') {
      errorMessage(`${info.file.name} error al cargar la imagen`);
    }
  },
};
