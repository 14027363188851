import ROL from '../../config/roles';
import clientAxios from '../../config/clientAxios';
import {
  setLocalStorage,
  getLocalStorage,
} from '../../utils/localStorageHelper/localHelper';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Row, Col, message } from 'antd';
import React, { useState } from 'react';

const FormLogin = ({ setUser, handleToggleLogin, handleToggleRegister }) => {
  const history = useHistory();
  const [isLoader, setIsLoader] = useState(false);

  const key = 'login';

  const [formLogin] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      setIsLoader(true);
      const response = await clientAxios.post('/login', {
        email: values.email,
        password: values.password,
      });
      setLocalStorage('user', response.data.user);
      setLocalStorage('token', response.data.token);
      const loggedUser = getLocalStorage('user');
      setUser(loggedUser);
      setIsLoader(false);
      if(response){
        handleToggleLogin();
      }
      if (loggedUser && loggedUser.role === ROL.ADMIN) {
        message.success({
          content: 'Bienvenido al Panel Administrator',
          key,
          duration: 3,
        });
        formLogin.resetFields();
        handleToggleLogin();
        history.push('/admin/inversiones');
        window.location.reload();
      }
      if (loggedUser && loggedUser.role === ROL.CONTACTO) {
        message.success({ content: 'Bienvenido a Totallia', key, duration: 3 });
        handleToggleLogin();
        history.push('/investorRoom/investments');
      }
      if (loggedUser && loggedUser.role === ROL.INVERSOR) {
        message.success({ content: 'Bienvenido a Totallia', key, duration: 3 });
        handleToggleLogin();
        history.push('/investorRoom/investments');
      }
    } catch (error) {
      setIsLoader(false);
      message.error({
        content: 'Usuario o Contraseña incorrecta',
        key,
        duration: 3,
      });
    }
  };

  const signUp = () => {
    handleToggleLogin();
    handleToggleRegister();
  };

  const cancelSubmit = () => {
    formLogin.resetFields();
    handleToggleLogin();
  };

  return (
    <div className="form-register-position">
      <Row>
        <Col span={24}>
          <Form
            name="formLogin"
            className="from-login"
            form={formLogin}
            onFinish={handleSubmit}
            autoComplete='off'
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Porfavor ingrese un email válido',
                },
              ]}
            >
              <Input placeholder="Usuario *" className="inputs-logRegister" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message:
                    'Campo requerido. La contraseña debe ser alfanumérica, con un mínimo de 8 caracteres y al menos 1 mayúscula',
                },
              ]}
            >
              <Input.Password
                placeholder="Contraseña *"
                className="inputs-logRegister"
              />
            </Form.Item>
            <Row className="margin-btn">
              <Col span={6}>
                <Button
                  type="primary"
                  htmlType="button"
                  className="cancelBtn-formLogin"
                  onClick={cancelSubmit}
                >
                  Cancelar
                </Button>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Button
                    loading={isLoader}
                    type="primary"
                    htmlType="submit"
                    className="btn-register"
                  >
                    Ingresar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <div>
              <p className="link-forgot-pass">
                ¿No tienes cuenta?
                <Button
                  type="link"
                  htmlType="button"
                  className="btn-SingUp"
                  onClick={signUp}
                >
                  Sign Up
                </Button>
              </p>
              <a
                href="/resetPassword"
                target="_blank"
                rel="noopener noreferrer"
                className="link-forgot-pass"
              >
                ¿Se te olvidó tu contraseña?
              </a>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default FormLogin;
