import { useState, useEffect } from 'react';
import { Row, Descriptions, Divider, Button, Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import { offerStatus } from 'config/offerStatus';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import AttachmentModal from 'components/Admin/Investors-Section/AttachmentModal/AttachmentModal';
import OfferModal from 'components/Admin/Investors-Section/OfferModal/OfferModal';

const InvestmentAdminView = () => {
  const offerId = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState({});
  const [investor, setInvestor] = useState({});

  
  // AttachmentModal
  const [attachmentId, setAttachmentId] = useState('');
  const [attachmentModalStatus, setAttachmentModalStatus] = useState(false);
  const [offerModalStatus, setOfferModalStatus] = useState(false);
  
  const handleOfferModalStatus = () => setOfferModalStatus((status) => !status);
  const handleAttachmentModalStatus = () =>
    setAttachmentModalStatus((status) => !status);


  const colorKey = {
    0: { color: 'red' },
    4: { color: 'green' },
    8: { color: 'blue' },
  }

  const tooltipText = {
    0: 'Es Problable que no puedas subir archivos hasta que la revision del contrato haya finalizado',
    4: 'Esperando Aceptacion de Transferencia.',
    8: 'Esperando la transferencia. Recuerde subir el comprobante en el boton "Subir Archivos"'
  }

  useEffect(() => {
    const fetchOffer = async () => {
      setIsLoading(true);
      const response = await clientAxios.get(`/offers/${offerId}`);
      const offerData = response.data;
      setInvestor(offerData.user)
      
      offerData.user = offerData.user._id;
      setOffer(offerData);

      setIsLoading(false);
    }
    
    fetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const handleOffer = (offer) => setOffer(offer);


  const handleModal = () => {
    handleOfferModalStatus();
    handleOffer(offer);
  }

  return (
    <>
        <Row align="middle">
            <BarChartOutlined
                style={{
                fontSize: '40px',
                color: '#08c',
                marginRight: '10px',
                marginBottom: '21px',
                }}
            />
            <h3>inversiones - {investor.firstName} {investor.lastName}</h3>
        </Row>
        <Divider />
        <LoadingInvestorRoom isLoading={isLoading}>
          <h2>{offer.investment?.name} <Button onClick={handleModal} type="primary" style={{ border: 'none', margin: 6, float: 'right' }}>Ver Archivos/Documentos</Button></h2>
          <Descriptions>
            <Descriptions.Item label="Fecha de Oferta">{dateFormat(offer.createdAt)}</Descriptions.Item>
            <Descriptions.Item label="Monto Comprometido">{currentFormat(offer.amountOffered)}</Descriptions.Item>
            <Descriptions.Item label="Estado" contentStyle={ colorKey[offer.status] }>
              <Tooltip title={tooltipText[offer.status]}>{offerStatus[offer.status]}</Tooltip>
            </Descriptions.Item>
          </Descriptions>

          <AttachmentModal
            attachmentModalStatus={attachmentModalStatus}
            setAttachmentModalStatus={setAttachmentModalStatus}
            attachmentId={attachmentId}
          />
          <OfferModal
          offerModalStatus={offerModalStatus}
          handleOfferModalStatus={handleOfferModalStatus}
          offer={offer}
          setOffer={setOffer}
          setAttachmentId={setAttachmentId}
          handleAttachmentModalStatus={handleAttachmentModalStatus}
        />
        </LoadingInvestorRoom>      
    </>
  );
};

export default InvestmentAdminView;
