import styles from 'styles/contactPage/contactPage.module.css';

const settings = {
  mmInverted: 26,
  investors: 350,
  proyect: 46,
};

const items = [
  { title: `U${settings?.mmInverted} MM`, subtitle: 'en diferentes proyectos' },
  {
    title: `${settings?.investors} INVERSORES`,
    subtitle: 'participaron de nuestros proyectos',
  },
  { title: `${settings?.proyect} PROYECTOS`, subtitle: 'fondeados' },
];

const TrackRecordItem = ({ title, subtitle }) => {
  return (
    <div className="col py-3 text-center">
      <h4 className="text-white">+{title}</h4>
      <h6 className="fw-normal" style={{ color: '#d8d8d8' }}>
        {subtitle}
      </h6>
      <hr className={styles.dividerTrackRecord} />
    </div>
  );
};

const TrackRecords = () => {
  return (
    <div
      className="container-fluid py-5"
      style={{ backgroundColor: '#142054' }}
    >
      <div className="row justify-content-center">
        {items.map(({ subtitle, title }) => (
          <TrackRecordItem subtitle={subtitle} title={title} key={title} />
        ))}
      </div>
    </div>
  );
};

export default TrackRecords;
