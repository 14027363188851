import { Row, Col, Table } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { tableDistributionsById } from './table-Structure/DistributionsById'
import clientAxios from '../../../../config/clientAxios';

export default function DistributionById() {
  const history = useHistory();
  const { id } = useParams();
  const [distribution, setDistribution] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDistribution = async () => {
      try {
        setLoading(true);
        const dataDistribution = await clientAxios.get(`/distributions/${id}`);
        setDistribution(dataDistribution.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getDistribution();
  }, [id]);

  return (
    <div>
      <Row className="mb-4">
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <h3>
            {distribution?.investment?.name
              ? distribution?.investment?.name
              : '-'}
          </h3>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}></Col>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <div className="center-container">
            <ArrowLeftOutlined
              className="btn-Back-inver"
              onClick={() => history.goBack()}
            />
          </div>
        </Col>
      </Row>
      <p className="btn-closeInv-filter">
        Monto Total:{' '}
        <b
          className={
            distribution?.amountTotal
              ? 'bg-success text-white ps-5 pe-5'
              : 'bg-secondary text-white ps-5 pe-5'
          }
        >
          ${distribution?.amountTotal ? distribution?.amountTotal : 0}
        </b>
      </p>
      <p className="btn-closeInv-filter me-5">
        Total: {distribution?.rents ? distribution?.rents?.length : 0}
      </p>
      <Table
        dataSource={distribution?.rents}
        columns={tableDistributionsById()}
        scroll={{ x: 1000 }}
        loading={loading}
        pagination={false}
      ></Table>
    </div>
  );
}
