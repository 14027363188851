import { Col, Modal, Row, Button } from 'antd';
import imgModalRegister from '../../img/registerImage.jpg';
import FormRegister from './FormRegister';
import iconGoogle from '../../img/google.png';

const ModalRegister = ({
  isModalRegisterVisible,
  handleToggleRegister,
  googleLogin,
  facebookLogin,
  setUser,
}) => {
  return (
    <Modal
      footer={null}
      width={900}
      bodyStyle={{ padding: 0, background: '#f5f5f5' }}
      visible={isModalRegisterVisible}
      onCancel={handleToggleRegister}
    >
      <Row>
        <Col md={11} xs={0}>
          <img
            src={imgModalRegister}
            alt="Imágen Edificio"
            className="img-register"
          />
        </Col>
        <Col md={11} xs={19}>
          <h4 className="title-modal-register">Unite a Totallia</h4>
          <FormRegister
            handleToggleRegister={handleToggleRegister}
            setUser={setUser}
          />
        </Col>
        <Col md={2} xs={4} className="div-btn-faceGoggle">
          <Button
            type="primary"
            className="btn-google position-btn-face-google"
            onClick={googleLogin}
          >
            <img src={iconGoogle} className="iconGoogle" alt="" />
          </Button>
          <Button
            type="primary"
            className="btn-facebook position-btn-face-google"
            onClick={facebookLogin}
          >
            <i className="fab fa-facebook-f"></i>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalRegister;
