import styles from '../../../styles/sliderHome.module.css';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ItemSlider = ({ img, children, imgClassName }) => (
  <div className={styles.container}>
    <img
      className={`${styles.containerSliderimg} ${imgClassName || ''}`}
      src={img}
      alt="edificio"
    />
    {children ? (
      children
    ) : (
      <div className={styles.containerText}>
        <div>
          <h1
            className={`${styles.positionTitle} text-uppercase text-center mb-1`}
          >
            Inversiones inteligentes<br></br>
            para inversores modernos.
          </h1>
          <p className={`${styles.positionText} text-center`}>
            Generá una rentabilidad neta de hasta un 15% anual.
          </p>
        </div>
        <Link to={'/investments'}>
          <Button className={styles.btn}>VER INVERSIONES</Button>
        </Link>
      </div>
    )}
  </div>
);

export default ItemSlider;
