import moment from 'moment';

export const getTodayDate = () => {
  const dateNow = Date.now();
  return new Date(dateNow);
};

export const getOneMonthLess = () => {
  const dateNow = Date.now();
  const todayDate = new Date(dateNow);
  todayDate.setMonth(todayDate.getMonth() - 1);
  return moment.utc(todayDate);
};
