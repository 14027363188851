import React, { useState, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import {
  getSessionStorage,
  setSessionStorage,
} from 'utils/sessionStorageHelper/sessionHelper';
import { successMessage } from 'utils/messageHelper/messageHelper';
import { useQuerySearch } from 'custom/hooks/queryHook';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { actionRedirect } from 'utils/actionRedirectHelper/actionRedirect';
import { dataToSendDocuSign } from 'utils/prepareDataToSignDocument/dataToSendDocuSign';
import { useHistory, useParams } from 'react-router-dom';
import { infoAlert } from 'utils/alertMessageHelper/alertMessage';
import clientAxios from 'config/clientAxios';
import PdfReader from './PdfReader/PdfReader';
import InvestNowLogo from 'components/investor-room/components/investNowLogo/InvestNowLogo';
import styles from 'components/investor-room/pages/investNow/investNow.module.css';
import FormDocuSign from './form/FormDocuSign';
import { getLocalStorage, setLocalStorage } from 'utils/localStorageHelper/localHelper';
import { removeLocalStorage } from 'utils/localStorageHelper/localHelper';
import { warningModalConfirm } from 'utils/modalMessageHelper/modalMessage';
import LoaderDocuSign from '../loaderDocuSign/LoaderDocuSign';

const DocuSign = () => {
  const [dataUser, setDataUser] = useState([]);
  const [pdfContract, setPdfContract] = useState('');
  const [docuSignEnvelopeId, setDocuSignEnvelope] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { Text } = Typography;
  const { id } = useParams();
  const history = useHistory();
  const query = useQuerySearch();

  const statusOfSigning = query.get('event');
  const pointReturn = getSessionStorage('pointReturn');

  // eslint-disable-next-line
  useEffect(async () => {
    if (statusOfSigning === 'signing_complete') {
      successMessage('Documento firmado');
      
      const user = getLocalStorage('user');
      const offerId = getLocalStorage('offerId');
      const attachmentResponse = await clientAxios.post(
        '/attachments/envelope', {
          investment: id,
          user: user._id,
          private: true,
          key: getLocalStorage('envelopeId'),
          name: "envelopeId"
      });
      
      await clientAxios.put(
        `/user/offers/${offerId}`,
        {
          status: 0,
          attachments: [{
            _id: attachmentResponse.data._id,
            name: "Contrato",
            toValidate: true,
          }]
        }
      );


      
      removeLocalStorage('amount');
      removeLocalStorage('offerId');
      removeLocalStorage('envelopeId');
      history.push(`/investorRoom/myInvestments/${offerId}`);

    } else if (statusOfSigning === 'decline') {
      setSessionStorage('nextTab', 'tab2');
      history.push(pointReturn);
    }
    
    // eslint-disable-next-line
  }, [pointReturn, history, statusOfSigning]);

  useEffect(() => {
    const envelopeDocuSign = async () => {
      try {
        if (docuSignEnvelopeId !== '') {
          const dataToSend = {
            investment: id,
            user: dataUser._id,
            envelopeId: docuSignEnvelopeId,
          };

          await clientAxios.post('/saveEnvelopeDocuSign', dataToSend);
        }
      } catch (error) {
        errorMessage('Tuvimos inconvenientes para guardar el contrato');
      }
    };
    envelopeDocuSign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docuSignEnvelopeId]);

  const handleReturn = () => {
    const pointReturn = getSessionStorage('pointReturn');
    setSessionStorage('nextTab', 'tab1');
    history.push(pointReturn);
  };

  useEffect(() => {
    const getDocument = async () => {
      try {
        const user = getLocalStorage('user');
        setDataUser(user);
        const response = await clientAxios.get(`/getContractDocuSign/${id}`);
        const Response = response.data;
        setPdfContract(Response);
      } catch (error) {
        errorMessage('Tuvimos inconvenientes para mostrar el contrato');
      }
    };
    getDocument();
  }, [statusOfSigning, id]);

  const handleDocuSign = async (values) => {
    if (getLocalStorage('offerId')) {
      const dataToSend = dataToSendDocuSign(dataUser, values);
      try {
        setIsLoading(true);
        const response = await clientAxios.post(
          '/createEnvelopeDocuSign',
          dataToSend
        );
        const dsResponse = response.data;
        setDocuSignEnvelope(dsResponse.envelopeId);
        setLocalStorage("envelopeId", dsResponse.envelopeId);
        const redirectUrl = dsResponse.redirectUrl;
        if (redirectUrl !== undefined) {
          actionRedirect(redirectUrl);
        } else {
          errorMessage('Tuvimos inconvenientes al acceder a docuSign');
        }
      } catch (error) {
        errorMessage('Tuvimos inconvenientes al acceder a los datos');
      } finally {
        setIsLoading(false);
      }
    } else {
      warningModalConfirm(
        'Importante',
        'Antes de firmar ingrese el monto a ofertar en el paso 1.',
        handleReturn
      );
    }
  };

  return (
    <LoaderDocuSign isLoading={isLoading}>
      <Row align="middle" justify="center">
        <Text className={styles.title}>Invertí de manera rápida y facil</Text>
      </Row>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <InvestNowLogo />
      </Row>
      <Row className={styles.container}>
        <Col span={24} lg={{ span: 12 }} className={styles.containerSign}>
          <h4>Firma de contrato</h4>
          {infoAlert(
            'Informacion.',
            'Si usted posee mas firmantes para el contrato, puede agregar el nombre completo y el mail, se les informara por mail a cada firmante para que puedan firmar.',
            'info'
          )}
          <FormDocuSign onFinish={handleDocuSign} />
        </Col>
        <Col span={24} lg={{ span: 12 }} className={styles.containerPdfReader}>
          <PdfReader pdfFile={pdfContract} />
        </Col>
      </Row>
    </LoaderDocuSign>
  );
};

export default DocuSign;
