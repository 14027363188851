import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';

const notificationPostHelper = async (title, user) => {
  try {
    await clientAxios.post('/notifications', { title: title, user: user });
  } catch (error) {
    errorMessage('Error al actualizar');
  }
};

export default notificationPostHelper;
