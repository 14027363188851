import { useContext, useState } from 'react';
import { Layout, Menu, Row, Col, Button, Dropdown, message } from 'antd';
import { 
  UserOutlined, 
  ExportOutlined, 
  ToolOutlined, 
  DownOutlined, 
  NotificationOutlined, 
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import ModalLogin from './Login-Register/ModalLogin';
import ModalRegister from './Login-Register/ModalRegister';
import TheNavbarMobile from './TheNavbarMobile';
import { removeLocalStorage } from '../utils/localStorageHelper/localHelper';
import logoNavbar from 'img/totallia-logo-color.svg';
import {googleProvider, facebookProvider} from '../config/googleFacebook';
import { isUserRoleAdmin } from 'utils/isUserRoleAdminHelper/isUserRoleAdmin';
import styles from 'styles/theNavbar/theNavbar.module.css';
import { removeSessionStorage } from 'utils/sessionStorageHelper/sessionHelper';
import { NotificationContext } from 'provider/NotificationProvider';

const TheNavbar = ({ user, setUser, handleToggleRegister, isModalRegisterVisible }) => {
  const history = useHistory();
  const [isModalLoginVisible, setIsModaLoginlVisible] = useState(false);
  const [notification] = useContext(NotificationContext);
  
  const handleToggleLogin = () => setIsModaLoginlVisible((state) => !state);

  const logout = () => {
    try {
      removeLocalStorage('user');
      removeLocalStorage('token');
      removeSessionStorage('nextTab')
      removeSessionStorage('pointReturn')
      removeSessionStorage('token')
      setUser(null);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

   const menu = (
     <Menu className={styles.MenuDropDown}>
      <Menu.Item  icon={<UserOutlined />}  key="perfil" className={styles.menuColorItem}>
         <Link to={'/investorRoom/Profile'} >
           Perfil
         </Link>
       </Menu.Item>
       <Menu.Item  
         icon={<NotificationOutlined className={notification && `${styles.flickered} text-danger`} />} 
         key="novedades" 
         className={!notification ? styles.menuColorItem : 'bg-light'}
       >
         <Link to={'/investorRoom/novelties'}>
           Notificaciones
         </Link>
       </Menu.Item>
       {isUserRoleAdmin(user) && (
         <Menu.Item key="admin" icon={<ToolOutlined />} className={styles.menuColorItem}>
           <Link to="/admin/inversiones">Panel Admin</Link>
         </Menu.Item>
       )}
       <Menu.Item
         key="logout"
         icon={<ExportOutlined />}
         onClick={logout}
         danger
       >
         Cerrar Sesión
       </Menu.Item>
     </Menu>
   );

  const key = 'googleFacebookLogin';

  const googleLogin = async () => {
    try {
      const loggedUser = await googleProvider();
      setUser(loggedUser);
      if (isModalLoginVisible === true) {
        message.success({ content: 'Bienvenido a Totallia', key, duration: 5 });
        handleToggleRegister();
        history.push('/investorRoom/investments');        
        }     
      if (isModalRegisterVisible === true) {
        if (!loggedUser.firstName){
          message.success({ content: 'Bienvenido a Totallia: Ingresa al Menu, Panel del inversor y luego a Perfil para completar los datos que hacen falta en el perfil.', key, duration: 7 });
          handleToggleRegister();
          history.push('/thankyoupage');
      }}
      window.location.reload();
    } catch (error) {
      message.error({content:'Algo salio mal... Intentalo de nuevo en unos momentos', key, duration: 3});
    }
  };

  const facebookLogin = async () => {
    try {
      const loggedUser = await facebookProvider();
      message.success({content:'Bienvenido a Totallia', key, duration:3})
      setUser(loggedUser);
      if (isModalLoginVisible === true) {
        if (!loggedUser.firstName) {
          message.success({ content: 'Bienvenido a Totallia: Ingresa al Menu, Panel del inversor y luego a Perfil para completar los datos que hacen falta en el perfil.', key, duration: 7 });
        }
        handleToggleLogin();
      }
      if (isModalRegisterVisible === true) {
        message.success({ content: 'Bienvenido a Totallia', key, duration: 3 });
        handleToggleRegister();
        history.push('/thankyoupage');
      }
    } catch (error) {
      if(error.code === 'auth/account-exists-with-different-credential'){
        message.error({content: 'Error: el usuario fué registrado con otro método. Intenta iniciar sesión con tu email o cuenta de Google', key, duration:3});
      }
    }
  };

  return (

  <Layout>
    <div className="bg-navbar" >
      <Row className="display-none-mobile">
        <Col span={4}>
          <Link to="/">
            <img src={logoNavbar} className="logoTotalliaNavbar" alt="logo" />
          </Link>
        </Col>
        <Col span={20}>
          <Menu className="menu-items-nav" mode="horizontal">
            <Menu.Item key="inversiones">
              <Link to="/investments" className="items-navbar">INVERSIONES</Link>
            </Menu.Item>
            {user && (
              <Menu.Item  key="mis-inversiones">
                <Link to='/investorRoom/myInvestments' className="items-navbar">
                  MI CARTERA
                </Link>
              </Menu.Item>
            )}
            <Menu.Item  key="aprender">
              <Link to="/aprender" className="items-navbar">NOTICIAS</Link>
            </Menu.Item>
            <Menu.Item  key="nosotros">
              <Link to="/aboutUs" className="items-navbar">NOSOTROS</Link>
            </Menu.Item>
            <Menu.Item  key="contacto">
              <Link to="/contacto" className="items-navbar">CONTACTO</Link>
            </Menu.Item>
            <Menu.Item  key="logButtons" className={styles.dropdownItem}>
            {!user && (
              <>
                <Button
                  className="btn-navbar btn-navbar-register"
                  onClick={handleToggleRegister}>
                  Registrarse
                </Button>
                <Button className="btn-navbar" onClick={handleToggleLogin}>
                  Ingresar
                </Button>
              </>
            )}
            {user && (
              <Dropdown overlay={menu} placement="bottomCenter" arrow>
                <p style={{fontSize:'15px'}} className={`${styles.text}${styles.flickered}`}>
                  {user.firstName}
                  <DownOutlined
                      className={notification && styles.flickered}
                      style={{ fontSize: '13px', marginLeft: '4px', color: 'red' }}
                    />
                </p>
              </Dropdown>
            )}
            </Menu.Item>
          </Menu>
        </Col>      
      </Row>
      <Row className="display-none-desktop nav-mobile">
        <TheNavbarMobile 
          user={user} 
          handleToggleLogin={handleToggleLogin} 
          handleToggleRegister={handleToggleRegister}
          logout={logout}
          notification={notification}
        />
        <h4 
          className={notification ? `title-nav-mobile ${styles.flickered}` : "title-nav-mobile"}
        >
          Totallia
        </h4>
      </Row>
    </div>
    <ModalLogin 
      isModalLoginVisible={isModalLoginVisible} 
      handleToggleLogin={handleToggleLogin} 
      handleToggleRegister={handleToggleRegister} 
      setUser={setUser}
      googleLogin={googleLogin}
      facebookLogin={facebookLogin}
    />
    <ModalRegister 
      isModalRegisterVisible={isModalRegisterVisible} 
      handleToggleRegister={handleToggleRegister} 
      setUser={setUser}
      googleLogin={googleLogin}
      facebookLogin={facebookLogin}
    />
  </Layout>
  )
}

export default TheNavbar;