import { auth } from '../firebase';
import {
  setLocalStorage,
  getLocalStorage,
} from '../utils/localStorageHelper/localHelper';
import clientAxios from './clientAxios';

const googleProvider = async () => {
  const provider = new auth.GoogleAuthProvider();
  const { user } = await auth().signInWithPopup(provider);
  const idToken = await user.getIdToken();
  const response = await clientAxios.post('/login/firebase', { idToken });
  setLocalStorage('token', response.data.token);
  setLocalStorage('user', response.data.user);
  const loggedUser = getLocalStorage('user');
  return loggedUser;
};

const facebookProvider = async () => {
  const provider = new auth.FacebookAuthProvider();
  const { user } = await auth().signInWithPopup(provider);
  const idToken = await user.getIdToken();
  const response = await clientAxios.post('/login/firebase', { idToken });
  setLocalStorage('token', response.data.token);
  setLocalStorage('user', response.data.user);
  const loggedUser = getLocalStorage('user');
  return loggedUser;
};

export { googleProvider, facebookProvider };
