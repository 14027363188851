import img from '../../img/imgSlide2.jpg';
import style from '../../styles/AboutUs/aboutUs.module.css';

const WeAreTotallia = () => {
  return (
    <>
      <div className={style.container}>
        <img src={img} alt="edificio" className={style.imgEdificio} />
        <div className={style.containerText}>
          <h3 className={style.titleWeAreTotallia}>SOMOS TOTALLIA</h3>
          <p className={style.pWeAreTotallia}>
            Una empresa dedicada a invertir en propiedades<br />
            inmobiliarias comerciales.
          </p>
          <p className={style.pWeAreTotallia}>
            Conectamos a inversores que quieren invertir en <br />
            real estate comercial con empresas que buscan <br />
            capital para lanzar sus proyectos.
          </p>
        </div>
      </div>
    </>
  );
};
export default WeAreTotallia;
