import { Row, Spin } from 'antd';
import styles from './loading.module.css';
import { infoAlert } from 'utils/alertMessageHelper/alertMessage';

const LoaderDocuSign = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <Row align="middle" justify="center">
        <div className={styles.loadingSpiner}>
          {infoAlert(
            'Información.',
            'Aguarde mientras preparamos el contrato, no actualice la pagina hasta que realice la firma.',
            'info'
          )}
          <Spin className={styles.iconStyle}/> 
        </div>
      </Row>
    );
  return children;
};

export default LoaderDocuSign;
