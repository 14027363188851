import TheNavbar from '../../TheNavbar';
import Footer from '../../Footer';
import AllGuides from './AllGuides';
import { useState } from 'react';
import { Row } from 'antd';
import style from '../../../styles/Aprender/aprender.module.css';

const GuidePage = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);
  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <Row justify="center" className={style.rowAllArticles}>
        <AllGuides />
      </Row>
      <Footer />
    </>
  );
};

export default GuidePage;
