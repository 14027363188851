import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GenerateReports from '../generateReports/GenerateReports';

const ReportsRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={GenerateReports} />
    </Switch>
  );
};

export default ReportsRoutes;
