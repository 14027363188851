import { Modal } from 'antd';
import { Document, Page } from 'react-pdf';
import { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';
import { dataToSendDocuSign } from 'utils/prepareDataToSignDocument/dataToSendDocuSign';
import clientAxios from 'config/clientAxios';
import LoadingComponent from '../../../Loader/LoadingComponent';

const ATTACHMENT_PAGE = 1;

const AttachmentModal = ({
  attachmentModalStatus,
  setAttachmentModalStatus,
  attachmentId,
}) => {
  const [dataUser, setDataUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [attachmentURL, setAttachmentURL] = useState('');
  const [docuSign, setDocuSign] = useState('');
  const [isContract, setisContract] = useState(false);

  const getURLFileExtension = (url) =>
    url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  useEffect(() => {
    setIsLoading(true);
    setAttachmentURL('');
    setDocuSign('');

    const fetchAttachmentUrl = async () => {
      const { data: attachment } = await clientAxios.get(
        `/attachments/private/${attachmentId}`
      );

      const REGEX_ATTACHMENT_KEY = new RegExp(/^[A-Za-z0-9--\s]+$/g);

      const isValidKey = REGEX_ATTACHMENT_KEY.test(attachment?.key);
      const isEnvelope = attachment?.name === 'envelopeId';

      if (isEnvelope || isValidKey) {
        try {
          const user = getLocalStorage('user');
          setDataUser(user);
          const dataToSend = dataToSendDocuSign(dataUser);
          dataToSend.envelopeId = attachment?.key;
          const contractViewDocuSign = await clientAxios.post(
            '/getContractViewDocuSign',
            dataToSend
          );
          setDocuSign(contractViewDocuSign?.data?.redirectUrl);
          setIsLoading(false);
        } catch (error) {
          errorMessage('Tuvimos inconvenientes para mostrar el contrato');
        }
      } else {
        setisContract(true);
        setAttachmentURL(attachment?.url);
        setIsLoading(false);
      }
    };
    if (attachmentId) fetchAttachmentUrl();
    // eslint-disable-next-line
  }, [attachmentId]);

  return (
    <Modal
      title={null}
      visible={attachmentModalStatus}
      onCancel={() => setAttachmentModalStatus(false)}
      style={{ textAlign: 'center' }}
      footer={null}
      width="80%"
      height={400}
    >
      <LoadingComponent isLoading={isLoading}>
        {getURLFileExtension(attachmentURL) === 'pdf' || isContract ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Document
              loading={<p className="mt-1">Cargando PDF...</p>}
              file={{ url: attachmentURL }}
              onLoadSuccess={(event) => onDocumentLoadSuccess(event)}
              style={{ flexGrow: 1 }}
            >
              <Page pageNumber={ATTACHMENT_PAGE} />
            </Document>
            <p style={{ zIndex: 2, alignSelf: 'center', margin: 0 }}>
              Página {ATTACHMENT_PAGE} de {numPages} -{' '}
              <a href={attachmentURL} rel="noreferrer" target="_blank">
                Descargar <DownloadOutlined />
              </a>
            </p>
          </div>
        ) : docuSign ? (
          <>
            <iframe
              title="docusign iframe"
              src={docuSign}
              width="100%"
              height={'500px'}
              frameBorder="0"
            ></iframe>
          </>
        ) : (
          <img style={{ width: '100%' }} src={attachmentURL} alt="attachment" />
        )}
      </LoadingComponent>
    </Modal>
  );
};

export default AttachmentModal;
