import TheNavbar from './TheNavbar';
import Slider from './HomePage/Slider';
import TrackRecords from './HomePage/TrackRecords';
import SectionsHome from './HomePage/SectionsHome';
import { useState, useEffect } from 'react';
import clientAxios from '../config/clientAxios';
import { errorMessage } from '../utils/messageHelper/messageHelper';

const investmentInitialValues = {
  revenue: '',
  irr: '',
  appreciation: '',
  termInYears: 0,
  complementaryInformation: {},
  country: [],
  city: [],
  proyect: '',
  sponsor: '',
  minInvestment: 0,
};

export default function LandingPage({ user, setUser }) {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const [settings, setSettings] = useState([]);
  const [lastInvestment, setLastInvestment] = useState(investmentInitialValues);
  const [lastNews, setLastNews] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/settings');
        setSettings(res.data[0]);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Track records no disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchLastInvestment = async () => {
      try {
        const { data } = await clientAxios.get(`/posted`, {
          params: { lastInvestment: true },
        });
        const dataResponse = data[0];
        setLastInvestment(dataResponse);
      } catch (error) {
        errorMessage('Los datos de la inversión no están disponibles');
      }
    };
    fetchLastInvestment();
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const { data } = await clientAxios.get('/articlesPosted', {
          params: { limit: 1 },
        });
        setLastNews(data[0]);
      } catch (error) {
        errorMessage('Los datos de la noticia no están disponibles');
      }
    };
    fetchArticles();
  }, []);

  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />

      <Slider
        user={user}
        handleToggleRegister={handleToggleRegister}
        settings={settings}
        lastInvestment={lastInvestment}
        lastNews={lastNews}
      />
      <TrackRecords settings={settings} isLoading={isLoading} />
      <SectionsHome />
    </>
  );
}
