import { Form, Button, Row, Col, Checkbox, Space } from 'antd';
import {
  successMessage,
  errorMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { useRef, useState, useEffect } from 'react';
import clientAxios from '../../../../config/clientAxios';

const IntruccionesBancariasAnt = ({ handleInvestment, singleInvestment }) => {
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formbank = useRef();

  useEffect(() => {
    if (singleInvestment.bank) {
      const safeBanksCopy = [singleInvestment].map(
        (investment) => investment.bank
      );
      const onlyIds = safeBanksCopy[0].map((bank) => bank._id);
      const bankCopy = banks.map((bank) => ({ ...bank }));
      const bank = bankCopy.map((bank) => ({
        ...bank,
        checked: onlyIds.some((id) => id === bank.id),
      }));
      setBanks(bank);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleInvestment, isLoading]);

  useEffect(() => {
    const fetchDataBanks = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/banks`);
        const banks = res.data;
        const banksCopy = banks.map((bank) => ({ ...bank }));
        const bankNewObject = banksCopy.map((bank) => ({
          id: bank._id,
          name: bank.name,
          labelName: bank.labelName,
          checked: false,
        }));
        setBanks(bankNewObject);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Bancos no disponibles');
      }
      setIsLoading(false);
    };
    fetchDataBanks();
  }, []);

  const onFinish = () => {
    const banksCopy = banks.map((bank) => ({ ...bank }));
    const bankSelected = banksCopy.filter((bank) => bank.checked === true);
    const banksIds = bankSelected.map((bank) => bank.id);
    const objectBank = { bank: banksIds };
    handleInvestment(objectBank);
    successMessage('Datos Guardados');
    window.scrollTo(0, 0);
  };
  const onFinishFailed = () => {
    errorMessage('Hubo un error al guardar los datos');
  };

  const handleBankSelected = (value) => {
    const bankIdSelected = value.target.value;
    const banksCopy = banks.map((bank) => ({ ...bank }));
    const bank = banksCopy.find((bank) => bank.id === bankIdSelected);
    bank.checked = !bank.checked;
    setBanks(banksCopy);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <h4 className="title-dataPublish">Vincular banco</h4>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Form
          name="basic"
          layout="vertical"
          ref={formbank}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Bancos:" name="bank">
            <Space direction="vertical">
              {banks.map((bank) => (
                <Checkbox
                  key={bank.id}
                  value={bank.id}
                  checked={bank.checked}
                  onChange={handleBankSelected}
                >
                  {bank.labelName}
                </Checkbox>
              ))}
            </Space>
          </Form.Item>
          <div className="center-container">
            <Button type="primary" htmlType="submit" className="btn-save">
              Guardar formulario
            </Button>
          </div>
        </Form>
      </Row>
    </>
  );
};
export default IntruccionesBancariasAnt;
