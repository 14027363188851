import React, { useState, useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import style from 'styles/homePage/cardInvestment.module.css';
import imgCard from 'img/imgCard.jpg';

const CardInvestment = ({ investment }) => {
  const [viewMore, setViewMore] = useState(true);
  const { url } = useRouteMatch();

  useEffect(() => {
    setViewMore(investment?.isOpen);
  }, [investment]);

  return (
    <Card
      hoverable
      className={style.card}
      style={{ width: 300 }}
      bodyStyle={{ padding: '20px' }}
      cover={
        <Link to={ viewMore ? `${url}/detail/${investment?._id}` : ''} className='text-dark'>
          <img
            alt="Imagen destacada de la inversion"
            src={
              investment?.attachments?.featuredImg
                ? investment?.attachments?.featuredImg
                : imgCard
            }
            className={style.img}
          />
          <div
            className={investment?.isOpen ? `${style.open}` : `${style.close}`}
          >
            <p className={style.pOpen}>
              {investment?.isOpen ? 'Abierta' : 'Cerrada'}
            </p>
          </div>
        </Link>
      }
    >
      <Link to={ viewMore ? `${url}/detail/${investment?._id}` : ''} className='text-dark'>
        <h5 className={style.title}>{investment?.name}</h5>
        <Row>
          <Col span={12}>
            <div>
              <p className={style.pTitle}>Renta Anual</p>
              <p className={style.pText}>{investment?.revenue}</p>
            </div>
            <div>
              <p className={style.pTitle}>TIR</p>
              <p className={style.pText}>{investment?.irr}</p>
            </div>
            <div>
              <p className={style.pTitle}>Apreciación</p>
              <p className={style.pText}>{investment?.appreciation}</p>
            </div>
          </Col>
          <Col span={12} className={style.col2}>
            <div>
              <p className={style.pTitle}>Plazo</p>
              <p className={style.pText}>{`${investment?.termInYears} añ${
                investment?.termInYears > 1 ? 'os' : 'o'
              }`}</p>
            </div>
            <div>
              <p className={style.pTitle}>Inversión minima</p>
              <p className={style.pText}>{currentFormat(investment?.minInvestment)}</p>
            </div>
            <div>
              <p className={style.pText}>-</p>
            </div>
          </Col>
        </Row>
        <p className={style.sponsor}>
          Sponsor <span className={style.span}>{investment?.sponsor}</span>
        </p>
      </Link>
    </Card>
  );
};
export default CardInvestment;
