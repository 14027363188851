export const offerStatus = {
  0: 'Revision de Contrato',
  1: 'Contrato Aprobado, aguardando transferencia',
  2: 'Documentación en revisión',
  3: 'Documento de cierre aprobados',
  4: 'Transferencia enviada',
  5: 'Transferencia recibida',
  6: 'Aguardando contrafirma',
  7: 'Inversión concretada',
  8: 'Contrato rechazado, vuelva a firmar',
  9: 'Cupón de transferencia no legible.'
};
