import { Row, Col } from 'antd';
import style from '../../styles/investments/investmentPage.module.css';
import img from '../../img/imgInvestmentPage.jpg';
import arrow from '../../img/icons/Trazado48.svg';

const Description = () => {
  return (
    <>
      <Row className={style.containerImg}>
        <img src={img} alt="edificio" />
        <div className={style.containerText}>
          <h3 className={style.title}>Inversiones a tu medida</h3>
          <p className={style.p1}>
            Cada inversor tiene necesidades <br />y deseos particulares.
          </p>
          <p className={style.p2}>
            Por ello, ideamos dos herramientas para
            <br />
            que puedas invertir en propiedades
            <br />
            comerciales globales.
          </p>
        </div>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={24} className={style.bgBlue}>
          <div className={style.pContainer}>
            <p className={style.p3}>
              Aquí podrás acceder a los proyectos de inversión en <br />
              propiedades comerciales que están disponibles para invertir <br />
              y elegir el que más te gusta.
            </p>
            <p className={style.p3}>
              Explora todos los detalles de los proyectos, a las empresas
              <br />
              que los llevan adelante, su estructura, rentabilidad, riesgo,
              <br />
              plazo y elige el que más te interese para tu portafolio.
              <br />
            </p>
            <p className={style.p3}>
              Encontrarás oportunidades en formato Cartera
              <br />o Proyectos Individuales.
            </p>
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} className={style.bgRose} id="foundProyect">
          <div className={style.roseContainer}>
            <Row className={style.rowRose}>
              <Col span={8}>
                <img src={arrow} alt="flecha" className={style.arrowRight} />
              </Col>
              <Col span={16}>
                <h4 className={style.h4}>Carteras</h4>
                <p className={style.p4}>
                  Es la mejor forma de comenzar a invertir, <br />
                  accediendo a una cartera diversificada
                  <br />
                  de propiedades con una sola y simple inversión
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <img src={arrow} alt="flecha" className={style.arrowRight} />
              </Col>
              <Col span={16}>
                <h4 className={style.h4}>Proyectos Individuales</h4>
                <p className={style.p4}>
                  Invierte específicamente <br />
                  en un proyecto en particular
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Description;
