import React, { useState } from 'react';
import { Divider } from 'antd';
import PersonalDataForm from './forms/PersonalDataForm';
import DataBankForm from './forms/DataBankForm';

const GeneralInformation = ({ userData }) => {
  const [isModalPassport, setIsModalPassport] = useState(false);
  const [isModalInstructionsURL, setIsModalInstructionsURL] = useState(false);

  const handleModalPassport = () => {
    setIsModalPassport((state) => !state);
  };

  const handleModalinstructionsURL = () => {
    setIsModalInstructionsURL((state) => !state);
  };

  return (
    <>
      <Divider />
      <h4>1. Datos personales</h4>
      <div>
        <PersonalDataForm
          isModalPassport={isModalPassport}
          handleModalPassport={handleModalPassport}
          userData={userData}
        />
      </div>
      <Divider />
      <h4>2. Instrucciones Bancarias</h4>
      <div>
        <DataBankForm
          handleModalinstructionsURL={handleModalinstructionsURL}
          isModalInstructionsURL={isModalInstructionsURL}
          userData={userData}
        />
      </div>
    </>
  );
};

export default GeneralInformation;
