import moment from 'moment';
import { formatMoney } from '../../../../../utils/currentFormatHelper/currentFormatHelper';

const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

const dateColumn = {
  title: 'Fecha',
  dataIndex: 'createdAt',
  render: (record) => record && moment(record).format(DATE_FORMAT),
};
const userColumn = {
  title: 'Usuario',
  dataIndex: 'user',
  render: (user) => user?.email ?? '-',
};

export const investmentCardLastVisitsColumn = [
  dateColumn,
  userColumn,
  {
    title: 'Tarjeta visitada',
    dataIndex: 'details',
    render: (detail) => (detail?.investment ? detail?.investment?.name : '-'),
  },
];

export const articleLastVisitsColumn = [
  dateColumn,
  userColumn,
  {
    title: 'Articulo visitado',
    dataIndex: 'details',
    render: (detail) => (detail?.article ? detail?.article?.title : '-'),
  },
];

export const lastLoginColumn = [
  {
    title: 'Fecha',
    dataIndex: 'lastLogIn',
    render: (record) => record && moment(record).format(DATE_FORMAT),
  },
  {
    title: 'Usuario',
    dataIndex: 'email',
  },
];

export const pendingOffersColumn = [
  dateColumn,
  userColumn,
  {
    title: 'Tarjeta visitada',
    dataIndex: 'investment',
    render: (investment) => investment?.name ?? '-',
  },
  {
    title: 'Monto oferido',
    dataIndex: 'amountOffered',
    render: (amount) => (amount ? formatMoney(amount) : '$0.00'),
  },
  {
    align: 'center',
    title: 'Contrato firmado',
    dataIndex: 'isContractSigned',
    render: (isContractSigned) => (isContractSigned ? 'Si' : 'No'),
  },
];
