import moment from 'moment';

const backgroundColorColumn = '#d7d7d7'
const formatDate = 'YYYY-MM-DD';

export const NotificationsTableStructure = () => [
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (_, record) => {
      return {
        props: {
          style: { background: !record?.active && backgroundColorColumn }
        },
        children: <>{moment(record.createdAt).format(formatDate)}</>
      };
    }
  },
  {
    title: 'Eventos',
    dataIndex: 'title',
    key: 'title',
    enable: true,
    render: (_, record) => {
      return {
        props: {
          style: { background: !record?.active && backgroundColorColumn }
        },
        children: record?.title
      };
    }
  },
];
