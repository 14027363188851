import { Col, Row, Input, Button, Select, DatePicker } from 'antd';
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sortNumber } from '../../../../utils/sortNumberHelper/sortNumberHelper';
import { handleToggleColumns } from '../../../../utils/toggleColumnsHelper/toggleColumnsHelper';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import { dateFormat } from '../../../../utils/dateFormatHelper/dateFormat';
import { tableHeadingsTemplate } from './table-Structure/CloseInvestmentTableStructure';
import ToggleColumnsModal from '../../toggleColumnsModal/ToggleColumnsModal';
import ModalInvesment from '../modal/ModalInvesment';
import GenericTable from '../../reusableTable/GenericTable';

const { Search } = Input;
const { Option } = Select;

const CloseInvestment = ({ allDataInvesment, dataUser, isLoading }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [filterInput, setFilterInput] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterFinishDate, setFilterFinishDate] = useState('');
  const [filterTermOfYear, setFilterTermOfYear] = useState(0);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [termOfYear, setTermOfYear] = useState([]);

  const history = useHistory();
  let { path } = useRouteMatch();

  const tableName = 'closeTable';

  const togglePreferenceClose = getLocalStorage(`${dataUser?.uid}${tableName}`);

  const handleInfoInvesmentModal = (data) => {
    setDataModal(data);
    setIsVisible((state) => !state);
  };

  const handleToggleModal = () => setIsModalVisible((state) => !state);

  useEffect(() => {
    const openInvesment = allDataInvesment.filter(
      (invesment) => invesment.isOpen === false
    ).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
    
    setDataTable(openInvesment);
  }, [allDataInvesment]);

  useEffect(() => {
    const handleUserTogglePreference = () => {
      if (togglePreferenceClose) {
        const togglePreferenceOpenSave = tableHeadingsTemplate(
          handleInfoInvesmentModal,
          handleEditInvesment
        ).map((column) => ({
          ...column,
          enable: togglePreferenceClose[column.dataIndex],
        }));
        setTableHeadings(togglePreferenceOpenSave);
      } else {
        setTableHeadings(
          tableHeadingsTemplate(handleInfoInvesmentModal, handleEditInvesment)
        );
      }
    };

    const handleTermInYears = () => {
      const termInYearsArray = dataTable.map(
        (invesment) => invesment.termInYears
      );
      const yearsNotRepeated = Array.from(new Set(termInYearsArray));
      const orderYears = sortNumber(yearsNotRepeated);
      setTermOfYear(orderYears);
    };
    handleUserTogglePreference();
    handleTermInYears();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable]);

  const handleSearchInvesment = (value) => {
    setFilterInput(value);
  };

  const handleFilterTypeInvestment = ({ value }) => {
    setFilterType(value);
  };

  const handleFilterDate = (date) => {
    if (date) {
      const finishDateFormat = dateFormat(date);
      setFilterFinishDate(finishDateFormat);
    } else {
      setFilterFinishDate('');
    }
  };

  const handleFilterTermInYears = ({ value }) => {
    if (value) {
      setFilterTermOfYear(value);
    } else {
      setFilterTermOfYear(0);
    }
  };

  const handleFilterByName = () => {
    if (
      filterInput === '' &&
      filterType === '' &&
      filterFinishDate === '' &&
      filterTermOfYear === 0
    )
      return dataTable;

    if (filterInput) {
      return dataTable.filter(({ name }) =>
        name?.toLowerCase().includes(filterInput.toLowerCase())
      );
    }

    if (filterType) {
      return dataTable.filter(({ investmentType }) =>
        investmentType?.includes(filterType)
      );
    }

    if (filterFinishDate) {
      return dataTable.filter(({ complementaryInformation }) =>
        dateFormat(complementaryInformation.finishDate).includes(
          filterFinishDate
        )
      );
    }

    if (filterTermOfYear) {
      return dataTable.filter(
        ({ termInYears }) => termInYears === filterTermOfYear
      );
    }
  };

  const handleEditInvesment = (id) => {
    history.push(`${path}/editarInversion/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Button
            onClick={handleToggleModal}
            className="btn-closeInv-filter input-size"
          >
            <i className="fas fa-cog me-2"></i>Tablas
          </Button>
          <Search
            enterButton
            placeholder="Buscar"
            onSearch={handleSearchInvesment}
            className="btn-closeInv-filter input-size search-border-color"
          />
          <DatePicker
            placeholder="Fecha de cierre"
            onChange={handleFilterDate}
            className="btn-closeInv-filter input-size select-item datePicker-border-color"
          />
          <ToggleColumnsModal
            isModalVisible={isModalVisible}
            tableHeadings={tableHeadings}
            handleToggleModal={handleToggleModal}
            handleToggleColumns={handleToggleColumns(
              tableHeadings,
              setTableHeadings,
              dataUser,
              tableName
            )}
          />
          <ModalInvesment
            dataModal={dataModal}
            isVisible={isVisible}
            handleEditInvesment={handleEditInvesment}
            handleInfoInvesmentModal={handleInfoInvesmentModal}
          />
          <Select
            labelInValue
            defaultValue={{ value: 'Tipo de inversión' }}
            className="btn-closeInv-filter input-size selector-border-color "
            onChange={handleFilterTypeInvestment}
          >
            <Option value="">Tipo de Inversión</Option>
            <Option value="Fondo de Inversión">Cartera de Inversión</Option>
            <Option value="Inversión Individual">Inversión Individual</Option>
          </Select>
          <Select
            labelInValue
            defaultValue={{ value: 'Plazo' }}
            className="btn-closeInv-filter input-size"
            onChange={handleFilterTermInYears}
          >
            <Option value="">Plazo</Option>
            {termOfYear.map((year) => (
              <Option key={year} value={year}>
                {year === 1 ? `${year} año` : `${year} años`}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <GenericTable
        handleFilterByName={handleFilterByName}
        tableHeadings={tableHeadings}
        isLoading={isLoading}
        rowKey="_id"
      />
    </>
  );
};

export default CloseInvestment;
