import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Articles from './Articles';
import FormArticle from './FormArticle';

const ArticlesRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/editarArticulo/:id`} component={FormArticle} />
      <Route path={`${path}/nuevoArticulo`} component={FormArticle} />
      <Route path={path} component={Articles} />
    </Switch>
  );
};

export default ArticlesRoutes;
