import React, { useState } from 'react';
import { Upload } from 'antd';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';
import { getBase64 } from 'utils/getBase64/getBase64';
import { successModal } from 'utils/modalMessageHelper/modalMessage';
import {
  getFormData,
  getConfigUpload,
} from 'utils/uploadContractHelper/uploadContractHelper';
import { errorModal } from 'utils/modalMessageHelper/modalMessage';
import { PlusOutlined } from '@ant-design/icons';
import clientAxios from 'config/clientAxios';
import styles from './uploadContract.module.css';
import ModalPreview from './modalPreview/ModalPreview';

const UploadContract = ({ nameButton, setIsFile }) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [imgPreview, setImgPreview] = useState('');

  const { id } = useParams();
  const userData = getLocalStorage('user');

  const uploadImage = async ({ onSuccess, onError, file, onProgress }) => {
    try {
      await clientAxios.post(
        '/attachments/private',
        getFormData(file, true, 'contract', id, userData._id),
        getConfigUpload(onProgress)
      );
      setIsFile(true);
      successModal(
        <div>
          <h5>El contrato se subio con éxito.</h5>
        </div>
      );
      onSuccess('Ok');
    } catch (err) {
      errorModal(
        <div>
          <h5>Error al subir el archivo.</h5>
          <p>Por favor intente nuevamente.</p>
        </div>
      );
      onError({ err });
    }
  };

  const handleOnChange = ({ fileList }) => {
    setDefaultFileList(fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setImgPreview(file.preview);
    handleModal();
  };

  const handleModal = () => {
    setIsPreviewVisible((state) => !state);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{nameButton}</div>
    </div>
  );

  return (
    <div className={styles.container}>
      <Upload
        accept=".pdf"
        customRequest={uploadImage}
        onChange={handleOnChange}
        onPreview={handlePreview}
        listType="picture-card"
        defaultFileList={defaultFileList}
        maxCount={1}
      >
        {defaultFileList.length >= 1 ? null : uploadButton}
      </Upload>
      <ModalPreview
        handleModal={handleModal}
        previewImage={imgPreview}
        previewVisible={isPreviewVisible}
      />
    </div>
  );
};

export default UploadContract;
