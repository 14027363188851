import { useState, useEffect } from 'react';
import { Row, Tabs, Divider } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import InProgress from './tables/InProgress';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import { ActiveStructure } from './tables-structure/ActiveStructure';
import { HistoryStructure } from './tables-structure/HistoryStructure';
import Active from './tables/Active';

const { TabPane } = Tabs;

const MyInvestments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [investmentsInProgress, setInvestmentsInProgress] = useState([]);
  const [offersCompleted, setOffersCompleted] = useState([]);
  const [offersFinalized, setOffersFinalized] = useState([]);


  useEffect(() => {
    const fetchUserInvestments = async () => {
      try {
        setIsLoading(true);
        const responseOffers = await clientAxios.get('/user/offers');
        setInvestmentsInProgress(responseOffers.data);
        const responseActive = await clientAxios.get('/user');
    
        const completedOffers = responseActive.data.investmentOffers.filter(// eslint-disable-next-line
          (offer) => offer.isTransactionCompleted === true && offer.investment.finalized === false ||// eslint-disable-next-line 
          offer.status === 7 && offer.investment.finalized === false 
        );
        setOffersCompleted(completedOffers);

        const finalizedOffer = responseActive.data.investmentOffers.filter(// eslint-disable-next-line
          (offer) => offer.isTransactionCompleted === true && offer.investment.finalized === true ||// eslint-disable-next-line 
          offer.status === 7 && offer.investment.finalized === true
        );
        setOffersFinalized(finalizedOffer);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInvestments();
  }, []);

  return (
    <div className="my-3 mx-4">
      <Row align="middle">
        <BarChartOutlined
          style={{
            fontSize: '40px',
            color: '#08c',
            marginRight: '10px',
            marginBottom: '21px',
          }}
        />
        <h3>Mi cartera</h3>
      </Row>
      <Divider className="mt-1" />
      <LoadingInvestorRoom>
        <Tabs defaultActiveKey="Ofertas">
          <TabPane tab="Ofertas" key="Ofertas">
            <InProgress
              isLoading={isLoading}
              data={investmentsInProgress}
              setInvestmentsInProgress={setInvestmentsInProgress}
            />
          </TabPane>
          <TabPane tab="Activas" key="Activas">
            <Active offersCompleted={offersCompleted} tableStructure={ActiveStructure} />
          </TabPane>
          <TabPane tab="Finalizadas" key="Finalizadas">
            <Active offersCompleted={offersFinalized} tableStructure={HistoryStructure} />
          </TabPane>
        </Tabs>
      </LoadingInvestorRoom>
    </div>
  );
};

export default MyInvestments;
