import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import AttachmentModal from '../../../../Admin/Investors-Section/AttachmentModal/AttachmentModal';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import AdditionalDocs from '../modals/AdditionalDocs/AdditionalDocs';
import moment from 'moment';

const Active = ({ offersCompleted, tableStructure }) => {
  const [dataTable, setdataTable] = useState([]);
  const [attachmentId, setAttachmentId] = useState('');
  const [attachmentModalStatus, setAttachmentModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState({});
  const [showAdditionalDocs, setShowAdditionalDocs] = useState(false);

  const handleAttachmentId = (value) => {
    setAttachmentId('');
    setAttachmentModalStatus(true);
    setAttachmentId(value);
  };

  const handleShowAdditionalDocs = (value, offer) => {
    setShowAdditionalDocs(value);
    setOffer(offer);
  };

  useEffect(() => {
    const getDataTable = async () => {
      if (offersCompleted.length === 0) {
        return null;
      }
      setLoading(true);
      try {
        const dataData = await Promise.all(
          offersCompleted.map(async (offer) => {
            const rents = await clientAxios.get(`/rents/offer/${offer._id}`).then(d=>d);
            return {
              ...offer,
              rents: rents.data === 'The id is wrong' ? [] : rents.data,
            };
          })
        );

        setdataTable(dataData);
      } catch (error) {
        setdataTable([]);
        errorMessage('Hubo un error al cargar los datos, intentelo de nuevo.');
      } finally {
        setLoading(false);
      }
    };
    getDataTable();
  }, [offersCompleted]);

  const expandedColumn = [
    {
      title: 'Renta:',
      dataIndex: 'rent',
      key: 'rent',
      enable: true,
      className: 'text-center text-success fw-bold',
      render: (rent) => `$${rent}`,
    },
    {
      title: 'Share:',
      dataIndex: 'share',
      key: 'share',
      enable: true,
      className: 'text-center text-success fw-bold',
      render: (share) => `%${share}`,
    },
    {
      title: 'Fecha de Renta:',
      dataIndex: 'createdAt',
      key: 'createdAt',
      enable: true,
      className: 'text-center text-success fw-bold',
      render: (createdAt) =>
        createdAt ? <>{moment(createdAt).format('YYYY-MM-DD')}</> : '-',
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Table
            columns={tableStructure(
              handleAttachmentId,
              handleShowAdditionalDocs
            )}
            dataSource={dataTable}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <Table
                  dataSource={record?.rents}
                  columns={expandedColumn}
                  pagination={false}
                />
              ),
              rowExpandable: (record) => record?.rents?.length > 0,
              expandIconColumnIndex: -1,
            }}
            loading={loading}
            rowKey="_id"
            scroll={{ x: 1000 }}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 4 }}
          />
        </Col>
        <AdditionalDocs
          offer={offer}
          onCancel={() => setShowAdditionalDocs(false)}
          open={showAdditionalDocs}
        />
        <AttachmentModal
          attachmentModalStatus={attachmentModalStatus}
          setAttachmentModalStatus={setAttachmentModalStatus}
          attachmentId={attachmentId}
        />
      </Row>
    </>
  );
};

export default Active;
