import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Newsletter from './Newsletter';
import FormNewsletter from './FormNewsletter';

const NewsletterRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/editarNewsletter/:id`} component={FormNewsletter} />
      <Route path={`${path}/newsletter`} component={FormNewsletter} />
      <Route path={path} component={Newsletter} />
    </Switch>
  );
};

export default NewsletterRoutes;