import { useState } from 'react';
import { Table, Tooltip, Popconfirm, Modal } from 'antd';
import { EyeFilled, DeleteOutlined } from '@ant-design/icons';
import {
  errorMessage,
  successMessage,
} from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import Loading from 'components/Loader/LoadingComponent';
import Attachment from './Attachment';

const SeeAdditionalDocs = ({
  additionalDocumentation,
  offer,
  setReloadData,
  isAdmin,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [attachment, setAttachment] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAdditionalDocumentation = async (id) => {
    try {
      setIsLoading(true);
      setShowModal(true);
      const { data } = await clientAxios(`/attachments/private/${id}`);
      setAttachment(data);
    } catch (error) {
      errorMessage('Hubo un error al mostrar el archivo, intentelo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAdditionalDocumentation = async (id) => {
    try {
      await clientAxios.delete(`/user/offers/${offer?._id}/additional-docs/${id}`);
      setReloadData(true);
      successMessage('Se ha borrado el archivo.');
    } catch (error) {
      errorMessage('Hubo un error a la hora de eliminar el archivo.');
    }
  };

  const additionalDocColumn = [
    {
      dataIndex: 'name',
      title: 'Nombre del archivo',
    },
    {
      align: 'center',
      title: 'Acciones',
      render: (record) => (
        <>
          <Tooltip
            className="text-primary h6"
            placement="left"
            title={'Ver archivo'}
          >
            <EyeFilled
              style={{ cursor: 'pointer' }}
              onClick={() => getAdditionalDocumentation(record?._id)}
            />
          </Tooltip>
          {isAdmin && (
            <Popconfirm
              className="ms-3 text-danger h6"
              placement="top"
              title={'Desea eliminar este archivo?'}
              onConfirm={() => deleteAdditionalDocumentation(record?._id)}
              okText="Si"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      {additionalDocumentation?.length === 0 ? (
        <p className="mb-0 text-danger">No hay archivos disponibles.</p>
      ) : (
        <Table
          dataSource={additionalDocumentation}
          columns={additionalDocColumn}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 4 }}
        />
      )}
      <Modal
        title={null}
        footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        width="80%"
        height={400}
      >
        <Loading isLoading={isLoading}>
          <Attachment attachment={attachment} />
        </Loading>
      </Modal>
    </div>
  );
};

export default SeeAdditionalDocs;
