import { Card, Row, Col } from 'antd';
import style from 'styles/Aprender/newsletter.module.css';
import arrow from 'img/icons/Trazado48.svg';
import { classification } from '../../Admin/settings/Newsletter/ClassificationNewsletter';
import moment from 'moment';
import 'moment/locale/es';

const NewsletterCard = ({ newsletter }) => {
  const classificationNewsletter = classification[newsletter.classification].name;
  const icon = classification[newsletter.classification].icon;
  const date = newsletter.writerDate;
  const day = moment(date).format('DD');
  const month = moment(date).format('MMMM');
  const year = moment(date).format('YYYY');

  return (
    <Card
      bodyStyle={{ padding: 0, backgroundColor: '#F5F5F5', borderRadius: 20 }}
      hoverable
      className={style.card}
    >
      <a href={newsletter.link} target="_blank" rel="noreferrer">
      <Row>
        <Col sm={10} className={style.imgCardContainer}>
          <img src={icon} alt="" className={style.imgCard} />
        </Col>
        <Col sm={14} className={style.colCardData}>
          <Row className={style.rowDataCard}>
            <p className={style.pClasification}>{classificationNewsletter}</p>
          </Row>
          <Row className={style.rowDataCard}>
            <h4 className={style.titleCard}>{newsletter.title}</h4>
          </Row>
          <Row className={style.rowDataCard}>
            <p className={style.pDate}>{`${day} de ${month} de ${year}`}</p>
              <img src={arrow} alt="flecha" className={style.arrow} />
          </Row>
        </Col>
      </Row>
      </a>
    </Card>
  );
};

export default NewsletterCard;
