import { Layout, Menu, Row, Col, Dropdown } from 'antd';
import { 
  ExportOutlined, 
  ToolOutlined, 
  DownOutlined, 
  UserOutlined, 
  NotificationOutlined, 
} from '@ant-design/icons';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import logoNavbar from 'img/totallia-logo-color.svg';
import { removeLocalStorage } from 'utils/localStorageHelper/localHelper';
import styles from 'styles/theNavbar/theNavbar.module.css';
import NavbarInvestorRoomMobile from './NavBarInvestorRoomMobile';
import { isUserRoleAdmin } from 'utils/isUserRoleAdminHelper/isUserRoleAdmin';
import Footer from 'components/Footer';
import InvestorRoomRoutes from './routes/InvestorRoomRoutes';
import { removeSessionStorage } from 'utils/sessionStorageHelper/sessionHelper';
import {NotificationContext} from 'provider/NotificationProvider'
import { useContext } from 'react';

const { Content } = Layout;

const NavbarInvestorRoom = ({ user, setUser }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [notification, setPath] = useContext(NotificationContext);
  setPath(history.location.pathname);
  
  const logout = () => {
    try {
      removeLocalStorage('user');
      removeLocalStorage('token');
      removeSessionStorage('nextTab')
      removeSessionStorage('pointReturn')
      removeSessionStorage('token')
      setUser(null);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (
    <Menu className={styles.MenuDropDown}>
      <Menu.Item  icon={<UserOutlined />}  key="perfil" className={styles.menuColorItem}>
         <Link to={`${url}/Profile`} >
           Perfil
         </Link>
       </Menu.Item>
       <Menu.Item  
         icon={<NotificationOutlined className={notification && `${styles.flickered} text-danger`} />} 
         key="novedades" 
         className={!notification ? styles.menuColorItem : 'bg-light'}>
         <Link to={`${url}/novelties`}>
           Notificaciones
         </Link>
       </Menu.Item>
      {isUserRoleAdmin(user) && (
        <Menu.Item
          key="admin"
          icon={<ToolOutlined />}
          className={styles.menuColorItem}
        >
          <Link to="/admin/inversiones">Panel Admin</Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout" icon={<ExportOutlined />} onClick={logout} danger>
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout>
      <div className="bg-navbar">
        <Row className="display-none-mobile">
          <Col span={4}>
            <Link to="/">
              <img
                src={logoNavbar}
                className="logoTotalliaNavbar"
                alt="logo"
              />
            </Link>
          </Col>
          <Col span={20}>
            <Menu className="menu-items-nav" mode="horizontal">
              <Menu.Item  key="inversiones">
                <Link to={`${url}/investments`} className="items-navbar">
                  INVERSIONES
                </Link>
              </Menu.Item>
              <Menu.Item  key="mis-inversiones">
                <Link to={`${url}/myInvestments`} className="items-navbar">
                  MI CARTERA
                </Link>
              </Menu.Item>
              <Menu.Item key="aprender">
                <Link to="/aprender" className="items-navbar">
                  NOTICIAS
                </Link>
              </Menu.Item>
              <Menu.Item key="nosotros">
                <Link to="/aboutUs" className="items-navbar">
                  NOSOTROS
                </Link>
              </Menu.Item>
              <Menu.Item key="contacto">
                <Link to="/contacto" className="items-navbar">
                  CONTACTO
                </Link>
              </Menu.Item>
              <Menu.Item   key="dropdown" className={styles.dropdownItem}>
              {user && (
              <Dropdown overlay={menu} placement="bottomCenter" arrow>
                <p style={{fontSize:'15px'}}>
                  {user.firstName}
                  <DownOutlined
                      className={notification && styles.flickered}
                      style={{ fontSize: '13px', marginLeft: '4px', color:'red' }}
                    />
                </p>
              </Dropdown>
            )}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        <Row className="display-none-desktop nav-mobile">
          <NavbarInvestorRoomMobile user={user} logout={logout} notification={notification}/>
          <h4 
          className={notification ? `title-nav-mobile ${styles.flickered}` : "title-nav-mobile"}
        >
          Totallia
        </h4>
        </Row>
      </div>
      <Content className={styles.contentInvestorRoom}>
        <InvestorRoomRoutes />
      </Content>
      <Footer />
    </Layout>
  );
};

export default NavbarInvestorRoom;
