import Section3Home from './Section3Home';
import Section4Home from './Section4Home';
import Section5Home from './Section5Home';
import Section6Home from './Section6Home';
import InversionesDestacadas from './InversionesDestacadas';
import Testimonios from './Testimonios';
import Contactanos from './Contactanos';
import Footer from '../Footer';

const SectionsHome = () => {
  return (
    <>
      <Section3Home />
      <Section4Home />
      <Section5Home />
      <Section6Home />
      <InversionesDestacadas />
      <Testimonios />
      <Contactanos />
      <Footer />
    </>
  );
};

export default SectionsHome;
