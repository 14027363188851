import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';

const PrivateRouteInvestorRoom = ({ children, ...rest }) => {
  const token = getLocalStorage('token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token !== null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouteInvestorRoom;
