import React from 'react';
import {
  Input,
  Form,
  Button,
} from 'antd';
import {
  successModal
} from 'utils/modalMessageHelper/modalMessage';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';

import styles from '../investorProfile.module.css';



const UpdatePassword = ({
    setIsSendingDataForm,
    isSendingDataForm
}) => {
  const handleOnFinish = async (values) => {
    setIsSendingDataForm(true);
    try {
        const respond = await clientAxios.put(`/updatePassword`, { password: values.password });
        console.log(respond);
        setIsSendingDataForm(false);
        successModal(
          <div>
            <h5>Contraseña actualizada correctamente.</h5>
          </div>
        );
        window.location.reload()
    } catch (error) {
        errorMessage('error al actualizar los datos.');
    } finally {
        setIsSendingDataForm(false);
    }
  };

  return (
    <Form
      className={styles.wrapper}
      layout="vertical"
      onFinish={handleOnFinish}
    >
      <div>
      <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Porfavor Ingrese una contraseña',
            },
            {
              min: 8,
              message: 'La contraseña debe tener minimo 8 caracteres',
            },
            {
              pattern: /(?=.*\d)(?=.*[a-záéíóúüñ]).*[A-ZÁÉÍÓÚÜÑ].*/,
              message:
                'La contraseña debe tener al menos una minúscula, una mayúscula y un número',
            },
          ]}
        >
          <Input.Password
            placeholder="Contraseña"
            className="inputs-logRegister"
          />
        </Form.Item>
        <Form.Item
          name="repeat_password"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button
          loading={isSendingDataForm}
          type="primary"
          htmlType="submit"
          className={styles.btnConfirm}
        >
          Actualizar Contraseña
        </Button>
      </div>
    </Form>
  );
};

export default UpdatePassword;
