import { createContext, useState, useEffect } from 'react';
import clientAxios from 'config/clientAxios';

export const NotificationContext = createContext();

const NotificationProvider = ({ children, user }) => {
  const notificationRoute = '/user/notifications?active=true';
  const notificationPage = '/investorRoom/novelties';
  const myInvestmentsPage = '/investorRoom/myInvestments';
  
  const [path, setPath] = useState(myInvestmentsPage);
  const [notification, setNotification] = useState(false);
  
  useEffect(() => {
    if (!user) {
      return setNotification(false);
    }
    const existNewNotifications = async () => {
      try {
        const { data } = await clientAxios.get(notificationRoute);
        if (data.length === 0) {
          return setNotification(false);
        }
        setNotification(true);
      } catch (error) {
        setNotification(false);
      }
    };

    switch (path) {
      case notificationPage:
        setNotification(false);
        break;
      case myInvestmentsPage:
        !notification && existNewNotifications();
        break;
      default:
        break;
    }
  }, [path, notification, user]);

  return (
    <NotificationContext.Provider value={[notification, setPath]}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
