import { useState, useEffect } from 'react';
import { Row, Descriptions, Divider, Button, Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { getLocalStorage, setLocalStorage } from 'utils/localStorageHelper/localHelper';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import { dataToSendDocuSign } from 'utils/prepareDataToSignDocument/dataToSendDocuSign';
import { offerStatus } from 'config/offerStatus';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import UploadModal from './../../../components/uploadModal/UploadModal';
import AttachmentModal from 'components/Admin/Investors-Section/AttachmentModal/AttachmentModal';

const InvestmentView = () => {
  const offerId = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [uploadModalState, setUploadModalState] = useState(false);
  const [dataUser, setDataUser] = useState([]);
  const [offer, setOffer] = useState({});
  const [parsedAttachments, setParseAttachments] = useState([]);
  const [parsedTransfers, setParsedTransfers] = useState([]);
  const [typeDoc, setTypeDoc] = useState('');
  const history = useHistory();
  const amount = {
    value: null
  }
  
  // AttachmentModal
  const [attachmentId, setAttachmentId] = useState('');
  const [attachmentModalStatus, setAttachmentModalStatus] = useState(false);
  
  const handleAttachmentModalStatus = () =>
    setAttachmentModalStatus((status) => !status);


  const colorKey = {
    0: { color: 'red' },
    4: { color: 'green' },
    8: { color: 'blue' },
  }
  const imagesExt = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'svg'];

  const tooltipText = {
    0: 'Es Problable que no puedas subir archivos hasta que la revision del contrato haya finalizado',
    4: 'Esperando Aceptacion de Transferencia.',
    8: 'Esperando la transferencia. Recuerde subir el comprobante en el boton "Subir Archivos"'
  }

  const toggleUploadModal = () => setUploadModalState(!uploadModalState);


  const getURLFileExtension = (url) => {
    return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
  };

  useEffect(() => {
    const fetchOffer = async () => {
      setIsLoading(true);
      const response = await clientAxios.get(`/user/offers/${offerId}`);
      const offerData = response.data;
      amount.value = offerData.amountOffered;
      setOffer(offerData);
      setLocalStorage("investmentId", offerData.investment._id);

      const attachments = [];

      for (let index = 0; index < offerData.attachments?.length; index++) {
        console.log(offerData.attachments[index]);
        const attach = offerData.attachments[index]
        
        if(attach._id.name === 'envelopeId'){
          try {
            const user = getLocalStorage('user');
            setDataUser(user);
            const dataToSend = dataToSendDocuSign(dataUser);
            dataToSend.envelopeId = attach._id.key;
            const response = await clientAxios.post('/getContractViewDocuSign', dataToSend);
            const Response = response.data;
            attachments.push({ ...attach, data: { url: Response.redirectUrl, type: 'docusign' } });
            setIsLoading(false)
          } catch (error) {
            errorMessage('Tuvimos inconvenientes para mostrar el contrato');
          }
        } else {
          const attachID = attach._id._id;
          const response = await clientAxios.get(
            `/attachments/private/${attachID}`
          );

          attachments.push({ ...attach, data: response.data });
          
        }
      }

      parseAttachments(attachments, offer.investment);
      parseTransfers(attachments);

      setIsLoading(false);
    }
    
    fetchOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const aginSign = () => {
    setLocalStorage("offerId", offerId);
    setLocalStorage("amount", amount.value);
    const id = getLocalStorage("investmentId");
    history.push(`/investorRoom/investments/detail/${id}/investNow/`);
  }

  const parseAttachments = (attachments) => {
    // eslint-disable-next-line
    const parsedAttachmentTemp = attachments?.map((attachment) => {
      if(attachment?.name === "Contrato"){
        return (
          <>
            Estado:{' '}
            {attachment?.isAproved ? (
              'Aprobado'
            ) : attachment?.toValidate ? (
              'Pediente de Validacion'
            ) : (
              <>
                Rechazado{' '}
                <button
                  className="ant-btn ant-btn-primary"
                  type="button"
                  onClick={() => aginSign()}
                >
                  Firmar nuevamente
                </button>
              </>
            )}
          </>
        );
      }
    })

    setParseAttachments(parsedAttachmentTemp);
  }

  const parseTransfers = (attachments) => {
    // eslint-disable-next-line
    const parsedTransfersTemp = attachments?.map((attachment) => {
      if(attachment.name === "Transferencia"){
        const ext = getURLFileExtension(attachment.data.url);
        console.log("ext", ext);
        console.log(/\.(jpe?g|png|gif)$/i.test(ext))
        return (
          <>
            {imagesExt.some(el => ext.endsWith(el))? (
              <>
                {/* eslint-disable-next-line */}
                <a onClick={() => {
                  setAttachmentId(attachment.data._id);
                  handleAttachmentModalStatus();
                }}>
                    <img src={attachment.data.url} alt="" width="200px" />
                </a>
                 Estado: {attachment.isAproved? "Aprobado" : attachment.toValidate? "Pediente de Validacion" : "Rechazado"}
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setAttachmentId(attachment.data._id);
                    handleAttachmentModalStatus();
                  }}
                  type="primary"
                  style={{ border: 'none', margin: 3 }}
                >
                  Ver Archivo
                </Button>
                Estado: {attachment.isAproved? "Aprobado" : attachment.toValidate? "Pediente de Validacion" : "Rechazado"}
              </>
            )}
          </>
        )
      }
    })

    setParsedTransfers(parsedTransfersTemp);
  }
  
  const documents = offer?.documentation?.map((doc) => {
    const document = (
      <li key={doc?._id}>
        
        <Button
          onClick={() => {
            setAttachmentId(doc?._id._id);
            handleAttachmentModalStatus();
          }}
          type="primary"
          style={{ border: 'none', margin: 3 }}
        >
          {doc?.name}
        </Button>
      </li>
    );
    return document;
  });

  const handleOffer = (offer) => setOffer(offer);


  const handleUploadFiles = (data) => {
    if(data === "transfer"){
      setTypeDoc("transfer")
    } else if (data === "doc") {
      setTypeDoc("doc")
    }

    toggleUploadModal();
    handleOffer(offer);
  }

  return (
    <>
        <Row align="middle">
            <BarChartOutlined
                style={{
                fontSize: '40px',
                color: '#08c',
                marginRight: '10px',
                marginBottom: '21px',
                }}
            />
            <h3>Mi cartera</h3>
        </Row>
        <Divider />
        <LoadingInvestorRoom isLoading={isLoading}>
          {/* <h2>{offer.investment?.name} <Button disabled={offer.status === 0? 'disabled' : ''} onClick={handleUploadFiles} type="primary" style={{ border: 'none', margin: 6, float: 'right' }}>Subir Archivos</Button></h2> */}
          <h2>{offer.investment?.name}</h2>
          <Descriptions>
            <Descriptions.Item label="Fecha de Oferta">{dateFormat(offer.createdAt)}</Descriptions.Item>
            <Descriptions.Item label="Monto Comprometido">{currentFormat(offer.amountOffered)}</Descriptions.Item>
            <Descriptions.Item label="Estado" contentStyle={ colorKey[offer.status] }>
              <Tooltip title={tooltipText[offer.status]}>{offerStatus[offer.status]}</Tooltip>
            </Descriptions.Item>
          </Descriptions>,

          <h5>Contratos</h5>
          {parsedAttachments}
          <Divider />
          <h5>Transferencia <Button type="primary" disabled={offer.status === 0? 'disabled' : ''} style={{ border: 'none', margin: 4 }} onClick={() => {handleUploadFiles('transfer')}}>Realizar Transferencia</Button></h5>
          {console.log(parsedTransfers.length > 1)}
          {console.log(parsedTransfers)}
          {parsedTransfers.length > 1? parsedTransfers : "no hay transferencias"}
          <Divider />
          <h5>Documentacion <Button type="primary" style={{ border: 'none', margin: 4 }} onClick={() => {handleUploadFiles('doc')}}>Subir Documentacion complementaria</Button></h5>
          {documents}

          <UploadModal
            uploadModalState={uploadModalState}
            handleCancel={toggleUploadModal}
            offer={offer}
            setOffer={setOffer}
            typeDoc={typeDoc}
          />

          <AttachmentModal
            attachmentModalStatus={attachmentModalStatus}
            setAttachmentModalStatus={setAttachmentModalStatus}
            attachmentId={attachmentId}
          />
        </LoadingInvestorRoom>      
    </>
  );
};

export default InvestmentView;
