import { Row, Input } from 'antd';
import ArticleCard from './ArticleCard';
import style from '../../styles/Aprender/aprender.module.css';
import { useState, useEffect } from 'react';
import clientAxios from '../../config/clientAxios';
import LoadingComponent from '../Loader/LoadingComponent';
import arrow from '../../img/icons/Trazado48.svg';
import iconHome from '../../img/icons/Trazado178.svg';
import iconWhatsapp from '../../img/icons/Grupo118.svg';
import { warningMessage } from 'utils/messageHelper/messageHelper';
const { Search } = Input;

const Articles = () => {
  const MAX_ARTICLE_DISPLAY = 4;
  const [dataArticle, setDataArticle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const lastFourArticles = dataArticle.slice(0, MAX_ARTICLE_DISPLAY);
  const [filter, setFilter] = useState();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/articlesPosted');
        setDataArticle(res.data);
        setIsLoading(false);
      } catch (error) {
        warningMessage('Los artículos no están disponibles');
      }
    };
    fetchArticles();
  }, []);

  const handleFilterValue = (value) => {
    setFilter(value);
  };
  const handleSearch = (value) => {
    if (!value) {
      return lastFourArticles;
    }
    if (value) {
      const filter = dataArticle.filter(({ title }) =>
        title?.toLowerCase().includes(value?.toLowerCase())
      );
      return filter;
    }
  };

  return (
    <>
      <Row justify="end" className={style.searchInput}>
        <Search
          placeholder="Buscar artículos"
          onSearch={handleFilterValue}
          style={{ width: 200 }}
        />
      </Row>
      <LoadingComponent isLoading={isLoading}>
        <Row className={style.divRowCards}>
          {handleSearch(filter).map((article) => (
            <ArticleCard article={article} key={article._id} />
          ))}
        </Row>
      </LoadingComponent>
      <Row justify="center">
        <a href="/articulos" className={style.aViewMore}>
          Ver más <img src={arrow} className={style.arrow} alt="" />
        </a>
      </Row>
      <Row justify="end">
        <a href="/" className={style.aIcon}>
          <img src={iconHome} className={style.icon} alt="" />
        </a>
        <a href="https://wa.link/kx4f6d" className={style.aIcon}>
          <img src={iconWhatsapp} className={style.icon} alt="" />
        </a>
      </Row>
    </>
  );
};

export default Articles;
