import styles from '../../styles/contactPage/contactPage.module.css';
import clientAxios from '../../config/clientAxios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Row, Col, } from 'antd';
import {
  successMessage,
  errorMessage,
} from '../../utils/messageHelper/messageHelper';
// import { useRef } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';

const FormContact = ({ buttonName }) => {
  const history = useHistory();
  
  const [successSendingMessage, setSuccessSendingMessage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false)
  const [input, setInput] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });
  // const reCaptchaKey = '6LddXMIeAAAAAOXJJ6s_WuQRoPzLu4Axcg1xJmTu';
  // const recaptcha = useRef();
  
  // const onCaptchaChange = (value) => {
  //   if (value) {
  //     console.log('Captcha value:', value);
  //   }
  // };
  const onChange = (e, name) => {
      setInput({ ...input, [name]: e.target.value });
  };
  const onSubmit = async () => {
    try {
      setLoadingButton(true);
      const message = await clientAxios.post('/contact', input);
      if (message) {
        successMessage('Mensaje enviado correctamente');
        setSuccessSendingMessage(true);
        history.push('/contacto/exito');
      }
    } catch (error) {
      console.log(error);
      errorMessage('Ocurrió un error, intentelo nuevamente');
    } finally{
      setLoadingButton(false);
    }
  };

  return (
    <Form
      name="contact"
      layout="vertical"
      className={`${styles.form} mt-5 pb-2 `}
      initialValues={{
        remember: true,
      }}
      onFinish={(values) => onSubmit()}
    >
      <Row justify='center'>
        { !successSendingMessage 
          ? 
          <Col span={16} className={`${styles.formContact} p-5 pb-0 pt-4`} >
            <Form.Item
              className=' d-flex justify-content-center'
              label={<label className={styles.label}>Nombre y Apellido</label>}
              name="name"
              onChange={(e) => onChange(e, 'name')}
              rules={[
                {
                  required: true,
                  message: 'Complete este campo',
                },
              ]}
            >
              <Input className={styles.input} />
            </Form.Item>
            <Form.Item
            label={<label className={styles.label}>Email</label>}
            name="email"
              onChange={(e) => onChange(e, 'email')}
              rules={[
                {
                  required: true,
                  message: 'Complete este campo',
                },
                {
                  type: "email",
                  message: "Ingrese un email valido",
                },
              ]}
            >
              <Input className={styles.input} />
            </Form.Item>
            <Form.Item
              label={<label className={styles.label}>Teléfono</label>}
              name="phoneNumber"
              onChange={(e) => onChange(e, 'phoneNumber')}
            >
              <Input className={styles.input} />
            </Form.Item>
            {/* <ReCAPTCHA
              sitekey={reCaptchaKey}
              ref={recaptcha}
              required
              onChange={onCaptchaChange(recaptcha?.current?.getValue())}
            /> */}
            <Form.Item
              // className='ps-2'
            >
              <Row justify='center'>
                <Button type="text" loading={loadingButton} htmlType="submit" className={`${styles.btn} mt-0`}>
                  {buttonName}
                </Button>
              </Row>
            </Form.Item>

          </Col>
          : 
          <Col span={16} className={`${styles.formContact} p-5 pb-0 pt-4 text-center`}>
            <div className='mt-5'>
              <h1 className='fw-bold'>¡Muchas gracias!</h1>
            </div>
            <h3>Nos contactaremos a la brevedad</h3>
          </Col> }
      </Row>
    </Form>
  );
};
export default FormContact;
