import { dateFormat } from "../../../../../../utils/dateFormatHelper/dateFormat";
import { currentFormat } from "utils/currentFormatHelper/currentFormatHelper";


const methodStructure = { 0: 'Ingreso', 1: 'Egreso' };

export const tableHeadingsTemplate =  [
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    width: 160,
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (key) => {
      const finishDate = key;
      if (finishDate) {
        const finishDateFormat = dateFormat(finishDate);
        return finishDateFormat;
      }
    },
  },
  {
    title: 'Concepto',
    dataIndex: 'motive',
    key: 'motive',
    enable: true,
    width: 160,
  },
  {
    title: 'Tipo Mov.',
    dataIndex: 'method',
    key: 'method',
    enable: true,
    width: 160,
    render: (key) => methodStructure[key],
  },
  {
    title: 'Monto',
    dataIndex: 'money',
    key: 'money',
    enable: true,
    width: 160,
    render: (key) => (
      key && currentFormat(key)
    ),
  },
  {
    title: 'Saldo',
    dataIndex: 'balanceAtTheTime',
    key: 'balanceAtTheTime',
    enable: true,
    width: 160,
    render: (key) => (
      key && currentFormat(key)
    ),
  },
];

