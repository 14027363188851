import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, DatePicker, Space } from 'antd';
import { TableHeadingsLastConection } from './tablesReportUsers/TableHeadings/TableHeadingsLastConection';
import { FileExcelOutlined } from '@ant-design/icons';
import { getTodayDate, getOneMonthLess } from 'utils/getTodayDate/getTodayDate';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import {
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import TableReportUsers from './tablesReportUsers/TableReportUsers';
import clientAxios from 'config/clientAxios';
import styles from './style/reports.module.css';
import ButtonExportCsv from 'components/Admin/exportCsv/ButtonExportCsv';

const ContactReport = ({ rangePicker }) => {
  const [dateLastConection, setDateLastConection] = useState('');
  const [dataReportLastConection, setDataReportLast] = useState([]);
  const [dataToSend, setDataToSend] = useState([]);
  const [isLoadingLastConection, setIsLoadingLastConection] = useState(false);

  useEffect(() => {
    const handleGetReportContact = async () => {
      const date =
        dateLastConection !== '' ? dateLastConection : getOneMonthLess();
      const extractDateOfRange = rangePicker?.map((date) => date._d);
      const rangeDate =
        extractDateOfRange.length === 0
          ? [getOneMonthLess(), getTodayDate()]
          : extractDateOfRange;

      const sendData = {
        lastLogin: date,
        initial: rangeDate[0],
        final: rangeDate[1],
      };
      setDataToSend(sendData);
      try {
        setIsLoadingLastConection(true);
        const response = await clientAxios.post('/reports/investors', sendData);
        const userResponse = response.data;
        setDataReportLast(userResponse);
        successMessage('Reporte Creado');
      } catch (error) {
        errorMessage('Error al crear el reporte');
      } finally {
        setIsLoadingLastConection(false);
      }
    };
    handleGetReportContact();
  }, [dateLastConection, rangePicker]);

  const handleDateLastConection = (value) => {
    if (value !== null) {
      const formatterDate = value;
      setDateLastConection(formatterDate);
    } else {
      setDateLastConection(getOneMonthLess());
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={{ span: 24 }}>
          <Space direction="vertical">
            <p>Inversores NO tuvieron conexión desde: </p>
          </Space>
          <DatePicker
            defaultValue={getOneMonthLess()}
            className={styles.sizeInputs}
            onChange={handleDateLastConection}
          />
        </Col>
        <Divider />
      </Row>
      <Row align="middle" justify="center">
        <h3>Reporte Contactos</h3>
      </Row>
      <Row>
        <p>
          {`Fecha: ${
            dateLastConection === ''
              ? dateFormat(getOneMonthLess())
              : dateFormat(dateLastConection)
          } al dia de hoy`}
        </p>
      </Row>
      <Divider />
      <TableReportUsers
        rowKey="_id"
        isLoading={isLoadingLastConection}
        dataSourse={dataReportLastConection}
        tableHeadings={TableHeadingsLastConection}
      />
      <Row align="middle" justify="center">
        <ButtonExportCsv
          dataToSend={dataToSend}
          nameButton="Exportar CSV"
          nameFile={'reporte-contacto-totallia.csv'}
          endPoint={'/reports/investors/export'}
          style={styles.btnDownloadCsv}
          icon={
            <FileExcelOutlined style={{ fontSize: '30px', color: '#327f59' }} />
          }
        />
      </Row>
    </>
  );
};

export default ContactReport;
