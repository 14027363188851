import React from 'react';
import { Button, Col, Divider, Input, Modal, Row, InputNumber } from 'antd';
import {
  formatter,
  parser,
} from '../../../../utils/formatParseHelper/formatParseHelper';
import { dateFormat } from '../../../../utils/dateFormatHelper/dateFormat';
import style from './modal.module.css';

const ModalInvesment = ({
  dataModal,
  isVisible,
  handleInfoInvesmentModal,
  handleEditInvesment,
}) => {
  return (
    <Modal
      title={`Informacion: ${dataModal.name}`}
      centered
      visible={isVisible}
      onCancel={handleInfoInvesmentModal}
      width={1000}
      footer={[
        <Button
          type="primary"
          onClick={() => handleEditInvesment(dataModal._id)}
        >
          Ir a editar datos de la inversion
        </Button>,
      ]}
    >
      <div className={style.modalCenter}>
        <Row gutter={16}>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Fecha de inicio:
            </label>
            <Input
              value={dateFormat(
                dataModal?.complementaryInformation?.startDate
              )}
            />
            <label>
              Fecha de cierre:
            </label>
            <Input
              value={dateFormat(
                dataModal?.complementaryInformation?.finishDate
              )}
            />
          </Col>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Monto Minimo:
            </label>
            <InputNumber
              className="prefix-width-modal"
              defaultValue={dataModal?.complementaryInformation?.minAmount}
              formatter={formatter}
              parser={parser}
            />
            <label>
              Monto Maximo:
            </label>
            <InputNumber
              className="prefix-width-modal"
              defaultValue={dataModal?.complementaryInformation?.maxAmount}
              formatter={formatter}
              parser={parser}
            />
          </Col>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Nombre de la inversión:
            </label>
            <Input value={dataModal?.name} />
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row gutter={16}>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Tipo de inversion:
            </label>
            <Input value={dataModal?.investmentType} />
          </Col>
          {dataModal.isOpen ? (
            <Col className="gutter-row" sm={24} md={8}>
              <label>
                Cantidad de oferentes:
                <Input value="" />
              </label>
            </Col>
          ) : null}
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Cantidad de inversores:
              <Input value="" />
            </label>
          </Col>
        </Row>
        <Divider orientation="left"></Divider>
        <Row gutter={16}>
          <Col className="gutter-row" sm={24} md={8}>
            {dataModal.isOpen ? (
              <>
                <label>
                  Monto comprometido:
                </label>
                <InputNumber
                  className="prefix-width-modal"
                  defaultValue={0}
                  formatter={formatter}
                  parser={parser}
                  value={dataModal?.amountInvested}
                />
              </>
            ) : (
              <>
                <label>
                  Monto distribuido:
                </label>
                <InputNumber
                  className="prefix-width-modal"
                  defaultValue={0}
                  formatter={formatter}
                  parser={parser}
                  value={dataModal?.amountDistributed}
                />
              </>
            )}
            <label>
              Firmante:
            </label>
            <Input value={dataModal?.complementaryInformation?.signer} />
          </Col>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Monto invertido:
            </label>
            <InputNumber
              className="prefix-width-modal"
              value={dataModal?.amountInvested}
              formatter={formatter}
              parser={parser}
            />
            <label>
              Estado de publicacion:
            </label>
            <Input
              value={
                dataModal?.isPosted ? 'Si' : 'No'
              }
            />
          </Col>
          <Col className="gutter-row" sm={24} md={8}>
            <label>
              Porcentaje de inversion:
            </label>
            <Input value="" />
            <label>
              Banco:
            </label>
            <Input value={dataModal?.bank?.name} />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalInvesment;
