import React from 'react';
import { Space, Alert } from 'antd';

const AlertInfoReport = () => {
  return (
    <Alert
      message="Datos a analizar"
      description={
        <Space direction="vertical">
          <ul>
            <li>Cant. Oferentes</li>
            <li>Cant. Inversores</li>
            <li>Monto invertido total</li>
            <li>% Objetivo cumplido</li>
          </ul>
        </Space>
      }
      type="info"
      showIcon
    />
  );
};

export default AlertInfoReport;
