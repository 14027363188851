import { Row, Collapse } from 'antd';
import NewsletterCard from './NewsletterCard';
import clientAxios from 'config/clientAxios';
import { warningMessage } from 'utils/messageHelper/messageHelper';
import styles from 'styles/Aprender/newsletter.module.css';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
const { Panel } = Collapse;

const Newsletter = () => {
  const MAX_NEWSLETTER_DISPLAY = 4;
  const [newsletters, setNewsletters] = useState([]);
  const lastFourNewsletter = newsletters.slice(0, MAX_NEWSLETTER_DISPLAY);
  const theRestNewsletter = newsletters.slice(4);
  const [changeTitle, setChangeTitle] = useState(true);

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        const res = await clientAxios.get('/newsletterPublic');
        setNewsletters(res.data);
      } catch (error) {
        warningMessage('Los datos no están disponibles en este momento');
      }
    };
    fetchNewsletters();
  }, []);

  const handleChangeTitle = () => setChangeTitle((state) => !state);
  return (
    <>
      <Row justify="start" className={styles.rowCards}>
        {lastFourNewsletter.map((newsletter) => (
          <NewsletterCard newsletter={newsletter} key={newsletter._id} />
        ))}
      </Row>
      <Collapse
        ghost
        expandIcon={({ isActive }) => (
          <ArrowDownOutlined
            className={styles.arrowCollapse}
            style={{ fontSize: 30 }}
            rotate={isActive ? 180 : 0}
          />
        )}
        onChange={handleChangeTitle}
        expandIconPosition="right"
      >
        <Panel
          key="Newsletters"
          header={
            <p className={styles.aHeaderPanel}>
              {changeTitle ? 'Ver más' : 'Ver menos'}
            </p>
          }
        >
          <Row justify="start" className={styles.rowCards}>
            {theRestNewsletter.map((newsletter) => (
              <NewsletterCard newsletter={newsletter} key={newsletter._id} />
            ))}
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

export default Newsletter;
