import { useState } from 'react';
import { Route, Switch } from 'react-router';
import ThankYoupage from '../components/ThankYoupage';
import PanelAdmin from '../components/Admin/PanelAdmin';
import LandingPage from '../components/LandingPage';
import ResetPassword from '../components/ResetPassword';
import AboutUs from '../components/AboutUs/AboutUs';
import Investments from '../components/Investments/Investments';
import DescriptionInvestment from '../components/Investments/DescriptionInvestment';
import ContactPage from '../components/ContactPage/ContactPage';
import LearnPage from '../components/Aprender/LearnPage';
import ArticlePage from '../components/Aprender/ArticlePage';
import GuidePage from '../components/Aprender/QuickStartGuide/GuidePage';
import OneArticlePage from '../components/Aprender/OneArticlePage';
import OneGuidePage from '../components/Aprender/QuickStartGuide/OneGuidePage';
import { getLocalStorage } from '../utils/localStorageHelper/localHelper';
import PrivateRouteInvestorRoom from 'components/investor-room/Private/PrivateRouteInvestorRoom';
import NavbarInvestorRoom from 'components/investor-room/NavbarInvestorRoom';
import PrivateRouteAdmin from 'components/Admin/private/PrivateRouteAdmin';
import WhatsappBtn from '../components/WhatsappBtn/WhatsappBtn';
import NotificationProvider from '../provider/NotificationProvider'

export default function Routes() {
  const [user, setUser] = useState(getLocalStorage('user'));

  return (
    <NotificationProvider user={user}>
      <Switch>
        <Route path="/articulos/detalle/:id">
          <OneArticlePage user={user} setUser={setUser} />
        </Route>
        <Route path="/articulos">
          <ArticlePage user={user} setUser={setUser} />
        </Route>
        <Route path="/guias/detalle/:id">
          <OneGuidePage user={user} setUser={setUser} />
        </Route>
        <Route path="/guiasdeiniciorapido">
          <GuidePage user={user} setUser={setUser} />
        </Route>
        <Route path="/aprender">
          <LearnPage user={user} setUser={setUser} />
        </Route>
        <Route path="/contacto">
          <ContactPage user={user} setUser={setUser} />
        </Route>
        <Route path="/contacto/exito">
          <ContactPage user={user} setUser={setUser}  />
        </Route>
        <Route path="/investments/detailInvestment/:id">
          <DescriptionInvestment user={user} setUser={setUser} />
        </Route>
        <Route path="/investments">
          <Investments user={user} setUser={setUser} />
        </Route>
        <Route path="/aboutUs">
          <AboutUs user={user} setUser={setUser} />
        </Route>
        <PrivateRouteInvestorRoom path="/investorRoom">
          <NavbarInvestorRoom user={user} setUser={setUser}/>
        </PrivateRouteInvestorRoom>
        <PrivateRouteAdmin user={user} path="/admin">
          <PanelAdmin user={user} setUser={setUser} />
        </PrivateRouteAdmin>
        <Route path="/resetPassword">
          <ResetPassword />
        </Route>
        <Route path="/thankYouPage">
          <ThankYoupage user={user} />
        </Route>
        <Route path="/">
          <LandingPage user={user} setUser={setUser} />
        </Route>
      </Switch>
      <WhatsappBtn />
    </NotificationProvider>
  );
}
