import 'moment/locale/es';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './styles/index.css';
import './styles/forms.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/es_ES';
import Routes from './routes/Routes';

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Router>
        <Routes />
      </Router>
    </ConfigProvider>
  );
}

export default App;
