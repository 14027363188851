import React from 'react';
import { Row } from 'antd';
import { SolutionOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {ReactComponent as ContractFirm} from 'img/icons/Grupo124.svg'
import styles from './investNowLogo.module.css';

const InvestNowLogo = () => {
  return (
    <Row>
      <div className={styles.containerIco}>
        <div className={styles.icon}>
          <SolutionOutlined className={styles.icoBox} />
        </div>
        <div className={styles.containerParraf}>
          <p className={styles.parraf}>Completá el formulario</p>
        </div>
      </div>
      <div className={styles.containerArrow}>
        <div className={styles.icon}>
          <ArrowRightOutlined className={styles.icoArrow} />
        </div>
      </div>
      <div className={styles.containerIco}>
        <div className={styles.icon}>
          <Icon component={ContractFirm} className={styles.icoBox} />
        </div>
        <div className={styles.containerParraf}>
          <p className={styles.parraf}>Firmá el contrato</p>
        </div>
      </div>
    </Row>
  );
};

export default InvestNowLogo;
