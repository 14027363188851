import icon0 from 'img/icons/Grupo59.svg';
import icon1 from 'img/icons/Grupo60.svg';
import icon2 from 'img/icons/Grupo133.svg';
import icon3 from 'img/icons/Grupo64.svg';

export const classification = [
  {key: 0, name: 'Totallia News', icon: icon0},
  {key: 1, name: 'Artículos de Interés', icon: icon1},
  {key: 2, name: 'Nuevos Lanzamientos', icon: icon2},
  {key: 3, name: 'Update Proyectos en curso', icon: icon3}
]