import styles from '../../../styles/sliderHome.module.css';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import ItemSlider from './ItemSlider';

const LastNews = ({ news }) => {
  const history = useHistory();
  const readMore = (id) => {
    history.push(`/articulos/detalle/${id}`);
    window.scrollTo(0, 0);
  };


  return (
    <ItemSlider img={news?.imgThumbnail} imgClassName={styles.optimizeImg}>
      <div className={styles.containerTextNews}>
        <div className={styles.cardLastNew}>
          <h1 className={styles.positionTitleNews}>
            {news?.title && news?.title?.toLocaleUpperCase()}
          </h1>
          <br />
          <br />
          <div className="d-flex justify-content-start">
            <Button className={styles.btn} onClick={() => readMore(news._id)}>
              LEER NOTA
            </Button>
          </div>
        </div>
      </div>
    </ItemSlider>
  );
};

export default LastNews;
