import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import InvestorProfile from '../investorProfile/InvestorProfile';
import InvestmentAdminView from '../investorProfile/investment/investmentAdminView/InvestmentAdminView';
import Investors from '../Investors';

const InvestorsRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/perfil-del-Inversor/:uid/offers/:id`} component={InvestmentAdminView} />
      <Route path={`${path}/perfil-del-Inversor/:uid`} component={InvestorProfile} />
      <Route path={path} component={Investors} />
    </Switch>
  );
};

export default InvestorsRoutes;
