import React from 'react';
import { Pie } from '@ant-design/charts';

const PieGraph = ({ responseDataReport }) => {
  const data = [
    {
      type: '% oferentes',
      value: responseDataReport.offerorsQty,
    },
    {
      type: '% inversores',
      value: responseDataReport.investorsQty,
    },
    {
      type: 'cupo no obtenido',
      value: - ((responseDataReport.totalAmountToInvest * 100) / responseDataReport.totalAmountToInvest) + 100,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };
  return <Pie {...config} />;
};

export default PieGraph;
