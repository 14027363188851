import React from 'react';
import { Modal, Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const ImportShareModal = ({
    modalImportVisible,
    setModalImportVisible,
    formImport,
    onImportFinish,
    onImportFinishFailed,
    clearUploadedFile,
    defaultFileList,
    setDefaultFileList,
    submitting
}) => {

    const handleOnChange = ({ fileList }) => {
        setDefaultFileList(fileList);
    };
      

    return (
        <Modal
            title={
                <>
                    <Form
                        ref={formImport}
                        onFinish={onImportFinish}
                        onFinishFailed={onImportFinishFailed}
                    >
                        <span>Importador Share</span>
                        <Form.Item
                            name="importadorShareExcel"
                            className="attachment-form-item"
                        >
                            <Upload
                                fileList={defaultFileList}                                beforeUpload={() => false} // Desactivar la carga automática de archivos
                                maxCount={1}
                                onChange={handleOnChange}
                            >
                                <Button icon={<UploadOutlined />} type='button'>Cargar Archivo</Button>
                            </Upload>
                        </Form.Item>

                        <div className="center-container">
                            <Button type="primary" htmlType="submit" className="btn-save" loading={submitting}>
                                {submitting ? 'Procesando...' : 'Procesar Archivo'}
                            </Button>
                        </div>
                    </Form>
                </>
            }
            className='mt-5'
            centered
            visible={modalImportVisible}
            onCancel={() => {
                setModalImportVisible(false)
                clearUploadedFile(); // Limpia el archivo subido al cerrar el modal
            }}
            footer={false}
            width={500}
            bodyStyle={{ maxHeight: '100%', paddingBottom: 60 }}
        />
    );
};

export default ImportShareModal;
