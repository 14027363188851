import { Link } from 'react-router-dom'
import { Button } from 'antd';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';

export const tableDistributions = () => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (createdAt) => dateFormat(createdAt),
  },
  {
    title: 'Cantidad de inv.',
    enable: true,
    render: (e) => e?.rents.length,
  },
  {
    title: 'Monto Total',
    dataIndex: 'amountTotal',
    key: 'amountTotal',
    enable: true,
    render: (m) => (
      <p className="text-success">
        <b>{`$${m}`}</b>
      </p>
    ),
  },
  {
    title: 'Acciones',
    dataIndex: '_id',
    key: '_id',
    enable: true,
    render: (id) => (
      <>
        <Button
          style={{ border: 'none' }}
          size="small"
          icon={<Link to={`/admin/inversiones/distribucion/${id}`}><InfoCircleOutlined /></Link>}
        />
        <Button
          style={{ border: 'none' }}
          size="small"
          icon={<Link to={`/admin/inversiones/editarDistribucion/${id}`}><EditOutlined /></Link>}
        />
      </>
    ),
  },
];
