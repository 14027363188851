import React, { useEffect, useState } from 'react';
import { Col, Divider, Radio, Row, Space, Select } from 'antd';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { getTodayDate, getOneMonthLess } from 'utils/getTodayDate/getTodayDate';
import { infoMessage } from 'utils/messageHelper/messageHelper';
import { FileExcelOutlined, AreaChartOutlined } from '@ant-design/icons';
import { successMessage } from 'utils/messageHelper/messageHelper';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { notifications } from 'utils/notificationsHelper/NotificationsHelper';
import clientAxios from 'config/clientAxios';
import LoadingComponent from 'components/Loader/LoadingComponent';
import DualAxesGraph from '../../reports/graphReports/DualAxesGraph/DualAxesGraph';
import GaugeGraph from '../../reports/graphReports/GaugeGraph/GaugeGraph';
import PieGraph from '../../reports/graphReports/PieGraph/PieGraph';
import AlertInfoReport from '../../reports/alertInfo/AlertInfoReport';
import LoadingReports from '../../reports/loadingReports/LoadingReports';
import styles from '../../reports/style/reports.module.css';
import ButtonExportCsv from 'components/Admin/exportCsv/ButtonExportCsv';

const { Option } = Select;

const InvestmentReports = ({ rangePicker }) => {
  const [investmentsNamesAndId, setInvestmensNamesAndId] = useState([]);
  const [radioSelectIdInvestment, setRadioSelectIdInvestment] = useState('');
  const [nameOfInvestmentSelected, setNameOfInvestmentSelected] = useState({});
  const [dataToSend, setdataToSend] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [radioSelect, setRadioSelect] = useState(true);
  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const [responseDataReport, setResponseDataReport] = useState([]);

  useEffect(() => {
    const handleGetOpenInvestments = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(
          `/investments?isOpen=${radioSelect}`
        );
        const openInvestments = response.data;
        const openInvestmentsCopy = openInvestments.map((investment) => ({
          ...investment,
        }));
        const onlyNamesAndId = openInvestmentsCopy.map((investment) => ({
          name: investment.name,
          id: investment._id,
        }));

        setInvestmensNamesAndId(onlyNamesAndId);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    handleGetOpenInvestments();
  }, [radioSelect]);

  useEffect(() => {
    const handlegetReport = async () => {
      if (rangePicker !== null && radioSelectIdInvestment !== '') {
        const extractDateOfRange = rangePicker?.map((date) => date._d);

        const dateToSend =
          extractDateOfRange.length === 0
            ? [getOneMonthLess(), getTodayDate()]
            : extractDateOfRange;

        const createConsultReport = {
          investmentId: radioSelectIdInvestment,
          initial: dateToSend[0],
          final: dateToSend[1],
        };
        setdataToSend(createConsultReport);
        try {
          const response = await clientAxios.post(
            '/reports/investment',
            createConsultReport
          );
          const userResponse = response.data;
          setResponseDataReport(userResponse);
          successMessage('Reporte creado');
        } catch (error) {
          errorMessage('Error en la consulta de datos');
        } finally {
          setIsLoadingReports(false);
        }
      } else {
        if (rangePicker === null) {
          infoMessage('Seleccione los rangos de fecha');
        } else {
          notifications(
            'Notificacion',
            'Para empezar a ver sus reportes seleccione una inversión.',
            <AreaChartOutlined style={{ color: '#ef4b59' }} />
          );
        }
      }
    };
    handlegetReport();
  }, [radioSelectIdInvestment, rangePicker]);

  const handleOptionsNames = (value) => {
    const nameOfInvestment = investmentsNamesAndId.filter(
      ({ id }) => id === value
    );
    setNameOfInvestmentSelected(nameOfInvestment[0]);
    setRadioSelectIdInvestment(value);
  };

  const handleRadioSelect = () => {
    setRadioSelect((state) => !state);
  };

  return (
    <>
      <Row>
        <Col span={24} lg={{ span: 24 }}>
          <Radio.Group onChange={handleRadioSelect} value={radioSelect}>
            <Space direction="vertical">
              <Radio value={true}>Inversiones abiertas</Radio>
              <Radio value={false}>Inversiones cerradas</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col span={24} lg={{ span: 24 }} className={styles.marginRow}>
          <h4>
            Reportes por inversión({radioSelect ? 'Abiertas' : 'Cerradas'})
          </h4>
        </Col>
      </Row>
      <Divider />
      <LoadingComponent isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={24} lg={{ span: 8 }}>
            <h4>Seleccionar Inversión</h4>
            <Select style={{ width: 200 }} onChange={handleOptionsNames}>
              {investmentsNamesAndId.map((investment) => (
                <Option key={investment.id} value={investment.id}>
                  {investment.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={24} lg={{ span: 8 }}>
            <AlertInfoReport />
          </Col>
          <Col span={24} lg={{ span: 8 }}></Col>
          <Divider />
        </Row>
      </LoadingComponent>
      {radioSelectIdInvestment && (
        <LoadingReports isLoadingReport={isLoadingReports}>
          <Row align="middle" justify="center">
            {Object.keys(nameOfInvestmentSelected).length !== 0 && (
              <h3>Reporte inversión: {nameOfInvestmentSelected?.name}</h3>
            )}
          </Row>
          <Row>
            <p>
              Fecha:
              {` ${dateFormat(dataToSend.initial)} al ${dateFormat(
                dataToSend.final
              )}`}
            </p>
          </Row>
          <Row>
            <Col span={24} lg={{ span: 12 }}>
              <div className={styles.colorDiv}>
                <h5>{`Monto requerido: ${currentFormat(
                  responseDataReport.totalAmountToInvest
                )} `}</h5>
              </div>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <div className={styles.colorDiv}>
                <h5>{`Monto total inv.: ${currentFormat(
                  responseDataReport.totalAmountInvested
                )} `}</h5>
              </div>
            </Col>
          </Row>
          <Row className={styles.marginRow}>
            <Col span={24} lg={{ span: 24 }}>
              <h4>Cantidad de oferentes e inversores</h4>
              <DualAxesGraph responseDataReport={responseDataReport} />
            </Col>
          </Row>
          <Divider />
          <Row align="middle" justify="center">
            <Col span={24} lg={{ span: 24 }}>
              <h4>Objetivo cumplido</h4>
              <GaugeGraph responseDataReport={responseDataReport} />
            </Col>
            <Col span={24} lg={{ span: 24 }}>
              <h4>Objetivo aproximado</h4>
              <PieGraph responseDataReport={responseDataReport} />
            </Col>
          </Row>
          <Divider />
          <Row align="middle" justify="center">
            <ButtonExportCsv
              dataToSend={dataToSend}
              nameButton="Exportar CSV"
              nameFile={'reporte-inversiones-totallia.csv'}
              endPoint={'/reports/investment/export'}
              style={styles.btnDownloadCsv}
              icon={
                <FileExcelOutlined
                  style={{ fontSize: '30px', color: '#327f59' }}
                />
              }
            />
          </Row>
        </LoadingReports>
      )}
    </>
  );
};

export default InvestmentReports;
