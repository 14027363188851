import { Row, Col, Button, Table, Popconfirm } from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clientAxios from 'config/clientAxios';
import {
  errorMessage,
  successMessage,
} from 'utils/messageHelper/messageHelper';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import styles from 'styles/panelAdmin/adminBanks.module.css';

const Articles = () => {
  const [dataArticles, setDataArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { path } = useRouteMatch();
  const history = useHistory();

  const tableHeadings = [
    {
      title: 'Fecha',
      dataIndex: 'writingDate',
      key: 'writingDate',
      render: (key) => {
        const dateNewFormat = dateFormat(key);
        return <p>{dateNewFormat}</p>;
      },
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Clasificación',
      dataIndex: 'classification',
      key: 'classification',
    },
    {
      title: 'Escritor',
      dataIndex: 'writerName',
      key: 'writerName',
    },
    {
      title: 'Publicado',
      dataIndex: 'isPosted',
      key: 'isPosted',
      render: (key) => {
        const data = key ? 'Si' : 'No';
        return <p>{data}</p>;
      },
    },
    {
      title: 'Acciones',
      dataIndex: '_id',
      key: '_id',
      render: (key) => (
        <>
          <Button
            style={{ border: 'none' }}
            size="small"
            icon={<EditOutlined />}
            onClick={() => editArticle(key)}
          />
          <Popconfirm
            title="¿Seguro desea eliminar el artículo?"
            okText="Si"
            cancelText="No"
            onConfirm={() => deleteArticle(key)}
          >
            <Button
              style={{ border: 'none' }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get(`/articles?delete=false`);
        setDataArticles(res.data);
      } catch (error) {
        errorMessage('Datos no disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchArticles();
  }, []);

  const addArticle = () => {
    history.push(`${path}/nuevoArticulo`);
  };
  const deleteArticle = async (id) => {
    try {
      await clientAxios.delete(`/articles/${id}`);
      window.scrollTo(0, 0);
      successMessage('Articulo eliminado exitosamente');
      const filteredArticles = dataArticles.filter(
        (article) => article._id !== id
      );
      setDataArticles(filteredArticles);
    } catch (error) {
      errorMessage('Hubo un error al eliminar el artículo');
    }
  };
  const editArticle = (id) => {
    history.push(`${path}/editarArticulo/${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <Row justify="center" className="bg-title-nuevaInversion">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3>Artículos</h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="center-container">
              <Button
                onClick={addArticle}
                icon={<PlusCircleOutlined />}
                className={styles.btnAddBank}
              >
                Crear artículo
              </Button>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Table
          rowKey="_id"
          dataSource={dataArticles}
          columns={tableHeadings}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
          loading={isLoading}
          scroll={{ x: 1000 }}
        />
      </Row>
    </div>
  );
};

export default Articles;
