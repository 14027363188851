import { Menu, Button, Drawer } from 'antd';
import { useState } from 'react';
import { ExportOutlined, ToolOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import styles from 'styles/theNavbar/theNavbar.module.css';
import { isUserRoleAdmin } from 'utils/isUserRoleAdminHelper/isUserRoleAdmin';

const NavbarInvestorRoomMobile = ({ user, logout, notification }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  
  const { SubMenu } = Menu;
  const { url } = useRouteMatch();
  
  const handleShowDrawer = () => setShowDrawer((state) => !state);
  const handleMenu = () => {
    handleShowDrawer();
  };

  return (
    <div>
      <Button
        type="link"
        onClick={handleShowDrawer}
        className="btn-bars-mobile"
      >
        <i className="fas fa-bars"></i>
      </Button>
      <Drawer
        title="Totallia"
        placement="left"
        closable={handleShowDrawer}
        onClose={handleShowDrawer}
        visible={showDrawer}
        key="left"
        id="drawerNav"
      >
        <Menu mode="inline" onClick={handleMenu}>
          <Menu.Item key="inicio">
            <Link to="/" className="items-navbar">
              INICIO
            </Link>
          </Menu.Item>
          <Menu.Item key="inversiones">
            <Link to={`${url}/investments`} className="items-navbar">
              INVERSIONES
            </Link>
          </Menu.Item>
          <Menu.Item key="mis-inversiones">
            <Link to={`${url}/myInvestments`} className="items-navbar">
              MI CARTERA
            </Link>
          </Menu.Item>
          <Menu.Item key="perfil">
            <Link to={`${url}/Profile`} className="items-navbar">
              PERFIL
            </Link>
          </Menu.Item>
          <Menu.Item key="novedades">
            <Link 
              to={`${url}/novelties`} 
              className={ notification ? `${styles.flickered} items-navbar` : "items-navbar" }>
              NOTIFICACIONES
            </Link>
          </Menu.Item>
          {user && (
            <SubMenu
              key="sub1"
              className="items-navbar btn-nav-mobile"
              title={user.firstName}
            >
              {isUserRoleAdmin(user) && (
                <Link to="/admin">
                  <Button
                    className={styles.buttonNavbar}
                    type="primary"
                    icon={<ToolOutlined />}
                  >
                    Panel Admin
                  </Button>
                </Link>
              )}
              <Button
                className={styles.buttonNavbar}
                icon={<ExportOutlined />}
                danger
                onClick={logout}
              >
                Cerrar Sesión
              </Button>
            </SubMenu>
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

export default NavbarInvestorRoomMobile;
