import { Form, Row, Col, Space, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TextEditor from 'components/Admin/TextEditor/TextEditor';
const FormSeccionesDataPublish = ({ singleInvestment }) => {
  const sections = singleInvestment?.sections;
  return (
    <Form.Item label="Secciones">
      <Form.List name="sections">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row key={key}>
                <Space align="baseline">
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                    >
                      <TextEditor
                        label={'Titulo:'}
                        initialValue={
                          Object.keys(singleInvestment)?.length
                            ? sections[fieldKey]?.title
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
                <Col span={24}>
                  <Form.Item
                    {...restField}
                    name={[name, 'text']}
                    fieldKey={[fieldKey, 'text']}
                  >
                    <TextEditor
                      label={'Texto:'}
                      initialValue={
                        Object.keys(singleInvestment)?.length
                          ? sections[fieldKey]?.text
                          : null
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
                className="select-item"
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Agregar
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
export default FormSeccionesDataPublish;
