import TrackRecords from './TrackRecords';
import SliderPreview from './SliderPreview';
import styles from '../../../../styles/panelAdmin/adminBanks.module.css';
import { useState, useEffect } from 'react';
import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import clientAxios from '../../../../config/clientAxios';
import LoadingComponent from '../../../Loader/LoadingComponent';

const SettingsHome = () => {
  const [imgSlider, setImgSlider] = useState([]);
  const [settings, setSettings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const handleImgSlider = (imgSlider) => setImgSlider(imgSlider);
  const handleSettings = (values) => setSettings(values);

  useEffect(() => {
    const getTrackRecords = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/settingsHome');
        const settingsData = res.data[0];
        const urlImages = settingsData.sliderHome;
        handleSettings(settingsData);
        handleImgSlider(urlImages);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    getTrackRecords();
  }, []);

  return (
    <LoadingComponent isLoading={isLoading}>
      <>
        <TrackRecords
          imgSlider={imgSlider}
          handleImgSlider={handleImgSlider}
          settings={settings}
          handleSettings={handleSettings}
        />
        <h5 className={styles.h5Title}>Preview imágenes del slider</h5>
        {imgSlider?.map((attachment) => (
          <SliderPreview
            attachment={attachment}
            imgSlider={imgSlider}
            handleImgSlider={handleImgSlider}
          />
        ))}
      </>
    </LoadingComponent>
  );
};

export default SettingsHome;
