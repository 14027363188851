import { Modal, Switch } from 'antd';

const ToggleColumnsModal = ({
  isModalVisible,
  tableHeadings,
  handleToggleColumns,
  handleToggleModal,
}) => {
  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      width={300}
      onCancel={handleToggleModal}
      title="Mostrar/Ocultar Columnas"
    >
      {tableHeadings.map((column) => (
        <div key={column.dataIndex} className="div-switch-toggle-columns">
          <Switch
            checked={column.enable}
            onChange={() => handleToggleColumns(column.dataIndex)}
          />
          <p className="toggle-title">{column.title}</p>
        </div>
      ))}
    </Modal>
  );
};

export default ToggleColumnsModal;
