import { Divider, Button, Table, Row, Col, message } from 'antd';
import clientAxios from 'config/clientAxios';
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { tableHeadingsTemplate } from './tableStructure/TableStructure';
import AdditionalDocs from 'components/investor-room/pages/myInvestments/modals/AdditionalDocs/AdditionalDocs';
import ManualOfferModal from './ManualOffersModal';
import { errorMessage, successMessage } from 'utils/messageHelper/messageHelper';

const InvestmentProfile = ({ userData, handleUpdateData }) => {
  const history = useHistory();

  const [dataTable, setDataTable] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [offer, setOffer] = useState({});
  const [showAdditionalDocs, setShowAdditionalDocs] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const formManualOffer = useRef();

  useEffect(() => {
    setDataTable(userData.investmentOffers);
  }, [userData]);

  const handleShowAdditionalDocs = (value, offer) => {
    setShowAdditionalDocs(value);
    setOffer(offer);
  };

  const deleteOffer = async (idOffer) => {
    try {
      setButtonLoading(true);
      const offerDeleted = await clientAxios.delete(`/offers/${idOffer}`);
      if (offerDeleted.status === 200) {
        return message.success(
          'Felicidades, se han guardado los cambios con exito!'
        );
      }
    } catch (error) {
      message.error('Ha ocurrido un error inesperado, intentelo de nuevo.');
    } finally {
      setButtonLoading(false);
      handleUpdateData();
    }
  };

  const clearManualOffer = () => {
    formManualOffer.current.resetFields(['contract','amount','investment']);
    setDefaultFileList([]);
  };

  const onSaveManualOffer = async (values) => {
    setSubmitting(true);
    const contract = values?.contract?.file;
    const amountOffered = values?.amount;
    const investmentId = values.investment;
    const userId = userData._id;
    if (contract && amountOffered && investmentId) {
      const fileName = contract.name.toLowerCase();
  
      if (fileName.endsWith('.pdf')) {
        const formData = new FormData();
        formData.append('contract', contract);
        formData.append('amountOffered', amountOffered);
        formData.append('investmentId', investmentId);
        formData.append('userId', userId);

        await insertOffer(formData);
        setModalVisible(false);
        clearManualOffer();
      } else {
        errorMessage('El archivo debe ser de tipo PDF', 0);
      }
    } else {
      errorMessage('No se ha seleccionado ningún archivo', 0);
    }
    setSubmitting(false);
  }

  const insertOffer = async (formData) => {
    try {
      const response = await clientAxios.post(`/offers/completed`, formData);
      
      if (response.status === 201) {
        successMessage("Oferta creada con exito.");
        setDataTable([...dataTable, response.data])
      } else {
        errorMessage(response.message, 0);
      }
    } catch (error) {
      if (error.response) {
        errorMessage(error.response.data.message, 0);
      } else {
        errorMessage('Error: ' + error.message, 0);
      }
    }
  }

  const onSaveManualOfferFailed = () => {
    errorMessage('Hubo un error al crear la oferta');
  }

  // Verificar si hay inversiones activas
  const hasActiveInvestments = dataTable.length > 0;

  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <h4>Inversiones</h4>
        <Button onClick={() => setModalVisible(true)}>Crear Oferta Manual</Button>
      </div>
      <Divider />
      {hasActiveInvestments ? (
        <Row>
          <Col>
            <Table
              columns={tableHeadingsTemplate(
                history,
                deleteOffer,
                buttonLoading,
                handleShowAdditionalDocs
              )}
              dataSource={dataTable}
              rowKey="_id"
              pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
              scroll={{ x: 1300 }}
            />
          </Col>
        </Row>
      ) : (
        <div className='d-flex justify-content-between align-items-center'>
          <h2>No tiene inversiones activas</h2>
        </div>
      )}
      <AdditionalDocs
        offer={offer}
        onCancel={() => setShowAdditionalDocs(false)}
        open={showAdditionalDocs}
        isAdmin={true}
      />
      <ManualOfferModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        formManualOffer={formManualOffer}
        onSaveManualOffer={onSaveManualOffer}
        onSaveManualOfferFailed={onSaveManualOfferFailed}
        defaultFileList={defaultFileList}
        setDefaultFileList={setDefaultFileList}
        clearManualOffer={clearManualOffer}
        submitting={submitting}
      />
    </>
  );
};

export default InvestmentProfile;
