import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ROL from 'config/roles';

const PrivateRouteAdmin = ({ user, children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user && user.role === ROL.ADMIN ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouteAdmin;
