import { Row } from 'antd';
import style from '../../../styles/Aprender/aprender.module.css';
import img from '../../../img/imgSlide2.jpg';
import GuideCard from './GuideCard';
import LoadingComponent from '../../Loader/LoadingComponent';
import { useState, useEffect } from 'react';
import clientAxios from 'config/clientAxios';
import { warningMessage } from 'utils/messageHelper/messageHelper';

const AllGuides = () => {
  const [dataGuides, setDataGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/guidesPosted');
        const guides = res.data;
        setDataGuides(guides);
      } catch (error) {
        warningMessage('Las guías no están disponibles');
      }
      finally{
        setIsLoading(false);
      }
    };
    fetchGuides();
  }, []);
  return (
    <>
      <div className={style.container}>
        <img src={img} alt="edificio" className={style.imgEdificio} />
        <h3 className={style.title}>Wiki</h3>
      </div>
      <Row>
        <LoadingComponent isLoading={isLoading}>
          <Row className={style.divRowCards}>
            {dataGuides.map((guide) => (
                <GuideCard guide={guide} key={guide._id} />
            ))}
          </Row>
        </LoadingComponent>
      </Row>
    </>
  );
};

export default AllGuides;
