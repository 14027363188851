import { Row, Col, Form, InputNumber, Button, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from '../../../../styles/panelAdmin/adminBanks.module.css';
import {
  errorMessage,
  successMessage,
  warningMessage,
} from '../../../../utils/messageHelper/messageHelper';
import clientAxios from '../../../../config/clientAxios';
import { useState } from 'react';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import LoadingComponent from '../../../Loader/LoadingComponent';

const TrackRecords = ({
  imgSlider,
  settings,
  handleSettings,
  handleImgSlider,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [maxCount, setMaxCount] = useState(3);
  const token = getLocalStorage('token');
  const MAX_FILE_TO_UPLOAD = 3;

  const createSettings = async (values) => {
    const { fileList } = values.sliderHome;
    const slider = fileList?.map((url) => url.response) || '';
    const images = [...imgSlider, ...slider];
    handleImgSlider(images);
    try {
      setIsLoading(true);
      const res = await clientAxios.post('/settingsHome', {
        ...values,
        sliderHome: images,
      });
      const settingsData = res.data;
      handleSettings(settingsData);
      successMessage('Configuraciones realizadas con éxito');
    } catch (error) {
      errorMessage('Hubo un problema al realizar las configuraciones');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = (info) => {
    if (info.file.status === 'done') {
      return warningMessage('Máximo de carga: tres imágenes');
    }
    setMaxCount(MAX_FILE_TO_UPLOAD - imgSlider.length);
  };

  return (
    <LoadingComponent isLoading={isLoading}>
      <>
        <Row className="bg-title-nuevaInversion">
          <Col span={24}>
            <h3> Configuración home page</h3>
          </Col>
        </Row>
        <Row className={styles.rowForm}>
          <h5 className={styles.h5Title}>Track Records</h5>
          <Form
            name="formBank"
            className={styles.formBank}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={settings}
            onFinish={createSettings}
          >
            <Form.Item label="MM INVERTIDOS" name="mmInverted">
              <InputNumber />
            </Form.Item>
            <Form.Item label="PROYECTOS" name="proyect">
              <InputNumber />
            </Form.Item>
            <Form.Item label="SUSCRIPTORES" name="suscriptors">
              <InputNumber />
            </Form.Item>
            <Form.Item label="INVERSORES" name="investors">
              <InputNumber />
            </Form.Item>
            <h5 className={`${styles.h5Title} ${styles.titleSlider}`}>
              Slider
            </h5>
            <Space
              direction="vertical"
              style={{ width: '100%', marginLeft: '100px' }}
              size="large"
            >
              <Form.Item name="sliderHome">
                <Upload
                  name="attachment"
                  action={`${process.env.REACT_APP_API_URL}/attachments`}
                  headers={{ Authorization: token }}
                  listType="picture"
                  onChange={handleUpload}
                  multiple
                  maxCount={maxCount}
                  disabled={imgSlider.length === MAX_FILE_TO_UPLOAD}
                >
                  <Button
                    icon={<UploadOutlined />}
                    className={styles.btnUpload}
                  >
                    Cargar (Max: 3)
                  </Button>
                </Upload>
              </Form.Item>
            </Space>
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <span style={{ fontSize: '10px' }}>
                Imagen recomendada .jpg 1350 x 530px
              </span>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.btnAddBank}
              >
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </>
    </LoadingComponent>
  );
};

export default TrackRecords;
