import { Form, Input, Select, Spin } from 'antd';
import styles from '../../styles/contactPage/contactPage.module.css';
import img1 from 'img/bgFormContact.png';
import { useState } from 'react';
import phoneCodes from './constants/phoneCodes';
import clientAxios from 'config/clientAxios';
import {
  errorMessage,
  successMessage,
} from 'utils/messageHelper/messageHelper';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const RULES = [
  {
    required: true,
    message: 'Complete este campo',
  },
];

const INPUTS = [
  {
    label: 'Nombre',
    name: 'firstName',
  },
  {
    label: 'Apellido',
    name: 'lastName',
  },
  {
    label: 'Email',
    name: 'email',
    rules: [
      ...RULES,
      { type: 'email', message: 'Por favor, introduce un email válido' },
    ],
  },
];

const FormItem = ({ label, name, rules = RULES }) => {
  return (
    <>
      <strong style={{ fontSize: '16px' }}>{label}</strong>
      <br />
      <Form.Item
        style={{ marginBottom: '10px' }}
        key={name}
        name={name}
        rules={rules}
      >
        <Input className={styles.inputContact} />
      </Form.Item>
    </>
  );
};

const Contact = ({ setIsMessageSent }) => {
  const history = useHistory();
  const [formContact] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState('+54');

  const onSubmit = async ({ email, phone, firstName, lastName }) => {
    try {
      setIsLoading(true);
      const payload = {
        email,
        name: `${firstName} ${lastName}`,
        phoneNumber: `${selectedPhoneCode} ${phone}`,
      };

      const { data } = await clientAxios.post('/contact', payload);
      if (!data) return;
      successMessage('Mensaje enviado correctamente');
      setIsMessageSent(true);
      history.push('/contacto/exito');
    } catch (error) {
      errorMessage('Ocurrió un error, intentelo nuevamente');
    } finally {
      formContact.resetFields();
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        backgroundImage: `url(${img1})`,
        backgroundSize: 'cover',
      }}
      className="container-fluid"
    >
      <div className="container" style={{ padding: '3.2rem 0px' }}>
        <div
          className="row rounded shadow"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.45)' }}
        >
          <div
            className="col col-md-5 m-2 d-flex justify-content-center align-items-center rounded text-center"
            style={{ backgroundColor: '#142054' }}
          >
            <div className="p-3">
              <h4 className="text-white fw-bold mb-0">Inversíon mínima</h4>
              <h4 className="text-white fw-bold">USD 25.000</h4>
              <h4 className="text-white fw-bold" style={{ fontSize: '2.2rem' }}>
                |
              </h4>
              <h4 className="text-white fw-bold">
                Las mejores inversiones <br /> inmobiliarias en <br /> Estados
                Unidos
              </h4>
              <h4 className="text-white fw-bold" style={{ fontSize: '2.2rem' }}>
                |
              </h4>
              <h4 className="text-white fw-bold mb-0">Rendimiento anual</h4>
              <h4 className="text-white fw-bold mb-0">18%</h4>
            </div>
          </div>
          <div className="col my-2">
            <div className="container">
              <div className="row">
                <div className="col-2"></div>
                <div className="col mt-5 mb-1">
                  <h5 className="mb-0" style={{ fontWeight: '900' }}>
                    Dejá tus datos para recibir más
                  </h5>
                  <h5 className="mb-3" style={{ fontWeight: '900' }}>
                    información
                  </h5>
                  <Form
                    form={formContact}
                    onFinish={(values) => onSubmit(values)}
                    layout="vertical"
                  >
                    {INPUTS.map(({ label, name, rules }) => (
                      <FormItem
                        label={label}
                        name={name}
                        rules={rules}
                        key={name}
                      />
                    ))}
                    <strong style={{ fontSize: '16px' }}>Teléfono</strong>
                    <br />
                    <div className="d-flex">
                      <Select
                        className={styles.selectPhoneCode}
                        value={selectedPhoneCode}
                        onChange={(value) => setSelectedPhoneCode(value)}
                        options={phoneCodes}
                      />
                      <Form.Item
                        className="col"
                        style={{ marginBottom: '10px' }}
                        name={'phone'}
                        rules={[
                          ...RULES,
                          {
                            pattern: /^[1-9]\d*$/,
                            message: 'Ingresa un número de teléfono válido',
                          },
                          {
                            min: 6,
                            max: 13,
                            message: 'Ingresa un número de teléfono válido',
                          },
                        ]}
                      >
                        <Input className={styles.inputContact} />
                      </Form.Item>
                    </div>
                    <div className="d-flex d-flex justify-content-end">
                      <Spin
                        spinning={isLoading}
                        indicator={
                          <LoadingOutlined style={{ color: '#142054' }} />
                        }
                      >
                        <button className={styles.btnContact}>Enviar</button>
                      </Spin>
                    </div>
                  </Form>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
