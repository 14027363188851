import clientAxios from '../../config/clientAxios';
import { Row, Col, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import style from '../../styles/homePage/inversionesDestacadas.module.css';
import CardInvestment from './CardInvestment';
import { useEffect } from 'react';
import { useState } from 'react';
import { errorMessage } from '../../utils/messageHelper/messageHelper';

const InversionesDestacadas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [featuredInvestments, setFeaturedInvestments] = useState([]);
  const [restOfInvestments, setRestOfInvestments] = useState([]);
  const [seeMore, setSeeMore] = useState(false);

  useEffect(() => {
    const fetchFeaturedInvestments = async () => {
      try {
        setIsLoading(true);
        const { data } = await clientAxios.get('/posted');
        const investmentData = getInvestmentData(data);
        if (investmentData.length > 8) {
          return setValues(investmentData.slice(0, 8), investmentData.slice(8, investmentData.length));
        }else if (investmentData.length > 4) {
          return setValues(investmentData.slice(0, 4), investmentData.slice(4, investmentData.length));
        }
        setFeaturedInvestments(investmentData);
        setSeeMore(null)
      } catch (error) {
        const message = 'Inversiones destacadas no disponibles';
        errorMessage(message);
      }finally{
        setIsLoading(false);
      }
    };
    fetchFeaturedInvestments();
  }, []);

  const getInvestmentData = (investments) => {
    const investmentOpen = investments
      .filter((investment) => investment.isFeatured === true && investment.isOpen === true)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const investmentClosed = investments
      .filter((investment) => investment.isFeatured === true && investment.isOpen === false)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return [...investmentOpen,...investmentClosed ];
  };
  const setValues = (investments, restOfInvestments) => {
    setFeaturedInvestments(investments);
    setRestOfInvestments(restOfInvestments);
    setSeeMore(false);
  };

  const getButtonSeeMore = () => {
    if (seeMore === null) {
      return null;
    }
    return !seeMore
      ? <Button type='text' onClick={()=> setSeeMore(true)}>
          <p className={style.verTodas}>
            Ver Todas{' '}
            <span>
              <i className="fas fa-arrow-down"></i>
            </span>
          </p>
        </Button>
      : <Button type='text' onClick={()=> setSeeMore(false)}>
          <p className={style.verTodas}>
            Ver Menos{' '}
            <span>
              <i className="fas fa-arrow-up"></i>
            </span>
          </p>
        </Button> 
  };

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 24 }} spin />;
  }

  return (
    <div>
      <Row className={style.container}>
        <Col span={24}>
          <h4 className={style.title}>Inversiones Destacadas</h4>
        </Col>
      </Row>
      <Row className={style.rowCards}>
        {featuredInvestments.map((investment) => {
          return <CardInvestment key={investment._id} investment={investment} />;
        })}
      </Row>
      { seeMore === true &&
        <Row className={style.rowCards}>
        {restOfInvestments.map((investment) => {
          return <CardInvestment key={investment._id} investment={investment} />;
        })}
        </Row>
      }
      <Row className={style.rowCenteredButton}>
        { getButtonSeeMore() }
      </Row>
    </div>
  );
};

export default InversionesDestacadas;
