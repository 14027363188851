import styles from '../../styles/AboutUs/aboutUs.module.css';
import { Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { header, projects } from './data/proyects';

export default function Projects() {
  const thousandsSeparator = (num) => {
    let aux = String(num).split(); 
    aux[0] = aux[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return aux.join()
  }

  return (
    <>
      <div className="mt-5 container">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {header.map((head, index) => (
            <Col
              key={index}
              span={head.span}
              xs={head.xs}
              sm={head.sm}
              md={head.md}
              className="gutter-row d-flex justify-content-center"
            >
              <p className={styles.headerText}>{head.content}</p>{' '}
              {!head?.notIcon && (
                <DownOutlined
                  style={{ fontSize: '9px', color: '#706f6f' }}
                  className="pt-1 ms-1"
                />
              )}
            </Col>
          ))}
        </Row>
      </div>
      <div className="mb-5 container">
        {projects.map((item, index) => (
          <Row
            key={index}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            className={
              index === 0
                ? `${styles.projects} ${styles.borderTopRadius}`
                : index === projects.length - 1
                ? `${styles.projects} ${styles.borderBottomRadius} border-top border-2`
                : `${styles.projects} border-top border-2`
            }
          >
            <Col
              span={2}
              xs={12}
              sm={5}
              md={2}
              className={`${styles.project} gutter-row justify-content-center`}
            >
              <img
                style={{ height: '100' }}
                className={`${styles.projectImage} img-fluid rounded-circle projectImage`}
                src={item.img}
                alt=""
              />
            </Col>
            <Col
              span={4}
              xs={12}
              sm={7}
              md={4}
              className={`${styles.project} gutter-row`}
            >
              <div>
                <p className="m-0 p-0">
                  <small>{item.location}</small>
                </p>
                <b className="text-dark">{item.name}</b>
              </div>
            </Col>
            <Col
              span={4}
              xs={12}
              sm={6}
              md={4}
              className={`${styles.project} gutter-row d-flex justify-content-center text-center`}
            >
              {item.sponsor}
            </Col>
            <Col
              span={3}
              xs={12}
              sm={6}
              md={3}
              className={`${styles.project} gutter-row d-flex justify-content-center`}
            >
              {item.sector}
            </Col>
            <Col
              span={2}
              xs={12}
              sm={6}
              md={2}
              className={`${styles.project} gutter-row d-flex justify-content-center`}
            >
              {item.startDate}
            </Col>
            <Col
              span={2}
              xs={12}
              sm={6}
              md={2}
              className={`${styles.project} gutter-row d-flex justify-content-center`}
            >
              {item.endDate}
            </Col>
            <Col
              span={4}
              xs={12}
              sm={6}
              md={4}
              className={`${styles.project} gutter-row d-flex justify-content-center`}
            >
              <b className="text-success">{`$${thousandsSeparator(item.totalInvestment)}`}</b>
            </Col>
            <Col
              span={3}
              xs={12}
              sm={6}
              md={3}
              className={`${styles.project} gutter-row d-flex justify-content-center`}
            >
              <b className="text-dark">{`${item.finalProfitability}%`}</b>
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}
