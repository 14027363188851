import { Row, Col, Button } from "antd"
import LogoNavbar from '../img/logoTotalliaNavbar.png';
import { useHistory } from "react-router-dom";
import imgPage from '../img/thankYouPage.jpg'
import '../styles/thankyoupage.css';

const ThankYoupage = ({ user }) => {
  const history = useHistory();
  const home = () => {
    history.push('/')
  }

  return (
    <div id="thankYouPage">
      <Row> 
        <Col span={24} className="thankyouPage-nav">
          <img src={LogoNavbar} className="logoTotalliaNavbar" alt="logo" />
        </Col> 
      </Row>
      <Row>
        <Col lg={16} md={16} xs={24} className="bg-image-mobile">
          <div className="bg-text-mobile">
            <h2 className="h2-thankYouPage">¡Felicidades,<br></br>
            {user && user.firstName}!
            </h2>
            <h4 className="h4-thankYouPage">Gracias por registrarte en nuestro portal</h4>
            <Row>
              <Col lg={8} className="col-p-thankYouPage">
                <p className="p-thankYouPage">
                Gracias por registrarse en nuestro portal.</p>
                <p className="p-thankYouPage">
                Tiene acceso completo a nuestras ofertas actuales.</p>
                <p className="p-thankYouPage">
                También puede terminar de construir su perfil<br></br>
                de inversionista ahora o completarlo más tarde.</p>
              </Col>
              <Col lg={8} className="col-p-thankYouPage">
                <Button className="btn-home" onClick={home}>
                  Home
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={8} md={8} xs={0}>
          <img src={imgPage} className="imgEdificio"  alt="edificio" />
        </Col>
      </Row>
      
    </div>
  )
}

export default ThankYoupage