import { useHistory, useRouteMatch } from 'react-router-dom';
import { Row, Col, Input, Button, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { handleToggleColumns } from '../../../../utils/toggleColumnsHelper/toggleColumnsHelper';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import { tableColumns } from './table-Structure/ActiveTableStructure';
import ToggleColumnsModal from '../../toggleColumnsModal/ToggleColumnsModal';
import clientAxios from '../../../../config/clientAxios';
import GenericTable from '../../reusableTable/GenericTable';

const { Search } = Input;

const ActivesTable = ({ dataUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeInvestors, setActiveInvestors] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [investorPreference, setInvestorPreference] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalOfferors, setTotalOfferors] = useState(0);

  const history = useHistory();
  let { path } = useRouteMatch();

  const tableName = 'activeTable';

  useEffect(() => {
    setInvestorPreference(getLocalStorage(`${dataUser?.uid}${tableName}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await clientAxios.get(`/users`, {
          params: {
            limit: 10,
            active:true,
            onlyUsers: true,
            skip: page,
            search: searchInput
          }
        });
        const { users, pagination } = response.data;
        setActiveInvestors(users);
        setPages(pagination?.totalPages);
        setTotalOfferors(pagination?.total);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage('Los datos no estan disponibles');
      }
    };
    fetchData();
  }, [page, searchInput]);

  useEffect(() => {
    const handleUserTogglePreference = () => {
      if (investorPreference) {
        const togglePreferenceOpenSave = tableColumns(
          handleInvestorProfile
        ).map((column) => ({
          ...column,
          enable: investorPreference[column.dataIndex],
        }));
        setTableHeadings(togglePreferenceOpenSave);
      } else {
        setTableHeadings(tableColumns(handleInvestorProfile));
      }
    };
    handleUserTogglePreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorPreference]);

  const handleSearch = (value) => {
    setSearchInput(value);
    setPage(1);
  };

  const handleInvestorProfile = (uid) => {
    history.push(`${path}/perfil-del-Inversor/${uid}`);
    window.scrollTo(0, 0);
  };

  const handleFilterByName = () => {
    if (searchInput === '') return activeInvestors;
    if (searchInput) {
      return activeInvestors.filter((investor) => {
        const investorFullname = investor.firstName + ' ' + investor.lastName;
        return investorFullname
          ?.toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    }
  };

  const handleToggleModal = () => setIsModalVisible((state) => !state);

  const handleChangePagination = async (value = 1) => {
    if (!pages) return null;
    setPage(value);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Button
            onClick={handleToggleModal}
            className="btn-closeInv-filter input-size"
          >
            <i className="fas fa-cog me-2"></i>Tablas
          </Button>
          <Search
            enterButton
            placeholder="Buscar"
            onSearch={handleSearch}
            className="btn-closeInv-filter input-size search-border-color"
            style={{ width: 200 }}
          />
          <ToggleColumnsModal
            isModalVisible={isModalVisible}
            tableHeadings={tableHeadings}
            handleToggleModal={handleToggleModal}
            handleToggleColumns={handleToggleColumns(
              tableHeadings,
              setTableHeadings,
              dataUser,
              tableName
            )}
          />
        </Col>
      </Row>
      <GenericTable
        handleFilterByName={handleFilterByName}
        tableHeadings={tableHeadings}
        isLoading={isLoading}
        rowKey="uid"
        totalData={totalOfferors}
        withoutPagination={true}
      />
      {
        totalOfferors > 0
        && <Pagination
          className='d-flex justify-content-center mt-4'
          total={totalOfferors}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={false}
          onChange={(e) => handleChangePagination(e)}
        />
      }
    </div>
  );
};

export default ActivesTable;
