import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';

const thousandsSeparator = (num) => {
  let aux = String(num).split(); 
  aux[0] = aux[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return aux.join()
}

const getAmountOffered = () => {
  const amountOfferedData = getLocalStorage('amount');
  const amountOffered = thousandsSeparator(amountOfferedData);
  return amountOffered;
}

export const dataToSendDocuSign = (dataUser, otherSigners) => {
  const basePath = '';
  const accountId = '';
  const accessToken = '';
  const signerEmail = dataUser?.email;
  const clientUserId = dataUser?.email;
  const signerClientId = dataUser?.email;
  const address = dataUser?.address;
  const signerName = `${dataUser?.firstName} ${dataUser?.lastName}`;
  const amountOffered = getAmountOffered();
  const dni = dataUser?.dni;
  const dateFirma = dateFormat(new Date());
  const dsReturnUrl = window.location.href;
  const signers = otherSigners?.signers;
  const signerPhoneNumber = dataUser.phoneNumber;

  return {
    basePath,
    accountId,
    accessToken,
    envelopeArgs: {
      signerClientId,
      signerEmail,
      signerName,
      signerPhoneNumber,
      dsReturnUrl,
      clientUserId,
      dni,
      amountOffered,
      dateFirma,
      address,
      signers,
    },
  };
};
