import React, {useState, useEffect} from 'react';
import { Modal, Form, Upload, Button, Select, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';


const ManualOfferModal = ({
    modalVisible,
    setModalVisible,
    formManualOffer,
    onSaveManualOffer,
    onSaveManualOfferFailed,
    clearManualOffer,
    defaultFileList,
    setDefaultFileList,
    submitting
}) => {

    const { Option } = Select;

    const [openInvestmentsSelected, setOpenInvestmentsSelected] = useState([]);

    const handleOnChange = ({ fileList }) => {
        setDefaultFileList(fileList);
    };

    useEffect(() => {
        const handleGetOpenInvestment = async () => {
            try {
                const response = await clientAxios.get(`/investments`);
                const responseData = response.data;
                const investments = responseData.filter((investment) => {
                    return investment.isOpen && investment.active;
                });
                setOpenInvestmentsSelected(investments);
                
            } catch (error) {
                errorMessage('Tuvimos inconvenientes con la consulta.', 0);
            }
        }
    
        handleGetOpenInvestment();
    }, []);
      

    return (
        <Modal
            title={
                <>
                    <Form
                        ref={formManualOffer}
                        onFinish={onSaveManualOffer}
                        onFinishFailed={onSaveManualOfferFailed}
                    >
                        <span className='d-block mb-3'>Crear Oferta Manual</span>

                        <span className='d-block mb-2'>Inversión</span>
                        <Form.Item name='investment'>
                            <Select style={{width: '100%'}}>
                                {openInvestmentsSelected?.map((investment) => (
                                <Option key={investment?._id} value={investment?._id}>
                                    {investment?.name}
                                </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <span className='d-block mb-2'>Monto</span>
                        <Form.Item
                            name="amount"
                        >
                            <Input style={{width: '100%'}} type='number' min={1} />
                        </Form.Item>

                        <Form.Item
                            label="Agregar Documento"
                            name="contract"
                            className="attachment-form-item"
                        >
                            <Upload
                                fileList={defaultFileList}                                
                                beforeUpload={() => false}
                                maxCount={1}
                                onChange={handleOnChange}
                            >
                                <Button icon={<UploadOutlined />} type='button'>Cargar Archivo</Button>
                            </Upload>
                        </Form.Item>

                        <div className="center-container">
                            <Button type="primary" htmlType="submit" className="btn-save" loading={submitting}>
                                {submitting ? 'Enviando...' : 'Crear Oferta'}
                            </Button>
                        </div>
                    </Form>
                </>
            }
            className='mt-5'
            centered
            visible={modalVisible}
            onCancel={() => {
                setModalVisible(false)
                clearManualOffer(); // Limpia el archivo subido al cerrar el modal
            }}
            footer={false}
            width={400}
            bodyStyle={{ maxHeight: '100%', paddingBottom: 30}}
        />
    );
};

export default ManualOfferModal;
