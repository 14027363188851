import { Card, Row, Col } from 'antd';
import style from 'styles/Aprender/videoCanal.module.css';

const CardVideo = ({ video }) => {
  return (
    <div className={style.divCard}>
      <Card
        hoverable
        className={style.card}
        style={{ width: '100%' }}
        bodyStyle={{ backgroundColor: '#F5F5F5' }}
        cover={
          <iframe
            className={style.iframe}
            src={video.youtubeLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        }
      >
        <Row>
          <Col xs={16} md={18}>
            <h6 className={style.h6NameVideo}>{video.name}</h6>
          </Col>
          <Col xs={8} md={6}>
            <a
              href={video.youtubeLink}
              target="_blank"
              rel="noreferrer"
              className={style.aVideoYoutube}
            >
              Ver en youtube
            </a>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CardVideo;
