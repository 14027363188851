import { Button } from 'antd';
import { offerStatus } from 'config/offerStatus';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { formatMoney } from 'utils/currentFormatHelper/currentFormatHelper';

export const InProgressStructure = (history) => [
  {
    title: 'Fecha',
    key: 'createdAt',
    enable: true,
    render: (key, data) => {
      return <>{dateFormat(data.createdAt)}</>;
    },
  },
  {
    title: 'Nombre de la inv.',
    dataIndex: 'investment.name',
    key: 'investment.name',
    enable: true,
    render: (key, data) => {
      return <p>{data.investment.name}</p>;
    },
  },
  {
    title: 'Monto ofertado',
    dataIndex: 'amountOffered',
    key: 'amountOffered',
    enable: true,
    render: (amountOffered) => formatMoney(amountOffered),
  },
  {
    title: 'Es una reinversión',
    dataIndex: 'isReinvestment',
    key: 'isReinvestment',
    enable: true,
    render: (key) => {
      const status = key ? 'Si' : 'No';
      return <p>{status}</p>;
    },
  },
  {
    title: 'Status operación',
    dataIndex: 'status',
    key: 'status',
    render: (key) => <p>{offerStatus[key]}</p>,
  },
  {
    title: 'Acciones',
    dataIndex: '_id',
    key: '_id',
    enable: true,
    render: (key, data) => (
      <>
        <Button
          onClick={() => {
            history.push(`/investorRoom/myInvestments/${data._id}`);
          }}
          type="primary"
          style={{ border: 'none', margin: 6 }}
        >
          Ver Oferta
        </Button>
      </>
    ),
  },
];
