import { Button, Row, Col, Input, Pagination } from 'antd';
import { useEffect, useState } from 'react';
import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import { getLocalStorage } from '../../../../utils/localStorageHelper/localHelper';
import { handleToggleColumns } from '../../../../utils/toggleColumnsHelper/toggleColumnsHelper';
import { tableColumns } from './table-Structure/OfferorsTableStructure';
import ToggleColumnsModal from '../../toggleColumnsModal/ToggleColumnsModal';
import clientAxios from '../../../../config/clientAxios';
import GenericTable from '../../reusableTable/GenericTable';
import { useHistory, useRouteMatch } from 'react-router-dom';
import OfferModal from '../OfferModal/OfferModal';
import AttachmentModal from '../AttachmentModal/AttachmentModal';

const { Search } = Input;

const OfferorsTable = ({ dataUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [offerorInvestors, setOfferorInvestors] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [investorPreference, setInvestorPreference] = useState([]);
  const [offer, setOffer] = useState(null);
  const [offerModalStatus, setOfferModalStatus] = useState(false);
  const [attachmentModalStatus, setAttachmentModalStatus] = useState(false);
  const [attachmentId, setAttachmentId] = useState('');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalOfferors, setTotalOfferors] = useState(0);

  const history = useHistory();
  let { path } = useRouteMatch();

  const handleSetOffer = (offer) => setOffer(offer);

  const tableName = 'offerorsTable';
  const role = 'Oferente';
  const limit = 10;

  const handleOfferModalStatus = () => setOfferModalStatus((status) => !status);
  const handleAttachmentModalStatus = () =>
    setAttachmentModalStatus((status) => !status);

  useEffect(() => {
    setInvestorPreference(getLocalStorage(`${dataUser?.uid}${tableName}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(`/users`, {
          params: {
            role,
            limit,
            skip: page,
            search: searchInput
          }
        });
        const { users, pagination } = response.data;
        setOfferorInvestors(users);
        setPages(pagination?.totalPages)
        setTotalOfferors(pagination?.total);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorMessage('Los datos no estan disponibles');
      }
    };
    fetchData();
  }, [page, searchInput]);

  useEffect(() => {
    const handleUserTogglePreference = () => {
      if (investorPreference) {
        const togglePreferenceOpenSave = tableColumns(
          handleUpdateStatus,
          isUpdatingStatus,
          handleInvestorProfile,
          handleSetOffer,
          handleOfferModalStatus
        ).map((column) => ({
          ...column,
          enable: investorPreference[column.dataIndex],
        }));
        setTableHeadings(togglePreferenceOpenSave);
      } else {
        setTableHeadings(
          tableColumns(
            handleUpdateStatus,
            isUpdatingStatus,
            handleInvestorProfile,
            handleSetOffer,
            handleOfferModalStatus
          )
        );
      }
    };
    handleUserTogglePreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorPreference]);

  const handleSearch = (value) => {
    setSearchInput(value);
    setPage(1);
  };

  const handleInvestorProfile = (uid) =>
    history.push(`${path}/perfil-del-Inversor/${uid}`);

  const handleUpdateStatus = async (status, user, isUpdatingStatus) => {
    try {
      setIsUpdatingStatus(true);
      let investorsCopy = offerorInvestors;
      const response = await clientAxios.put(`/users/${user.uid}`, { status });
      const updatedUser = response.data;
      investorsCopy = investorsCopy.map((investor) =>
        investor.uid !== updatedUser.uid ? investor : updatedUser
      );
      setOfferorInvestors(investorsCopy);
      setIsUpdatingStatus(false);
    } catch (error) {
      console.log(error.message);
      errorMessage('Error al actualizar estado');
    }
  };

  const handleFilterByName = () => offerorInvestors;
  const handleToggleModal = () => setIsModalVisible((state) => !state);

  const handleChangePagination = async (value = 1) => {
    if (!pages) return null;
    setPage(value);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Button
            onClick={handleToggleModal}
            className="btn-closeInv-filter input-size"
          >
            <i className="fas fa-cog me-2"></i>Tablas
          </Button>
          <Search
            enterButton
            placeholder="Buscar"
            onSearch={handleSearch}
            className="btn-closeInv-filter input-size search-border-color"
            style={{ width: 200 }}
          />
          <ToggleColumnsModal
            isModalVisible={isModalVisible}
            tableHeadings={tableHeadings}
            handleToggleModal={handleToggleModal}
            handleToggleColumns={handleToggleColumns(
              tableHeadings,
              setTableHeadings,
              dataUser,
              tableName
            )}
          />
        </Col>
        <OfferModal
          offerModalStatus={offerModalStatus}
          handleOfferModalStatus={handleOfferModalStatus}
          offer={offer}
          setOffer={setOffer}
          setAttachmentId={setAttachmentId}
          handleAttachmentModalStatus={handleAttachmentModalStatus}
          setOfferorInvestors={setOfferorInvestors}
        />
        <AttachmentModal
          attachmentModalStatus={attachmentModalStatus}
          setAttachmentModalStatus={setAttachmentModalStatus}
          attachmentId={attachmentId}
        />
      </Row>
      <GenericTable
        handleFilterByName={handleFilterByName}
        tableHeadings={tableHeadings}
        isLoading={isLoading}
        rowKey="uid"
        totalData={totalOfferors}
        withoutPagination={true}
      />
      {
        totalOfferors > 0
        && <Pagination
          className='d-flex justify-content-center mt-4'
          total={totalOfferors}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={false}
          onChange={(e) => handleChangePagination(e)}
        />
      }
    </div>
  );
};

export default OfferorsTable;
