import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Row, Col } from 'antd';
import styles from './pdfReader.module.css';

const PdfReader = ({ pdfFile }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const convertBase46ToPdf = 'data:application/pdf;base64, ' + pdfFile;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleOnDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleChangePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const handlePreviousPage = () => {
    handleChangePage(-1);
  };

  const handleNextPage = () => {
    handleChangePage(1);
  };
  return (
    <>
      <Row align="middle" justify="center">
        <Col>
          <Document
            file={convertBase46ToPdf}
            loading="Cargando pdf..."
            onLoadSuccess={handleOnDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Col>
        <Col sm={24} md={24} lg={24} xl={24}>
          <div>
            Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
          </div>
          <Button
            className={styles.btnPdf}
            type="primary"
            disabled={pageNumber <= 1}
            onClick={handlePreviousPage}
          >
            Ant.
          </Button>
          <Button
            className={styles.btnPdf}
            type="primary"
            disabled={pageNumber >= numPages}
            onClick={handleNextPage}
          >
            Sig.
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PdfReader;
