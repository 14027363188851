import { Link } from 'react-router-dom'
import { Button } from 'antd';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { dateFormat } from '../../../../../utils/dateFormatHelper/dateFormat';
import { investmentStatus } from '../../investmentStatusStructure/investmentStatusStructure';

export const tableHeadingsTemplate = (
  handleInfoInvesmentModal,
  handleEditInvesment
) => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    width: 180,
    render: (key) => {
      const createdAtNewFormat = dateFormat(key);
      return <p>{createdAtNewFormat}</p>;
    },
  },
  {
    title: 'Nombre de la inv.',
    dataIndex: ['name','_id'],
    key: 'name',
    enable: true,
    render: (text, row) => 
    <Link to={`inversiones/distribuciones/${row['_id']}/${row['name']}`}>{row['name']}</Link>,
    width: 190,
  },
  {
    title: 'Tipo de inv.',
    dataIndex: 'investmentType',
    key: 'investmentType',
    enable: true,
    width: 190,
  },
  {
    title: 'Cant Oferentes',
    dataIndex: 'oferentQuantity',
    key: 'oferentQuantity',
    enable: true,
    width: 100,
  },
  {
    title: 'Monto Comprometido',
    dataIndex: 'amount1',
    key: 'amount1',
    enable: true,
    width: 100,
  },
  {
    title: 'Cant inversores',
    dataIndex: 'investors',
    key: 'investors',
    enable: true,
    width: 100,
    render: (key) => <p>{key.length}</p>,
  },
  {
    title: 'Monto Invertido',
    dataIndex: 'amount2',
    key: 'amount2',
    enable: true,
    width: 100,
  },
  {
    title: '% inversión',
    dataIndex: 'percInvestment',
    key: 'percInvestment',
    enable: true,
    width: 100,
  },
  {
    title: 'Plazo',
    dataIndex: 'termInYears',
    key: '_id',
    enable: true,
    width: 100,
    render: (key, { termInYears }) => (
      <p>{termInYears === 1 ? `${termInYears} año` : `${termInYears} años`}</p>
    ),
  },
  {
    title: 'Fecha limite cierre',
    dataIndex: 'finishDate',
    key: '_id',
    enable: true,
    width: 180,
    render: (key, { complementaryInformation }) => {
      const finishDate = complementaryInformation.finishDate;
      if (finishDate) {
        const finishDateFormat = dateFormat(finishDate);
        return <p>{finishDateFormat}</p>;
      }
    },
  },
  {
    title: 'Estado inversión',
    dataIndex: 'investmentStatus',
    key: '_id',
    enable: true,
    width: 140,
    render: (key) => {
      const investmentStatusRender = investmentStatus[key];
      return <p>{investmentStatusRender}</p>;
    },
  },
  {
    title: 'Acciones',
    dataIndex: '_id',
    key: '_id',
    enable: true,
    width: 100,
    render: (key, data) => (
      <>
        <Button
          style={{ border: 'none' }}
          onClick={() => handleInfoInvesmentModal(data)}
          size="small"
          icon={<InfoCircleOutlined />}
        />
        <Button
          style={{ border: 'none' }}
          size="small"
          onClick={() => handleEditInvesment(key)}
          icon={<EditOutlined />}
        />
      </>
    ),
  },
];
