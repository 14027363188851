import { Row, Col } from 'antd';
import img from '../../img/imgSection4Home.jpg';
import style from '../../styles/homePage/sectionshome.module.css';
import arrow from '../../img/icons/Trazado48.svg';

const Section4Home = () => {
  return (
    <Row>
      <Col lg={12} sm={24} className={style.colImg}>
        <div className={style.boxImg}>
          <img src={img} alt="totallia" />
        </div>
      </Col>
      <Col lg={12} sm={24} className={style.boxText}>
        <div className={style.texts}>
          <h1>
            Beneficios de invertir <br />
            con Totallia
          </h1>
          <p>
            Hemos creado una nueva forma para que los inversores <br />
            participen en inversiones inmobiliarias de calidad institucional
          </p>
        </div>
        <div >
          <a href="#section5">
            <img src={arrow} alt="" className={style.arrow}/>
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default Section4Home;
