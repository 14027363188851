import React, { useEffect, createRef } from 'react';
import styles from './formBank.module.css';
import { Form, Row, Button, message } from 'antd';
import Pdf from 'react-to-pdf';
import { CopyOutlined } from '@ant-design/icons';

const FormItems = ({ bankSelected }) => {
  const formRef = createRef();
  const pdfRef = createRef();

  useEffect(() => {
    formRef.current?.setFieldsValue(bankSelected);
  }, [bankSelected,formRef]);

  const optionsPdf = {
    orientation: 'landscape',
    unit: 'in',
    format: [14, 7],
  };

  const copyText = (e, field) => {
    e.preventDefault();
    if(field){
      navigator.clipboard.writeText(field);
      message.success({ content:`Texto copiado: ${field}`, duration: 1 })
    }else{
      message.error({ content:`Este campo no posee texto`, duration: 1 })
    }
  };

  return (
    <>
      {Object.keys(bankSelected).length !== 0 && (
        <div>
          <div  ref={pdfRef}>
            <div>
              <p className={styles.warning} style={{width: "90%", margin: "0 auto"}}>
                <b>
                IMPORTANTE: El campo de reference field / info adicional es un requisito que debe incluirse obligatoriamente en las instrucciones de transferencia cuando los fondos salen desde una cuenta fuera de USA. Es el único lugar donde están los datos específicos de Totallia en el banco. Sin este dato, los fondos no llegarán a destino. Si tiene dudas, consulte a info@totallia.com o por whatsapp al +54 9 11 5422-9285.
                </b>
              </p>
            </div>
            <div >
              <Form
                ref={formRef}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 10 }}
                className={styles.wrapper}
              >
                <div>
                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Bank type / Tipo de banco</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.type ? bankSelected.type : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.type)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Bank Name / Nombre del Banco</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.name ? bankSelected.name : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.name)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>ABA Routing Number or Transit Number / Número ABA o Número de Tránsito</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.transitNumber ? bankSelected.transitNumber : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.transitNumber)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Bank Address / Dirección del Banco</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.address ? bankSelected.address : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.address)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Beneficiary Name / Nombre del Beneficiario</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.beneficiaryName ? bankSelected.beneficiaryName : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.beneficiaryName)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Beneficiary Address / Dirección del Beneficiario</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.beneficiaryAddress ? bankSelected.beneficiaryAddress : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.beneficiaryAddress)}/>
                  </React.Fragment>

                </div>
                <div>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Account Number / Número de Cuenta</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.accountNumber ? bankSelected.accountNumber : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.accountNumber)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>Account type / Tipo de cuenta</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.typeOfAccount ? bankSelected.typeOfAccount : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.typeOfAccount)}/>
                  </React.Fragment>
                  
                  <React.Fragment className={styles.fragment}>
                    <div className={styles.wrapperParagraph}>
                      <label className={styles.title}>SWIFT / BIC</label>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.BIC ? bankSelected.BIC : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.BIC)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <label className={styles.title}>IBAN</label>
                    <div className={styles.wrapperParagraph}>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.IBAN ? bankSelected.IBAN : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.IBAN)}/>
                  </React.Fragment>

                  <React.Fragment className={styles.fragment}>
                    <label className={styles.title}>Reference Field / Campo de Referencia / Instrucciones Especiales</label>
                    <div className={styles.wrapperParagraph}>
                      <div className={styles.divOverflow}>
                        <p className={styles.paragraph}>
                          {bankSelected.reference ? bankSelected.reference : "-"}
                        </p>
                      </div>
                    </div>
                    <CopyOutlined className={styles.copy} onClick={(e) => copyText(e, bankSelected.referenceField)}/>
                  </React.Fragment>

                </div>
              </Form>
            </div>
          </div>
          <Row justify="center">
            <Pdf
              targetRef={pdfRef}
              filename={`instrucciones-bancarias-${bankSelected?.type}.pdf`}
              options={optionsPdf}
              x={0.4}
              y={0.3}
              scale={0.85}
            >
              {({ toPdf }) => (
                <Button type="primary" className={styles.btnConfirm} onClick={toPdf}>
                  Descargar instrucciones
                </Button>
              )}
            </Pdf>
          </Row>
        </div>
      )}
    </>
  );
};

export default FormItems;
