import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import ContentInvestment from '../../components/contentInvestment/ContentInvestment';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';

const InvestmentDetail = () => {
  const [investment, setInvestment] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleGetSingleInvestment = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(`/posted/${id}`);
        const dataResponse = response.data[0];
        setInvestment(dataResponse);
      } catch (error) {
        errorMessage('Los datos de la inversión no están disponibles', 3);
      } finally {
        setIsLoading(false);
      }
    };
    handleGetSingleInvestment();
  }, [id]);

  useEffect(() => {
    const createVisitPage = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (!user) return;
        if (!investment._id) return;

        const payload = {
          contentType: 'INVESTMENT',
          details: {
            investment: investment._id
          },
          pagePath: pathname,
        };
        await clientAxios.post('/page-visit', payload)
      } catch (error) {}
    }
    createVisitPage()
  }, [investment, pathname]);

  return (
    <LoadingInvestorRoom isLoading={isLoading}>
      <ContentInvestment investment={investment} />
    </LoadingInvestorRoom>
  );
};

export default InvestmentDetail;
