import React, { useState, useEffect } from 'react';
import { Row, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import PersonalDataForm from './forms/PersonalDataForm';
import DataBankForm from './forms/DataBankForm';
import UpdatePassword from './forms/UpdatePassword';

const InvestorProfile = () => {
  const [isModalPassport, setIsModalPassport] = useState(false);
  const [isModalInstructionsURL, setIsModalInstructionsURL] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingDataForm, setIsSendingDataForm] = useState(false);
  const [isSendingBankForm, setIsSendingBankForm] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [imageBankUrl, setImageBankUrl] = useState('');
  const [userData, setUserData] = useState({});
  const [success, setSuccess] = useState(false)

  const handleModalPassport = () => {
    setIsModalPassport((state) => !state);
  };

  const handleModalinstructionsURL = () => {
    setIsModalInstructionsURL((state) => !state);
  };

  const handleEditSuccess = () => setSuccess((state) => !state);

  useEffect(() => {
    const handleGetUserData = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(`/user`);
        const userResponse = response.data;
        setUserData(userResponse);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    handleGetUserData();
  }, [success]);

  useEffect(() => {
    if (userData?.passport?.url) {
      setImageUrl(userData?.passport?.url);
    }
  }, [userData]);

  const handleTakeImages = (value) => {
    setImageUrl(value);
  };

  const handleTakeBankImges = (value) => {
    setImageBankUrl(value);
  };

  return (
    <LoadingInvestorRoom isLoading={isLoading}>
      <Row align="middle">
        <UserOutlined
          style={{
            fontSize: '40px',
            color: '#08c',
            marginRight: '10px',
            marginBottom: '21px',
          }}
        />
        <h3>Mi perfil de inversor</h3>
      </Row>
      <Divider />
      <h4>1. Datos personales</h4>
      <div>
        <PersonalDataForm
          imageUrl={imageUrl}
          setIsSendingDataForm={setIsSendingDataForm}
          isModalPassport={isModalPassport}
          handleModalPassport={handleModalPassport}
          handleTakeImages={handleTakeImages}
          isSendingDataForm={isSendingDataForm}
          userData={userData}
          handleEditSuccess={handleEditSuccess}
        />
      </div>
      <Divider />
      <h4>2. Instrucciones Bancarias</h4>
      <div>
        <DataBankForm
          imageBankUrl={imageBankUrl}
          setIsSendingBankForm={setIsSendingBankForm}
          handleModalinstructionsURL={handleModalinstructionsURL}
          handleTakeBankImges={handleTakeBankImges}
          isModalInstructionsURL={isModalInstructionsURL}
          isSendingBankForm={isSendingBankForm}
          userData={userData}
          handleEditSuccess={handleEditSuccess}
        />
      </div>

      <h4>Cambiar Contraseña</h4>
      <div>
        <UpdatePassword
          setIsSendingDataForm={setIsSendingDataForm}
          isSendingDataForm={isSendingDataForm}
        />
      </div>
    </LoadingInvestorRoom>
  );
};

export default InvestorProfile;
