import React, { useState, useEffect } from 'react';
import clientAxios from 'config/clientAxios';
import { successModal } from 'utils/modalMessageHelper/modalMessage';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { Form, Button, Space, Radio } from 'antd';
// import { bankDataStructure } from 'utils/investorProfileHelper/initialValuesForms';
import UploadImages from 'components/investor-room/components/uploadImages/UploadImages';
import ModalImages from '../../../components/ModalImages/ModalImages';
import styles from '../investorProfile.module.css';
import BankUsaForm from './BankUsaForm';
import BankForm from './BankForm';

const DataBankForm = ({
  imageBankUrl,
  setIsSendingBankForm,
  handleModalinstructionsURL,
  handleTakeBankImges,
  handleEditSuccess,
  isModalInstructionsURL,
  isSendingBankForm,
  userData,
}) => {
  const [formAntd] = Form.useForm()
  const [bankType, setBankType] = useState(userData.bankType ? userData.bankType : 'notUSA');
  const bankValidation = bankType === 'USA';
  const [form, setForm] = useState(bankValidation ? userData?.bankUSA : userData?.bank);

  useEffect(() => {
    setForm(bankValidation ? userData?.bankUSA : userData?.bank)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankType])

  useEffect(() => {
    formAntd.setFieldsValue(form)
  }, [formAntd, form])

  const handleFinishFormBank = async (values) => {
    setIsSendingBankForm(true);
    const dataToSend = {
      [bankValidation ? "bankUSA" : "bank"]: {
        ...values,
        instructionsURL:
          imageBankUrl !== '' ? imageBankUrl : userData?.bank?.instructionsURL,
      },
      [bankValidation ? "bank" : "bankUSA"] : {},
      bankType,
    };
    try {
      await clientAxios.put(`/user`, dataToSend);
      successModal(
        <div>
          <h5>Se actualizo correctamente los datos bancarios.</h5>
        </div>, 
        '', 
        handleEditSuccess()
      );
    } catch (error) {
      errorMessage('error al actualizar los datos.');
    } finally {
      setIsSendingBankForm(false);
    }
  };
  return (
    <Form
      form={formAntd}
      layout="vertical"
      className={styles.wrapper}
      initialValues={form}
      onFinish={handleFinishFormBank}
    >
      <Form.Item
        name="bankType"
        label="Seleccione un banco"
        initialValue={bankType}
        rules={[
          {
            required: true,
            message: 'Por favor, seleccione un banco!',
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="notUSA" onClick={() => setBankType("notUSA")}>Cuenta NO USA</Radio.Button>
          <Radio.Button value="USA" onClick={() => setBankType("USA")}>Cuenta USA</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {
        bankValidation ? (
          <BankUsaForm 
            form={form}
          />
        ):(
          <BankForm 
            form={form}
          />
        )
      }
      <div>
        <Form.Item label="Instrucciones bancarias" name="instructionsURL">
          <Space direction="vertical">
            <Button
              type="primary"
              disabled={!userData?.bank?.instructionsURL ? true : false}
              ghost
              className={styles.btnConfirm}
              onClick={() => handleModalinstructionsURL()}
            >
              Ver instrucciones bancarias
            </Button>
            <UploadImages
              nameButton="Subir instrucciones bancarias"
              takeImages={handleTakeBankImges}
            />
          </Space>
        </Form.Item>
        <ModalImages
          isModalPassport={isModalInstructionsURL}
          handleCancel={handleModalinstructionsURL}
          imageKey={userData?.bank?.instructionsURL}
        />

        <Button
          loading={isSendingBankForm}
          type="primary"
          htmlType="submit"
          className={styles.btnConfirm}
        >
          Actualizar datos bancarios
        </Button>
      </div>
    </Form>
  );
};

export default DataBankForm;
