import React from 'react';
import styles from 'styles/contactPage/contactPage.module.css';

const items = [
  {
    number: 1,
    text: 'Desde 2013 buscamos acercar el mundo de las inversiones inmobiliarias comerciales a inversores individuales de América Latina.',
  },
  {
    number: 2,
    text: 'Nos especializamos en inversiones en propiedades comerciales de Estados Unidos.',
  },
  {
    number: 3,
    text: 'Son las inversiones más eficientes del mercado, gracias a la relación riesgo/retorno que presentan.',
  },
  {
    number: 4,
    text: 'Seleccionamos a los mejores desarrolladores inmobiliarios de Estados Unidos para llevar adelante en cada uno de los proyectos que invertimos.',
  },
];

const InfoToInvest = () => {
  return (
    <>
      <div style={{ borderTop: '4px solid #fc505e', paddingBottom: '4rem' }}>
        <h1
          className="text-center"
          style={{
            color: '#fc505e',
            fontWeight: '900',
            fontSize: '2.7rem',
            marginBottom: '4rem',
            marginTop: '6rem',
          }}
        >
          Por qué invertir en Totallia?
        </h1>
        <div className="container">
          {items.map(({ number, text }) => (
            <div
              className={`row mb-4 ${styles.infoToInvestContainer}`}
              key={number}
            >
              <div className={`col-1 h4 rounded-circle ${styles.circleItem}`}>
                {number}
              </div>
              <div className="col-1"></div>
              <div
                className="col h4 d-flex align-items-center"
                style={{ color: '#142054', fontWeight: '600' }}
              >
                {text}
              </div>
              <p
                className="text-center text-danger "
                style={{
                  color: '#fc505e',
                  fontSize: '1.2rem',
                  margin: '1rem 0px',
                }}
              >
                ..................................
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bottomFlag}>
        <div style={{ backgroundColor: '#fc505e', color: '#fc505e' }}>-</div>
        <div style={{ backgroundColor: '#142054', width: '80%' }}>-</div>
      </div>
    </>
  );
};

export default InfoToInvest;
