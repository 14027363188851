import { Modal } from 'antd';

export const successModal = (title, content, onOk) =>
  Modal.success({
    title,
    content,
    onOk,
  });

export const errorModal = (title, content) =>
  Modal.error({
    title,
    content,
  });

export const warningModal = (title, content) =>
  Modal.warning({
    title,
    content,
  });

export const infoModal = (title, content) =>
  Modal.info({
    title,
    content,
  });

export const warningModalConfirm = (title, content, handleFunction) =>
  Modal.warning({
    title,
    content,
    onOk() {
      handleFunction();
    },
  });
