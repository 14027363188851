export const currentFormat = (number, currency = 'USD') =>
  new Intl.NumberFormat('es-AR', { style: 'currency', currency })
    .format(number)
    .replace(/\D00(?=\D*$)/, '');

export const formatMoney = (numberToFormat) => {
  if (numberToFormat === undefined || numberToFormat === null) return '$0.00';

  const moneyString = numberToFormat.toString().replace(',', '.');
  const [wholePart, decimalPart] = moneyString.split('.');

  const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const formattedMoney = `$${formattedWholePart},${decimalPart || '00'}`;
  return formattedMoney;
};
