import '../../styles/admin.css';
import {
  Redirect,
  Route,
  Switch,
  Link,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import {
  GlobalOutlined,
  DownOutlined,
  LogoutOutlined,
  AppstoreOutlined,
  DollarCircleOutlined,
  SolutionOutlined,
  ProjectOutlined,
  TeamOutlined,
  SettingOutlined,
  BankOutlined,
  LayoutOutlined,
  FileTextOutlined,
  ReadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import ROL from '../../config/roles';
import { removeLocalStorage } from '../../utils/localStorageHelper/localHelper';
import LogoNav from '../../img/logoTotallia.png';
import { Layout, Menu, Row, Dropdown } from 'antd';
import InversionRoutes from './Investment-Section/routes/InversionRoutes';
import InvestorsRoutes from './Investors-Section/routes/InvestorsRoutes';
import UsersRoutes from './usersSection/usersRoutes';
import BanksRoutes from './settings/banks/BanksRoutes';
import TrackRecordsRoutes from './settings/trackRecords/TrackRecordsRoutes';
import ArticlesRoutes from './settings/Articles/ArticlesRoutes';
import QuickStartGuideRoutes from './settings/QuickStartGuide/QuickStartGuideRoutes';
import ReportsRoutes from './Reports-Section/routes/ReportsRoutes';
import NewsletterRoutes from './settings/Newsletter/NewsletterRoutes';
import VideoCanalRoutes from './settings/videoCanal/VideoCanalRoutes';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const PanelAdmin = ({ user, setUser }) => {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  if (!user || user.role === ROL.CONTACTO) {
    return <Redirect to="/" />;
  }
  const logout = async () => {
    try {
      removeLocalStorage('user');
      removeLocalStorage('token');
      setUser(null);
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="home" icon={<GlobalOutlined />}>
        <Link to="/">Totallia Home web</Link>
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        danger
        onClick={logout}
      >
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout>
        <Sider breakpoint="lg" collapsedWidth="0">
            <Link to={`${url}/inversiones`}>
              <img src={LogoNav} alt="Logo Totallia" className="logoNav" />
            </Link>
          <Menu theme="dark" mode="inline" className="sidebarAdmin">
            <Menu.Item key="Inversiones" icon={<DollarCircleOutlined />}>
              <Link to={`${url}/inversiones`}>Inversiones</Link>
            </Menu.Item>
            <Menu.Item key="Inversores" icon={<SolutionOutlined />}>
              <Link to={`${url}/inversores`}>Inversores</Link>
            </Menu.Item>
            <Menu.Item key="Reportes" icon={<ProjectOutlined />}>
              <Link to={`${url}/reportes`}>Reportes</Link>
            </Menu.Item>
            <Menu.Item key="Usuarios" icon={<TeamOutlined />}>
              <Link to={`${url}/usuarios`}>Usuarios</Link>
            </Menu.Item>
            <SubMenu
              key="configuraciones"
              icon={<SettingOutlined />}
              title="Configuraciones"
            >
              <Menu.Item key="bancos" icon={<BankOutlined />}>
                <Link to={`${url}/bancos`}>Bancos</Link>
              </Menu.Item>
              <Menu.Item key="homePage" icon={<LayoutOutlined />}>
                <Link to={`${url}/homePage`}>Home Page</Link>
              </Menu.Item>
              <Menu.Item key="articles" icon={<FileTextOutlined />}>
                <Link to={`${url}/articulos`}>Artículos</Link>
              </Menu.Item>
              <Menu.Item key="newsletter" icon={<ReadOutlined />}>
                <Link to={`${url}/newsletter`}>Newsletter</Link>
              </Menu.Item>
              <Menu.Item key="guiadeiniciorapido" icon={<ReadOutlined />}>
                <Link to={`${url}/guiadeiniciorapido`}>
                  Wiki
                </Link>
              </Menu.Item>
              <Menu.Item key="videoCanal" icon={<VideoCameraOutlined />}>
                <Link to={`${url}/videoCanal`}>Video canal</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="Perfil" icon={<AppstoreOutlined />}>
              <Link to="/admin">Perfil</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ display: 'grid' }}>
            <Row align="middle" justify="end">
              {user && (
                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                  <p style={{ color: 'white' }}>
                    {user.firstName}
                    <DownOutlined
                      style={{ fontSize: '13px', marginLeft: '4px', color: 'red' }}
                    />
                  </p>
                </Dropdown>
              )}
            </Row>
          </Header>
          <Content>
            <div className="site-layout-background content-admin">
              <Switch>
                <Route path={`${path}/inversiones`}>
                  <InversionRoutes />
                </Route>
                <Route path={`${path}/inversores`}>
                  <InvestorsRoutes />
                </Route>
                <Route path={`${path}/usuarios`}>
                  <UsersRoutes />
                </Route>
                <Route path={`${path}/reportes`}>
                  <ReportsRoutes />
                </Route>
                <Route path={`${path}/bancos`}>
                  <BanksRoutes />
                </Route>
                <Route path={`${path}/homePage`}>
                  <TrackRecordsRoutes />
                </Route>
                <Route path={`${path}/articulos`}>
                  <ArticlesRoutes />
                </Route>
                <Route path={`${path}/newsletter`}>
                  <NewsletterRoutes />
                </Route>
                <Route path={`${path}/guiadeiniciorapido`}>
                  <QuickStartGuideRoutes />
                </Route>
                <Route path={`${path}/videoCanal`}>
                  <VideoCanalRoutes />
                </Route>
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Totallia ©2021 Created by Rolling Code Studio
          </Footer>
        </Layout>
      </Layout>
    </>
  );
};
export default PanelAdmin;
