import { Divider, Table, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import clientAxios from 'config/clientAxios';
import {
  errorMessage,
  loadingMessage,
} from 'utils/messageHelper/messageHelper';
import { tableHeadingsTemplate } from './tableStructure/TableStructure';

const AccountStatus = ({ userData }) => {
  const [isLoadingTransaction, setIsLoadingTransaction] = useState(false);
  const [transactionData, setTransactionData] = useState([]);

  useEffect(() => {
    const handleGetTransaction = async () => {
      if (userData?.investments[0]?._id) {
        try {
          setIsLoadingTransaction(true);
          const response = await clientAxios.get(
            `/transactions/investment/${userData?.investments[0]?._id}/user/${userData?._id}`
          );
          const userResponse = response.data;
          setTransactionData(userResponse);
        } catch (error) {
          errorMessage('Los datos no estan disponibles');
        } finally {
          setIsLoadingTransaction(false);
        }
      } else {
        loadingMessage('Cargando transacciones');
      }
    };
    handleGetTransaction();
  }, [userData]);

  if (transactionData.length === 0) return <h2>No hay estado de cuenta</h2>;

  return (
    <>
      <h4>Estado de cuenta</h4>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={24} lg={24}>
          <h5>{userData?.investments[0]?.name}</h5>
          <Table
            isLoading={isLoadingTransaction}
            columns={tableHeadingsTemplate}
            dataSource={transactionData}
            rowKey="_id"
            pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
            scroll={{ x: 1300 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountStatus;
