import { Modal } from 'antd';
import { Document, Page } from 'react-pdf';
import { useState, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/Loader/LoadingComponent';


const PDFView = ({ url }) => {
  const pageNumber = 1;

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  return (
    <>
      <Document file={{ url }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Página {pageNumber} de {numPages} -{' '}
        <a href={url} rel="noreferrer" target="_blank">
          Descargar <DownloadOutlined />
        </a>
      </p>
    </>
  );
};

const Attachment = ({ attachment }) => {
  const isValidExtension = (url, extensionToCheck) => {
    if (!url) return false;

    const urlExtensionMatch = url.match(/\.(.+)$/);
    if (!urlExtensionMatch) return false;

    const urlExtension = urlExtensionMatch[1];
    return urlExtension.toLowerCase() === extensionToCheck;
  };

  if (isValidExtension(attachment?.key, 'pdf')) {
    return <PDFView url={attachment?.url} />;
  }

  if (!attachment?.url) return <div>El archivo solicitado no existe.</div>;

  return (
    <div className="mt-1">
      <img src={attachment?.url} alt="img-doc" className="img-fluid mt-4" />
    </div>
  );
};

const ModalAttachment = ({ open, handleCancel, attachmentId }) => {
  const [attachment, setAttachment] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getImageUrlPrivate = async () => {
        try {
          setIsLoading(true);
          const { data } = await clientAxios.get(
            `/attachments/private/${attachmentId}`
          );
          setAttachment(data);
        } catch (error) {
          errorMessage(
            'Tuvimos inconvenientes para traer el Documento Identidad'
          );
        } finally {
          setIsLoading(false);
        }
    };
    attachmentId && getImageUrlPrivate();
  }, [attachmentId]);

  return (
    <Modal
      title={null}
      visible={open}
      onCancel={handleCancel}
      style={{ textAlign: 'center' }}
      footer={null}
      width={1000}
    >
      <LoadingInvestorRoom isLoading={isLoading}>
        <Attachment attachment={attachment} />
      </LoadingInvestorRoom>
    </Modal>
  );
};

export default ModalAttachment;
