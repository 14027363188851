import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

//Configuración Firebase Totallia
const firebaseConfig = {
  apiKey: "AIzaSyDdIJXej-hyQZHZ_DqFSmukHPy1DNJjDxQ",
  authDomain: "totallia-api.firebaseapp.com",
  projectId: "totallia-api",
  storageBucket: "totallia-api.appspot.com",
  messagingSenderId: "858462762629",
  appId: "1:858462762629:web:c7e324403c201065efdbec"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth;

export { auth }