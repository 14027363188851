import { Button, Tooltip } from 'antd'
import { basicColumns } from './BasicStructure'

export const HistoryStructure = (handleAttachmentId) => [
  basicColumns.columnName,
  basicColumns.columnInvestmentType,
  basicColumns.columnAmountOffered,
  basicColumns.columnStartDate,
  basicColumns.columnfinishDate,
  {
    title: "Contrato",
    key: 'contract',
    enable: true,
    render: (record) => record?.attachments.length !== 0 
      ? (<Button type="primary" onClick={() => {
      const attachment = record?.attachments?.filter(
        (attachment) => attachment.name === 'envelopeId' || attachment.name === 'Contrato'
      )
      handleAttachmentId(attachment[0]._id)}}>Ver</Button>)
      : <Button type="primary" disabled >Ver</Button>
  },
  {
    title: "Renta",
    key: 'action',
    enable: true,
    render: (record) => record?.rents.length !== 0 
    ? <Button type="primary">Ver</Button>
    : <Tooltip placement='left' title='No hay renta disponible' >
        <Button type="primary" disabled >Ver</Button>
      </Tooltip>
  }
];
