import { Button, Card, Row, Col } from 'antd';
import style from '../../../styles/Aprender/aprender.module.css';
import { useHistory } from 'react-router';
import moment from 'moment';
import 'moment/locale/es';

const GuideCard = ({ guide }) => {
  const history = useHistory();
  const readMore = (id) => {
    history.push(`/guias/detalle/${id}`);
    window.scrollTo(0, 0);
  };
  const date = guide.writingDate;
  const day = moment(date).format('DD');
  const month = moment(date).format('MMMM');
  const year = moment(date).format('YYYY');

  return (
    <Card
      bodyStyle={{ padding: 0, backgroundColor: '#F5F5F5' }}
      hoverable
      className={style.card}
    >
      <Row>
        <Col md={10} className={style.imgCardContainer}>
          <img src={guide.imgThumbnail} alt="" className={style.imgCard} />
        </Col>
        <Col md={14} className={style.colCardData}>
          <Row className={style.rowDataCard}>
            <p className={style.pClasification}>{guide.classification}</p>
          </Row>
          <Row className={style.rowDataCard}>
            <h4 className={style.titleCard}>{guide.title}</h4>
          </Row>
          <Row className={style.rowDataCard}>
            <p className={style.pWriter}>{guide.writerName}</p>
            <p className={style.pDate}>{`${day} de ${month} de ${year}`}</p>
          </Row>
          <Row justify="end" className={style.rowDataCard}>
            <Button
              type="link"
              className={style.btnReadMore}
              onClick={() => readMore(guide._id)}
            >
              Leer más
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default GuideCard;
