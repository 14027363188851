import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import VideoCanal from './VideoCanal';
import FormVideoCanal from './FormVideoCanal';

const VideoCanalRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/editarVideo/:id`} component={FormVideoCanal} />
      <Route path={`${path}/nuevoVideo`} component={FormVideoCanal} />
      <Route path={path} component={VideoCanal} />
    </Switch>
  );
};

export default VideoCanalRoutes;
