import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Upload,
  Switch,
  Image,
} from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { uploadProps } from './UploadProps';
import { useState, useEffect } from 'react';
import FormParagraph from './FormParagraph';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import clientAxios from 'config/clientAxios';
import {
  warningMessage,
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import LoadingComponent from 'components/Loader/LoadingComponent';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

const FormArticle = () => {
  const [form] = Form.useForm()
  const [isPost, setIsPost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [articleEdit, setArticleEdit] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const dateFormat = 'DD/MM/YYYY';
  const callbackImg = (info) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      successMessage(`${info.file.name} carga de imagen exitosa`);
      setArticleEdit({ ...articleEdit, imgThumbnail: info.file.response });
    } else if (info.file.status === 'error') {
      errorMessage(`${info.file.name} error al cargar la imagen`);
    }
  }
  const imgThumbnailProps = { ...uploadProps, maxCount: 1, onChange: callbackImg };
  
  useEffect(() => {
    if (id) {
      const fetchArticle = async () => {
        try {
          setIsLoading(true);
          const res = await clientAxios.get(`/articles/${id}`);
          let article = res.data;
          const writingDate = moment(article.writingDate);
          article = { ...article, writingDate };
          setArticleEdit(article);
          setIsPost(article.isPosted);
          setIsLoading(false);
        } catch (error) {
          errorMessage('Error al cargar el artículo');
        }
      };
      fetchArticle();
    }
  }, [id]);

  const handleSwitchIsPosted = () => {
    setIsPost((state) => !state);
  };

  const isBodyEqualToFormData = (data) => {
    const originalArticle = articleEdit.body.map(({image}) => image);
    const newArticle = form.getFieldValue().body.map(({image}) => image);
    originalArticle.map((image, index) => {
      if (JSON.stringify(image) ===  JSON.stringify(newArticle[index])) {
        data.body[index].image = image;
      }
      return null;
    })
    return data;
  };

  const saveArticle = (values) => {
    const dateWritin = values.writingDate;
    const miniImg = values.imgThumbnail;
    const writingDate = dateWritin.format();
    const imgThumbnail = miniImg?.file?.response || articleEdit?.imgThumbnail;
    if(!imgThumbnail){
      return warningMessage('Ingrese una imagen miniatura');
    }
    const arrayBody = values.body;
    const body = arrayBody?.map((body) => ({
      ...body,
      image: body?.image?.fileList?.map((img) => img?.response || img?.image),
    }));
    const bodyParagraph = arrayBody
      ?.map((body) => body?.paragraph === undefined)
      .find((paragraph) => paragraph === true);
    const data = { ...values, writingDate, imgThumbnail, body };
    if (!body || bodyParagraph) {
      return warningMessage('Ingrese al menos un párrafo para el artículo');
    }
    if (!id) {
      sendData(data);
    } else {
      const payload = isBodyEqualToFormData(data);
      updateData(payload, id);
    }
  };

  const sendData = async (article) => {
    try {
      setIsLoading(true);
      await clientAxios.post('/articles', article);
      successMessage('Artículo creado exitosamente');
      history.push('/admin/articulos');
      window.scrollTo(0, 0);
    } catch (error) {
      errorMessage('hubo un error al crear el artículo');
    } finally {
      setIsLoading(false);
    }
  };

  const updateData = async (article, id) => {
    try {
      setIsLoading(true);
      await clientAxios.put(`/articles/${id}`, article);
      successMessage('Artículo editado exitosamente');
      history.push('/admin/articulos');
      window.scrollTo(0, 0);
    } catch (error) {
      errorMessage('Error al editar el artículo');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row className="bg-title-nuevaInversion">
        <Col sm={{ span: 8 }}>
          <h3> {id ? 'Editar Artículo' : 'Agregar Artículo'} </h3>
        </Col>
      </Row>
      <LoadingComponent isLoading={isLoading}>
        <Form
          name="formBank"
          form={form}
          className={styles.formBank}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={id ? articleEdit : { isPosted: false }}
          onFinish={saveArticle}
        >
          <Form.Item label="Estado del artículo" name="isPosted">
            <Switch
              checked={isPost}
              checkedChildren="Publicado"
              unCheckedChildren="No publicado"
              onChange={() => handleSwitchIsPosted()}
            />
          </Form.Item>
          <Form.Item
            label="Fecha"
            name="writingDate"
            rules={[
              {
                required: true,
                message: 'Ingrese una fecha',
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              style={{ width: '300px' }}
              locale={locale}
            />
          </Form.Item>
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: 'Ingrese el título del artículo',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Clasificación"
            name="classification"
            rules={[
              {
                required: true,
                message: 'Ingrese la clasificación del artículo',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Escrito por"
            name="writerName"
            rules={[
              {
                required: true,
                message: 'Ingrese nombre y apellido del escritor',
              },
            ]}
          >
            <Input placeholder="Nombre y Apellido" />
          </Form.Item>
          <Form.Item
            label="Imagen Thumbnail"
            name="imgThumbnail"
            rules={[
              {
                required: false,
                message: 'Ingrese una imágen miniatura',
              },
            ]}
          >
            <Upload {...imgThumbnailProps}>
              <Button icon={<UploadOutlined />}>Subir imágen</Button>
            </Upload>
            <span style={{ fontSize: '10px' }}>
              Imagen recomendada .jpg 1350 x 530px
            </span>
          </Form.Item>
          <Row>
            <Col span={16} offset={8}>
              {id && (
                <Image width={50} height={50} src={articleEdit?.imgThumbnail} />
              )}
            </Col>
          </Row>
          <FormParagraph articleEdit={articleEdit} />
          <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.btnAddBank}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </LoadingComponent>
    </>
  );
};

export default FormArticle;
