export const getFormData = (file, boolean, name, idInvestment, idUser) => {
  const fmData = new FormData();
  fmData.append('attachment', file);
  fmData.append('private', boolean);
  fmData.append('name', name);
  fmData.append('investment', idInvestment);
  fmData.append('user', idUser);
  return fmData;
};

export const getConfigUpload = (onProgress) => {
  return {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };
};
