import React, { useState, useEffect } from 'react';
import { getSessionStorage } from 'utils/sessionStorageHelper/sessionHelper';
import { Tabs } from 'antd';
import styles from './investNowTabs.module.css';
import SignContract from './options/signContract/SignContract';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import FormStepOne from './options/form/FormStepOne';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';


const { TabPane } = Tabs;

const InvestNowTabs = ({ idInvestment }) => {
  // eslint-disable-next-line
  const [offerId, setOfferId] = useState(getLocalStorage('offerId') || null)
  const [tabSelected, setTabSelected] = useState('tab1');
  const [isLoading, setIsLoading] = useState(true);
  const nextTab = getSessionStorage('nextTab');

  const {id} = useParams()

  useEffect(() => {
    if (nextTab) {
      setTabSelected(nextTab);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    if(offerId){
      setTabSelected('tab2')
    }
    // eslint-disable-next-line
  }, [nextTab]);

  return (
    <LoadingInvestorRoom isLoading={isLoading}>
      <Tabs activeKey={tabSelected} centered className={styles.tabs} onChange={(activeKey) => setTabSelected(activeKey)}>
        {!offerId? (
          <TabPane tab="Completá el formulario" key="tab1">
            <FormStepOne idInvestment={idInvestment} setTabSelected={setTabSelected} />
          </TabPane>
        ) : (<></>)}
        
        <TabPane tab="Firmá el contrato" key="tab2">
          <SignContract investmentId={id} setTabSelected={setTabSelected} />
        </TabPane>
      </Tabs>
    </LoadingInvestorRoom>
  );
};

export default InvestNowTabs;
