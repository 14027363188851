import { useEffect, useState } from 'react';
import { Col, Row, Form, Input, Button, Select } from 'antd';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  errorMessage,
  successMessage,
} from '../../../../utils/messageHelper/messageHelper';
import clientAxios from '../../../../config/clientAxios';
import styles from '../../../../styles/panelAdmin/adminBanks.module.css';
import style from './formBank.module.css'

const { Option } = Select;

const FormBank = () => {
  const history = useHistory();
  const { id } = useParams();
  const [bankEdit, setBankEdit] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const saveBank = async (values) => {
    try {
      await clientAxios.post(`/banks`, values);
      successMessage('Banco creado exitosamente');
      history.goBack();
    } catch (error) {
      errorMessage('No se pudo agregar el banco, intente nuevamente', 3);
    }
  };

  const updateBank = async (values) => {
    try {
      await clientAxios.put(`/banks/${id}`, values);
      successMessage('Banco actualizado exitosamente');
      history.goBack();
    } catch (error) {
      errorMessage('No se pudo actualizar el banco, intente nuevamente', 3);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchOneBank = async () => {
        try {
          setIsLoading(true);
          const res = await clientAxios.get(`/banks/${id}`);
          const bank = res.data[0];
          setBankEdit(bank);
          setIsLoading(false);
        } catch (error) {
          errorMessage('Datos del banco no disponibles');
        }
      };
      fetchOneBank();
    }
  }, [id]);

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 24 }} spin />;
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row justify="center" className="bg-title-nuevaInversion">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <h3> {id ? 'Editar Banco' : 'Agregar Banco'} </h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        ></Col>
      </Row>
      <Row justify="center" >
        <Form
          name="formBank"
          className={style.wrapper}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={id ? bankEdit : null}
          onFinish={id ? updateBank : saveBank}
        >
          <div>
          <Form.Item
            label="Bank type / Tipo de banco"
            name="type"
            rules={[{ required: true, message: 'Elija un tipo de cuenta' }]}
          >
            <Select defaultValue={null} style={{ maxWidth: 350 }}>
              <Option value={null} disabled>
                Tipo de Banco
              </Option>
              <Option value={0}>Doméstico USA</Option>
              <Option value={1}>Internacional USA</Option>
              <Option value={2}>Europeo</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Etiqueta"
            name="labelName"
            rules={[{ required: true, message: 'Ingrese un nombre de Etiqueta' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bank Name / Nombre del Banco"
            name="name"
            rules={[{ required: true, message: 'Ingrese un nombre de banco' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ABA Routing Number or Transit Number / Número ABA o Número de Tránsito"
            name="transitNumber"
            rules={[{ min: 9, message: 'El número debe tener 9 dígitos' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Bank Address / Dirección del Banco" name="address">
            <Input />
          </Form.Item>
          <Form.Item
            label="Beneficiary Name / Nombre del Beneficiario"
            name="beneficiaryName"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Beneficiary Address / Dirección del Beneficiario"
            name="beneficiaryAddress"
          >
            <Input />
          </Form.Item>
          </div>
          <div>
          <Form.Item
            label="Account Number / Número de Cuenta"
            name="accountNumber"
            rules={[
              {
                min: 6,
                max: 17,
                message: 'El número debe tener entre 6 y 17 dígitos',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Account type / Tipo de cuenta" name="typeOfAccount">
            <Input />
          </Form.Item>
          <Form.Item
            label="SWIFT / BIC "
            name="BIC"
            rules={[
              {
                pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
                message: 'El código debe ser alfanumérico',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="IBAN"
            name="IBAN"
            rules={[
              {
                max: 34,
                message: 'El código debe tener 34 dígitos',
              },
              {
                pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
                message: 'El código debe ser alfanumérico',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference Field / Campo de Referencia / Instrucciones Especiales"
            name="reference"
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5, span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.btnAddBank}
            >
              {id ? 'Actualizar' : 'Agregar'}
            </Button>
          </Form.Item>
          </div>
        </Form>
      </Row>
    </>
  );
};

export default FormBank;
