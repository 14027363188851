import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Switch,
  Select,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import clientAxios from 'config/clientAxios';
import {
  warningMessage,
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import { classification } from './ClassificationNewsletter';
import LoadingComponent from 'components/Loader/LoadingComponent';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';
const { Option } = Select;

const FormNewsletter = () => {
  const [isPost, setIsPost] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterEdit, setNewsletterEdit] = useState({});
  const dateFormat = 'DD/MM/YYYY';
  const history = useHistory();
  const { id } = useParams();

  const handleSwitchIsPosted = () => {
    setIsPost((state) => !state);
  };

  useEffect(() => {
    if (id) {
      const fetchNewsletter = async () => {
        try {
          setIsLoading(true);
          const res = await clientAxios.get(`/newsletter/${id}`);
          let newsletter = res.data;
          const writerDate = moment(newsletter.writerDate);
          newsletter = { ...newsletter, writerDate };
          setNewsletterEdit(newsletter);
          setIsPost(newsletter.isPosted);
          setIsLoading(false);
        } catch (error) {
          warningMessage('Error al cargar el newsletter');
        }
      };
      fetchNewsletter();
    }
  }, [id]);

  const saveNewsletter = async (values) => {
    try {
      setIsLoading(true);
      await clientAxios.post('/newsletter', values);
      successMessage('Newsletter creado exitosamente');
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un error al crear el newsletter');
    } finally {
      setIsLoading(false);
    }
  };

  const editNewsletter = async (values) => {
    try {
      setIsLoading(true);
      await clientAxios.put(`/newsletter/${id}`, values);
      successMessage('Newsletter actualizado exitosamente');
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un error al actualizar el newsletter');
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row className="bg-title-nuevaInversion">
        <Col sm={{ span: 8 }}>
          <h3> {id ? 'Editar Newsletter' : 'Agregar Newsletter'} </h3>
        </Col>
      </Row>
      <LoadingComponent isLoading={isLoading}>
        <Form
          name="formBank"
          className={styles.formBank}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={id ? newsletterEdit : { isPosted: false }}
          onFinish={id ? editNewsletter : saveNewsletter}
        >
          <Form.Item label="Estado" name="isPosted">
            <Switch
              checked={isPost}
              checkedChildren="Publicado"
              unCheckedChildren="No publicado"
              onChange={() => handleSwitchIsPosted()}
            />
          </Form.Item>
          <Form.Item label="Fecha" name="writerDate">
            <DatePicker
              format={dateFormat}
              style={{ width: '300px' }}
              locale={locale}
            />
          </Form.Item>
          <Form.Item
            label="Título"
            name="title"
            rules={[
              {
                required: true,
                message: 'Ingrese el título del newsletter',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Clasificación"
            name="classification"
            rules={[
              {
                required: true,
                message: 'Ingrese la clasificación del newsletter',
              },
            ]}
          >
            <Select style={{ width: 350 }}>
              <Option value="" disabled></Option>
              {classification.map((classification) => (
                <Option key={classification.key} value={classification.key}>
                  {classification.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Escrito por" name="writerName">
            <Input placeholder="Nombre y Apellido" />
          </Form.Item>
          <Form.Item
            label="Link del Artículo"
            name="link"
            rules={[
              {
                required: true,
                message: 'Ingrese un link',
              },
            ]}
          >
            <Input placeholder="http://www.example.com/ o www.example.com/news"/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.btnAddBank}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </LoadingComponent>
    </>
  );
};

export default FormNewsletter;
