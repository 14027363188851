import React from 'react';
import { Input, Form } from 'antd';

function BankForm(form) {
  return (
    <div>
      <Form.Item label="País" name="country" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.country}/>
      </Form.Item>
      <Form.Item label="Nombre del banco intermediario" name="bankName">
        <Input className="prefix-width-modal" initialValue={form?.bankName}/>
      </Form.Item>
      <Form.Item label="SWIFT Code del banco intermediario" name="bankSwift">
        <Input className="prefix-width-modal" initialValue={form?.bankSwift}/>
      </Form.Item>
      <Form.Item label="Nombre del banco beneficiario" name="bankBeneficiaryName" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankBeneficiaryName}/>
      </Form.Item>
      <Form.Item label="Dirección del banco beneficiario" name="bankBeneficiaryAddress" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankBeneficiaryAddress}/>
      </Form.Item>
      <Form.Item label="SWIFT Code del banco beneficiario" name="bankBeneficiarySwift" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankBeneficiarySwift}/>
      </Form.Item>
      <Form.Item 
        label="Nº Cuenta del banco beneficiario / IBAN" 
        name="accountNumber" 
        rules={
          [ 
            { required: true }, 
            { 
              pattern: new RegExp(/^[A-Za-z0-9\s]+$/g), 
              message: "Solo se admiten numeros y letras.",
            }
          ]}>
        <Input className="prefix-width-modal" initialValue={form?.accountNumber}/>
      </Form.Item>
      <Form.Item label="Titular de cuenta del banco beneficiario" name="bankBeneficiaryOwner" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankBeneficiaryOwner}/>
      </Form.Item>
      <Form.Item label="Dirección del titular del banco beneficiario (calle, nro, codigo postal, ciudad, país)" name="bankBeneficiaryOwnerAddress" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankBeneficiaryOwnerAddress}/>
      </Form.Item>
      <Form.Item label="Comentarios / aclaraciones / consultas" name="comments">
        <Input className="prefix-width-modal" initialValue={form?.comments}/>
      </Form.Item>
    </div>
  );
}

export default BankForm;