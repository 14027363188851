import { Button, Popconfirm } from 'antd';
import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { offerStatus } from 'config/offerStatus';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import { formatMoney } from 'utils/currentFormatHelper/currentFormatHelper';

export const tableHeadingsTemplate = (
  history,
  deleteOffer,
  loading,
  handleShowAdditionalDocs
) => [
  {
    title: 'Fecha',
    key: 'createdAt',
    enable: true,
    render: (_, data) => <>{dateFormat(data.createdAt)}</>,
  },
  {
    title: 'Nombre de la inv.',
    dataIndex: 'investment.name',
    key: 'investment.name',
    enable: true,
    render: (_, data) => <>{data.investment?.name}</>,
  },
  {
    title: 'Monto ofertado',
    dataIndex: 'amountOffered',
    key: 'amountOffered',
    enable: true,
    render: (amountOffered) => formatMoney(amountOffered),
  },
  {
    title: 'Es una reinversión',
    dataIndex: 'isReinvestment',
    key: 'isReinvestment',
    enable: true,
    render: (key) => {
      const status = key ? 'Si' : 'No';
      return <>{status}</>;
    },
  },
  {
    title: 'Status operación',
    dataIndex: 'status',
    key: 'status',
    render: (key) => <>{offerStatus[key]}</>,
  },
  {
    title: 'Acciones',
    dataIndex: '_id',
    key: '_id',
    enable: true,
    render: (_, data) => (
      <>
        <Button
          onClick={() => {
            history.push(
              `/admin/inversores/perfil-del-Inversor/3lXtMXCa04VKmwbzvFo72lGEjNG2/offers/${data._id}`
            );
          }}
          type="primary"
          style={{ border: 'none', margin: 6 }}
        >
          Ver Oferta
        </Button>
        <Popconfirm
          placement="top"
          title={'Desea eliminar esta oferta?'}
          onConfirm={() => deleteOffer(data._id)}
          okText="Si"
          cancelText="No"
        >
          <Button
            loading={loading}
            danger
            className="bg-danger rounded"
            style={{ margin: 6 }}
            icon={<DeleteOutlined className="text-white" />}
          />
        </Popconfirm>
      </>
    ),
  },
  {
    align: 'center',
    width: 10,
    title: 'Archivos adicionales',
    key: 'additionalDocs',
    render: (record) => (
      <Button
        type="primary"
        icon={<FileAddOutlined />}
        onClick={() => handleShowAdditionalDocs(true, record)}
      />
    ),
  },
];
