import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SettingsHome from './SettingsHome';

const TrackRecordsRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} component={SettingsHome} />
    </Switch>
  );
};

export default TrackRecordsRoutes;
