import { Row, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { errorMessage } from '../../utils/messageHelper/messageHelper';
import CardInvestment from '../HomePage/CardInvestment';
import style from '../../styles/investments/investmentPage.module.css';
import clientAxios from '../../config/clientAxios';

const AllInvestments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [restOfInvestments, setRestOfInvestments] = useState([])
  const [seeMore, setSeeMore] = useState(false);

  const viewMore = true;

  useEffect(() => {
    const getAllInvestments = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get('/posted');
        const allInvestments = response.data;
        const investmentOpen = allInvestments
        .filter((investment) => investment.isOpen === true)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const investmentClosed = allInvestments
        .filter((investment) => investment.isOpen === false)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const sortedInverstments = [...investmentOpen,...investmentClosed ];
        saveRestOfInvestments(sortedInverstments);
        setIsLoading(false);
      } catch (error) {
        const message = 'Inversiones no disponibles';
        return errorMessage(message);
      }
    };
    getAllInvestments();
    // eslint-disable-next-line
  }, []);

  const saveRestOfInvestments = (data) => {
    if (data.length > 8) {
      return setValues(data.slice(0, 8), data.slice(8, data.length));
    }else if (data.length > 4) {
      return setValues(data.slice(0, 4), data.slice(4, data.length));
    }
    setInvestments(data);
    setSeeMore(null)
  }

  const setValues = (investments, restOfInvestments) => {
    setInvestments(investments);
    setRestOfInvestments(restOfInvestments);
    setSeeMore(false);
  }

  if (isLoading) {
    return <LoadingOutlined className='mt-5 mb-5' style={{ fontSize: 24 }} spin />;
  }

  const getButton = () => {
    if (seeMore === null) {
      return null;
    }
    return !seeMore
      ? <Button type='text' onClick={()=> setSeeMore(true)}>
          <p className={style.verTodas}>
            Ver Todas{' '}
            <span>
              <i className="fas fa-arrow-down"></i>
            </span>
          </p>
        </Button>
      : <Button type='text' onClick={()=> setSeeMore(false)}>
          <p className={style.verTodas}>
            Ver Menos{' '}
            <span>
              <i className="fas fa-arrow-up"></i>
            </span>
          </p>
        </Button> 
  };

  return (
    <>
      <Row className={style.rowCards}>
        {investments.map((investment) => {
          return <CardInvestment key={investment._id} investment={investment} viewMore={viewMore} />;
        })}
      </Row>
      { seeMore === true &&
        <Row className={style.rowCards}>
        {restOfInvestments.map((investment) => {
          return <CardInvestment key={investment._id} investment={investment} viewMore={viewMore} />;
        })}
        </Row>
      }
      <Row className='d-flex justify-content-center mb-5'>
        { getButton() }
      </Row>
    </>
  );
};
export default AllInvestments;
