import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, Switch } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import CardInvestment from './CardInvestment';
import styles from 'styles/investorRoom/investorRoom.module.css';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';

const InvestmentsInvestorRoom = () => {
  const [allDataInvestment, setAllDataInvestment] = useState([]);
  const [optionSelected, setOptionSelected] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleGetAllInvestments = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(
          `/posted?isOpen=${optionSelected}`
        );
        const allInvesment = response.data;
        setAllDataInvestment(allInvesment);
        setIsLoading(false);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
        setIsLoading(false);
      }
    };
    handleGetAllInvestments();
  }, [optionSelected]);

  const handleChange = (value) => {
    setOptionSelected(value);
  };
  return (
    <>
      <Row align="middle">
        <BarChartOutlined
          style={{
            fontSize: '40px',
            color: '#08c',
            marginRight: '10px',
            marginBottom: '21px',
          }}
        />
        <h3>Inversiones</h3>
      </Row>
      <Divider />
      <label>
        <strong>Inversiones: </strong>
        <Switch
          checkedChildren="Abiertas"
          unCheckedChildren="Cerradas"
          onChange={handleChange}
          defaultChecked
        />
      </label>
      <LoadingInvestorRoom isLoading={isLoading}>
        <Row justify="center">
          {allDataInvestment.map((investment) => (
            <Col key={investment._id} className={styles.cardColumns}>
              <CardInvestment investment={investment} />
            </Col>
          ))}
        </Row>
      </LoadingInvestorRoom>
    </>
  );
};

export default InvestmentsInvestorRoom;
