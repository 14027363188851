import styles from 'styles/investments/investmentDetail.module.css';

const SampleArrow = ({ className, style, onClick, image }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={image} alt="flecha" className={styles.arrows} />
    </div>
  );
};

export default SampleArrow;
