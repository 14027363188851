import { Modal, Form, Button, Radio, Divider } from 'antd';
import clientAxios from '../../../config/clientAxios';
import { errorMessage } from '../../../utils/messageHelper/messageHelper';
import { removeEmptyValues } from '../../../utils/removeEmptyValues/removeEmptyValues';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';

const ModalExportCsv = ({ modalCSV, handleToggleExportCSV, roles }) => {
  const [formExport] = Form.useForm();

  const cancelExport = () => {
    formExport.resetFields();
    handleToggleExportCSV();
  };
  const exportCsv = (csv) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'usuarios-totallia.csv';
    hiddenElement.click();
  };
  const getExportCsv = async (values) => {
    const params = removeEmptyValues(values);
    const url = new URLSearchParams(params).toString();
    try {
      const res = await clientAxios.get(`/users/export?${url}`);
      exportCsv(res.data);
    } catch (error) {
      errorMessage('Hubo un error al exportar la base de datos');
    }
  };
  return (
    <Modal visible={modalCSV} onCancel={cancelExport} footer={null}>
      <h6>Exportar usuarios</h6>
      <Divider />
      <Form
        name="basic"
        initialValues={{
          role: '',
          newsletter: '',
        }}
        form={formExport}
        onFinish={getExportCsv}
      >
        <h6>Rol</h6>
        <Form.Item name="role">
          <Radio.Group defaultValue="">
            <Radio value="">Todos</Radio>
            {roles?.map((rol, index) => (
              <Radio key={index} value={rol}>
                {rol}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <h6>Newsletter</h6>
        <Form.Item name="newsletter">
          <Radio.Group defaultValue="">
            <Radio value="">Todos</Radio>
            <Radio value={true}>Si</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className={styles.btnNewsletter}
          >
            Exportar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalExportCsv;
