import imgPlantersCrossing from '../../../img/imgAboutUs/Planters_Crossing.jpg';
import img5545SlausonXC from '../../../img/imgAboutUs/5545SlausonXC.png';
import imgNordhoff from '../../../img/imgAboutUs/Nordhoff.jpg';
import imgBrelfTwo from '../../../img/imgAboutUs/BrelfTwo.jpeg';
import imgOrlandoOffice from '../../../img/imgAboutUs/OrlandoOffice.jpg';
import imgMountainVista from '../../../img/imgAboutUs/MountainVista.jpg';
import imgLakelandXF from '../../../img/imgAboutUs/LakelandXF.jpg';
import imgOaklandAirportPlata from '../../../img/imgAboutUs/OaklandAirportPlata.jpg';
import imgLafayette from '../../../img/imgAboutUs/Lafayette.png';
import imgWoodmontKiddieAcademy from '../../../img/imgAboutUs/WoodmontKiddieAcademy.jpg';
import imgElevateAt604West from '../../../img/imgAboutUs/ElevateAt604West.jpg';
import imgTheGreenOn5th from '../../../img/imgAboutUs/TheGreenOn5th.jpg';
import imgMultifamilyPortfolio from '../../../img/imgAboutUs/Multifamily_Portfolio_21.jpg';
import imgIndustrialPortfolio from '../../../img/imgAboutUs/Industrial_Portfolio.png';
import imgLegacyOpportunityFund from '../../../img/imgAboutUs/LegacyOpportunityFund.jpg';
import imgWoodmontLoneTree from '../../../img/imgAboutUs/WoodmontLoneTree.jpg';

export const projects = [
  {
    name: 'Planters Crossing',
    img: imgPlantersCrossing,
    sponsor: 'LN Development Group',
    sector: 'Multifamiliar',
    startDate: 2013,
    endDate: 2021,
    totalInvestment: 2266000,
    finalProfitability: 83,
  },
  {
    name: '5545 Slauson XC',
    img: img5545SlausonXC,
    sponsor: 'Xebec',
    sector: 'Industrial',
    startDate: 2014,
    endDate: 2015,
    totalInvestment: 100000,
    finalProfitability: 34,
  },
  {
    name: 'Nordhoff XC',
    img: imgNordhoff,
    sponsor: 'Xebec',
    sector: 'Industrial',
    startDate: 2015,
    endDate: 2017,
    totalInvestment: 500000,
    finalProfitability: 76,
  },
  {
    name: 'Brelf II',
    img: imgBrelfTwo,
    sponsor: 'Broadmark',
    sector: 'RE Lending',
    startDate: 2015,
    endDate: 2019,
    totalInvestment: 600000,
    finalProfitability: 36,
  },
  {
    name: 'Orlando Office',
    img: imgOrlandoOffice,
    sponsor: 'Denholtz',
    sector: 'Oficinas',
    startDate: 2016,
    endDate: 2018,
    totalInvestment: 250000,
    finalProfitability: 19,
  },
  {
    name: 'Mountain Vista',
    img: imgMountainVista,
    sponsor: 'Northstar Commercial Partners',
    sector: 'Industrial',
    startDate: 2017,
    endDate: 2021,
    totalInvestment: 250000,
    finalProfitability: 15,
  },
  {
    name: 'Oakland Airport Office',
    img: imgOaklandAirportPlata,
    sponsor: 'Vertical Ventures',
    sector: 'Oficinas',
    startDate: 2017,
    endDate: 2019,
    totalInvestment: 200000,
    finalProfitability: 93,
  },
  {
    name: 'Lafayette Medical Center',
    img: imgLafayette,
    sponsor: 'Northstar Commercial Partners',
    sector: 'Centro Médico',
    startDate: 2018,
    endDate: 2020,
    totalInvestment: 500000,
    finalProfitability: 44,
  },
  {
    name: 'Lakeland XF',
    img: imgLakelandXF,
    sponsor: 'Xebec',
    sector: 'Industrial',
    startDate: 2018,
    endDate: 2021,
    totalInvestment: 1250000,
    finalProfitability: 121,
  },
  {
    name: 'Woodmont Kiddie Academy',
    img: imgWoodmontKiddieAcademy,
    sponsor: 'The Woodmont Company',
    sector: 'Especial',
    startDate: 2018,
    endDate: 2020,
    totalInvestment: 390000,
    finalProfitability: 63,
  },
  {
    name: 'Woodmont Lone Tree',
    img: imgWoodmontLoneTree,
    sponsor: 'The Woodmont Company',
    sector: 'Especial',
    startDate: 2018,
    endDate: 2022,
    totalInvestment: 700000,
    finalProfitability: 87,
  },
  {
    name: 'Legacy Opportinuty Fund ',
    img: imgLegacyOpportunityFund,
    sponsor: 'Legacy Group Capital',
    sector: 'RE Lending',
    startDate: 2018,
    endDate: 2020,
    totalInvestment: 850000,
    finalProfitability: 29,
  },
  {
    name: 'Industrial Portfolio',
    img: imgIndustrialPortfolio,
    sponsor: 'Taurus',
    sector: 'Industrial',
    startDate: 2020,
    endDate: 2021,
    totalInvestment: 400000,
    finalProfitability: 56,
  },
  {
    name: 'Multifamily Portfolio 21',
    img: imgMultifamilyPortfolio,
    sponsor: 'Lurin',
    sector: 'Multifamiliar',
    startDate: 2020,
    endDate: 2022,
    totalInvestment: 500000,
    finalProfitability: 81,
  },
  {
    name: 'The Greens on 5th',
    img: imgTheGreenOn5th,
    sponsor: 'Lurin',
    sector: 'Multifamiliar',
    startDate: 2019,
    endDate: 2021,
    totalInvestment: 400000,
    finalProfitability: 135,
  },
  {
    name: 'Elevate at 604 West',
    img: imgElevateAt604West,
    sponsor: 'Lurin',
    sector: 'Multifamiliar',
    startDate: 2019,
    endDate: 2021,
    totalInvestment: 400000,
    finalProfitability: 170,
  },
];

const baseResposive = {
  span: 3,
  xs: 12,
  sm: 6,
  md: 3,
};
const baseResposiveB = {
  ...baseResposive,
  span: 4,
  md: 4,
};
const baseResposiveC = {
  ...baseResposive,
  span: 2,
  md: 2,
};

export const header = [
  {
    ...baseResposive,
    content: 'Nombre',
  },
  {
    ...baseResposive,
    notIcon: true,
  },
  {
    ...baseResposiveB,
    content: 'Sponsor',
  },
  {
    ...baseResposive,
    content: 'Sector',
  },
  {
    ...baseResposiveC,
    content: 'Inicio',
  },
  {
    ...baseResposiveC,
    content: 'Fin',
  },
  {
    ...baseResposiveB,
    content: 'Inversion Total',
  },
  {
    ...baseResposive,
    content: 'Rentabilidad Final',
  },
];
