import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { DownloadOutlined } from '@ant-design/icons';

const PDFView = ({ url }) => {
  const pageNumber = 1;

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  return (
    <>
      <Document file={{ url }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Página {pageNumber} de {numPages} -{' '}
        <a href={url} rel="noreferrer" target="_blank">
          Descargar <DownloadOutlined />
        </a>
      </p>
    </>
  );
};

const Attachment = ({ attachment }) => {
  const isValidExtension = (url, extensionToCheck) => {
    if (!url) return false;

    const urlExtensionMatch = url.match(/\.(.+)$/);
    if (!urlExtensionMatch) return false;

    const urlExtension = urlExtensionMatch[1];
    return urlExtension.toLowerCase() === extensionToCheck;
  };

  if (isValidExtension(attachment?.key, 'pdf')) {
    return <PDFView url={attachment?.url} />;
  }

  if (
    isValidExtension(attachment?.key, 'jpeg') ||
    isValidExtension(attachment?.key, 'png')
  ) {
    return (
      <div className="mt-1">
        <img src={attachment?.url} alt="img-doc" className="img-fluid mt-4" />
      </div>
    );
  }

  if (!attachment?.url) return <div>El archivo solicitado no existe.</div>;

  return (
    <div>
      <a href={attachment?.url} rel="noreferrer" target="_blank">
        Descargar <DownloadOutlined />
      </a>
    </div>
  );
};

export default Attachment;
