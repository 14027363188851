import styles from './WhatsappBtn.module.css';
import { useLocation } from 'react-router';

export default function WhatsappBtn() {
  const location = useLocation();
  const isAdminPage = location.pathname.includes('admin');

  return (
    <>{ !isAdminPage &&
      <a
        className={styles.WhatsappLink}
        href="https://wa.me/+5491154229285"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={styles.WhatsappPng}
          src="/whatsappImg.png"
          alt="Whatsapp"
        />
      </a>}
    </>
  );
}
