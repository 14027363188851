import { Modal, Menu, Dropdown, Upload, Button, Divider, Input } from 'antd';
import { useState, useEffect } from 'react';
import { getLocalStorage } from 'utils/localStorageHelper/localHelper';
import {
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import TransferOptions from './../../pages/myInvestments/investmentView/transfer/TransferOptions';

const UploadModal = ({
  uploadModalState,
  handleCancel,
  offer,
  setOffer,
  typeDoc
}) => {
  const token = getLocalStorage('token');
  const [attachmentType, setAttachmentType] = useState(
    'Seleccionar'
  );
  const [attachmentId, setAttachmentId] = useState('');
  const [documentName, setDocumentName] = useState('');

  const onClick = ({ key }) => {
    setAttachmentType(key);
  };

  
   // eslint-disable-next-line 
  useEffect(() => {
    console.log("typeDoc", typeDoc);
    if(typeDoc === "transfer"){
      setAttachmentType("Transferencia");
    } else if (typeDoc === "doc") {
      setAttachmentType("Documentacion");
    }
  });

  const updateAttachment = async () => {
    try {
      if (attachmentType === 'Seleccionar') {
        return errorMessage('Seleccione que tipo de de documento desea subir');
      } else if (documentName === '' && attachmentType === 'Documentacion') {
        return errorMessage('Ingrese el nombre del documento');
      }
      const offerCopy = offer;
      const attachment =
        attachmentType === 'Documentacion'
          ? {
              _id: attachmentId,
              name: documentName,
            }
          : {
              _id: attachmentId,
              name: attachmentType,
              toValidate: true,
            };

      const attachmentExists = offerCopy.attachments.some(
        (item) => item.name === attachmentType
      );

      if (attachmentExists) {
        offerCopy.attachments = offerCopy.attachments.map((item) => {
          if (item.name === attachmentType) {
            return attachment;
          }
          return item;
        });
      } else if (
        attachment.name !== 'Contrato' &&
        attachment.name !== 'Transferencia'
      ) {
        offerCopy.documentation.push(attachment);
      } else {
        offerCopy.attachments.push(attachment);
      }

      if (attachmentType === 'Transferencia') {
        offerCopy.status = 4;
      }

      try {
        const response = await clientAxios.put(
          `/user/offers/${offer._id}`,
          offerCopy
        );
        const updatedOffer = response.data.offer;
        setOffer(updatedOffer);
      } catch (error) {
        console.log(error);
      }

      const msg = attachmentExists
        ? `Se actualizó el adjunto ${attachmentType} correctamente`
        : 'Documento subido correctamente';

      successMessage(msg);
      setDocumentName('');
      handleCancel();
      window.location.reload(false);
    } catch (error) {
      console.log(error);
      errorMessage('Error al actualizar');
    }
  };

  const menuItems = (
    <>
      <Menu.Item
        key="Contrato"
        disabled={offer?.attachments?.some(
          (item) => item.name === 'Contrato' && item.isAproved
        )}
      >
        Contrato
      </Menu.Item>
      <Menu.Item
        key="Transferencia"
        disabled={offer?.attachments?.some(
          (item) => item.name === 'Transferencia' && item.isAproved
        )}
      >
        Transferencia
      </Menu.Item>
      <Menu.Item
        key="Documentacion"
        disabled={offer?.attachments?.some(
          (item) => item.name === 'Documentacion' && item.isAproved
        )}
      >
        Documentacion
      </Menu.Item>
    </>
  );

  const menu = <Menu onClick={onClick}>{menuItems}</Menu>;

  const props = {
    name: 'attachment',
    action: `${process.env.REACT_APP_API_URL}/attachments/private`,
    withCredentials: true,
    headers: {
      authorization: token,
    },
  };

  const handleTakeImage = (info) => {
    if (info.file.status === 'done') {
      setAttachmentId(info.file.response._id);
    } else if (info.file.status === 'error') {
      errorMessage(`${info.file.name} error al cargar el archivo`);
    }
  };

  return (
    <>
      <Modal
        title={null}
        visible={uploadModalState}
        onCancel={() => {
          handleCancel();
          setAttachmentType('Seleccionar');
          setDocumentName('');
        }}
        footer={null}
        width={'90%'}
      >
        <h2>{typeDoc === "transfer"? "Informacion para transferir" : "Subir archivo (Imagen/PDF)"}</h2>
        <Divider />
        
        {typeDoc === "transfer" || typeDoc === "doc"? (<></>) : (
          <>
            <h4>Seleccione Tipo de Archivo</h4>
            <Dropdown overlay={menu} key="menu">
              <a href="#/" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {attachmentType}
              </a>
            </Dropdown>
            <Divider />
          </>
        )}
        
        
        
        {attachmentType === 'Documentacion' && (
          <>
            <span>Nombre de documento:</span>
            <Input
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
              placeholder="Ejemplo: recibo de sueldo, boleta de servicio, etc"
            />
            <Divider />
          </>
        )}

        {attachmentType === 'Transferencia' && (
          <TransferOptions investmentId={offer.investment._id} />
        )}
        
        <h4>Adjuntar Comprobante/Archivo</h4>
        <Upload
          key="upload"
          {...props}
          onChange={handleTakeImage}
          accept="image/*,.pdf"
          maxCount={1}
        >
          <Button>Seleccionar Archivo</Button>
        </Upload>

        <Divider />
        
        <Button
          onClick={() => {
            updateAttachment();
            setAttachmentType('Seleccionar');
          }}
          type="primary"
          style={{ backgroundColor: 'green', border: 'none', margin: 6 }}
        >
          Subir
        </Button>
      </Modal>
    </>
  );
};

export default UploadModal;
