import { Modal, Row, Col, Button } from 'antd';
import FormLogin from './FormLogin';
import imgModalLogin from '../../img/INGRESO.jpg';
import iconGoogle from '../../img/google.png';

const ModalLogin = ({
  setUser,
  isModalLoginVisible,
  handleToggleLogin,
  handleToggleRegister,
  googleLogin,
  facebookLogin,
}) => {
  return (
    <Modal
      width={900}
      visible={isModalLoginVisible}
      onCancel={handleToggleLogin}
      bodyStyle={{ padding: 0, background: '#f5f5f5' }}
      footer={null}
    >
      <Row>
        <Col lg={11} md={10} xs={0}>
          <img
            src={imgModalLogin}
            alt="Imágen Edificio"
            className="img-login img-login-mobile"
          />
        </Col>
        <Col lg={11} md={12} xs={19}>
          <h4 className="title-modal-login">Ingrese a su cuenta</h4>
          <FormLogin
            setUser={setUser}
            handleToggleLogin={handleToggleLogin}
            handleToggleRegister={handleToggleRegister}
          />
        </Col>
        <Col lg={2} md={2} xs={4} className="div-btn-faceGoggle">
          <Button
            type="primary"
            className="btn-google position-btn-face-google"
            onClick={googleLogin}
          >
            <img src={iconGoogle} className="iconGoogle" alt="" />
          </Button>
          <Button
            type="primary"
            className="btn-facebook position-btn-face-google"
            onClick={facebookLogin}
          >
            <i className="fab fa-facebook-f"></i>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalLogin;
