const phoneCodes = [
  {
    label: 'Afghanistan (+93)',
    value: '+93',
    key: 1,
  },
  {
    label: 'Albania (+355)',
    value: '+355',
    key: 2,
  },
  {
    label: 'Algeria (+21)',
    value: '+21',
    key: 3,
  },
  {
    label: 'American Samoa (+684)',
    value: '+684',
    key: 4,
  },
  {
    label: 'Andorra (+376)',
    value: '+376',
    key: 5,
  },
  {
    label: 'Angola (+244)',
    value: '+244',
    key: 6,
  },
  {
    label: 'Anguilla (+1-264)',
    value: '+1-264',
    key: 7,
  },
  {
    label: 'Antigua and Barbuda (+1-268)',
    value: '+1-268',
    key: 8,
  },
  {
    label: 'Argentina (+54)',
    value: '+54',
    key: 9,
  },
  {
    label: 'Australia (+61)',
    value: '+61',
    key: 10,
  },
  {
    label: 'Austria (+43)',
    value: '+43',
    key: 11,
  },
  {
    label: 'Bahamas (+1-242)',
    value: '+1-242',
    key: 12,
  },
  {
    label: 'Bahrain (+973)',
    value: '+973',
    key: 13,
  },
  {
    label: 'Bangladesh (+880)',
    value: '+880',
    key: 14,
  },
  {
    label: 'Barbados (+1-246)',
    value: '+1-246',
    key: 15,
  },
  {
    label: 'Belarus (+375)',
    value: '+375',
    key: 16,
  },
  {
    label: 'Belgium (+32)',
    value: '+32',
    key: 17,
  },
  {
    label: 'Belize (+501)',
    value: '+501',
    key: 18,
  },
  {
    label: 'Benin (+229)',
    value: '+229',
    key: 19,
  },
  {
    label: 'Bermuda (+1-441)',
    value: '+1-441',
    key: 20,
  },
  {
    label: 'Bolivia (+591)',
    value: '+591',
    key: 21,
  },
  {
    label: 'Bosnia and Herzegovina (+387)',
    value: '+387',
    key: 22,
  },
  {
    label: 'Botswana (+267)',
    value: '+267',
    key: 23,
  },
  {
    label: 'Brazil (+55)',
    value: '+55',
    key: 24,
  },
  {
    label: 'British Virgin Islands (+1-284)',
    value: '+1-284',
    key: 25,
  },
  {
    label: 'Brunei Darusalaam (+673)',
    value: '+673',
    key: 26,
  },
  {
    label: 'Bulgaria (+359)',
    value: '+359',
    key: 27,
  },
  {
    label: 'Burkina Faso (+226)',
    value: '+226',
    key: 28,
  },
  {
    label: 'Burundi (+257)',
    value: '+257',
    key: 29,
  },
  {
    label: 'Byelorussian (+7)',
    value: '+7',
    key: 30,
  },
  {
    label: 'Cambodia (+855)',
    value: '+855',
    key: 31,
  },
  {
    label: 'Cameroon (+237)',
    value: '+237',
    key: 32,
  },
  {
    label: 'Canada (+001)',
    value: '+001',
    key: 33,
  },
  {
    label: 'Cape Verde (+238)',
    value: '+238',
    key: 34,
  },
  {
    label: 'Cayman Islands (+1-345)',
    value: '+1-345',
    key: 35,
  },
  {
    label: 'Central African Republic (+236)',
    value: '+236',
    key: 36,
  },
  {
    label: 'Chad (+235)',
    value: '+235',
    key: 37,
  },
  {
    label: 'Chile (+56)',
    value: '+56',
    key: 38,
  },
  {
    label: 'China (+86)',
    value: '+86',
    key: 39,
  },
  {
    label: 'Christmas Island (+672)',
    value: '+672',
    key: 40,
  },
  {
    label: 'Cocos Islands (+672)',
    value: '+672',
    key: 41,
  },
  {
    label: 'Colombia (+57)',
    value: '+57',
    key: 42,
  },
  {
    label: 'Commonwealth of the Northern Mariana Islands (+1-670)',
    value: '+1-670',
    key: 43,
  },
  {
    label: 'Comoros and Mayotte Island (+269)',
    value: '+269',
    key: 44,
  },
  {
    label: 'Congo (+242)',
    value: '+242',
    key: 45,
  },
  {
    label: 'Cook Islands (+682)',
    value: '+682',
    key: 46,
  },
  {
    label: 'Costa Rica (+506)',
    value: '+506',
    key: 47,
  },
  {
    label: 'Croatia (+385)',
    value: '+385',
    key: 48,
  },
  {
    label: 'Cuba (+53)',
    value: '+53',
    key: 49,
  },
  {
    label: 'Cyprus (+357)',
    value: '+357',
    key: 50,
  },
  {
    label: 'Czech Republic (+420)',
    value: '+420',
    key: 51,
  },
  {
    label: 'Denmark (+45)',
    value: '+45',
    key: 52,
  },
  {
    label: 'Diego Garcia (+246)',
    value: '+246',
    key: 53,
  },
  {
    label: 'Djibouti (+253)',
    value: '+253',
    key: 54,
  },
  {
    label: 'Dominica (+1-767)',
    value: '+1-767',
    key: 55,
  },
  {
    label: 'Dominican Republic (+1-809)',
    value: '+1-809',
    key: 56,
  },
  {
    label: 'East Timor (+62)',
    value: '+62',
    key: 57,
  },
  {
    label: 'Ecuador (+593)',
    value: '+593',
    key: 58,
  },
  {
    label: 'Egypt (+20)',
    value: '+20',
    key: 59,
  },
  {
    label: 'El Salvador (+503)',
    value: '+503',
    key: 60,
  },
  {
    label: 'Equatorial Guinea (+240)',
    value: '+240',
    key: 61,
  },
  {
    label: 'Estonia (+372)',
    value: '+372',
    key: 62,
  },
  {
    label: 'Ethiopia (+251)',
    value: '+251',
    key: 63,
  },
  {
    label: 'Faeroe Islands (+298)',
    value: '+298',
    key: 64,
  },
  {
    label: 'Falkland Islands (Islas Malvinas) (+500)',
    value: '+500',
    key: 65,
  },
  {
    label: 'Fiji (+679)',
    value: '+679',
    key: 66,
  },
  {
    label: 'Finland (+358)',
    value: '+358',
    key: 67,
  },
  {
    label: 'France (+33)',
    value: '+33',
    key: 68,
  },
  {
    label: 'French Antilles (+590)',
    value: '+590',
    key: 69,
  },
  {
    label: 'French Guiana (+594)',
    value: '+594',
    key: 70,
  },
  {
    label: 'French Polynesia (+689)',
    value: '+689',
    key: 71,
  },
  {
    label: 'Gabon (+241)',
    value: '+241',
    key: 72,
  },
  {
    label: 'Gambia (+220)',
    value: '+220',
    key: 73,
  },
  {
    label: 'Georgia (+995)',
    value: '+995',
    key: 74,
  },
  {
    label: 'Germany (+49)',
    value: '+49',
    key: 75,
  },
  {
    label: 'Ghana (+233)',
    value: '+233',
    key: 76,
  },
  {
    label: 'Gibraltar (+350)',
    value: '+350',
    key: 77,
  },
  {
    label: 'Greece (+30)',
    value: '+30',
    key: 78,
  },
  {
    label: 'Greenland (+299)',
    value: '+299',
    key: 79,
  },
  {
    label: 'Grenada (+1-473)',
    value: '+1-473',
    key: 80,
  },
  {
    label: 'Guam (+1-671, +671)',
    value: '+1-671, +671',
    key: 81,
  },
  {
    label: 'Guatemala (+502)',
    value: '+502',
    key: 82,
  },
  {
    label: 'Guinea (+224)',
    value: '+224',
    key: 83,
  },
  {
    label: 'Guinea-Bissau (+245)',
    value: '+245',
    key: 84,
  },
  {
    label: 'Guyana (+592)',
    value: '+592',
    key: 85,
  },
  {
    label: 'Haiti (+509)',
    value: '+509',
    key: 86,
  },
  {
    label: 'Honduras (+504)',
    value: '+504',
    key: 87,
  },
  {
    label: 'Hong Kong (+852)',
    value: '+852',
    key: 88,
  },
  {
    label: 'Hungary (+36)',
    value: '+36',
    key: 89,
  },
  {
    label: 'Iceland (+354)',
    value: '+354',
    key: 90,
  },
  {
    label: 'India (+91)',
    value: '+91',
    key: 91,
  },
  {
    label: 'Indonesia (+62)',
    value: '+62',
    key: 92,
  },
  {
    label: 'Iran (+98)',
    value: '+98',
    key: 93,
  },
  {
    label: 'Iraq (+964)',
    value: '+964',
    key: 94,
  },
  {
    label: 'Irish Republic (+353)',
    value: '+353',
    key: 95,
  },
  {
    label: 'Israel (+972)',
    value: '+972',
    key: 96,
  },
  {
    label: 'Italy (+39)',
    value: '+39',
    key: 97,
  },
  {
    label: 'Ivory Coast (+225)',
    value: '+225',
    key: 98,
  },
  {
    label: 'Jamaica (+1-876)',
    value: '+1-876',
    key: 99,
  },
  {
    label: 'Japan (+81)',
    value: '+81',
    key: 100,
  },
  {
    label: 'Jordan (+962)',
    value: '+962',
    key: 101,
  },
  {
    label: 'Kenya (+254)',
    value: '+254',
    key: 102,
  },
  {
    label: 'Kiribati Republic (+686)',
    value: '+686',
    key: 103,
  },
  {
    label: 'Kuwait (+965)',
    value: '+965',
    key: 104,
  },
  {
    label: 'Laos (+856)',
    value: '+856',
    key: 105,
  },
  {
    label: 'Latvia (+371)',
    value: '+371',
    key: 106,
  },
  {
    label: 'Lebanon (+961)',
    value: '+961',
    key: 107,
  },
  {
    label: 'Lesotho (+266)',
    value: '+266',
    key: 108,
  },
  {
    label: 'Liberia (+231)',
    value: '+231',
    key: 109,
  },
  {
    label: 'Libya (+21)',
    value: '+21',
    key: 110,
  },
  {
    label: 'Liechtenstein (+41)',
    value: '+41',
    key: 111,
  },
  {
    label: 'Lithuania (+370)',
    value: '+370',
    key: 112,
  },
  {
    label: 'Luxembourg (+352)',
    value: '+352',
    key: 113,
  },
  {
    label: 'Macao (+853)',
    value: '+853',
    key: 114,
  },
  {
    label: 'Macedonia (+389)',
    value: '+389',
    key: 115,
  },
  {
    label: 'Madagascar (+261)',
    value: '+261',
    key: 116,
  },
  {
    label: 'Malawi (+265)',
    value: '+265',
    key: 117,
  },
  {
    label: 'Malaysia (+60)',
    value: '+60',
    key: 118,
  },
  {
    label: 'Maldives (+960)',
    value: '+960',
    key: 119,
  },
  {
    label: 'Mali (+223)',
    value: '+223',
    key: 120,
  },
  {
    label: 'Malta (+356)',
    value: '+356',
    key: 121,
  },
  {
    label: 'Marshall Islands (+692)',
    value: '+692',
    key: 122,
  },
  {
    label: 'Martinique (+596)',
    value: '+596',
    key: 123,
  },
  {
    label: 'Mauritania (+222)',
    value: '+222',
    key: 124,
  },
  {
    label: 'Mauritius (+230)',
    value: '+230',
    key: 125,
  },
  {
    label: 'Mexico (+1-706, +1-905, +52)',
    value: '+1-706, +1-905, +52',
    key: 126,
  },
  {
    label: 'Micronesia (+691)',
    value: '+691',
    key: 127,
  },
  {
    label: 'Monaco (+33)',
    value: '+33',
    key: 128,
  },
  {
    label: 'Mongolia (+976)',
    value: '+976',
    key: 129,
  },
  {
    label: 'Montserrat (+1-664, +1-809)',
    value: '+1-664, +1-809',
    key: 130,
  },
  {
    label: 'Morocco (+212)',
    value: '+212',
    key: 131,
  },
  {
    label: 'Mozambique (+258)',
    value: '+258',
    key: 132,
  },
  {
    label: 'Myanmar (+95)',
    value: '+95',
    key: 133,
  },
  {
    label: 'Namibia (+264)',
    value: '+264',
    key: 134,
  },
  {
    label: 'Nauru (+674)',
    value: '+674',
    key: 135,
  },
  {
    label: 'Nepal (+977)',
    value: '+977',
    key: 136,
  },
  {
    label: 'Netherlands (+31)',
    value: '+31',
    key: 137,
  },
  {
    label: 'Netherlands Antilles (+599)',
    value: '+599',
    key: 138,
  },
  {
    label: 'New Caledonia (+687)',
    value: '+687',
    key: 139,
  },
  {
    label: 'New Zealand (+64)',
    value: '+64',
    key: 140,
  },
  {
    label: 'Nicaragua (+505)',
    value: '+505',
    key: 141,
  },
  {
    label: 'Niger (+227)',
    value: '+227',
    key: 142,
  },
  {
    label: 'Nigeria (+234)',
    value: '+234',
    key: 143,
  },
  {
    label: 'Niue (+683)',
    value: '+683',
    key: 144,
  },
  {
    label: 'Norfolk Island (+672)',
    value: '+672',
    key: 145,
  },
  {
    label: 'North Korea (+850)',
    value: '+850',
    key: 146,
  },
  {
    label: 'North Yemen (+967)',
    value: '+967',
    key: 147,
  },
  {
    label: 'Northern Mariana Islands (+670)',
    value: '+670',
    key: 148,
  },
  {
    label: 'Norway (+47)',
    value: '+47',
    key: 149,
  },
  {
    label: 'Oman (+968)',
    value: '+968',
    key: 150,
  },
  {
    label: 'Pakistan (+92)',
    value: '+92',
    key: 151,
  },
  {
    label: 'Panama (+507)',
    value: '+507',
    key: 152,
  },
  {
    label: 'Papua New Guinea (+675)',
    value: '+675',
    key: 153,
  },
  {
    label: 'Paraguay (+595)',
    value: '+595',
    key: 154,
  },
  {
    label: 'Peru (+51)',
    value: '+51',
    key: 155,
  },
  {
    label: 'Philippines (+63)',
    value: '+63',
    key: 156,
  },
  {
    label: 'Poland (+48)',
    value: '+48',
    key: 157,
  },
  {
    label: 'Portugal (+351)',
    value: '+351',
    key: 158,
  },
  {
    label: 'Puerto Rico (+1-787)',
    value: '+1-787',
    key: 159,
  },
  {
    label: 'Qatar (+974)',
    value: '+974',
    key: 160,
  },
  {
    label: 'Republic of San Marino (+378)',
    value: '+378',
    key: 161,
  },
  {
    label: 'Reunion (+262)',
    value: '+262',
    key: 162,
  },
  {
    label: 'Romania (+40)',
    value: '+40',
    key: 163,
  },
  {
    label: 'Russia (+7)',
    value: '+7',
    key: 164,
  },
  {
    label: 'Rwandese Republic (+250)',
    value: '+250',
    key: 165,
  },
  {
    label: 'Saint Helena and Ascension Island (+247)',
    value: '+247',
    key: 166,
  },
  {
    label: 'Saint Pierre et Miquelon (+508)',
    value: '+508',
    key: 167,
  },
  {
    label: 'San Marino (+39)',
    value: '+39',
    key: 168,
  },
  {
    label: 'Sao Tome e Principe (+239)',
    value: '+239',
    key: 169,
  },
  {
    label: 'Saudi Arabia (+966)',
    value: '+966',
    key: 170,
  },
  {
    label: 'Senegal (+221)',
    value: '+221',
    key: 171,
  },
  {
    label: 'Seychelles (+248)',
    value: '+248',
    key: 172,
  },
  {
    label: 'Sierra Leone (+232)',
    value: '+232',
    key: 173,
  },
  {
    label: 'Singapore (+65)',
    value: '+65',
    key: 174,
  },
  {
    label: 'Slovakia (+421)',
    value: '+421',
    key: 175,
  },
  {
    label: 'Slovenia (+386)',
    value: '+386',
    key: 176,
  },
  {
    label: 'Solomon Islands (+677)',
    value: '+677',
    key: 177,
  },
  {
    label: 'Somalia (+252)',
    value: '+252',
    key: 178,
  },
  {
    label: 'South Africa (+27)',
    value: '+27',
    key: 179,
  },
  {
    label: 'South Korea (+82)',
    value: '+82',
    key: 180,
  },
  {
    label: 'South Yemen (+969)',
    value: '+969',
    key: 181,
  },
  {
    label: 'Spain (+34)',
    value: '+34',
    key: 182,
  },
  {
    label: 'Sri Lanka (+94)',
    value: '+94',
    key: 183,
  },
  {
    label: 'St. Kitts and Nevis (+1-869)',
    value: '+1-869',
    key: 184,
  },
  {
    label: 'St.Lucia (+1-758)',
    value: '+1-758',
    key: 185,
  },
  {
    label: 'St.Vincent and the Grenadines (+1-784)',
    value: '+1-784',
    key: 186,
  },
  {
    label: 'Sudan (+249)',
    value: '+249',
    key: 187,
  },
  {
    label: 'Suriname (+597)',
    value: '+597',
    key: 188,
  },
  {
    label: 'Svalbard and Jan Mayen Islands (+47)',
    value: '+47',
    key: 189,
  },
  {
    label: 'Swaziland (+268)',
    value: '+268',
    key: 190,
  },
  {
    label: 'Sweden (+46)',
    value: '+46',
    key: 191,
  },
  {
    label: 'Switzerland (+41)',
    value: '+41',
    key: 192,
  },
  {
    label: 'Syria (+963)',
    value: '+963',
    key: 193,
  },
  {
    label: 'Taiwan (+886)',
    value: '+886',
    key: 194,
  },
  {
    label: 'Tanzania (+255)',
    value: '+255',
    key: 195,
  },
  {
    label: 'Thailand (+66)',
    value: '+66',
    key: 196,
  },
  {
    label: 'Togolese Republic (+228)',
    value: '+228',
    key: 197,
  },
  {
    label: 'Tokelau (+690)',
    value: '+690',
    key: 198,
  },
  {
    label: 'Tonga (+676)',
    value: '+676',
    key: 199,
  },
  {
    label: 'Trinidad and Tobago (+1-868)',
    value: '+1-868',
    key: 200,
  },
  {
    label: 'Tunisia (+21)',
    value: '+21',
    key: 201,
  },
  {
    label: 'Turkey (+90)',
    value: '+90',
    key: 202,
  },
  {
    label: 'Turks (+1-649)',
    value: '+1-649',
    key: 203,
  },
  {
    label: 'Tuvalu (+688)',
    value: '+688',
    key: 204,
  },
  {
    label: 'US Virgin Islands (+1-340, +1-809)',
    value: '+1-340, +1-809',
    key: 205,
  },
  {
    label: 'Uganda (+256)',
    value: '+256',
    key: 206,
  },
  {
    label: 'Ukraine (+380)',
    value: '+380',
    key: 207,
  },
  {
    label: 'United Arab Emirates (+971)',
    value: '+971',
    key: 208,
  },
  {
    label: 'United Kingdom (+44)',
    value: '+44',
    key: 209,
  },
  {
    label: 'United States of America (+1)',
    value: '+1',
    key: 210,
  },
  {
    label: 'Uruguay (+598)',
    value: '+598',
    key: 211,
  },
  {
    label: 'Vanuatu (+678)',
    value: '+678',
    key: 212,
  },
  {
    label: 'Vatican City (+39)',
    value: '+39',
    key: 213,
  },
  {
    label: 'Venezuela (+58)',
    value: '+58',
    key: 214,
  },
  {
    label: 'Vietnam (+84)',
    value: '+84',
    key: 215,
  },
  {
    label: 'Wallis and Futuna Islands (+681)',
    value: '+681',
    key: 216,
  },
  {
    label: 'Western Sahara (+21)',
    value: '+21',
    key: 217,
  },
  {
    label: 'Western Samoa (+685)',
    value: '+685',
    key: 218,
  },
  {
    label: 'Yugoslavia (+381)',
    value: '+381',
    key: 219,
  },
  {
    label: 'Zaire (+243)',
    value: '+243',
    key: 220,
  },
  {
    label: 'Zambia (+260)',
    value: '+260',
    key: 221,
  },
  {
    label: 'Zimbabwe (+263)',
    value: '+263',
    key: 222,
  },
];

export default phoneCodes;
