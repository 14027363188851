import React, { useEffect, useState } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  Col,
  Divider,
  Row,
  Select,
  InputNumber,
  DatePicker,
} from 'antd';
import { parser } from 'utils/formatParseHelper/formatParseHelper';
import clientAxios from 'config/clientAxios';
import { formatter } from 'utils/formatParseHelper/formatParseHelper';
import { getTodayDate, getOneMonthLess } from 'utils/getTodayDate/getTodayDate';
import { TableHeadingsLastConection } from './tablesReportUsers/TableHeadings/TableHeadingsLastConection';
import { TableHeadingsAmount } from './tablesReportUsers/TableHeadings/TableHeadingsAmount';
import { TableHeadingsBalance } from './tablesReportUsers/TableHeadings/TableHeadingsBalance';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import TableReportUsers from './tablesReportUsers/TableReportUsers';
import styles from './style/reports.module.css';
import {
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import ButtonExportCsv from 'components/Admin/exportCsv/ButtonExportCsv';

const { Option } = Select;

const InvestorReport = ({ rangePicker }) => {
  const [optionReport, setOptionReport] = useState(0);
  const [maxBalance, setMaxBalance] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [dateLastConection, setDateLastConection] = useState('');
  const [dataToSend, setDataToSend] = useState([]);
  const [DataReportBalance, setDataReportBalance] = useState([]);
  const [DataReportAmount, setDataReportAmount] = useState([]);
  const [DataReportLastConection, setDataReportLastConection] = useState([]);
  const [isLoadingMaxBalance, setIsLoadingMaxBalance] = useState(false);
  const [isLoadingMaxAmount, setIsLoadingMaxAmount] = useState(false);
  const [isLoadingLastConection, setIsLoadingLastConection] = useState(false);

  useEffect(() => {
    const handleGetReportMaxBalance = async () => {
      if (rangePicker !== null && optionReport === 0) {
        const extractDateOfRange = rangePicker?.map((date) => date._d);
        const dateToSend =
          extractDateOfRange.length === 0
            ? [getOneMonthLess(), getTodayDate()]
            : extractDateOfRange;

        const createConsultReport = {
          accountBalance: maxBalance,
          initial: dateToSend[0],
          final: dateToSend[1],
        };
        setDataToSend(createConsultReport);

        try {
          setIsLoadingMaxBalance(true);
          const response = await clientAxios.post(
            '/reports/investors',
            createConsultReport
          );
          const userResponse = response.data;
          setDataReportBalance(userResponse);
          successMessage('Reporte Creado');
        } catch (error) {
          errorMessage('Error al crear el reporte');
        } finally {
          setIsLoadingMaxBalance(false);
        }
      }
    };
    const handleGetReportMaxAmount = async () => {
      if (rangePicker !== null && optionReport === 1) {
        const extractDateOfRange = rangePicker?.map((date) => date._d);
        const dateToSend =
          extractDateOfRange.length === 0
            ? [getOneMonthLess(), getTodayDate()]
            : extractDateOfRange;

        const createConsultReport = {
          amountInvested: maxAmount,
          initial: dateToSend[0],
          final: dateToSend[1],
        };

        try {
          setIsLoadingMaxAmount(true);
          const response = await clientAxios.post(
            '/reports/investors',
            createConsultReport
          );
          const userResponse = response.data;
          setDataReportAmount(userResponse);
          successMessage('Reporte Creado');
        } catch (error) {
          errorMessage('Error al crear el reporte');
        } finally {
          setIsLoadingMaxAmount(false);
        }
      }
    };
    const handleGetReportLastConection = async () => {
      if (optionReport === 2) {
        const date =
          dateLastConection !== '' ? dateLastConection : getOneMonthLess();
        const extractDateOfRange = rangePicker?.map((date) => date._d);
        const rangeDate =
          extractDateOfRange.length === 0
            ? [getOneMonthLess(), getTodayDate()]
            : extractDateOfRange;
        const dataToSend = {
          lastLogin: date,
          initial: rangeDate[0],
          final: rangeDate[1],
        };
        try {
          setIsLoadingLastConection(true);
          const response = await clientAxios.post(
            '/reports/investors',
            dataToSend
          );
          const userResponse = response.data;
          setDataReportLastConection(userResponse);
          successMessage('Reporte Creado');
        } catch (error) {
          errorMessage('Error al crear el reporte');
        } finally {
          setIsLoadingLastConection(false);
        }
      }
    };
    handleGetReportLastConection();
    handleGetReportMaxAmount();
    handleGetReportMaxBalance();
  }, [rangePicker, maxBalance, maxAmount, dateLastConection, optionReport]);

  const handleOptionsReport = (value) => {
    setOptionReport(value);
  };
  const handleMaxBalance = (event) => {
    if (event.charCode === 13) {
      setMaxBalance(event.target.ariaValueNow);
    }
  };
  const handleMaxAmout = (event) => {
    if (event.charCode === 13) {
      setMaxAmount(event.target.ariaValueNow);
    }
  };
  const handleDateLastContact = (value) => {
    if (value !== null) {
      const formatterDate = value;
      setDateLastConection(formatterDate);
    } else {
      setDateLastConection(getOneMonthLess());
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} lg={{ span: 24 }}>
          <Select
            value={optionReport}
            style={{ width: 350 }}
            onChange={handleOptionsReport}
          >
            <Option value={0}>Inversores con saldo a favor MAYOR a</Option>
            <Option value={1}>
              Inversores cuyo monto de inversion es MAYOR a
            </Option>
            <Option value={2}>Inversores NO tuvieron conexión desde</Option>
          </Select>
          {optionReport === 0 && (
            <InputNumber
              parser={parser}
              formatter={formatter}
              className={styles.sizeInputs}
              onKeyPress={(event) => handleMaxBalance(event)}
            />
          )}
          {optionReport === 1 && (
            <InputNumber
              parser={parser}
              formatter={formatter}
              className={styles.sizeInputs}
              onKeyPress={(event) => handleMaxAmout(event)}
            />
          )}
          {optionReport === 2 && (
            <DatePicker
              defaultValue={getOneMonthLess()}
              className={styles.sizeInputs}
              onChange={handleDateLastContact}
            />
          )}
        </Col>
        <Divider />
      </Row>
      <Row align="middle" justify="center">
        <h3>Reporte Inversores</h3>
      </Row>
      <Row>
        {optionReport === 2 ? (
          <p>
            {`Fecha: ${
              dateLastConection === ''
                ? dateFormat(getOneMonthLess())
                : dateFormat(dateLastConection)
            } al dia de hoy`}
          </p>
        ) : (
          <p>
            {`Fecha: ${dateFormat(dataToSend.initial)} al ${dateFormat(
              dataToSend.final
            )}`}
          </p>
        )}
      </Row>
      <Divider />
      {optionReport === 0 && (
        <TableReportUsers
          rowKey="_id"
          isLoading={isLoadingMaxBalance}
          dataSourse={DataReportBalance}
          tableHeadings={TableHeadingsBalance}
        />
      )}
      {optionReport === 1 && (
        <TableReportUsers
          rowKey="_id"
          isLoading={isLoadingMaxAmount}
          dataSourse={DataReportAmount}
          tableHeadings={TableHeadingsAmount}
        />
      )}
      {optionReport === 2 && (
        <TableReportUsers
          rowKey="_id"
          isLoading={isLoadingLastConection}
          dataSourse={DataReportLastConection}
          tableHeadings={TableHeadingsLastConection}
        />
      )}

      <Row align="middle" justify="center">
        <ButtonExportCsv
          dataToSend={dataToSend}
          nameButton="Exportar CSV"
          nameFile={'reporte-inversor-totallia.csv'}
          endPoint={'/reports/investors/export'}
          style={styles.btnDownloadCsv}
          icon={
            <FileExcelOutlined style={{ fontSize: '30px', color: '#327f59' }} />
          }
        />
      </Row>
    </>
  );
};

export default InvestorReport;
