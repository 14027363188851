import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { infoAlert } from 'utils/alertMessageHelper/alertMessage';
import { hiddenElement } from 'utils/hiddenElementDownload/hiddenElementDownload';
import imgDownload from 'img/icons/Grupo106.svg';
import clientAxios from 'config/clientAxios';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import styles from './manualContract.module.css';
import PdfReader from '../docuSign/PdfReader/PdfReader';
import UploadContract from 'components/investor-room/components/uploadContract/UploadContract';

const ManualContract = ({ handleManualContract, setTabSelected }) => {

  const [pdfContract, setPdfContract] = useState('');
  const [isLoadingFetching, setisLoadingFetching] = useState(false);
  const [isFile, setIsFile] = useState(false);

  useEffect(() => {
    const getDocument = async () => {
      try {
        setisLoadingFetching(true);
        const response = await clientAxios.get('/getContractDocuSign');
        const Response = response.data;
        setPdfContract(Response);
      } catch (error) {
        errorMessage('Tuvimos inconvenientes para mostrar el contrato');
      } finally {
        setisLoadingFetching(false);
      }
    };
    getDocument();
  }, []);

  const handleDownloadPdf = () => {
    hiddenElement(pdfContract, 'Contrato-Totallia.pdf');
  };
  return (
    <LoadingInvestorRoom isLoading={isLoadingFetching}>
      <Row className={styles.container}>
        <Col span={24} lg={{ span: 12 }} className={styles.containerSign}>
          <h4>Firma de contrato manual</h4>
          {
            infoAlert(
              'Información.',
              'Aquí podra descargar el documento, firmarlo, luego subir una imagen o pdf del documento firmado.',
              'info'
            )
          }
          <div className={styles.containerButton}>
            <div onClick={handleDownloadPdf}>
              <img
                src={imgDownload}
                alt="archivos descargables"
                className={styles.imgDownload}
              />
              <a href="/#" download>
                Descargar <br /> contrato
              </a>
            </div>
            <UploadContract nameButton="Subir contrato" setIsFile={setIsFile} />
          </div>
          <div>
            <Button
              type="primary"
              className={styles.btnConfirm}
              onClick={() => setTabSelected('tab3')}
              disabled={isFile}
            >
              Enviar contrato firmado
            </Button>
          </div>
          <Button danger className={styles.btnConfirm} onClick={handleManualContract}>
            Volver a opciones de firma
          </Button>
        </Col>
        <Col span={24} lg={{ span: 12 }} className={styles.containerPdfReader}>
          <PdfReader pdfFile={pdfContract} />
        </Col>
      </Row>
    </LoadingInvestorRoom>
  );
};

export default ManualContract;
