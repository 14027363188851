import React, { useState, useEffect } from 'react';
import { Button, Row, Col, InputNumber, Form, Tooltip } from 'antd';
import clientAxios from 'config/clientAxios';
import { infoAlert } from 'utils/alertMessageHelper/alertMessage';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { formatter, parser } from 'utils/formatParseHelper/formatParseHelper';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import styles from './formStepOne.module.css';
import { errorModal } from 'utils/modalMessageHelper/modalMessage';
import { setLocalStorage } from 'utils/localStorageHelper/localHelper';

const FormStepOne = ({ idInvestment, setTabSelected }) => {
  const [investment, setInvestment] = useState({});
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoadingAmount, setIsLoadingAmount] = useState(false);

  useEffect(() => {
    const handleGetInvestment = async () => {
      try {
        setIsLoadingFetch(true);
        const response = await clientAxios.get(`/posted/${idInvestment}`);
        const allInvestments = response.data[0];
        setInvestment(allInvestments);
      } catch (error) {
        errorMessage('Tuvimos inconvenientes para actualizar los datos.');
      } finally {
        setIsLoadingFetch(false);
      }
    };
    handleGetInvestment();
  }, [idInvestment]);

  const handleAmountOffered = async (value) => {
    if (
      value.amount >= investment?.minInvestment &&
      value.amount <= investment?.complementaryInformation?.maxAmount
    ) {
      try {
        setIsLoadingAmount(true);
        const dataToSend = {
          investmentId: idInvestment,
          amountOffered: value.amount,
        };
        setLocalStorage('amount', value.amount)
        let response = await clientAxios.post(`/user/offers`, dataToSend);
        let data = response.data;
        setLocalStorage('offerId', data.offer._id);

        setTabSelected('tab2')
      } catch (error) {
        errorMessage('error al actualizar los datos.');
      } finally {
        setIsLoadingAmount(false);
      }
    } else {
      errorModal(
        <div>
          <h5>Su oferta no se pudo guardar.</h5>
          <p>
            Por favor verifique el monto que este entre los rangos mínimos y
            máximos de la inversión.
          </p>
        </div>
      );
    }
  };

  return (
    <LoadingInvestorRoom isLoading={isLoadingFetch}>
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        className={styles.container}
      >
        <Col span={24} lg={{ span: 24 }}>
          <h4>PASO 1: Ingrese el monto a invertir</h4>
          {infoAlert(
            <h5>Información.</h5>,
            <div>
              <p>
                {`El monto mínimo para la inversion es de:
            ${currentFormat(investment?.minInvestment)}`}
              </p>
              <p>
                {`El monto máximo para la inversion es de:
          ${currentFormat(investment?.complementaryInformation?.maxAmount)}`}
              </p>
            </div>
          )}
        </Col>
        <Col span={24} lg={{ span: 24 }}>
          <Form onFinish={handleAmountOffered}>
            <Tooltip title="No ingresar puntos ni comas">
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el monto ofertado',
                  },
                ]}
              >
                <InputNumber
                  className={styles.inputNumber}
                  formatter={formatter}
                  parser={parser}
                />
              </Form.Item>
            </Tooltip>
            <div className={styles.containerButton}>
              <Button
                loading={isLoadingAmount}
                type="primary"
                htmlType="submit"
                className={styles.btnConfirm}
              >
                Siguiente paso
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </LoadingInvestorRoom>
  );
};

export default FormStepOne;
