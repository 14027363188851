import { useState } from 'react';
import { Col, Row, Table } from 'antd';
import { InProgressStructure } from '../tables-structure/InProgressStructure';
import UploadModal from '../../../components/uploadModal/UploadModal';
import AttachmentModal from '../../../components/attachmentModal/AttachmentModal';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import { useHistory } from 'react-router-dom';


const InProgress = ({ data, setInvestmentsInProgress, isLoading }) => {
  const [uploadModalState, setUploadModalState] = useState(false);
  const [offer, setOffer] = useState({});
  const [attachmentModalStatus, setAttachmentModalStatus] = useState(false);
  const history = useHistory();

  const toggleUploadModal = () => setUploadModalState(!uploadModalState);

  const toggleAttachmentModal = () =>
    setAttachmentModalStatus(!attachmentModalStatus);

  const handleOffer = (offer) => setOffer(offer);
  const deleteAttachmentFromOffer = async (attachment) => {
    try {
      const offerCopy = offer;
      offerCopy.attachments = offerCopy.attachments.filter(
        (att) => att._id !== attachment._id
      );
      offerCopy.documentation = offerCopy.documentation.filter(
        (att) => att._id !== attachment._id
      );
      handleOffer(offerCopy);
      await clientAxios.put(`/user/offers/${offer._id}`, offer);
      const response = await clientAxios.get('/user/offers');
      setInvestmentsInProgress(response.data);
      toggleAttachmentModal();
    } catch (error) {
      errorMessage('No se pudo borrar el adjunto');
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <UploadModal
            uploadModalState={uploadModalState}
            handleCancel={toggleUploadModal}
            offer={offer}
            setOffer={setOffer}
            investmentInProgress={data}
            setInvestmentsInProgress={setInvestmentsInProgress}
          />
          <AttachmentModal
            attachmentModalStatus={attachmentModalStatus}
            toggleAttachmentModal={toggleAttachmentModal}
            attachment={null}
            attachmentURL={null}
            deleteAttachmentFromOffer={deleteAttachmentFromOffer}
          />
          <Table
            columns={InProgressStructure(
              history
            )}
            dataSource={data}
            loading={isLoading}
            rowKey="_id"
            scroll={{ x: 1000 }}
            pagination={{ position: ['bottomCenter'], defaultPageSize: 4 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InProgress;
