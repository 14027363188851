import moment from 'moment';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { formatMoney } from 'utils/currentFormatHelper/currentFormatHelper';

export const basicColumns = {
  columnName: {
    title: 'Nombre',
    dataIndex: 'investment',
    key: 'name',
    enable: true,
    render: (investment) => (
      <div className="container">
        <div className="row">
          <p className="m-0">{investment?.name}</p>
        </div>
        {investment?.isPosted && (
          <Link
            to={`/investments/detailInvestment/${investment?._id}`}
            className="row"
          >
            <p className="m-0">
              Ver detalle{' '}
              <ArrowRightOutlined
                style={{ display: 'inline-flex', fontSize: '10px' }}
              />
            </p>
          </Link>
        )}
      </div>
    ),
  },
  columnInvestmentType: {
    title: 'Tipo de inversión',
    dataIndex: 'investment',
    key: 'investmentType',
    enable: true,
    render: (investment) => investment?.investmentType,
  },
  columnAmountOffered: {
    title: 'Monto Total Invertido',
    className: 'text-center',
    dataIndex: 'amountOffered',
    key: 'amountOffered',
    enable: true,
    render: (amountOffered) => formatMoney(amountOffered),
  },
  columnStartDate: {
    title: 'Fecha de inicio',
    dataIndex: 'investment',
    key: 'startDate',
    enable: true,
    render: (key) => <>{moment(key?.createdAt).format('YYYY-MM-DD')}</>,
  },
  columnfinishDate: {
    title: 'Fecha de finalización',
    dataIndex: 'investment',
    key: 'finishDate',
    enable: true,
    render: (key) => <>{moment(key?.updatedAt).format('YYYY-MM-DD')}</>,
  },
};
