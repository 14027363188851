import { Form, Input, Button, Row, Col, DatePicker, Switch } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import clientAxios from 'config/clientAxios';
import {
  warningMessage,
  successMessage,
  errorMessage,
} from 'utils/messageHelper/messageHelper';
import LoadingComponent from 'components/Loader/LoadingComponent';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

const FormVideoCanal = () => {
  const history = useHistory();
  const { id } = useParams();
  const [videoEdit, setVideoEdit] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPost, setIsPost] = useState(false);

  const handleSwitchIsPosted = () => {
    setIsPost((state) => !state);
  };

  useEffect(() => {
    if (id) {
      const fetchNewsletter = async () => {
        try {
          setIsLoading(true);
          const res = await clientAxios.get(`/videoCanal/${id}`);
          let video = res.data;
          const dischargeDate = moment(video.dischargeDate);
          video = { ...video, dischargeDate };
          setVideoEdit(video);
          setIsPost(video.isPosted);
          setIsLoading(false);
        } catch (error) {
          warningMessage('Error al cargar el newsletter');
        }
      };
      fetchNewsletter();
    }
  }, [id]);

  const saveVideo = async (values) => {
    try {
      setIsLoading(true);
      await clientAxios.post('/videoCanal', values);
      successMessage('Video creado exitosamente');
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un error al crear el video');
    } finally {
      setIsLoading(false);
    }
  };

  const editVideo = async (values) => {
    try {
      setIsLoading(true);
      await clientAxios.put(`/videoCanal/${id}`, values);
      successMessage('Video actualizado exitosamente');
      setIsLoading(false);
      history.goBack();
    } catch (error) {
      errorMessage('Hubo un error al actualizar el video');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row className="bg-title-nuevaInversion">
        <Col sm={{ span: 8 }}>
          <h3> {id ? 'Editar Newsletter' : 'Agregar Newsletter'} </h3>
        </Col>
      </Row>
      <LoadingComponent isLoading={isLoading}>
        <Form
          name="formBank"
          className={styles.formBank}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={id ? videoEdit : { isPosted: false }}
          onFinish={id ? editVideo : saveVideo}
        >
          <Form.Item label="Estado" name="isPosted">
            <Switch
              checked={isPost}
              checkedChildren="Publicado"
              unCheckedChildren="No publicado"
              onChange={() => handleSwitchIsPosted()}
            />
          </Form.Item>
          <Form.Item label="Fecha" name="dischargeDate">
            <DatePicker
              format={dateFormat}
              style={{ width: '300px' }}
              locale={locale}
            />
          </Form.Item>
          <Form.Item
            label="Nombre"
            name="name"
            rules={[
              {
                required: true,
                message: 'Ingrese el nombre del video',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Link del video"
            name="youtubeLink"
            rules={[
              {
                required: true,
                message: 'Ingrese un link de youtube',
              },
            ]}
          >
            <Input placeholder="http://www.example.com/ o www.example.com/news"/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.btnAddBank}
            >
              Guardar
            </Button>
          </Form.Item>
        </Form>
      </LoadingComponent>
    </>
  );
};

export default FormVideoCanal;
