import { WhatsAppOutlined } from '@ant-design/icons';
import styles from 'styles/contactPage/contactPage.module.css';
import logoNavbar from 'img/totallia-logo-color.svg';
import bgReceiptInfoContact from 'img/bgReceiptInfoContact.png';

const ContactSuccess = ({ handleClose }) => {
  return (
    <>
      <div className="bg-navbar d-flex justify-content-end">
        <img
          src={logoNavbar}
          style={{
            width: '150px',
            margin: '20px 60px',
          }}
          alt="logo"
        />
      </div>
      <div
        onClick={() => handleClose()}
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(${bgReceiptInfoContact})`,
          backgroundSize: 'cover',
        }}
        className="container-fluid"
      >
        <div className="container" style={{ padding: '1.5rem 0px' }}>
          <div className={`row ${styles.containerContactSuccess}`}>
            <div className="my-4 p-0 col-2"></div>
            <div className="my-4 col text-center p-0">
              <h1 style={{ color: '#fc505e', fontWeight: 'bold' }}>
                Felicitaciones!
              </h1>
              <h3 className="fw-bold">
                Uno de nuestros representantes se pondrá en contacto contigo a
                la brevedad
              </h3>
              <h3
                style={{
                  color: '#fc505e',
                  fontWeight: 'bold',
                  marginTop: '1.5rem',
                }}
              >
                Si te interesa recibir esta informacion por WhatsApp podés
                escribirnos haciendo clic en el botón
              </h3>
            </div>
            <div className="my-4 p-0 col-2"></div>
          </div>
          <div className="d-flex d-flex justify-content-center mt-4">
            <a
              href="https://wa.me/+5491154229285"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={styles.btnWtsContact}>
                <WhatsAppOutlined
                  style={{ fontSize: '1.9rem', marginRight: '10px' }}
                />
                <span>Contactar por WhatsApp</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSuccess;
