import { Row, Button, Popconfirm, Table } from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { dateFormat } from 'utils/dateFormatHelper/dateFormat';
import styles from 'styles/panelAdmin/adminBanks.module.css';
import { useHistory, useRouteMatch } from 'react-router';
import {
  errorMessage,
  successMessage,
  warningMessage,
} from 'utils/messageHelper/messageHelper';
import { useState, useEffect } from 'react';
import { classification } from './ClassificationNewsletter';
import clientAxios from 'config/clientAxios';

const Newsletter = () => {
  const [dataNewsletter, setDataNewsletter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        setIsLoading(true);
        const res = await clientAxios.get('/newsletter?delete=false');
        setDataNewsletter(res.data);
        setIsLoading(false);
      } catch (error) {
        warningMessage('Los newsletters no están disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    fetchNewsletters();
  }, []);
  
  const deleteNewsletter = async (id) => {
    try {
      await clientAxios.delete(`/newsletter/${id}`);
      window.scrollTo(0, 0);
      successMessage('Newsletter eliminado exitosamente');
      const filteredNewsletter = dataNewsletter.filter(
        (newsletter) => newsletter._id !== id
      );
      setDataNewsletter(filteredNewsletter);
    } catch (error) {
      errorMessage('Hubo un error al eliminar el newsletter');
    }
  };

  const editNewsletter = (id) => {
    history.push(`${path}/editarNewsletter/${id}`);
    window.scrollTo(0, 0);
  };

  const tableHeadings = [
    {
      title: 'Fecha',
      dataIndex: 'writerDate',
      key: 'writerDate',
      render: (key) => {
        const dateNewFormat = dateFormat(key);
        return <p>{dateNewFormat}</p>;
      },
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Clasificación',
      dataIndex: 'classification',
      key: 'classification',
      render: (key) => {
        const classificationRender = classification[key].name;
        return <p>{classificationRender}</p>;
      },
    },
    {
      title: 'Escritor',
      dataIndex: 'writerName',
      key: 'writerName',
    },
    {
      title: 'Publicado',
      dataIndex: 'isPosted',
      key: 'isPosted',
      render: (key) => {
        const data = key ? 'Si' : 'No';
        return <p>{data}</p>;
      },
    },
    {
      title: 'Artículo',
      dataIndex: 'link',
      key: 'link',
      render: (key) => {
        return (
          <a href={key} target="_blank" rel="noopener noreferrer">
            {' '}
            Ver artículo
          </a>
        );
      },
    },
    {
      title: 'Acciones',
      dataIndex: '_id',
      key: '_id',
      render: (key) => (
        <>
          <Button
            style={{ border: 'none' }}
            size="small"
            icon={<EditOutlined />}
            onClick={() => editNewsletter(key)}
          />
          <Popconfirm
            title="¿Seguro desea eliminar el newsletter?"
            okText="Si"
            cancelText="No"
            onConfirm={() => deleteNewsletter(key)}
          >
            <Button
              style={{ border: 'none' }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];
  const addNewsletter = () => {
    history.push(`${path}/newsletter`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Row justify="space-between" className="bg-title-nuevaInversion">
        <h3>Newsletters</h3>
        <Button
          onClick={addNewsletter}
          icon={<PlusCircleOutlined />}
          className={styles.btnAddBank}
        >
          Crear Newsletter
        </Button>
      </Row>
      <Row justify="center">
        <Table
          rowKey="_id"
          dataSource={dataNewsletter}
          columns={tableHeadings}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 10 }}
          loading={isLoading}
          scroll={{ x: 1000 }}
        />
      </Row>
    </>
  );
};

export default Newsletter;
