import style from '../../styles/AboutUs/aboutUs.module.css';
import { Row, Col } from 'antd';
import { missions } from './data/missions';

const Mission = () => {
  return (
    <>
      <Row className={style.rowMission} id="sectionMision">
        <h3 className={style.titleMission}>Misión</h3>
      </Row>
      <Row justify='center' className='ms-5 me-5'>
        {missions.map((mission) => (
          <Col md={10} lg={6}>
            <Row className={`pe-4 ${style.rowsIcons}`} align='middle'>
              <Col span={8} xs={5} md={9} lg={8}>
                <img src={mission.icon} alt="icono" className={style.icons} />
              </Col>
              <Col span={16} xs={19} md={15} lg={16}>
                <h5 className={style.h4Icons}>{mission.title}</h5>
                <p className={style.pIcons}>{mission.content}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default Mission;
