import { Button, Tooltip } from 'antd'
import { FileAddOutlined } from '@ant-design/icons';
import { basicColumns } from './BasicStructure'

export const ActiveStructure = (handleAttachmentId, handleShowAdditionalDocs) => [
  basicColumns.columnName,
  basicColumns.columnInvestmentType,
  basicColumns.columnAmountOffered,
  basicColumns.columnStartDate,
  {
    title: "Contrato",
    key: 'contract',
    enable: true,
    render: (record) => record?.attachments.length !== 0 
      ? (<Button type="primary" onClick={() => {
      const attachment = record?.attachments?.filter(
        (attachment) => attachment.name === 'envelopeId' || attachment.name === 'Contrato'
      )
      handleAttachmentId(attachment[0]._id)}}>Ver</Button>)
      : <Button type="primary" disabled >Ver</Button>
  },
  {
    align: 'center',
    width: 10,
    title: 'Archivos adicionales',
    key: 'additionalDocumentation',
    enable: true,
    render: (record) => (
      <Button
        type="primary"
        icon={<FileAddOutlined />}
        onClick={() => handleShowAdditionalDocs(true, record)}
      />
    ),
  },
  {
    title: "Renta",
    key: 'action',
    enable: true,
    render: (record) => record?.rents.length !== 0 
    ? <Button type="primary">Ver</Button>
    : <Tooltip placement='left' title='No hay renta disponible' >
        <Button type="primary" disabled >Ver</Button>
      </Tooltip>
  }
];
