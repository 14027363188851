import { Row, Col, Carousel, Card, Button, Collapse, Spin, Skeleton } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import ModalDownloads from './ModalDownloads';
import ModalImages from './ModalImages';
import styles from '../../styles/investments/investmentDetail.module.css';
import img1 from '../../img/imgDescriptionInvestment.jpg';
import arrowDown from '../../img/icons/Trazado232.svg';
import arrowLeft from '../../img/icons/Trazado233.svg';
import arrowRight from '../../img/icons/Trazado234.svg';
import imgDownload from '../../img/icons/Grupo106.svg';
import { useState, useEffect } from 'react';
import { currentFormat } from 'utils/currentFormatHelper/currentFormatHelper';
import moment from 'moment';
import { removeLocalStorage } from 'utils/localStorageHelper/localHelper';
import { useHistory } from 'react-router-dom';

const ContentInvestment = ({ investment }) => {
  const [imgLoading, setImgLoading] = useState(true);
  const [openModalDownloads, setOpenModalDownloads] = useState(false);
  const handleModalDownloads = () => setOpenModalDownloads((state) => !state);
  const [openModalImages, setOpenModalImages] = useState(false);
  const handleModalImages = () => setOpenModalImages((state) => !state);
  const attachments = investment && investment?.attachments;
  const { Panel } = Collapse;
  const iconDownload = [1, 2, 3];
  const history = useHistory();

  useEffect(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), []);

  useEffect(() => {
    if (attachments) {
      setImgLoading(false);
    }
  }, [attachments]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <img src={arrowRight} alt="flecha" className={styles.arrows} />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <img src={arrowLeft} alt="flecha" className={styles.arrows} />
      </div>
    );
  };

  const embedYoutubeUrl = () => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = investment?.youtubeVideo ? investment?.youtubeVideo.match(regExp) : "";
    return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : "";
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <ModalImages
        openModalImages={openModalImages}
        handleModalImages={handleModalImages}
        attachments={attachments}
      />
      <ModalDownloads
        openModalDownloads={openModalDownloads}
        handleModalDownloads={handleModalDownloads}
        attachments={attachments}
      />
      <Spin size="large" spinning={imgLoading}>
        <Row className={styles.containerGray}>
          {attachments && <img
            src={attachments?.backgroundImg || img1}
            alt="edificio"
            className={styles.imgCover}
          />}
          {
            !attachments
              ? <div className={styles.boxInfoInvestment}>
                <Skeleton active />
                <a href="#indicators">
                  <img src={arrowDown} alt="flecha" className={styles.arrowDown} />
                </a>
              </div>
              : <div className={styles.boxInfoInvestment}>
                <h3 className={styles.title}>{investment?.name}</h3>
                <p className={styles.p1}>{investment?.subtitle}</p>
                <a href="#indicators">
                  <img src={arrowDown} alt="flecha" className={styles.arrowDown} />
                </a>
              </div>
          }
        </Row>
      </Spin>
      <div className={styles.containerCarousel}>
        <Carousel arrows {...settings}>
          {attachments?.galleryImg.map((src, index) => (
            <div key={index} className={styles.itemCarousel}>
              <img src={src} alt="imágenes de inversiones" />
            </div>
          ))}
        </Carousel>
        <Row className={styles.rowBtn}>
          <Button
            type="text"
            className={styles.viewImg}
            onClick={handleModalImages}
          >
            Ver imágenes
          </Button>
        </Row>
      </div>
      <Row>
        <Col lg={10} md={24} className={styles.col1}>
          <h4 id="indicators" className={styles.h4Indicators}>
            Principales indicadores
          </h4>
          <Row>
            <Col span={12} className={styles.colIndicators}>
              <div>
                <p className={styles.pTitle}>Renta Anual</p>
                <p className={styles.pText}>{investment?.revenue}</p>
              </div>
              <div>
                <p className={styles.pTitle}>TIR</p>
                <p className={styles.pText}>{investment?.irr} %</p>
              </div>
              <div>
                <p className={styles.pTitle}>Apreciación</p>
                <p className={styles.pText}>{investment?.appreciation} x</p>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <p className={styles.pTitle}>Plazo</p>
                <p className={styles.pText}>{`${investment?.termInYears} añ${investment?.termInYears > 1 ? 'os' : 'o'
                  }`}</p>
              </div>
              <div>
                <p className={styles.pTitle}>Inversión minima</p>
                <p className={styles.pText}>
                  {currentFormat(investment?.minInvestment)}
                </p>
              </div>
              <div>
                <p className={styles.pTitle}>Fecha de cierre</p>
                <p className={styles.pText}>
                  {moment(
                    investment?.complementaryInformation?.finishDate
                  ).format('l')}{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={14} md={24} className={styles.col2}>
          <h4 className={styles.h4Proyect}>El proyecto</h4>
          <p className={styles.pProyect}>{investment?.proyect}</p>
          <div className={styles.p2Proyect}>
            <p className={styles.p3}>Sponsor: {investment?.sponsor}</p>
            <Row>
              <Col>
                {investment?.country?.map((country, index) => (
                  <p key={index} className={styles.p3}>
                    {country}
                    {',  '}
                  </p>
                ))}
              </Col>
              <Col>
                {investment?.city?.map((city, index) => (
                  <p key={index} className={styles.p3}>
                    {city}
                  </p>
                ))}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {investment?.youtubeVideo ? (
        <Row>
          <Col lg={12} md={24} className={styles.colCards}>
            <Row className={styles.justifyCenter}>
              <Card className={styles.card} bodyStyle={{ padding: '0px' }}>
                <h5 className={styles.titleDownload}>Descargas</h5>
                <p className={styles.pDownload}>
                  Lanzamiento {investment?.name}
                </p>
                <div className={styles.divDownloads}>
                  {iconDownload.map((icon, index) => (
                    <img
                      key={index}
                      src={imgDownload}
                      alt="archivos descargables"
                      className={styles.imgDownload}
                    />
                  ))}
                </div>
                <div className={styles.btnDownload}>
                  <Button
                    type="text"
                    className={styles.btnName}
                    onClick={handleModalDownloads}
                  >
                    Descargar
                  </Button>
                </div>
              </Card>
            </Row>
          </Col>
          <Col lg={12} md={24} className={styles.colCards}>
            <Row className={styles.justifyCenter}>
              <Card className={styles.card} bodyStyle={{ padding: '6px' }}>
                <iframe
                  className={styles.video}
                  src={
                    embedYoutubeUrl() &&
                    embedYoutubeUrl().includes('https://www.youtube.com/embed/')
                      ? embedYoutubeUrl()
                      : null
                  }
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Card>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row justify='center'>
          <Col lg={12} md={24} className={styles.colCards}>
            <Row className={styles.justifyCenter}>
              <Card className={styles.card} bodyStyle={{ padding: '0px' }}>
                <h5 className={styles.titleDownload}>Descargas</h5>
                <p className={styles.pDownload}>
                  Lanzamiento {investment?.name}
                </p>
                <div className={styles.divDownloads}>
                  {iconDownload.map((icon, index) => (
                    <img
                      key={index}
                      src={imgDownload}
                      alt="archivos descargables"
                      className={styles.imgDownload}
                    />
                  ))}
                </div>
                <div className={styles.btnDownload}>
                  <Button
                    type="text"
                    className={styles.btnName}
                    onClick={handleModalDownloads}
                  >
                    Descargar
                  </Button>
                </div>
              </Card>
            </Row>
          </Col>
        </Row>
      )}
      <Row className={styles.justifyCenter}>
        <h4 className={styles.titleDetailInvestment}>
          Detalles de la inversión
        </h4>
      </Row>
      <Row className={styles.justifyCenter}>
        <Collapse
          accordion
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <ArrowDownOutlined
              rotate={isActive ? 180 : 0}
              style={{ color: '#142054', fontSize: '20px' }}
            />
          )}
          className={styles.collapse}
          ghost
        >
          {investment?.sections?.map((section, index) => (
            <Panel
              header={
                <div dangerouslySetInnerHTML={{ __html: section?.title }} />
              }
              key={index}
              className={styles.panel}
            >
              <div dangerouslySetInnerHTML={{ __html: section?.text }} />
            </Panel>
          ))}
        </Collapse>
      </Row>
      {investment?.isOpen ? (
        <Row className={styles.justifyCenter}>
          <Button
            type="text"
            className={styles.btnToInvest}
            onClick={() => {
              removeLocalStorage('amount');
              removeLocalStorage('offerId');
              removeLocalStorage('investmentId');
              history.push(
                `/investorRoom/investments/detail/${investment._id}/investNow/`
              );
            }}
          >
            Invertir ahora
          </Button>
        </Row>
      ) : (
        <Row className={styles.marginRowBtnDisable}></Row>
      )}
    </>
  );
};
export default ContentInvestment;
