import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Inversiones from "../tabs/InversionesAnt";
import NuevaInversion from "../NewInvestment/NuevaInversionAnt";
import DistributionsTab from "../distributions/DistributionsTab";
import DistributionById from '../distributions/DistributionById'
import NewDistribution from '../distributions/NewDistribution'

const InversionRoutes = () => {
  let { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route path={`${path}/nuevaInversion`} component={NuevaInversion} />
      <Route path={`${path}/editarInversion/:id`} component={NuevaInversion} />
      <Route path={`${path}/distribuciones/:id/:inv`} component={DistributionsTab} />
      <Route path={`${path}/distribucion/:id`} component={DistributionById} />
      <Route path={`${path}/editarDistribucion/:id`} component={NewDistribution} />
      <Route path={`${path}/newDistribution/:inv`} component={NewDistribution} />
      <Route path={path} component={Inversiones} />
    </Switch>
  );
};

export default InversionRoutes;
