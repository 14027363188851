import { Modal, Divider, Row } from 'antd';
import imgDownload from 'img/icons/Grupo106.svg';
import styles from 'styles/investments/investmentDetail.module.css';
import style from 'styles/investments/modalsDetailInvestment.module.css';

const ModalDownloads = ({
  handleModalDownloads,
  openModalDownloads,
  attachments,
}) => {
  return (
    <Modal
      visible={openModalDownloads}
      onCancel={handleModalDownloads}
      footer={null}
      bodyStyle={{ background: '#f5f5f5' }}
    >
      <Divider orientation="left" plain>
        Archivos Descargables
      </Divider>
      <Row>
        {attachments?.downloadFiles?.map((file) => (
          <div className={style.divDownload}>
            <img
              key={file.length}
              src={imgDownload}
              alt="archivos descargables"
              className={styles.imgDownload}
            />
            <a href={file} download className={style.aDownload}>
              Descargar
            </a>
          </div>
        ))}
      </Row>
      <Divider orientation="left" plain>
        Contratos
      </Divider>
      <Row>
        {attachments?.contracts?.map((file) => (
          <div className={style.divDownload}>
            <img
              key={file.length}
              src={imgDownload}
              alt="archivos descargables"
              className={styles.imgDownload}
            />
            <a href={file} download className={style.aDownload}>
              Descargar
            </a>
          </div>
        ))}
      </Row>
      {
        attachments?.termAndConditions && 
        <>
          <Divider orientation="left" plain>
            Terminos y condiciones
          </Divider>
          <Row>
            <div className={style.divDownload}>
              <img
                src={imgDownload}
                alt="archivos descargables"
                className={styles.imgDownload}
              />
              <a
                href={attachments?.termAndConditions}
                download
                className={style.aDownload}
              >
                Descargar
              </a>
            </div>
          </Row>
        </>
      }
    </Modal>
  );
};
export default ModalDownloads;
