import { Modal } from 'antd';
import style from './modalPassport.module.css'
import { Document, Page } from 'react-pdf';


const getURLFileExtension = (url) => {
  return url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
};

const pageNumber = 1;

const ModalPassport = ({
  isModalPassport,  
  handleCancel,
  passportImg,
}) => {
  return (
    <Modal
      title={null}
      visible={isModalPassport}      
      onCancel={handleCancel}
      style={{ textAlign: 'center' }}
      footer={null}
      width={1000}
    >
      {getURLFileExtension(passportImg) === 'pdf' ? (
        <>
          <Document
            file={{ url: passportImg }}
            
          >
             <Page pageNumber={pageNumber} />
          </Document>
        </>
      ) : (
        <>
          {passportImg ? (
            <img src={passportImg} alt="Imágen de pasaporte" className={style.passport} />
          ) : (
            <h1>El usuario no subio el Documento Identidad</h1>
          )}
        </>
      )}
      
    </Modal>
  );
};

export default ModalPassport;
