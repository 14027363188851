import { DualAxes } from '@ant-design/charts';

const DualAxesGraph = ({ responseDataReport }) => {
  const config = {
    data: [responseDataReport?.barGraphData, responseDataReport?.lineGraphData],
    xField: 'time',
    yField: ['value', 'count'],
    geometryOptions: [
      {
        geometry: 'column',
        isGroup: true,
        seriesField: 'type',
      },
      {
        geometry: 'line',
        lineStyle: { lineWidth: 2 },
      },
    ],
  };
  return <DualAxes {...config} />;
};

export default DualAxesGraph;
