import React, { useEffect } from 'react';
import { useQueryHash } from 'custom/hooks/queryHook';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  setSessionStorage,
  getSessionStorage,
} from 'utils/sessionStorageHelper/sessionHelper';
import { getDocuSignLinkAuth } from 'utils/docuSignLinkAuth/getDocuSignLinkAuth';
import { actionRedirect } from 'utils/actionRedirectHelper/actionRedirect';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';

const GetTokenDocuSign = () => {
  const query = useQueryHash();
  const history = useHistory();
  const { url } = useRouteMatch();

  const token = query.get('access_token');

  useEffect(() => {
    const handleGetDocuSign = () => {
      if (token) {
        setSessionStorage('token', token);
        console.log('test');
        history.push(`${url}/docuSign`);
      }
    };
    handleGetDocuSign();
  }, [token, history, url]);

  useEffect(() => {
    if (getSessionStorage('token')) {
      history.push(`${url}/docuSign`);
    } else {
      const redirectUrl = getDocuSignLinkAuth();
      actionRedirect(redirectUrl);
    }
  }, [history, url]);

  return <LoadingInvestorRoom isLoading={true}></LoadingInvestorRoom>;
};

export default GetTokenDocuSign;
