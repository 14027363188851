import TheNavbar from '../TheNavbar';
import Footer from '../Footer';
import AllArticles from './AllArticles';
import { useState } from 'react';
import { Row } from 'antd';
import style from '../../styles/Aprender/aprender.module.css';

const ArticlePage = ({ user, setUser }) => {
  const [isModalRegisterVisible, setIsModaRegisterlVisible] = useState(false);
  const handleToggleRegister = () =>
    setIsModaRegisterlVisible((state) => !state);
  return (
    <>
      <TheNavbar
        user={user}
        setUser={setUser}
        isModalRegisterVisible={isModalRegisterVisible}
        handleToggleRegister={handleToggleRegister}
      />
      <Row justify="center" className={style.rowAllArticles}>
        <AllArticles />
      </Row>
      <Footer />
    </>
  );
};

export default ArticlePage;
