import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import imgPdf from "../../../../img/iconoPDF..png";

const ImagePrev = ({
  attachment,
  type,
  name,
  handleUpdateInvestmentAttachment,
}) => {
  const attachmentArray = Array.isArray(attachment) ? attachment : [attachment];

  const deleteAttachment = (attachmentDelete) => {
    const attachmentFiltered = attachmentArray.filter(
      (attachmentFilter) => attachmentFilter !== attachmentDelete
    );
    const deleteResult = Array.isArray(attachment) ? attachmentFiltered : '';
    const attachmentUpdate = { [name]: deleteResult };
    handleUpdateInvestmentAttachment(attachmentUpdate);
  };
  
  return attachmentArray.map((attachment) => (
    <div className="div-image-prev">
      {attachment && type === "image" && (
        <>
          <img src={attachment} alt="Adjuntos" className="image-prev" />
          <Button
            icon={<DeleteOutlined />}
            size="small"
            className="btn-image-prev"
            onClick={() => deleteAttachment(attachment)}
          />
        </>
      )}
      {attachment && type === "download" && (
        <>
          <a href={attachment} download>          
            <img
              src={imgPdf}
              alt="Descargar PDF"
              className="image-prev image-prev-pdf"
            />
          </a>
          <Button
            icon={<DeleteOutlined />}
            size="small"
            className="btn-image-prev"
            onClick={() => deleteAttachment(attachment)}
          />
        </>
      )}
      {attachment && type === "video" && (
        <>
          <video src={attachment} className="image-prev" />
          <Button
            icon={<DeleteOutlined />}
            size="small"
            className="btn-image-prev"
            onClick={() => deleteAttachment(attachment)}
          />
        </>
      )}
    </div>
  ));
};

export default ImagePrev;
