import { Modal, Carousel } from 'antd';
import style from '../../styles/investments/modalsDetailInvestment.module.css';

const ModalImages = ({ openModalImages, handleModalImages, attachments }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <Modal
      visible={openModalImages}
      onCancel={handleModalImages}
      footer={null}
      width={700}
    >
      <div>
        <Carousel {...settings}>
          {attachments?.galleryImg?.map((src) => (
            <div key={src.length} className={style.galleryImg}>
              <img src={src} alt="" />
            </div>
          ))}
        </Carousel>
      </div>
    </Modal>
  );
};

export default ModalImages;
