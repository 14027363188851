import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Table, Button, DatePicker , message, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {tableNewDistributions} from './table-Structure/NewDistribution'
import {tableDistributionsById} from './table-Structure/DistributionsById'
import clientAxios from '../../../../config/clientAxios';
import moment from 'moment';

export default function NewDistribution() {
  const history = useHistory();
  const { id, inv } = useParams();

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState({});
  const [amountToDivide, setAmountToDivide] = useState(null);
  const [calcRentCompleted, setcalcRentCompleted] = useState(true);
  const [rents, setRents] = useState([])
  const [success, setSuccess] = useState(null)
  const [createdAt, setCreatedAt] = useState('');

  useEffect(() => {
    if (id !== undefined) {
      const getDistribution = async (id) => {
        try {
          setLoading(true);
          const dataDistribution = await clientAxios.get(`/distributions/${id}`);
          await getInvestment(dataDistribution?.data?.investment?._id);
          const rentsData = dataDistribution?.data?.rents.map((data) => {
            return {
              offer: data._id,
              rent: data.rent,
              share: data.share,
            } 
          })
          setRents(rentsData);
          setOffers(dataDistribution?.data?.rents)
          setAmountToDivide(dataDistribution?.data?.amountTotal)
          setCreatedAt(moment(dataDistribution?.data?.createdAt))
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
      getDistribution(id);
    }else{
      const getOffers = async (id) => {
        try {
          setLoading(true);
          const dataOffer = await clientAxios.get(`/offers/investment/${id}`);
          const rentWrapped = dataOffer?.data?.map(offer => { 
            return { offer: offer._id, share: offer.share }
          })
          await getInvestment(inv);
          setOffers(dataOffer?.data);
          setRents(rentWrapped)
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      };
      getOffers(inv);
    }
  }, [id, inv]);

  const getInvestment = async (id) => {
    try {
      const res = await clientAxios.get(`/investments/${id}`);
      const dataInvestment = res.data;
      setInvestment(dataInvestment[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateOnChange = (date) => date !== undefined ? setCreatedAt(moment(date)) : setCreatedAt('');

  const handleShareOnChange = (id, value) => {
    const share = rents.find((s)=> s.offer === id )
    if (share !== undefined) {
      const allShare = rents.filter((s)=> s.offer !== id ); 
      share.share = value;  
      setRents([...allShare, share])
      share?.rent >= 0 && addRentsInTable([...allShare, share]);
    }else{
      setRents([...rents, { offer: id , share: value }]) 
    }
  };
  const handleRentsOnChange = (name, value) => {
    const rent = rents.find((r)=>r.offer === name)
    if (rent !== undefined) {
      const allRents = rents.filter((r)=>r.offer !== name); 
      const share = ((value / amountToDivide) * 100).toFixed(2)
      rent.rent = value;
      rent.share = parseFloat(share);
      setRents([...allRents, rent]) 
      addRentsInTable([...allRents, rent]);
    }
  }

  const calculateRent = () =>  {
    if (amountToDivide === null) { return message.error('Ingrese un monto a dividir'); }
    const dataRents = rents.map(s=>{
        const rent = ((s.share / 100) * amountToDivide).toFixed(2);
        return {
            ...s,
            rent: parseFloat(rent),
        }
    })
    setRents(dataRents);
    
    if (dataRents.length === offers.length || id !== undefined) {
      const totalShare = rents.reduce((acc,s) => acc + s.share, 0);
      if (totalShare === 100) {
        addRentsInTable(dataRents);
        setcalcRentCompleted(false);
        setSuccess(true);
        message.success('Se ha caculado la renta de forma exitosa!');
      }else{
        setSuccess(false);
        message.error('El share no puede ser mayor o menor al 100%.')
      }
    }else{
      setSuccess(false);
      message.error('Complete todos los campos antes de "Calcular Renta".')
    }
  }

  const addRentsInTable = (rents) => {
    const offersWithRents = offers.map((o)=>{
        const offer = rents.filter((r)=> r.offer === o._id)
        return {
          ...o,
          rent: parseFloat(offer[0].rent),
          share: offer[0].share,
        }
    })
    setOffers(offersWithRents);
  }

  const confirmChanges = async () => {
    try {
      if (amountToDivide === null) { return message.error('Ingrese un monto a dividir'); }
      if (createdAt === '') {
        return message.error('Seleccione la fecha de distribucion.');
      }
      const totalRents = rents.reduce((acc,r)=>acc + r.rent,0);
      if (totalRents <= amountToDivide) {
        setSuccess(true);
        setLoading(true);
        const distribucion = await clientAxios.post('/distributions', {
          investment: investment?._id,
          rents,
          amountTotal: amountToDivide,
          createdAt,
        }) 
        if (distribucion.status === 200) {
          setLoading(false);
          message.success('Felicidades, se ha completado la distribucion!');
          setcalcRentCompleted(true);
          return setTimeout(() => history.goBack(), 1000);
        }
      }else{
        setSuccess(false);
        setLoading(false);
        message.error('El total de renta supera el monto total, corrija los valores');
      }
    } catch (error) {
      setLoading(false);
      message.error('Ha ocurrido un error inesperado, intentelo de nuevo.')
      console.log(error);
    }
  };

  const updateDistribution = async () => {
    try {
      if (amountToDivide === null) { return message.error('Ingrese un monto a dividir');}
      if (createdAt === '') {
        return message.error('Seleccione la fecha de distribucion.');
      }
      const totalRents = rents.reduce((acc,r)=>acc + r.rent,0);
      if (totalRents <= amountToDivide) {
        setSuccess(true);
        setLoading(true);
        const rentsPayload = rents.map((rent)=>{
          return {
            ...rent,
            _id: rent.offer,
          }
        })
        const updateDistribution = await clientAxios.patch(`/distributions/${id}`, {
          rents: rentsPayload,
          createdAt,
          amountTotal: amountToDivide,
        }) 
        if (updateDistribution.status === 200) {
          setLoading(false);
          message.success('Felicidades, se han guardado los cambios con exito!');
          return setTimeout(() => history.goBack(), 1000);
        }
      }else{
        setSuccess(false);
        setLoading(false);
        message.error('El total de renta supera el monto total, corrija los valores');
      }
    } catch (error) {
      setLoading(false);
      message.error('Ha ocurrido un error inesperado, intentelo de nuevo.')
      console.log(error);
    }
  };

  return (
    <div>
      <Row className="mb-4">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
        >
          <h3>{investment?.name ? investment?.name : '-'}</h3>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 8 }}
        >
          <div className="center-container">
            <ArrowLeftOutlined
              className="btn-Back-inver"
              onClick={() => history.goBack()}
            />
          </div>
        </Col>
      </Row>
      <span className='me-2 ms-1'>Fecha de Distribucion:</span>
      <DatePicker value={id && createdAt} format='YYYY/MM/DD' onChange={(e)=> handleDateOnChange(e?._d)}/>
      <>
        <div className="btn-closeInv-filter" style={{width: '13.9rem'}}>
          <span>Monto a dividir:</span>
          <InputNumber 
            className={ 
              amountToDivide === null || success === false 
              ? "ms-2 border-danger w-50 text-danger ps-3"
              : "ms-2 bg-secondary w-50 text-white ps-3" }
            value={id && amountToDivide} 
            size='small'
            onChange={(e)=> setAmountToDivide(e)} 
          />
        </div>
        <p className='btn-closeInv-filter me-5'>Total: {offers.length}</p>
        <Table
          dataSource={offers}
          columns={id !== undefined 
                    ? 
                    tableDistributionsById(handleShareOnChange, handleRentsOnChange, success, true) 
                    : 
                    tableNewDistributions(handleShareOnChange, handleRentsOnChange, success, calcRentCompleted)}
          scroll={{ x: 1000 }}
          loading={loading}
          pagination={false}
        ></Table>
        <div className='mt-2'>
          { id !== undefined ? 
            <Button 
            className='btn-closeInv-filter' 
            onClick={updateDistribution}
            >
            Guardar Cambios
            </Button>
            : 
            <Button 
            className='btn-closeInv-filter' 
            disabled={calcRentCompleted}
            onClick={confirmChanges}
            >
              Confirmar
            </Button> 
          }
              <Button 
                danger 
                className='btn-closeInv-filter' 
                onClick={calculateRent}
              >
                Calcular Renta
              </Button>
        </div>
      </>
    </div>
  );
}
