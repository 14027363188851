import {
  errorMessage,
  successMessage,
} from '../../../../utils/messageHelper/messageHelper';
import { Form, Input, Button, DatePicker, Row, Col, InputNumber } from 'antd';
import { formatter, parser } from '../../../../utils/formatParseHelper/formatParseHelper';
import { useRef } from 'react';
import moment from 'moment';
import locale from 'antd/lib/locale/es_ES';
import 'moment/locale/es';

const InfoComplementariaAnt = ({ handleInvestment, singleInvestment }) => {
  const formInfo = useRef();

  const onFinish = (values) => {
    const {
      Datestart,
      Datefinish,
      minAmount,
      maxAmount,
      signer,
      investmentStatus,
      distributionPeriod,
      urlLastReport,
      reportName,
      ReportDate,
    } = values;
    const startDate = Datestart?.format();
    const finishDate = Datefinish?.format();
    const reportDate = ReportDate?.format();
    const complementaryInformation = {
      startDate,
      finishDate,
      minAmount,
      maxAmount,
      signer,
      investmentStatus,
      distributionPeriod,
      urlLastReport,
      reportName,
      reportDate,
    };
    handleInvestment({ complementaryInformation });
    successMessage('Datos Guardados');
    window.scrollTo(0, 0);
  };
  const onFinishFailed = () => {
    errorMessage('Hubo un error al guardar los datos');
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <h4 className="title-dataPublish">Información Complementaria</h4>
        </Col>
      </Row>
      <Form
        name="basic"
        ref={formInfo}
        className="formDataPublish"
        labelCol={{
          span: 8,
        }}
        initialValues={
          Object.entries(singleInvestment).length === 0
            ? { isPosted: false }
            : singleInvestment?.complementaryInformation
        }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Fecha Inicio" name="Datestart">
          <DatePicker
            className="select-item"
            locale={locale}
            defaultValue={
              singleInvestment?.complementaryInformation?.startDate &&
              moment(singleInvestment?.complementaryInformation?.startDate)
            }
            placeholder="Seleccionar Fecha"
          />
        </Form.Item>
        <Form.Item label="Fecha de Cierre" name="Datefinish">
          <DatePicker
            className="select-item"
            locale={locale}
            defaultValue={
              singleInvestment?.complementaryInformation?.finishDate &&
              moment(singleInvestment?.complementaryInformation?.finishDate)
            }
            placeholder="Seleccionar Fecha"
          />
        </Form.Item>
        <Form.Item label="Monto Mínimo" name="minAmount">
        <InputNumber
            defaultValue={0}
            formatter={formatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item label="Monto Máximo" name="maxAmount">
          <InputNumber
            defaultValue={0}
            formatter={formatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item label="Firmante" name="signer">
          <Input />
        </Form.Item>
        <Form.Item label="Periodo de Distribución" name="distributionPeriod">
          <Input />
        </Form.Item>
        <Form.Item label="Subir url último reporte" name="urlLastReport">
          <Input />
        </Form.Item>
        <Form.Item label="Fecha de reporte" name="ReportDate">
          <DatePicker
            className="select-item"
            locale={locale}
            defaultValue={
              singleInvestment?.complementaryInformation?.reportDate &&
              moment(singleInvestment?.complementaryInformation?.reportDate)
            }
          />
        </Form.Item>
        <Form.Item label="Nombre del reporte" name="reportName">
          <Input />
        </Form.Item>
        <div className="center-container">
          <Button type="primary" htmlType="submit" className="btn-save">
            Guardar formulario
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default InfoComplementariaAnt;
