import img from 'img/bgReceiptInfoContact.png';
import styles from 'styles/contactPage/contactPage.module.css';

export default function ReceiveInfo() {
  return (
    <div
      className="container-fluid px-0"
      style={{
        backgroundColor: 'white',
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        padding: '8rem 0px',
      }}
    >
      <h1 className={`mb-0 ${styles.receibeInfoTitle}`}>
        Que son las propiedades
      </h1>
      <h1 className={styles.receibeInfoTitle}>comerciales?</h1>
      <div className={styles.receibeInfoContainer}>
        <p className="text-center m-0 pt-2" style={{ fontSize: '1.6rem' }}>
          Las propiedades comerciales incluyen a edificios multifamiliares,
          centros logísticos, centros comerciales, oficinas, hoteles, entre
          otras propiedades cuyo único objetivo es generar ingresos para los
          inversores y propietarios de las mismas.
        </p>
        <div className="d-flex d-flex justify-content-center my-5">
          <button
            className={styles.btnMoreInfo}
            onClick={() => window.scrollTo(0, 0)}
          >
            <span>Quiero recibir</span>
            <br />
            <span>más información</span>
          </button>
        </div>
      </div>
    </div>
  );
}
