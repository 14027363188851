/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Select, Row, Divider, Tooltip } from 'antd';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import styles from './formBank.module.css';
import FormItems from './FormItems';

const FormBank = ({ typeOfBank, banks }) => {
  const [typeBankSelected, setTypeBankSelected] = useState([]);
  const [bankSelected, setBankSelected] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [disabledSelect, setDisabledSelect] = useState(false);

  const { Option } = Select;

  const typeBank = {
    0: 'DoméstIco USA',
    1: 'Internacional USA',
    2: 'Europeo',
  };

  useEffect(() => {
    setIsLoading(true);
    setBankSelected({});
    if (banks?.length < 0 || !banks[0]) {
      setDisabledSelect(true);
      return setIsLoading(false);
    }
    setDisabledSelect(false);
    const bankFilter = banks[0]?.filter(({ type }) => type === typeOfBank)
    const newBankFilter = [{ _id: "", value: "", name: ""}, ...bankFilter]
    setTypeBankSelected(newBankFilter);
    setDefaultValue(newBankFilter ? newBankFilter[0] : {});
    setIsLoading(false);
  }, [typeOfBank, banks]);

  const handleChangeSelected = (value) => {
    if(value !== ""){
      const bankFilter = banks?.map((bank) =>
        bank.filter(({ _id }) => _id === value)
      );

      handleBank(bankFilter)
    }
  };
  
  const handleBank = (bankFilter) => {
    const typeBankFilter = bankFilter[0]?.map((bank) => ({
      ...bank,
      type: typeBank[bank?.type],
    }));
    setBankSelected(typeBankFilter[0]);
    setDefaultValue(bankFilter ? bankFilter[0][0] : {});
  }

  return (
    <>
      <LoadingInvestorRoom isLoading={isLoading}>
        <Row justify="center" align="middle">
          {!isLoading && (
            <>
              <label className={styles.labelBank}>Seleccione el banco:</label>
              <Tooltip 
                placement="top" 
                title={ disabledSelect ? 'No hay bancos disponibles' : ''}>
                <Select 
                  style={{ width: 250 }} 
                  disabled={disabledSelect} 
                  onChange={handleChangeSelected} 
                  value={defaultValue?._id}
                >
                  {typeBankSelected?.map((bank) => (
                    <Option key={bank?._id} value={bank?._id} onSelect={handleChangeSelected}>
                      {bank?.name}
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </>
          )}
        </Row>
        <Divider />
        <Row justify="start">
        </Row>
        <FormItems bankSelected={bankSelected} />
      </LoadingInvestorRoom>
    </>
  );
};

export default FormBank;
