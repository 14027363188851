import { Card, Row, Col } from 'antd';
import style from '../../styles/homePage/cardInvestment.module.css';
import imgCard from '../../img/imgCard.jpg';
import { useHistory, useRouteMatch } from 'react-router-dom';

const CardInvestment = ({ investment, viewMore = true }) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const viewDetailInvestment = (id) => {
    if (path === '/') {
      return history.push(`investments/detailInvestment/${id}`);
    }
    history.push(`${path}/detailInvestment/${id}`);
  };

  const thousandsSeparator = (num) => {
    let aux = String(num).split(); 
    aux[0] = aux[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return aux.join()
  }

  return (
    <Card
      hoverable
      onClick={() => viewMore && viewDetailInvestment(investment?._id) }
      className={style.card}
      style={{ width: 300 }}
      bodyStyle={{ padding: '20px' }}
      cover={
        <div>
          <img
            alt="Imagen destacada de la inversion"
            src={
              investment?.attachments?.featuredImg
                ? investment?.attachments?.featuredImg
                : imgCard
            }
            className={style.img}
          />
          <div className={investment?.isOpen ? `${style.open}` : `${style.close}`}>
            <p className={style.pOpen}>
              {investment?.isOpen ? 'Abierta' : 'Cerrada'}
            </p>
          </div>
        </div>
      }
    >
      <div>
        <h5 className={style.title}>{investment?.name}</h5>
        <Row>
          <Col span={12}>
            <div>
              <p className={style.pTitle}>Renta Anual</p>
              <p className={style.pText}>{investment?.revenue}</p>
            </div>
            <div>
              <p className={style.pTitle}>TIR</p>
              <p className={style.pText}>{investment?.irr}%</p>
            </div>
            <div>
              <p className={style.pTitle}>Apreciación</p>
              <p className={style.pText}>{investment?.appreciation}</p>
            </div>
          </Col>
          <Col span={12} className={style.col2}>
            <div>
              <p className={style.pTitle}>Plazo</p>
              <p className={style.pText}>{investment?.termInYears}</p>

            </div>
            <div>
              <p className={style.pTitle}>Inversión minima</p>
              <p className={style.pText}>USD {thousandsSeparator(investment?.minInvestment || 0)}</p>
            </div>
          </Col>
        </Row>
        <p className={style.sponsor}>
          Sponsor <span className={style.span}>{investment?.sponsor}</span>
        </p>
      </div>
    </Card>
  );
};
export default CardInvestment;
