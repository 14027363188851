import { Row, Button } from 'antd';
import style from '../../styles/homePage/contactanos.module.css';
import icon1 from '../../img/icons/Grupo98.svg';
import icon2 from '../../img/icons/Trazado102.svg';
import icon3 from '../../img/icons/Grupo99.svg';

const Contactanos = () => {
  return (
    <>
      <Row className={style.divContactanos}>
        <h5 className={style.title}>Contactanos</h5>
      </Row>
      <Row className={style.rowIcons}>
        <div className={style.divIcons}>
          <img src={icon1} alt="" />
          <a href="https://wa.me/3815986532" target="_blank" rel="noreferrer">
            <Button type="text">
              Chateá con <br /> un representante
            </Button>
          </a>
        </div>
        <div className={style.divIcons}>
          <img src={icon2} alt="" />
          <a href="/contacto">
            <Button type="text">Escribinos ahora</Button>
          </a>
        </div>
        <div className={style.divIcons}>
          <img src={icon3} alt="" />
          <a
            href="https://calendar.google.com/calendar/selfsched?sstoken=UU05V0VQVklhRkVYfGRlZmF1bHR8YjcyNGRkZDEwNmQwMzg2NWJlMzBmYTI5ZjMyN2IxMzA"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="text">
              Agendá <br /> una reunión
            </Button>
          </a>
        </div>
      </Row>
    </>
  );
};
export default Contactanos;
