import { useState } from 'react';
import {Form, Button, Container, Alert} from 'react-bootstrap';
import { useCallback } from 'react';
import clientAxios from '../config/clientAxios';
import { useHistory } from 'react-router-dom';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      return;
    }
    resetPassword();
    setSuccess(true);
    setTimeout(() => {
      history.push('/');
    }, 2000);
  }
  
  const resetPassword = useCallback( async () => {
    try {
      const response = await clientAxios.post('/reset', { "email": email })
      console.log(response.data);
    } catch (error) {
      console.log(error)
    }
    },
    [email],
  )
  
  return (
    <>
      <Container className="resetPass">
        {
          success ? <Alert variant={'success'}>Email enviado, verifique su casilla de correo</Alert> : null
        }
        <div className="text-center">
        <h4>Recuperar Contraseña</h4>
        <p>Ingrese un email válido y le llegará un link de recuperación</p>
        </div>
          <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" 
                placeholder="Ingrese su email" 
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                />
              </Form.Group>
              <div className="d-grid gap-2">
              <Button variant="secondary" type="submit" className="btn btn-sm">
                Enviar
              </Button>
              </div>
          </Form>
        </Container>
    </>
  )
}
