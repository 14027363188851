import { useState, useEffect, useCallback } from 'react';
import { Button, Input, Select, Row, Switch } from 'antd';
import { errorMessage } from '../../../utils/messageHelper/messageHelper';
import { PlusCircleOutlined } from '@ant-design/icons';
import ModalAddUser from './ModalAddUser';
import ModalDetailUser from './ModalDetailUser';
import clientAxios from '../../../config/clientAxios';
import TableUsers from './TableUsers';
import ModalExportCsv from './ModalExportCSV';
import { sortWord } from '../../../utils/sortWordHelper/sortWordHelper';
import styles from '../../../styles/panelAdmin/adminUsers.module.css';

const { Search } = Input;
const { Option } = Select;

const Users = () => {
  const [dataUsers, setDataUsers] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterByName, setFilterByName] = useState('');
  const [filterByRole, setFilterByRole] = useState('');
  const [filterNewsletter, setFilterNewletter] = useState(null);
  const [filterByProyect, setFilterByProyect] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleDetailModal, setToggleDetailModal] = useState(false);
  const [modalCSV, setModalCSV] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [proyects, setProyects] = useState([]);
  
  const roles = ['Admin', 'Contacto', 'Lead', 'Inversor'];

  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await clientAxios.get('/users');
      const usersActive = response.data.filter((user) => user.active !== false);
      setDataUsers(usersActive);
    } catch (error) {
      errorMessage('Datos no disponibles');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    const createArrayProyects = () => {
      const dataUsersCopy = dataUsers?.map((data) => ({ ...data }));
      const investmentsProyects = dataUsersCopy?.flatMap((user) =>
        user?.investments?.map((investment) => ({
          name: investment?.name,
          id: investment._id,
        }))
      );
      const proyectsNoRepeat = Array.from(new Set(investmentsProyects));
      const ordererProyects = sortWord(proyectsNoRepeat);
      setProyects(ordererProyects);
    };
    createArrayProyects();
  }, [dataUsers]);

  useEffect(() => {
    let data = dataUsers;
    const searchByname = (data) => {
      if (filterByName === '') {
        return data;
      } else {
        return data?.filter(({ firstName, lastName }) => {
          const userFullname = firstName + ' ' + lastName;
          return userFullname
            ?.toLowerCase()
            .includes(filterByName.toLowerCase());
        });
      }
    };
    const filterRole = (data) => {
      if (filterByRole === '') {
        return data;
      } else {
        return data?.filter(({ role }) => role?.includes(filterByRole));
      }
    };
    const filterByNewsletter = (data) => {
      if (filterNewsletter === null) {
        return data;
      } else {
        return data?.filter(
          ({ newsletter }) => newsletter === filterNewsletter
        );
      }
    };
    const filterProyect = (data) => {
      if (filterByProyect === '') {
        return data;
      } else {
        const filter = data?.filter((user) =>
          user?.investments?.some(
            (investment) => investment?.name === filterByProyect
          )
        );
        return filter;
      }
    };
    data = searchByname(data);
    data = filterRole(data);
    data = filterByNewsletter(data);
    data = filterProyect(data);
    setDataFiltered(data);
  }, [
    dataUsers,
    filterByRole,
    filterNewsletter,
    filterByProyect,
    filterByName,
  ]);

  const toggleSwitchNewsletter = () => {
    setFilterNewletter(null);
    setDisabled(!disabled);
  };
  const handleToggleAddUser = () => setToggleModal((state) => !state);
  const handleToggleDetailModal = () => setToggleDetailModal((state) => !state);
  const handleToggleExportCSV = () => setModalCSV((state) => !state);
  const handleFilterName = (value) => setFilterByName(value);
  const handleFilterNewsletter = (value) => setFilterNewletter(value);
  const handleFilterRole = (value) => setFilterByRole(value);
  const handleFilterProyect = (value) => setFilterByProyect(value);

  return (
    <>
      <h3>Usuarios</h3>
      <Row className={styles.divFilters}>
          <Select
            defaultValue=""
            style={{ width: 120, marginRight: '30px' }}
            onChange={handleFilterRole}
          >
            <Option value="">Rol</Option>
            {roles.map(( role ) => (
              <Option key={role} value={role}>{role}</Option>
            ))}
          </Select>
          {
            !!proyects.length && <Select
              defaultValue=""
              style={{ width: 120, marginRight: '30px' }}
              onChange={handleFilterProyect}
            >
              <Option value="">Proyecto</Option>
              {proyects?.map(({ name, id }) => (
                <Option key={id} value={name}>{name}</Option>
              ))}
            </Select>
          }
          <Search
            placeholder="Buscar"
            onSearch={handleFilterName}
            style={{ width: 200 }}
          />
          <Button
            style={{ marginLeft: '30px' }}
            onClick={handleToggleExportCSV}
          >
            Exportar CSV
          </Button>
      </Row>
      <Row className={styles.divFilters} >
          <Button
            icon={<PlusCircleOutlined />}
            onClick={handleToggleAddUser}
            className={styles.btnAddUser}
          >
            Agregar usuario
          </Button>
          <Switch disabled={disabled} onChange={handleFilterNewsletter} className={styles.btnSwitch}/>
          <Button
            type="primary"
            onClick={toggleSwitchNewsletter}
            className={styles.btnNewsletter}
          >
            Newsletter
          </Button>
          <p className={styles.pUsers}>Total: {dataFiltered.length}</p>
      </Row>
      <ModalAddUser
        dataUsers={dataUsers}
        setDataUsers={setDataUsers}
        handleToggleAddUser={handleToggleAddUser}
        toggleModal={toggleModal}
      />
      <ModalDetailUser
        handleToggleDetailModal={handleToggleDetailModal}
        toggleDetailModal={toggleDetailModal}
        dataModal={dataModal}
      />
      <ModalExportCsv
        roles={roles}
        modalCSV={modalCSV}
        handleToggleExportCSV={handleToggleExportCSV}
      />
      <TableUsers
        dataUsers={dataUsers}
        setDataUsers={setDataUsers}
        filters={dataFiltered}
        isLoading={isLoading}
        handleToggleDetailModal={handleToggleDetailModal}
        setDataModal={setDataModal}
      />
    </>
  );
};
export default Users;
