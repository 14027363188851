export const initialState = {
  appreciation: 0,
  attachments: {
    backgroundImg: '',
    contracts: [],
    downloadFiles: [],
    featuredImg: '',
    galleryImg: [],
    sponsorLogo: '',
    termAndConditions: '',
    videos: [],
  },
  city: [],
  complementaryInformation: {
    distributionPeriod: '',
    finishDate: '',   
    maxAmount: 0,
    minAmount: 0,
    reportDate: '',
    reportName: '',
    signer: '',
    startDate: '',
    urlLastReport: '',
  },
  bank: null,
  country: [],
  investmentType: '',
  irr: 0,
  isFeatured: false,
  isOpen: true,
  isPosted: false,
  minInvestment: 0,
  investmentStatus:0,
  name: '',
  networth: 0,
  proyect: '',
  quantity: 0,
  quantityTag: '',
  revenue: '',
  sections: [],
  sponsor: '',
  subtitle: '',
  termInYears: 0,
  youtubeVideo: '',
};
