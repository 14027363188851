import { Row, Spin } from 'antd';
import styles from './loading.module.css';

const LoadingInvestorRoom = ({ isLoading, children }) => {
  if (isLoading)
    return (
      <Row align="middle" justify="center">
        <div className={styles.loadingSpiner}>
          <Spin />
        </div>
      </Row>
    );
  return children;
};

export default LoadingInvestorRoom;
