import style from '../../styles/AboutUs/aboutUs.module.css';
import { Row, Col } from 'antd';
import { Column } from '@ant-design/charts';
import img1 from '../../img/imgAboutUs/rent_d.png';
import img2 from '../../img/imgAboutUs/rent_b.png';
import img3 from '../../img/imgAboutUs/rent_c.png';
import img4 from '../../img/imgAboutUs/rent_f.png';
import img5 from '../../img/imgAboutUs/rent_a.png';


const data = [
  { date: '2015/16', 'Porcentaje': 34 },
  { date: '2017/18', 'Porcentaje': 28 },
  { date: '2019/20', 'Porcentaje': 24 },
  { date: '2021/22', 'Porcentaje': 30 },
];

const chartSetting = {
  data,
  xField: 'date',
  yField: 'Porcentaje',
  color: '#142054',
  label: {
    position: 'top',
    content: (record) => record['Porcentaje'] + '%',
    style: {
      fill: 'black',
      fontSize: 17,
      fontFamily: 'arial',
      fontWeight: 'bold',
    }
  },
  minColumnWidth: 50,
  maxColumnWidth: 50,
};

export default function TrackRecord() {

  const imgs = [img1, img2, img3, img4, img5];

  return (
    <>
      <div className='container px-0 border shadow'>
        <Row className={style.rowMission} id="sectionMision">
          <h3 className={style.titleMission}>Track record</h3>
        </Row>
        <Row className="mt-5">
          <Col span={12} xs={24} md={12} className='d-flex align-items-center pe-4 mb-5'>
            <div className='container pe-0 ps-4 pt-1'>
              <Column {...chartSetting} style={{ height: '14rem' }} />
            </div>
          </Col>
          <Col span={12} xs={24} md={12} className='mb-5'>
            <div
              style={{ borderRadius: '20px' }}
              className="ms-0 me-5 pt-0 pb-4 text-center"
            >
              <h3 className="text-start mb-0 ps-4 fw-bold" >RENTABILIDAD ANUAL</h3>
              <h4 className="text-start ps-4 fw-bold" >(2015 - 2022)</h4>
              <div className="container mx-0">
                <div className="row mx-o">
                  {
                    imgs.map((img, i) => (
                      <div key={i} className="col px-0 mt-4">
                        <img className='img-fluid' src={img} alt="" />
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
