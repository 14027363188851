import { useState, useEffect } from 'react';
import { Row, Divider } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import LoadingInvestorRoom from 'components/investor-room/components/loadingComponent/LoadingInvestorRoom';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import NotificationsTable from './NotificationsTable';

const Novelties = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const userNotificationsRoute = '/user/notifications';
  const errorGeneral = 'Los datos no estan disponibles';

  useEffect(() => {
    const fetchUserNotifications = async () => {
      try {
        setIsLoading(true);
        const { data } = await clientAxios.get(userNotificationsRoute);
        const notificationsData = data;
        if (notificationsData.length === 0) {
          setNotifications([]);
        }
        setNotifications(notificationsData);
        updateViewedNotifications(notificationsData);
      } catch (error) {
        setNotifications([]);
        errorMessage(errorGeneral);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserNotifications();
  }, []);

  const updateViewedNotifications = async (data) => {
    const notifications = data
      .filter((notification)=> notification.active === true)
      .map((notification)=> ({_id: notification._id}));
    if (notifications.length > 0) {
      await clientAxios.patch(userNotificationsRoute, { notifications })  
    }
  };

  return (
    <div className='mb-5'>
      <Row align="middle">
        <NotificationOutlined
          style={{
            fontSize: 40,
            color: '#08c',
            marginRight: 10,
            marginBottom: 21,
          }}
        />
        <h3>Novedades</h3>
      </Row>
      <Divider />
      <LoadingInvestorRoom isLoading={isLoading}>
        <NotificationsTable notifications={notifications} />
      </LoadingInvestorRoom>
    </div>
  );
};

export default Novelties;
