import { Row, Col, Tabs } from 'antd';
import { getLocalStorage } from '../../../utils/localStorageHelper/localHelper';
import { useEffect, useState } from 'react';
import ActivesTable from './Tables/ActivesTable';
import InactivesTable from './Tables/InactivesTable';
import OfferorsTable from './Tables/OfferorsTable';
import ToValidateTable from './Tables/ToValidateTable';

const { TabPane } = Tabs;

const Investors = () => {
  const [dataUser, setDataUser] = useState({});

  useEffect(() => {
    setDataUser(getLocalStorage('user'));
  }, []);

  return (
    <div>
      <Row>
        <Col span={24}>
          <h3>Inversores</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="Activos">
            <TabPane tab="Activos" key="Activos">
              <ActivesTable dataUser={dataUser} />
            </TabPane>
            <TabPane tab="Inactivos" key="Inactivos">
              <InactivesTable dataUser={dataUser} />
            </TabPane>
            <TabPane tab="Contactos Oferentes" key="Contactos Oferentes">
              <OfferorsTable dataUser={dataUser} />
            </TabPane>
            <TabPane tab="Contactos a validar ID" key="Contactos a validar ID">
              <ToValidateTable dataUser={dataUser} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Investors;
