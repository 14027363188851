import React, { useState } from 'react';
import { Col, Divider, Row, Tabs, DatePicker } from 'antd';
import UserReports from './optionsReports/UserReports';
import InvestmentReports from './optionsReports/InvestmentReports';
import LastVisitsReport from './optionsReports/LastVisitsReport';
import { getTodayDate, getOneMonthLess } from 'utils/getTodayDate/getTodayDate';
import {
  investmentCardLastVisitsColumn,
  articleLastVisitsColumn,
  lastLoginColumn,
  pendingOffersColumn,
} from './columns';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const dateFormat = 'YYYY/MM/DD';

const initialValue = [
  moment(getOneMonthLess()),
  moment(getTodayDate(), dateFormat),
];

const GenerateReports = () => {
  const [rangePicker, setRangePicker] = useState(initialValue);

  const handleRangePicker = (value) => {
    const [startDate, endDate] = [...value];
    const fromDate = startDate.date(1);
    const toDate = endDate.endOf('month');
    setRangePicker([fromDate, toDate]);
  };

  return (
    <>
      <Row>
        <Col span={24} lg={{ span: 3 }}>
          <h3>Reportes</h3>
        </Col>
        <Col span={24} lg={{ span: 21 }}>
          <RangePicker
            picker="month"
            defaultValue={initialValue}
            onChange={handleRangePicker}
          />
        </Col>
        <Divider />
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Reporte por inversión" key="1">
              <InvestmentReports rangePicker={rangePicker} />
            </TabPane>
            <TabPane tab="Reporte por usuarios" key="2">
              <UserReports rangePicker={rangePicker} />
            </TabPane>
            <TabPane tab="Últimas visitas de tarjetas de inversión" key="3">
              <LastVisitsReport
                key={'lastInvestmentReport'}
                columns={investmentCardLastVisitsColumn}
                isPagination={true}
                rangePicker={rangePicker}
                exportFileName='Últimas_visitas_de_tarjetas_de_inversión'
                queryParams={{
                  contentType: 'INVESTMENT',
                }}
              />
            </TabPane>
            <TabPane tab="Últimas visitas de Articulos" key="4">
              <LastVisitsReport
                key={'lastArticlesReport'}
                columns={articleLastVisitsColumn}
                isPagination={true}
                rangePicker={rangePicker}
                exportFileName='Últimas_visitas_de_Articulos'
                queryParams={{
                  contentType: 'ARTICLE',
                }}
              />
            </TabPane>
            <TabPane tab="Últimas conexiones de usuarios" key="5">
              <LastVisitsReport
                columns={lastLoginColumn}
                endpoint="/users"
                isPagination={true}
                rangePicker={rangePicker}
                key={'lastLoginReport'}
                exportFileName='Últimas_conexiones_de_usuarios'
                queryParams={{
                  sortBy: { lastLogIn: '-1' },
                }}
                responseName="users"
              />
            </TabPane>
            <TabPane tab="Inversiones no concretadas" key="6">
              <LastVisitsReport
                columns={pendingOffersColumn}
                endpoint="/offers"
                isPagination={true}
                rangePicker={rangePicker}
                key={'pendingInvestmentsReport'}
                exportFileName='Inversiones_no_concretadas'
                queryParams={{
                  isPending: true,
                  sortBy: { createdAt: '-1' },
                }}
                responseName="offers"
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default GenerateReports;
