import { dateFormat } from 'utils/dateFormatHelper/dateFormat';

export const TableHeadingsLastConection = [
  {
    title: 'Fecha de ult. conexion',
    dataIndex: 'lastLogIn',
    key: 'lastLogIn',
    enable: true,
    render: (key) => {
      const createdAtNewFormat = dateFormat(key);
      return createdAtNewFormat;
    },
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
];
