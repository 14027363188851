import React from 'react';
import { Row, Radio } from 'antd';
import { BankOutlined, DollarOutlined } from '@ant-design/icons';
import styles from './options.module.css';

const Options = ({ handleOptions }) => {
  return (
    <Row justify="center" align="middle">
      <Radio.Group
        defaultValue={0}
        style={{ padding: '30px' }}
        onChange={handleOptions}
      >
        <Radio.Button
          className={styles.optionsBank}
          style={{
            width: '250px',
            height: '250px',
            borderRadius: '20px',
            marginLeft: '10px',
          }}
          value={0}
        >
          <p className={styles.textsOptions}>
            DOMESTICA
          </p>
          <BankOutlined style={{ fontSize: '40px', marginRight: '20px' }} />
          Transferencias desde Cta USA a Cta USA
        </Radio.Button>
        <Radio.Button
          className={styles.optionsBank}
          style={{
            width: '250px',
            height: '250px',
            borderRadius: '20px',
            marginLeft: '10px',
          }}
          value={1}
        >
          <p className={styles.textsOptions}>
            INTERNACIONAL
          </p>
          <BankOutlined style={{ fontSize: '40px', marginRight: '20px' }} />
          Transferencias desde Ctas fuera de USA a Ctas de USA
        </Radio.Button>
        <Radio.Button
          className={styles.optionsBank}
          style={{
            width: '250px',
            height: '250px',
            borderRadius: '20px',
            marginLeft: '10px',
          }}
          value={2}
        >
          <DollarOutlined style={{ fontSize: '40px', marginRight: '20px' }} />
          Otro medio de pago
        </Radio.Button>
      </Radio.Group>
    </Row>
  );
};

export default Options;
