import { Menu, Button, Drawer } from 'antd';
import { useState } from 'react';
import { UserOutlined, ExportOutlined, ToolOutlined, NotificationOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from 'styles/theNavbar/theNavbar.module.css';
import { isUserRoleAdmin } from 'utils/isUserRoleAdminHelper/isUserRoleAdmin';

const TheNavbarMobile = ({
  user,
  handleToggleRegister,
  handleToggleLogin,
  logout,
  notification
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const handleShowDrawer = () => setShowDrawer((state) => !state);
  const { SubMenu } = Menu;

  const showRegister = () => {
    handleShowDrawer();
    handleToggleRegister();
  };
  const showLogin = () => {
    handleShowDrawer();
    handleToggleLogin();
  };
 
  return (
    <div>
      <Button
        type="link"
        onClick={handleShowDrawer}
        className="btn-bars-mobile"
      >
        <i className="fas fa-bars"></i>
      </Button>

      <Drawer
        title="Totallia"
        placement="left"
        closable={handleShowDrawer}
        onClose={handleShowDrawer}
        visible={showDrawer}
        key="left"
        id="drawerNav"
      >
        <Menu mode="inline">
          <Menu.Item key="inicio">
            <Link to="/" className="items-navbar">
              INICIO
            </Link>
          </Menu.Item>
          <Menu.Item key="inversiones">
            <Link to="/investments" className="items-navbar">
              INVERSIONES
            </Link>
          </Menu.Item>
          <Menu.Item key="aprender">
            <Link to="/aprender" className="items-navbar">
              NOTICIAS
            </Link>
          </Menu.Item>
          <Menu.Item key="nosotros">
            <Link to="/aboutUs" className="items-navbar">
              NOSOTROS
            </Link>
          </Menu.Item>
          <Menu.Item key="contacto">
            <Link to="/contacto" className="items-navbar">
              CONTACTO
            </Link>
          </Menu.Item>
          {!user && (
            <>
              <Menu.Item key="registrase">
                <Button
                  type="link"
                  className="items-navbar btn-nav-mobile"
                  onClick={showRegister}
                >
                  REGISTRARSE
                </Button>
              </Menu.Item>
              <Menu.Item key="ingresar">
                <Button
                  type="link"
                  className="items-navbar btn-nav-mobile"
                  onClick={showLogin}
                >
                  INGRESAR
                </Button>
              </Menu.Item>
            </>
          )}
          {user && (
            <SubMenu
              key="sub1"
              className="items-navbar btn-nav-mobile"
              title={ !notification 
                ? <span>{user.firstName}</span> 
                : <span>{user.firstName}<span className='text-danger'> *</span></span>}
            >
              <Link to="/investorRoom/Profile">
                <Button
                  className={styles.buttonNavbar}
                  type="primary"
                  icon={<UserOutlined />}
                >
                  Perfil <span style={{color: '#1890ff'}}>relleno-</span>
                </Button>
              </Link>
              <Link to="/investorRoom/novelties">
                <Button
                  className={ !notification 
                    ? styles.buttonNavbar 
                    : `${styles.flickered} ${styles.buttonNavbar}`
                  }
                  type="primary"
                  icon={<NotificationOutlined />}
                >
                  Notificaciones
                </Button>
              </Link>
              {isUserRoleAdmin(user) && (
                <Link to="/admin">
                  <Button
                    className={styles.buttonNavbar}
                    type="primary"
                    icon={<ToolOutlined />}
                  >
                    Panel Admin
                  </Button>
                </Link>
              )}
              <Button
                className={styles.buttonNavbar}
                icon={<ExportOutlined />}
                danger
                onClick={logout}
              >
                Cerrar Sesión
              </Button>
            </SubMenu>
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

export default TheNavbarMobile;
