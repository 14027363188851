import React from 'react';
import { Button } from 'antd';
import clientAxios from 'config/clientAxios';
import { errorMessage } from 'utils/messageHelper/messageHelper';

const ButtonExportCsv = ({
  dataToSend,
  style,
  icon = null,
  endPoint,
  nameFile,
  nameButton,
}) => {
  const exportCsv = (csv) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = nameFile;
    hiddenElement.click();
  };

  const handleDowndloadCSV = async () => {
    try {
      const response = await clientAxios.post(endPoint, dataToSend);
      exportCsv(response.data);
    } catch (error) {
      errorMessage('Hubo un error al exportar los datos');
    }
  };
  return (
    <Button className={style} onClick={handleDowndloadCSV} icon={icon}>
      {nameButton}
    </Button>
  );
};

export default ButtonExportCsv;
