import React from 'react';
import { Modal } from 'antd';

const ModalPreview = ({ previewVisible, previewImage, handleModal }) => {
  return (
    <Modal
      visible={previewVisible}
      footer={null}
      onCancel={handleModal}
      width={1000}
    >
      <img alt="example" style={{ width: '95%' }} src={previewImage} />
    </Modal>
  );
};

export default ModalPreview;
