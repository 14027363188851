import { Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import style from '../../styles/homePage/trackRecord.module.css';

const TrackRecords = ({ settings, isLoading }) => {

  const thousandsSeparator = (num) => {
    let aux = String(num).split(); 
    aux[0] = aux[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return aux.join()
  }

  return (
    <Row className={style.container}>
      {isLoading ? (
        <>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
          <h4 className={style.texth4}> Cargando Track records </h4>
        </>
      ) : (
        <>
          <Col lg={6} md={12} sm={24}>
            <h4 className={style.texth4}>
              +U$<span>{settings?.mmInverted} </span>MM INVERTIDOS
            </h4>
            <h6 className={style.texth6}>en diferentes proyectos</h6>
          </Col>
          <Col lg={6} md={12} sm={24} className={style.suscriptores}>
            <h4 className={style.texth4}>
              +<span>{thousandsSeparator(settings?.suscriptors)} </span>SUSCRIPTORES
            </h4>
            <h6 className={style.texth6}>suscriptos digitales activos</h6>
          </Col>
          <Col lg={6} md={12} sm={24} className={style.inversores}>
            <h4 className={style.texth4}>
              +<span>{settings?.investors} </span>INVERSORES
            </h4>
            <h6 className={style.texth6}>
              participaron de <br></br>
              nuestros proyectos
            </h6>
          </Col>
          <Col lg={6} md={12} sm={24} className={style.proyectos}>
            <h4 className={style.texth4}>
              +<span>{settings?.proyect} </span>PROYECTOS
            </h4>
            <h6 className={style.texth6}>fondeados</h6>
          </Col>
        </>
      )}
    </Row>
  );
};
export default TrackRecords;
