import { Button } from 'antd';
import { dateFormat } from '../../../../../utils/dateFormatHelper/dateFormat';

export const tableColumns = (handleInvestorProfile) => [
  {
    title: 'Fecha de Creación',
    dataIndex: 'createdAt',
    key: 'createdAt',
    enable: true,
    render: (key) => {
      const createdAtNewFormat = dateFormat(key);
      return createdAtNewFormat;
    },
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName',
    enable: true,
    render: (key, data) => {
      return (
        <Button type="link" onClick={() => handleInvestorProfile(data.uid)}>
          {key}
        </Button>
      );
    },
  },
  {
    title: 'Apellido',
    dataIndex: 'lastName',
    key: 'lastName',
    enable: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    enable: true,
  },
  {
    title: 'Tel.',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    enable: true,
  },
  {
    title: 'Cant. Inv. Activas',
    dataIndex: 'investments',
    key: 'investmentsQty',
    enable: true,
    render: (key) => {
      const activeInvestments = key?.length;
      return activeInvestments;
    },
  },
  {
    title: 'Monto Invertido',
    dataIndex: 'moneyInvested',
    key: 'moneyInvested',
    enable: true,
  },
  {
    title: 'Inversiones',
    dataIndex: 'investments1',
    key: 'investments',
    enable: true,
  },
  {
    title: 'Distrib. Totales',
    dataIndex: 'totalDistributions',
    key: 'totalDistributions',
    enable: true,
  },
  {
    title: 'Newsletter',
    dataIndex: 'newsletter',
    key: 'newsletter',
    enable: true,
    render: (key) => {
      const newsletterState = key ? 'Si' : 'No';
      return newsletterState;
    },
  },
];
