import React from 'react';
import { Input, Form } from 'antd';

function BankUsaForm(form) {
  return (
    <div>
      <Form.Item label="Nombre del banco" name="bankEntity" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.bankEntity}/>
      </Form.Item>
      <Form.Item label="Dirección del banco (calle, nro, codigo postal, ciudad, país)" name="addressBankEntity" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.addressBankEntity}/>
      </Form.Item>
      <Form.Item label="Numero de la cuenta" name="numberBankEntity" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.numberBankEntity}/>
      </Form.Item>
      <Form.Item label="Numero de routing / ABA" name="ABA" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.ABA}/>
      </Form.Item>
      <Form.Item label="Titular de la cuenta" name="beneficiaryName" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.beneficiaryName}/>
      </Form.Item>
      <Form.Item label="Dirección del titular (calle, nro, codigo postal, ciudad, país)" name="addressBeneficiary" rules={[{ required: true }]}>
        <Input className="prefix-width-modal" initialValue={form?.addressBeneficiary}/>
      </Form.Item>
      <Form.Item label="Comentarios / aclaraciones / consultas" name="commentsUSA">
        <Input className="prefix-width-modal" initialValue={form?.commentsUSA}/>
      </Form.Item>
    </div>
  );
}

export default BankUsaForm;