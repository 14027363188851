import { useEffect, useState } from 'react';
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Tabs } from 'antd';
import { errorMessage } from '../../../../utils/messageHelper/messageHelper';
import { useHistory, useParams } from 'react-router-dom';
import clientAxios from '../../../../config/clientAxios';
import GeneralInformation from './generalInformation/GeneralInformation';
import InvestmentProfile from './investment/InvestmentProfile';
import AccountStatus from './accountStatus/AccountStatus';

const { TabPane } = Tabs;

const InvestorProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [updateData, setUpdateData] = useState(false)

  const history = useHistory();
  const { uid } = useParams();

  const handleUpdateData = () => updateData ? setUpdateData(false) : setUpdateData(true);

  useEffect(() => {
    const handleGetUserData = async () => {
      try {
        setIsLoading(true);
        const response = await clientAxios.get(`/users/${uid}`);
        const userResponse = response.data[0];
        setUserData(userResponse);
      } catch (error) {
        errorMessage('Los datos no estan disponibles');
      } finally {
        setIsLoading(false);
      }
    };
    handleGetUserData();
  }, [uid, updateData]);

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 24 }} spin />;
  }

  return (
    <div>
      <Row>
        <Col span={12}>
          <h3>Perfil del inversor</h3>
        </Col>
        <Col span={12}>
          <ArrowLeftOutlined
            className="btn-Back-inver"
            onClick={() => history.goBack()}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="InfoGral">
            <TabPane tab="Info Gral" key="InfoGral">
              <GeneralInformation userData={userData} />
            </TabPane>
            <TabPane tab="Inversiones" key="Inversiones">
              <InvestmentProfile 
                userData={userData} 
                handleUpdateData={handleUpdateData} />
            </TabPane>
            <TabPane tab="Estado de cuenta" key="EstadoDeCuenta">
              <AccountStatus userData={userData} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default InvestorProfile;
