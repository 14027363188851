import { Divider, Modal, Button, Popconfirm, Menu, Dropdown, Spin } from 'antd';
import { attachmentType } from 'config/attachmentType';
import { errorMessage } from 'utils/messageHelper/messageHelper';
import clientAxios from 'config/clientAxios';
import { offerStatus } from 'config/offerStatus';
import { useState, useEffect } from 'react';
import notificationPostHelper from 'utils/notificationPostHelper/notificationPostHelper';
import style from 'styles/panelAdmin/adminInvestorSection.module.css';


const OfferModal = ({
  offerModalStatus,
  handleOfferModalStatus,
  offer,
  setOffer,
  setAttachmentId,
  handleAttachmentModalStatus,
  setOfferorInvestors,
}) => {
  const [statusOffer, setStatusOffer] = useState(null);
  // eslint-disable-next-line
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAbleToInvest, setIsAbleToInvest] = useState(false);
  const [keyDocusign, setKeyDocusign] = useState(false);
  const [attachments, setAttachments] = useState(false);

  useEffect(() => {
    setStatusOffer(offer?.status);
    setIsAbleToInvest(
      offer?.attachments?.every((attachment) => attachment.isAproved)
    );

    parseAttachments()
    // eslint-disable-next-line
  }, [offer]);

  const updateOffer = async (data) => {
    console.log(data);
    const dataOffer = (data && data._id)? data : offer;
    try {
      setIsUpdating(true);
      const responseOffer = await clientAxios.put(
        `/offers/${dataOffer._id}`,
        dataOffer
      );

      if(setOfferorInvestors){
        const responseOfferors = await clientAxios.get('/users?role=Oferente');
        setOfferorInvestors(responseOfferors.data);
      }
      
      setOffer(responseOffer.data);
      handleOfferModalStatus();
    } catch (error) {
      console.log(error);
      errorMessage('Error al actualizar');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCheckbox = () => {
    setOffer({...offer, againstSignature: offer.againstSignature? false : true });
  }

  const endButton = () => {
    if(offer?.againstSignature && offer?.active && parseInt(offer?.status) !== 7){
      return (<Button
          type="primary"
          disabled={!isAbleToInvest}
          onClick={async () => {
            setIsUpdating(true);
            setOffer({ ...offer, status: "7"})
            setStatusOffer(7);
  
            await updateOffer({ ...offer, status: "7"});
            await clientAxios.post('/offers/aprove', offer);
            setIsUpdating(false);
          }}
        >
          Finalizar oferta y convertir en inversión
      </Button>)
    } else {
      return (<></>)
    }
  }

  const menuItems = [];
  for (const status in offerStatus) {
    const item = (
      <Popconfirm
        title="¿Actualizar status?"
        onConfirm={() => {
          const offerCopy = { ...offer };
          const notificationTitle = `${offer?.investment?.name}: ha cambiado su estado a "${status}"`;
          const notificationUser = offer?.user;
          offerCopy.status = status;
          setOffer(offerCopy);
          notificationPostHelper(notificationTitle, notificationUser);
          setStatusOffer(status);
        }}
        okText="Si"
        cancelText="No"
      >
        <Menu.Item key={status}>{offerStatus[status]}</Menu.Item>
      </Popconfirm>
    );
    menuItems.push(item);
  }
  const menu = <Menu>{menuItems}</Menu>;

  const handleContract = async (attachment) => {
    // console.log(attachment);
    if(!keyDocusign){
      const response = await clientAxios.get(
        `/attachments/private/${attachment._id}`
      );
      const data = response.data;
      if(data.name === "envelopeId"){
        setKeyDocusign(data.key);
      }
    }
  }

  const parseAttachments = () => {
    const attachmentList = offer?.attachments?.map((attachment) => {
      if(attachment.name === "Contrato") 
        handleContract(attachment);
  
      const att = (
        <li key={attachment?._id}>
          
          <Button
            onClick={() => {
              setAttachmentId(attachment?._id);
              handleAttachmentModalStatus();
            }}
            type="primary"
            style={{ border: 'none', margin: 3 }}
          >
            Ver {attachment?.name}
          </Button>
          {attachment.toValidate ? (
            <>
              <Popconfirm
                title="¿Seguro que desea aprobar este documento?"
                onConfirm={() => {
                  const offerCopy = offer;
                  const statusToAprove = attachmentType[attachment?.name];
                  offerCopy[statusToAprove] = true;
                  if(attachment.name === "Contrato") {
                    offerCopy.status = 1;
                    setStatusOffer(1);
                  }

                  if(attachment.name === "Transferencia") {
                    offerCopy.status = 5;
                    setStatusOffer(5);
                  }
                  
                  offerCopy.attachments.filter((att) => {
                    if (att?.name === attachment?.name) {
                      const attValidate = att.toValidate = false;
                      const attAproved = att.isAproved = true;
                      return {attValidate, attAproved};
                    } else {
                      return att;
                    }
                  });
                  console.log("offerCopy", offerCopy);
                  setOffer(offerCopy);
                  parseAttachments();
                  const notificationTitle = `${offer?.investment?.name}: ${attachment?.name} ha sido aprobado`;
                  const notificationUser = offer?.user;
                  notificationPostHelper(notificationTitle, notificationUser);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type="primary"
                  style={{ background: 'green', border: 'none', margin: 3 }}
                >
                  Aprobar
                </Button>
              </Popconfirm>
  
              <Popconfirm
                title="¿Seguro que desea rechazar este documento?"
                onConfirm={() => {
                  const offerCopy = offer;
                  if(attachment.name === "Contrato") {
                    offerCopy.status = 8;
                    setStatusOffer(8);
                  }

                  if(attachment.name === "Transferencia") {
                    offerCopy.status = 9;
                    setStatusOffer(9);
                  }
                  offerCopy.attachments.filter((att) => {
                    if (att?.name === attachment?.name) {
                      return att.toValidate = false;
                    } else {
                      return att;
                    }
                  });
                  setOffer(offerCopy);
                  parseAttachments();
                  const notificationTitle = `${offer?.investment?.name}: ${attachment?.name} ha sido rechazado`;
                  const notificationUser = offer?.user;
                  notificationPostHelper(notificationTitle, notificationUser);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button
                  type="primary"
                  style={{ background: 'red', border: 'none', margin: 3 }}
                >
                  Rechazar
                </Button>
              </Popconfirm>
            </>
          ) : attachment.isAproved ? (
            <span>Aprobado</span>
          ) : (
            <span>Rechazado</span>
          )}
        </li>
      );
      return att;
    });

    setAttachments(attachmentList);
  }

  

  const documents = offer?.documentation?.map((doc) => {
    const document = (
      <li key={doc?._id}>
        <Button
          onClick={() => {
            setAttachmentId(doc?._id);
            handleAttachmentModalStatus();
          }}
          type="primary"
          style={{ border: 'none', margin: 3 }}
        >
          {doc?.name}
        </Button>
      </li>
    );
    return document;
  });

  return (
    <Modal
      title={null}
      visible={offerModalStatus}
      onCancel={() => {
        handleOfferModalStatus();
        if(setOfferorInvestors){
          setOffer(null);
        }
        
      }}
      style={{ textAlign: 'left' }}
      footer={null}
      width={800}
    >
      <h2>
        {offer?.investment?.name} - Monto ofertado: ${offer?.amountOffered}
      </h2>
      <Divider />
      <h3>Documentación</h3>
      {attachments?.length > 0 || documents?.length > 0 ? (
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
          {attachments}
          {documents}
        </ul>
      ) : (
        <p>No hay documentación</p>
      )}
      <Divider />
      <h3>Status</h3>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button type="link" className="ant-dropdown-link">
          {offerStatus[statusOffer]}
        </Button>
      </Dropdown>
      {parseInt(offer?.status) === 5 || parseInt(offer?.status) === 6 || parseInt(offer?.status) === 7? (
        <>
          <Divider />
          <h3>Link de Para contrafirma</h3>
          <p>{`https://app.docusign.com/documents/details/${keyDocusign}`}</p>
          <Divider />
          <p> Contra firma realizada? <input type="checkbox" name="" id="" checked={offer.againstSignature} onChange={handleCheckbox} /></p>
        </>
      ) : (
        <></>
      )}
      <Divider />
      {isUpdating ? <Spin /> : (<>
        <Button
          type="primary"
          className={style.btnUpdate}
          onClick={updateOffer}
        >
          Guardar
        </Button>
        {endButton()}
      </>)}
      
    </Modal>
  );
};

export default OfferModal;
